import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

interface Event {
  action: string;
  note: string;
  date?: string;
  time?: string;
  staffId?: number;
}

@Component({
  selector: 'app-encounter-status-update',
  templateUrl: './encounter-status-update.component.html',
  styleUrls: ['./encounter-status-update.component.css']
})
export class EncounterStatusUpdateComponent implements OnInit, OnDestroy {
  eForm!: FormGroup;
  isLoading: boolean = false; // Default to false to avoid UI showing loading state initially
  private destroy$ = new Subject<void>();
  cmpy: any; stf: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EncounterStatusUpdateComponent>,
    private toastr: ToastrService,
    private aSrvc: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.loadStorageData();
    this.initializeForm();
  }
  initializeForm(): void {
    this.eForm = this.fb.group({
      events: this.fb.array([])
    });
  }

  loadStorageData(): void {const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {this.stf = JSON.parse(stf);const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); 
    }
    console.log("Loaded Storage Data:",this.stf, this.cmpy);
  }

  ngOnInit(): void {
    console.log('Received encounter data:', this.data.encounter);
    this.populateInitialEvents(this.data?.encounter?.events || []);
  }
  get eventsFormArray(): FormArray { return this.eForm.get('events') as FormArray; }

  addEvent(eventData?: Event): void {
    const eventFormGroup = this.fb.group({
      action: [eventData?.action || '', Validators.required],
      note: [eventData?.note || '', Validators.required],
      date: [eventData?.date || new Date().toISOString().split('T')[0]],
      time: [eventData?.time || new Date().toLocaleTimeString(), Validators.required],
      staffId: [eventData?.staffId || this.stf.sID, Validators.required]
    });
    this.eventsFormArray.push(eventFormGroup);
  }

  populateInitialEvents(events: Event[]): void {
    if (Array.isArray(events)) {
      events.forEach(event => this.addEvent(event));
    } else {
      console.error('Invalid events data:', events);
      // Optionally handle the case where events is not an array
    }
  }

  saveEvents(): void {
    if (this.eForm.valid) {
      this.isLoading = true;
      this.dialogRef.close(this.eForm.value.events);
      this.aSrvc.updateEncntrEvents(this.data.encounter.id, this.eForm.value.events).subscribe({
        next: (result: any) => {
          console.log(result);
          this.toastr.success('Events updated successfully');
          this.dialogRef.close(result);
          this.isLoading = false;
        },
        error: (error: any) => {
          this.toastr.error('Failed to update events');
          console.error('Error updating events:', error);
          this.isLoading = false;
        }
      });
    } else {
      this.toastr.error('Please fill in all required fields');
    }
  }

  // Clean up subscription to prevent memory leaks
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
