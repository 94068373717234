<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card bg-info">
          <div class="card-body">
            <a routerLink="/cmpyAdmin/c/{{cmpy.cID}}" class="w-100 btn btn-primary">
              <h5 class="card-title">{{ cmpy.N }}</h5>
              <p class="card-text">
                <span><i class="bi bi-geo-alt"></i>{{' '+cmpy.Adr.A+', '+cmpy.Adr.A2}}<br>{{cmpy.Adr.C+', '+cmpy.Adr.S+', '+cmpy.Adr.Z }}</span><br>
                <span><i class="bi bi-telephone"></i>{{' '+cmpy.Adr.P1+', Fax:'+cmpy.Adr.F }}</span>
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let l_ of locs" (click)="showLocation(l_)">
        <div class="card bg-info">
          <div class="card-body">
            <a routerLink="/cmpyAdmin/l/{{l_.lID}}" class="w-100 btn btn-primary">
              <h5 class="card-title">{{ l_.N }}</h5>
              <p class="card-text">
                <span><i class="bi bi-geo-alt"></i>{{' '+l_.Adr.A+', '+l_.Adr.A2}}<br>{{l_.Adr.C+', '+l_.Adr.S+', '+l_.Adr.Z }}</span><br>
                <span><i class="bi bi-telephone"></i>{{' '+l_.Adr.P1+', Fax:'+l_.Adr.F }}</span>
              </p>
            </a>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let d_ of l_.Departments">
                <div class="me-auto float-start" style="width: 100%;">
                  <span style="font-weight: bold;"><i class="bi bi-geo-alt"></i>{{' '+d_.N+', '+d_.Adr.A2}}</span><br>
                  <a routerLink="/cmpyAdmin/d/{{l_.lID}}-{{d_.dID}}" class="btn btn-light" role="button">
                    <span><i class="bi bi-person-gear"></i> Healthcare Contact : {{d_.hcContact}}</span><br>
                    <span><i class="bi bi-person-gear"></i> Administration Contact: {{d_.adminContact}}</span><br>
                    <span><i class="bi bi-people"></i> Staff: {{d_.staffCnt}}</span><br>
                  </a>

                  <div style="width:100%;padding:.125rem;" *ngIf="uSrvc.isArray(d_.GroupSessions)">
                    <h5 style="margin:0;padding:0;">Group Therapy Sessions:</h5>
                    <ul class="list-group" class="session">
                      <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let g_ of d_.GroupSessions">
                        <div class="me-auto float-start" style="width: 100%;">
                          <a routerLink="/cmpyAdmin/{{l_.lID}}-{{d_.dID}}" class="btn btn-light" role="button">
                            <span style="font-weight: bold;"><span>{{g_.N}}</span><br>
                            <span><i class="bi bi-bookmark"></i> {{g_.T}}</span><br>
                            <span *ngFor="let c_ of g_.Coordinators"><i class="bi bi-person-gear"></i> Coordinator: {{c_.sID}}</span><br>
                            <span><i class="bi bi-geo-alt"></i> {{g_.Location.N}},  {{g_.Location.A}},  {{g_.Location.A2}}</span><br>
                            <span><i class="bi bi-calendar-event"></i> Date: {{g_.Schedule.sDate}} to {{g_.Schedule.eDate}}</span><br>
                            <span><i class="bi bi-alarm"></i> Time: {{g_.Schedule.sTime | date: 'hh:mm a'}} to {{g_.Schedule.eTime | date: 'hh:mm a'}}</span><br>
                            <span></span>Duration: {{g_.Schedule.duration}} minutes, Frequency: {{g_.Schedule.frequency}}</span><br>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>