<div class="container-fluid"><h3>{{loc.N }}</h3>
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let d_ of dpts" (click)="showDepartment(d_)">
        <div class="card bg-info">
          <div class="card-body">
            <a routerLink="/patients/{{lID}}-{{d_.dID}}" class="w-100 btn btn-primary">
                <h5 class="card-title">{{ d_.N }}</h5>
                <p class="card-text"><i class="bi bi-geo-alt"></i> {{d_.Adr.A+', '+d_.Adr.A2+', '+d_.Adr.C+', '+d_.Adr.S+', '+d_.Adr.Z }}</p>
                <p class="card-text"><i class="bi bi-telephone"></i> {{d_.Adr.P1+', Fax:'+d_.Adr.F }}</p>
                <p class="card-text"><i class="bi bi-calendar-date"></i> {{'Appointmens: '+(d_.Apmts-d_.ApmtsCmp)+' ['+d_.ApmtsCmp+']'}}</p>
                <p class="card-text"><i class="bi bi-alert"></i>{{'Alerts: '+d_.Alert }}</p>
               </a>
          </div>
          <div style="width:100%;padding:.125rem;" *ngIf="uSrvc.isArray(d_.group_sessions)">
            <ul class="list-group" class="session">
              <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let g_ of d_.group_sessions">
                <div class="me-auto float-start" style="width: 100%;">
                  <a routerLink="/sessions/{{lID}}-{{d_.dID}}-{{g_.gID}}" class="w-100 btn" role="button">
                    <span style="font-weight: bold;"><span>{{g_.N}}</span><br>
                    <span><i class="bi bi-bookmark"></i> {{g_.Type}}</span><br>
                    <span *ngFor="let c_ of g_.Coordinators"><i class="bi bi-person-gear"></i> Coordinator: {{c_.sID}}</span><br>
                    <span><i class="bi bi-geo-alt"></i> {{g_.Location.N}},  {{g_.Location.A}},  {{g_.Location.A2}}</span><br>
                    <span><i class="bi bi-calendar-event"></i> Date: {{g_.Schedule.sDate}} to {{g_.Schedule.eDate}}</span><br>
                    <span><i class="bi bi-alarm"></i> Time: {{g_.Schedule.sTime | date: 'hh:mm a'}} to {{g_.Schedule.eTime | date: 'hh:mm a'}}</span><br>
                    <span></span>Duration: {{g_.Schedule.duration}} minutes, Frequency: {{g_.Schedule.frequency}}</span><br>

            <!--span><i class="bi bi-people"></i> Capacity: {{g_.MaxSeats}} |  Occupancy: {{g_.Occupancy}} | Wait List: {{g_.Waiting}}</span><br!-->
                  </a>
                </div>
              </li>
            </ul>
          </div>




        </div>
      </div>
    </div>
</div>