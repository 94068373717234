<section class="vh-100" style="background-color: #fbfdfc;">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col col-xl-10">
                <div class="card">
                    <div class="row g-0" style="background-color:#fbfdfc;border-radius: 1rem;">
                        <div class="col-md-6 col-lg-5 d-none d-md-block">
                            <img src="../../../assets/_images/imsMPCLogo1.jpg" alt="login form" class="img-fluid"
                                style="border-radius: 9rem;scale: 1.25;float: left;position: relative;padding: 3rem;" />
                        </div>
                        <div class="col-md-6 col-lg-7 d-flex align-items-center">
                            <div class="card-body p-4 p-lg-5 text-black">
                                <form class="form-signin" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                    <h3 class="h3 mb-3 font-weight-normal text-center">Register User</h3>
                                    <!-- Errors -->
                                    <div *ngIf="errors?.fN" class="alert alert-danger mt-3">
                                        {{ errors?.fN }}
                                    </div>
                                    <div *ngIf="errors?.lN" class="alert alert-danger mt-3">
                                        {{ errors?.lN }}
                                    </div>
                                    <div *ngIf="errors?.E" class="alert alert-danger mt-3">
                                        {{ errors?.E }}
                                    </div>
                                    <div *ngIf="errors?.pswrd" class="alert alert-danger mt-3">
                                        {{ errors?.pswrd }}
                                    </div>
                                    <div *ngIf="errors?.pswrdC" class="alert alert-danger mt-3">
                                        {{ errors?.pswrdC }}
                                    </div>
                                    <!-- Signup form -->
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input type="text" class="form-control" formControlName="fN" />
                                    </div>
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input type="text" class="form-control" formControlName="lN" />
                                    </div>
                                    <div class="form-group">
                                        <label>Email address</label>
                                        <input type="email" class="form-control" formControlName="E" />
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input type="password" class="form-control" formControlName="pswrd" />
                                    </div>
                                    <div class="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" class="form-control"
                                            formControlName="pswrdC" />
                                    </div>
                                    <button type="submit" class="btn btn-block btn-primary">
                                        Register User
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>