<div class="container-fluid" style="padding:0.0625rem;">
    <form id="eForm" type="text/template" class="row tab-content" *ngIf="patientRdy">
        <div id="app-notes-initial-evaluation-div" class="">
            <app-notes-initial-evaluation id="initialEvaluation" class="row notePage" [patient]="patient"
                [templates]="templates" [cmpy]="cmpy" *ngIf="selectedTemplate?.id == 1"></app-notes-initial-evaluation>
        </div>
        <div id="app-notes-progress-div" class="">
            <app-notes-progress id="progress" class="row notePage" [patient]="patient" [encntr]="encntr" [cmpy]="cmpy"
                *ngIf="selectedTemplate?.id == 2"></app-notes-progress>
        </div>
        <div id="app-notes-intervention-div" class="">
            <app-notes-crisis-intervention id="crisisIntervention" class="row notePage" [patient]="patient"
                [encntr]="encntr" [templates]="templates" [cmpy]="cmpy"
                *ngIf="selectedTemplate?.id == 3"></app-notes-crisis-intervention>
        </div>
        <div id="app-notes-discharge-summary-div" class="">
            <app-notes-discharge-summary id="dischargeSummary" class="row notePage" [patient]="patient" [encntr]="encntr"
            [templates]="templates" [cmpy]="cmpy"
                *ngIf="selectedTemplate?.id == 4"></app-notes-discharge-summary>
        </div>
    </form>
</div>
