import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { HighchartsChartModule } from 'highcharts-angular';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { MaterialModule } from '../material.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SigninComponent } from './authentication/dashboard/sign-in/sign-in.component';
import { SignupComponent } from './authentication/dashboard/signup/signup.component';
import { StaffProfileComponent } from './authentication/dashboard/staff-profile/staff-profile.component';
import { AssessmentSigninComponent } from './authentication/assessment/sign-in/assessment-sign-in.component';

import { AppointmentsPageComponent } from './modules/patient/appointments-page/appointments-page.component';
import { AppointmentDetailPageComponent } from './modules/patient/appointment-detail-page/appointment-detail-page.component';
import { PatientsPageComponent } from './modules/patient/patients-page/patients-page.component';
import { PatientSummaryComponent } from './modules/patient/patient-summary/patient-summary.component';
import { AssessmentResultsComponent } from './modules/patient/assessment-results/assessment-results.component';

import { CompanyDashboardComponent } from './modules/dashboard/company-dashboard/company-dashboard.component';
import { LocationsPageComponent } from './modules/dashboard/locations-page/locations-page.component';
import { LocationDetailPageComponent } from './modules/dashboard/location-detail-page/location-detail-page.component';
import { SessionsPageComponent } from './modules/dashboard/sessions-page/sessions-page.component';
import { SessionDetailPageComponent } from './modules/dashboard/session-detail-page/session-detail-page.component';

import { NotesEncountersComponent } from './modules/patient/notes-encounters/notes-encounters.component';
import { NotesInitialEvaluationComponent } from './modules/patient/notes-initial-evaluation/notes-initial-evaluation.component';
import { NotesTreatmentPlanComponent } from './modules/patient/notes-treatment-plan/notes-treatment-plan.component';
import { NotesProgressComponent } from './modules/patient/notes-progress/notes-progress.component';
import { NotesCrisisInterventionComponent } from './modules/patient/notes-crisis-intervention/notes-crisis-intervention.component';
import { NotesDischargeSummaryComponent } from './modules/patient/notes-discharge-summary/notes-discharge-summary.component';
import { WriteupsComponent } from './modules/patient/writeups/writeups.component';
import { EncounterStatusUpdateComponent } from './modules/patient/encounter-status-update/encounter-status-update.component';
import { PatientNotesPageComponent } from './modules/patient/patient-notes-page/patient-notes-page.component';
import { ProfileEditorComponent } from './modules/patient/profile-editor/profile-editor.component';
import { DemographicComponent } from './modules/patient/demographic/demographic.component';
import { HistoryComponent } from './modules/patient/history/history.component';

import { AdherenceVisualizerDirective } from './directives/adherence-visualizer.directive';
import { AssessmentVisualizerDirective } from './directives/assessment-visualizer.directive';
import { BackgroundColorByIdDirective } from './directives/background-color-by-id.directive';

import { AppointmentsComponent } from './modules/assessments/appointments/appointments.component';
import { ConfirmationComponent } from './modules/assessments/confirmation/confirmation.component';
import { QuestionnaireComponent } from './modules/assessments/questionnaire/questionnaire.component';
import { ReviewComponent } from './modules/assessments/review/review.component';
import { DeactivationComponent } from './modules/assessments/deactivation/deactivation.component';

import { CmpyDashboardComponent } from './configurations/cmpy-dashboard/cmpy-dashboard.component';
import { CmpyConfigureComponent } from './configurations/cmpy-configure/cmpy-configure.component';
import { CompanyEditorComponent } from './configurations/company-editor/company-editor.component';
import { LocationEditorComponent } from './configurations/location-editor/location-editor.component';
import { DepartmentEditorComponent } from './configurations/department-editor/department-editor.component';
import { SessionsEditorComponent } from './configurations/sessions-editor/sessions-editor.component';
import { StaffEditorComponent } from './configurations/staff-editor/staff-editor.component';
import { EmployeesPageComponent } from './configurations/employees-page/employees-page.component';
import { EmployeeDetailPageComponent } from './configurations/employee-detail-page/employee-detail-page.component';

import { CredentialsInterceptor } from './service/credentials.interceptor';
import { PromTotalPipe } from './thePipes/prom-total.pipe';
import { PromChartGenPipe } from './thePipes/prom-chart-gen.pipe';
import { PatientDetailsPipe } from './thePipes/patient-details.pipe';
import { YearPickerComponent } from './assetComponents/year-picker/year-picker.component';
import { ConverterComponent } from './assetComponents/converter/converter.component';
import { QuestionPopupComponent } from './assetComponents/question-popup/question-popup.component';
import { LivingConditionsComponent } from './assetComponents/living-conditions/living-conditions.component';
import { MentalStatusHeaderComponent } from './assetComponents/mental-status-header/mental-status-header.component';
import { PsychiatricSymptomHeaderComponent } from './assetComponents/psychiatric-symptom-header/psychiatric-symptom-header.component';
import { PatientFilterPopupComponent } from './assetComponents/patient-filter-popup/patient-filter-popup.component';
import { SliderComponent } from './assetComponents/slider/slider.component';
@NgModule({
    declarations: [
        AppComponent,
        SigninComponent,
        SignupComponent,
        StaffProfileComponent,
        AssessmentSigninComponent,
        AppointmentsPageComponent,
        AppointmentDetailPageComponent,
        PatientsPageComponent,
        PatientSummaryComponent,
        AssessmentResultsComponent,
        CompanyDashboardComponent,
        LocationsPageComponent,
        LocationDetailPageComponent,
        SessionsPageComponent,
        SessionDetailPageComponent,
        NotesEncountersComponent,
        NotesInitialEvaluationComponent,
        NotesTreatmentPlanComponent,
        NotesProgressComponent,
        NotesCrisisInterventionComponent,
        NotesDischargeSummaryComponent,
        WriteupsComponent,
        EncounterStatusUpdateComponent,
        PatientNotesPageComponent,
        ProfileEditorComponent,
        DemographicComponent,
        HistoryComponent,
        AdherenceVisualizerDirective,
        AssessmentVisualizerDirective,
        BackgroundColorByIdDirective,
        AppointmentsComponent,
        ConfirmationComponent,
        QuestionnaireComponent,
        ReviewComponent,
        DeactivationComponent,
        CmpyDashboardComponent,
        CmpyConfigureComponent,
        CompanyEditorComponent,
        LocationEditorComponent,
        DepartmentEditorComponent,
        SessionsEditorComponent,
        StaffEditorComponent,
        EmployeesPageComponent,
        EmployeeDetailPageComponent,

        PatientFilterPopupComponent,
        PsychiatricSymptomHeaderComponent,
        MentalStatusHeaderComponent,
        PromTotalPipe,
        PromChartGenPipe,
        PatientDetailsPipe,
        YearPickerComponent,
        ConverterComponent,
        QuestionPopupComponent,
        LivingConditionsComponent,
        SliderComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HighchartsChartModule,
        DragDropModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        NgxCsvParserModule,
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(), // Add your configuration here
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !isDevMode(),
            connectInZone: true
        }),
        StoreModule.forRoot({}, {}),
        AppRoutingModule,
        MaterialModule
    ],
    providers: [
        PromTotalPipe,
        PatientDetailsPipe,
        PromChartGenPipe,
        provideHttpClient(
            withInterceptorsFromDi() // No arguments needed here
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CredentialsInterceptor,
            multi: true
        },
        provideAnimationsAsync(),
    ],
})
export class AppModule { }
