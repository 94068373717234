<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 col-md-6 card bg-info" style="padding: 0.125rem;" *ngFor="let l_ of locs" (click)="showLocation(l_)">
      <a routerLink="/location/{{l_.lID}}" class="w-100 btn btn-primary"><h5 class="card-title">{{ l_.N }}: Departments</h5>
        <p class="card-text">
          <span><i class="bi bi-geo-alt"></i>{{' '+l_.Adr.A+', '+l_.Adr.A2}}</span><br>
          <span>{{l_.Adr.C+', '+l_.Adr.S+', '+l_.Adr.Z }}</span><br>
          <span><i class="bi bi-telephone"></i>{{' '+l_.Adr.P1+', Fax:'+l_.Adr.F }}</span>
        </p>
      </a>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let d_ of l_.departments">
            <div class="me-auto float-start" style="width: 100%;">
              <span style="font-weight: bold;"><i class="bi bi-geo-alt"></i>{{' '+d_.N+', '+d_.Adr.A2}}</span><br>
              <a routerLink="/patients/{{l_.lID}}/{{d_.dID}}" class="w-100 btn btn-light" Role="button">
                <span><i class="bi bi-graph-up"></i> Encounters, Today: {{d_.ApmtsCmp}} | Avg: {{d_.ApmtsCmp}} | YTD: {{d_.ApmtsCmp}}</span><br>
                <span><i class="bi bi-graph-up"></i> Assessments, Today: {{d_.ApmtsCmp}} | Avg: {{d_.ApmtsCmp}} | YTD: {{d_.ApmtsCmp}}</span><br>
                <span><i class="bi bi-exclamation-triangle" [ngClass]="d_.Alert === 0 ?'text-success':'text-danger'"></i> Alerts: {{d_.Alert}}</span><br>
                <span><i class="bi bi-person-gear"></i> Healthcare Contact : {{d_.hcContact}}</span><br>
                <span><i class="bi bi-person-gear"></i> Administration Contact: {{d_.adminContact}}</span><br>
              </a>
              <a routerLink="/appointments/{{l_.lID}}/{{d_.dID}}" class="w-100 btn btn-light" Role="button">
                <span><i class="bi bi-calendar3"></i> Appointments: {{(d_.Apmts-d_.ApmtsCmp)+' ['+d_.ApmtsCmp+']'}}</span>
              </a>
              <div style="width:100%;padding:.125rem;" *ngIf="uSrvc.isArray(d_.group_sessions)">
                <ul class="list-group" class="session">
                  <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let g_ of d_.group_sessions">
                    <div class="me-auto float-start" style="width: 100%;">
                      <a routerLink="/sessions/{{l_.lID}}-{{d_.dID}}-{{g_.gID}}" class="w-100 btn" role="button">
                        <span style="font-weight: bold;"><span>{{g_.N}}</span><br>
                        <span><i class="bi bi-bookmark"></i> {{g_.T}}</span><br>
                        <span *ngFor="let c_ of g_.Coordinators"><i class="bi bi-person-gear"></i> Coordinator: {{c_.sID}}</span><br>
                        <span><i class="bi bi-geo-alt"></i> {{g_.Location.N}},  {{g_.Location.A}},  {{g_.Location.A2}}</span><br>
                        <span><i class="bi bi-calendar-event"></i> Date: {{g_.Schedule.sDate}} to {{g_.Schedule.eDate}}</span><br>
                        <span><i class="bi bi-alarm"></i> Time: {{g_.Schedule.sTime | date: 'hh:mm a'}} to {{g_.Schedule.eTime | date: 'hh:mm a'}}</span><br>
                        <span></span>Duration: {{g_.Schedule.duration}} minutes, Frequency: {{g_.Schedule.frequency}}</span><br>
                        <!--span><i class="bi bi-people"></i> Capacity: {{g_.MaxSeats}} |  Occupancy: {{g_.Occupancy}} | Wait List: {{g_.Waiting}}</span><br!-->
                        </a>
                      </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>







    <!--div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let l_ of locs" (click)="showLocation(l_)">
      <div class="card bg-info">
        <div class="card-body">
          <a routerLink="/loc/{{l_.lID}}" class="w-100 btn btn-primary">
            <h5 class="card-title">{{ l_.N }}</h5>
            <p class="card-text">
              <span><i class="bi bi-geo-alt"></i>{{' '+l_.Adr.A+', '+l_.Adr.A2}}</span><br>
              <span>{{l_.Adr.C+', '+l_.Adr.S+', '+l_.Adr.Z }}</span><br>
              <span><i class="bi bi-telephone"></i>{{' '+l_.Adr.P1+', Fax:'+l_.Adr.F }}</span>
            </p>
          </a>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let d_ of l_.Departments">
              <div class="me-auto float-start w-100">
                <span style="font-weight: bold;"><i class="bi bi-geo-alt"></i>{{' '+d_.N+', '+d_.Adr.A2}}</span><br>
                <a routerLink="/patients/{{l_.lID}}-{{d_.dID}}" class="w-100 btn btn-light" Role="button">
                  <span><i class="bi bi-graph-up"></i> Encounters, Today: {{d_.ApmtsCmp}} | Avg: {{d_.ApmtsCmp}} | YTD: {{d_.ApmtsCmp}}</span><br>
                  <span><i class="bi bi-exclamation-triangle" [ngClass]="d_.Alert === 0 ?'text-success':'text-danger'"></i> Alerts: {{d_.Alert}}</span>
                </a>
                <a routerLink="/appointments/{{l_.lID}}-{{d_.dID}}" class="w-100 btn btn-light" Role="button">
                  <span><i class="bi bi-calendar3"></i> Appointments: {{(d_.Apmts-d_.ApmtsCmp)+' ['+d_.ApmtsCmp+']'}}</span>
                </a>
              </div>

              <div style="width:100%;padding:.5rem;" *ngIf="d_.GroupSessions"><p style="margin:0;padding:0;">Group Therapy Sessions:</p>
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let g_ of d_.GroupSessions">
                    <div class="me-auto float-start">
                      <a routerLink="/patients/{{l_.lID}}-{{d_.dID}}" class="w-100 btn btn-light" Role="button">
                        <span style="font-weight: bold;"><i class="bi bi-geo-alt"></i>{{g_.N}}, {{g_.Location.Adr.A}}, Rm: {{g_.Location.N}}</span><br>
                        <span style="font-weight: bold;">Description: {{g_.Description}}</span><br>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div!-->
  </div>
</div>