<div class="card textcenter mt-3">
  <h5 class="card-title"></h5>
  <div class="card-body row" style="margin: 0 !important;padding:0 !important;  border-radius: .5rem;">
    <div class="col-md-10">
      <div *ngIf="data.encounter.appointment_Status; else noDataTemplate">
        <!-- Displaying status and details passed through the data object -->
        <p><strong>Current Status:</strong> {{ data.encounter.appointment_Status }}</p>
      </div>
      <!-- Fallback Content If No Data Is Available -->
      <ng-template #noDataTemplate>
        <p>No update information available.</p>
      </ng-template>
    </div><!-- form-group -->
    <div class="col-md-2">
      <a style="padding-right:.5rem;font-size: 1.75rem;float: right;" (click)="closeDialog()"><i class="bi bi-x-square"></i> </a>
    </div>
  </div><!-- card-body -->
  <div class="card text-center mt-3">
    <div class="card-body">
      <form [formGroup]="eForm" (ngSubmit)="saveEvents()">
        <div class="form-group">
          <div style="float: left; width: 100%;">
            <button type="button" class="btn" style="float: left;" title="Add Intervention Event"
              (click)="addEvent()"><i class="bi bi-plus-square"></i> Add Intervention Event</button>
            <button title="Update Encounter Intervention Event" type="submit" class="btn" style="float: right;"><i class="bi bi-save"></i></button>
          </div>
        </div>
        <div formArrayName="events" style="height: 30rem; overflow: scroll;float: left; width: 100%;">
          <!-- Use the index to access each group in the form array -->
          <div *ngFor="let eventFormGroup of eventsFormArray.controls; let i = index" [formGroupName]="i">
            <div class="form-group editGrpDiv row">
              <!-- Correctly bind the time property assuming it's directly within each eventFormGroup -->
              <div class="col-md-6">
                <label for="action{{i}}">Action &#64;: {{ eventFormGroup.get('time')?.value}} | Documented by: {{
                  eventFormGroup.get('staffId')?.value}}</label>
                <select id="action{{i}}" class="form-select" formControlName="action">
                  <option value="">Select Action</option>
                  <option *ngFor="let s_ of cmpy.Cnfg.apptStatus" [value]="s_.id">{{ s_.name }}</option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="note{{i}}">Note:</label>
                <textarea id="note{{i}}" formControlName="note" class="form-control"></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div><!-- card-primary -->