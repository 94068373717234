// appointment-monitor.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Appointment } from '../Store/Model/type.Interfeaces';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppointmentMonitorService {
  [x: string]: any;
  private apiUrl = environment.apiBaseUrl;
  private appointmentTimesSubject = new BehaviorSubject<Date[]>([]); // Use BehaviorSubject for appointment times
  private currentTime$ = timer(0, 1000).pipe(map(() => new Date())); // emits the current time every second

  constructor(private http: HttpClient) {
  }
  private monitorCurrentTime(): void {
    // Replace interval with timer for more control and start immediately
    timer(0, 5000).subscribe(() => {
      const currentTime = new Date();
      this.updateAppointmentsStatus(currentTime);
    });
  }
  // Example of a strongly-typed method within the service
  isAppointmentTime(): Observable<boolean> {
    // Implementation that returns an observable indicating whether it's appointment time
    return new Observable(subscriber => {
      // Example logic here
      const currentTime = new Date();
      const appointmentTime = new Date('2024-04-17T15:00:00Z'); // Example fixed date/time
      subscriber.next(currentTime >= appointmentTime);
      subscriber.complete();
    });
  }

  // Fetch and update appointment times as needed
  setAppointmentTimes(times: Date[]): void {
    this.appointmentTimesSubject.next(times);
  }

  private updateAppointmentsStatus(currentTime: Date): void {
    const updatedStatus = this.appointmentTimesSubject.value.map(appointmentTime => currentTime >= appointmentTime);
    // Here you could update another BehaviorSubject that holds the status of each appointment if needed
    // For demonstration, this is just a placeholder for where you would handle the logic of updating appointment statuses
  }

  getAppointment(id: string): Observable<Appointment> {
    return this.http.get<Appointment>(`${this.apiUrl}/appt/init/${id}`);
  }

  addAppointmentTime(appointmentTime: Date): void {
    const currentTimes = this.appointmentTimesSubject.getValue();
    this.appointmentTimesSubject.next([...currentTimes, appointmentTime]);
  }

  getAppointmentsStatus(): Observable<boolean[]> {
    return this.appointmentTimesSubject.asObservable().pipe(
      switchMap(appointmentTimes =>
        this.currentTime$.pipe(
          map(currentTime =>
            appointmentTimes.map(appointmentTime => currentTime >= appointmentTime)
          )
        )
      )
    );
  }

  getCurrentTime(): Observable<Date> {
    // Directly use a new Observable for current time to avoid unnecessary logging and make it cleaner
    return timer(0, 1000).pipe(map(() => new Date()));
  }
}
