import { Injectable } from '@angular/core';
import { startOfWeek, startOfMonth, startOfQuarter, startOfYear, subDays, format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DateCalculationsService {

  constructor() { }

  calculateIntervals(inputDate: Date | string) {
    const parsedDate = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

    const today = new Date();
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // Define the intervals
    const intervals = {
      today: { start: today, end: today },
      WTD: { start: startOfWeek(parsedDate, { weekStartsOn: 1 }), end: today },
      MTD: { start: startOfMonth(parsedDate), end: today },
      QTD: { start: startOfQuarter(parsedDate), end: today },
      YTD: { start: startOfYear(parsedDate), end: today },
      Last7Days: { start: subDays(startOfToday, 6), end: today },
      Last30Days: { start: subDays(startOfToday, 29), end: today },
      Last90Days: { start: subDays(startOfToday, 89), end: today },
      Last180Days: { start: subDays(startOfToday, 179), end: today },
      Last365Days: { start: subDays(startOfToday, 364), end: today },
    };

    // Calculate days in interval and format dates
    const results:any = {};
    for (const [key, { start, end }] of Object.entries(intervals)) {
      const numberOfDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1; // Adding 1 to include both start and end in the interval
      results[key] = {
        start: format(start, 'yyyy-MM-dd'),
        end: format(end, 'yyyy-MM-dd'),
        numberOfDays,
      };
    }
    return results;
  }
}
