<!-- patient-summary.component.html -->
<div class="card" *ngIf="pReady">
    <div class="card-body">
        <!-- Patient Information -->
        <h5 class="card-title">{{ patient.name }}</h5>
        <div class="row">
            <div class="col-7">
                <p class="card-text">DOB: {{ patient.dob }}, Age: {{ patient.Age }}</p>
            </div>
            <div class="col-5">
                <p class="card-text">ID: {{ patient.patient_id }}</p>
            </div>
        </div>

        <!-- Encounter Information -->
        <div class="row">
            <div class="col-10">
                <h6 class="card-text">{{ patient.encounter.T }}: {{ patient.encounter.eDate }} | {{ patient.LAPMT }}</h6>
            </div>
            <div class="col-2">
                <button (click)="viewResults(patient, patient.encounter)" class="btn icon-btn" aria-label="View Results" title="Uploaded to EHR">
                    <i class="bi" [ngClass]="{'bi-check-square': patient.encounter.status['EHR-UL'] !== '', 'bi-square': patient.encounter.status['EHR-UL'] === ''}" [title]="patient.encounter.status['EHR-UL'] ? 'Uploaded to EHR' : 'Requires Upload to EHR'"></i>
                </button>
            </div>
        </div>

        <!-- Appointment Status -->
        <div class="row">
            <div class="col-12">
                <button type="button" title="Update This Encounter Status"
                        [disabled]="isHidden(patient.encounter.appointment_Status)"
                        (click)="openInterventionUpdateDialog(patient)" class="btn btn-primary">
                    {{ patient.encounter.appointment_Status }}
                    <span class="icon-container">
                        <i class="bi bi-exclamation-triangle" *ngIf="showWarningIcon(patient.encounter.appointment_Status)"></i>
                    </span>
                </button>
            </div>
        </div>

        <!-- Assessment Information -->
        <div class="row">
            <div *ngFor="let key of assessmentKeys" class="col-3">
                <p class="card-text">{{ key }}: 
                    <span class="badge" [ngClass]="getBadgeClass(patient.encounter.prom[key].tlt_)">{{ patient.encounter.prom[key].tlt_ }}</span>
                </p>
            </div>
        </div>
    </div>

    <!-- Link to Detailed View -->
    <a [routerLink]="['/patient', patient.patient_id]" class="btn btn-primary" aria-label="View Patient Details">View Details</a>
</div>
