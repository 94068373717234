import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';

@Component({
  selector: 'app-location-detail-page',
  templateUrl: './location-detail-page.component.html',
  styleUrls: ['./location-detail-page.component.css'],
})
export class LocationDetailPageComponent implements OnInit {
  errors: any = null;
  isLoading: boolean = true;
  cmpy: any; locs: any; loc: any; dpts: any; dpt: any; rptDepart: any;
  stf: any;
  lID: any;

  showDepartment(item: object) { console.log(item); }

  constructor(private route: ActivatedRoute, public uSrvc: UtilitiesService, public aSrvc: AuthService) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) {console.log("redirect");}//this.router.navigate(['login/:id']);
    else {this.stf = JSON.parse(stf); console.log(this.stf);}
  }

  ngOnInit(): void {
    this.lID = this.route.snapshot.paramMap.get('param1');console.log(this.lID);

    this.aSrvc.getLoc(this.stf.cID,this.lID).subscribe(
      (result: any) => { this.loc = result; },
      (error: { error: any; }) => { this.errors = error.error; console.log(this.errors); },
      () => {this.dpts = this.loc.departments; console.log('location page this.loc:', this.loc,this.dpts);}
    )
  }

}
