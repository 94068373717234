import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms'; 

import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
more(Highcharts);
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Observable, of, forkJoin, Subscription } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';

import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { AssessmentService } from '../../../service/assessment.service';
import { PromChartGenPipe } from '../../../thePipes/prom-chart-gen.pipe';
import { AssessmentResultsComponent } from '../assessment-results/assessment-results.component';
import { PatientDetailsPipe } from 'src/app/thePipes/patient-details.pipe';
import { Encounter } from 'src/app/Store/Model/type.Interfeaces';
import { AdherenceStatusService } from 'src/app/service/adherence-status.service';
import { ObservableAssessmentService } from '../../../service/observable-assessment.service';

@Component({
  selector: 'app-notes-encounters',
  templateUrl: './notes-encounters.component.html',
  styleUrls: ['./notes-encounters.component.css']
})
export class NotesEncountersComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  errors: any = null; error: string | null = null;
  isLoading: boolean = true;
  [x: string]: any;
  ax_: any; // Assume this is populated either statically or from a service

  eForm!: FormGroup;
  encntrs: Encounter[] = [];
  encntr: any = [];
  currentEDate: any = "";
  eDates: any = [];
  eData: any = [];
  eD: any = [];
  eD_: any = [];
  svrtyD_: any = [];
  svrtyD: any = [];
  eNote: any = []; iNv: any = [];
  promRd: string = '';
  prom: any = [];
  cmpy: any; cnfg: any; mpCnfg: any; spCnfg: any; sympCnfg: any; medMngt: any; socialCnfg: any;
  stf: any;
  psyCnfg: any;
  psyMVCnfg: any;
  psyMACnfg: any;
  tpCnfg: any;
  tpPsyc: any;
  axCnfg: any;
  riskOptions: any;
  suOptions: any;
  routeOfAdministration: any;
  classes: any;
  frequencyOptions: any;
  medCompliance: any;
  patient: any;
  pName: any;
  pDOB: any;
  patientHistory: any;
  patientChrtRdy: boolean = false;
  patientRdy: boolean = false;
  AxMHTlt: any = 0;
  AxMHTrgt: any = 0;
  AxPsychTlt: any = 0;
  AxPsychTrgt: any = 0;
  private speechRecognition: SpeechRecognition | null = null;
  isListening: boolean = false;
  transcript: string = '';
  bootstrap: any;
  tDates: any = [];
  updateFlag = false;
  selectEncounter_: any = [];
  displayPromSummary: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private _location: Location,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private asmtSrvc: AssessmentService,
    private medAdhrSrvc: AdherenceStatusService,
    private oaxSrvc: ObservableAssessmentService,
    private promChartGen: PromChartGenPipe,
    private pDetails: PatientDetailsPipe
  ) {
    //this.stf = this.uSrvc.getLS_item('stf'); console.log("stf", this.stf);
    this.eForm = this.initializeForm(); console.log("NotesEncountersComponent constructor: this.eForm", this.eForm);
    this.initializeSpeechRecognition();
  }
  /************************************ SpeechRecognition */
  private initializeSpeechRecognition(): void {
    const SRClass: typeof SpeechRecognition | undefined = (window.SpeechRecognition || window.webkitSpeechRecognition) as typeof SpeechRecognition | undefined;
    if (SRClass) {
      this.speechRecognition = new SRClass();
      this.speechRecognition.continuous = true; // Capture continuous speech
      this.speechRecognition.interimResults = true; // Get both interim and final results
      this.speechRecognition.lang = 'en-US'; // Set language of the recognition
      this.speechRecognition?.start();
      this.speechRecognition?.stop();
      if (this.speechRecognition) {
        //this.speechRecognition.onresult = this.onResult.bind(this);
        //this.speechRecognition.onerror = this.onError.bind(this);
        this.speechRecognition.onresult = (event) => this.handleSRClassResult(event);
        this.speechRecognition.onerror = (event) => this.handleSRClassError(event);
      }
    } else { this.error = 'Speech Recognition is not available in this browser.'; }
  }
  private handleSRClassResult(event: SpeechRecognitionEvent): void {
    const newTranscript = Array.from(event.results).slice(event.resultIndex).map(result => result[0].transcript).join(' ');
    this.transcript += newTranscript;  // Append new transcript text to the existing one
  }
  private handleSRClassError(event: SpeechRecognitionErrorEvent): void { this.error = `An error occurred in speech recognition:${event.error}`; }
  onResult(event: SpeechRecognitionEvent): void {
    this.transcript = '';
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) { this.transcript += event.results[i][0].transcript + ' '; }
    }
  }
  onError(event: SpeechRecognitionErrorEvent): void { this.error = `An error occurred in speech recognition: ${event.error}`; }
  startListening(): void {
    if (!this.speechRecognition) { this.initializeSpeechRecognition(); }
    if (this.speechRecognition) { this.isListening = true; this.speechRecognition.start(); }
  }
  stopListening(): void { if (this.speechRecognition) { this.speechRecognition.stop(); this.isListening = false; } }
  clearTranscript(): void { if (this.speechRecognition) { this.speechRecognition.stop(); this.isListening = false; this.transcript = ''; } }
  /************************************ */

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | undefined;
  chartOptions!: Highcharts.Options; // defines chart options
  chartType: any = 'ax-trend';
  selectedOption: string = 'ax-trend'; // Default selection
  toggleChartType(chartType: any): void {
    this.chartType = chartType;
    console.log(this.chartType);
  }
  initializeForm(): FormGroup {
    console.log("NotesEncountersComponent initializeForm: this.eNote", this.eNote);
    return this.fb.group({
      Details: this.generateDetailsGroup(),
      Summary: this.fb.group({ Note: [this.eNote?.Summary?.Note ?? ''], }),
      Feedback: this.fb.group({ Note: [this.eNote?.Feedback?.Note ?? ''], }),
      //ShortTermGoals: this.fb.array([]),
      //LongTermGoals: this.fb.array([]),
      REC: this.generateRecGroup(),
      //Goal: this.generateGoalGroup(),
      Ax: this.generateAxGroup(),
    });
  }
  private generateDetailsGroup(): FormGroup {
    return this.fb.group({
      Date: [this.eNote?.Details?.Date ?? '', Validators.required],
      Time: [this.eNote?.Details?.Time ?? '', Validators.required],
      Duration: [this.eNote?.Details?.Duration ?? '', Validators.required],
      DurationOther: [this.eNote?.Details?.DurationOther ?? ''],
      EncounterType: [this.eNote?.Details?.EncounterType ?? '', Validators.required],
      DepartmentType: [this.eNote?.Details?.DepartmentType ?? '', Validators.required],
      Reason: [this.eNote?.Details?.Reason ?? ''],
      Problems: [this.eNote?.Details?.Problems ?? ''],
      Note: [this.eNote?.Details?.Note ?? ''],
    });
  }
  private generateRecGroup(): FormGroup {
    return this.fb.group({
      Note: [this.eNote?.REC?.Note ?? ''],
      Intervention: [this.eNote?.REC?.Intervention ?? ''],
      Medications: [''],
      Education: [this.eNote?.REC?.Education ?? ''],
      Referral: this.fb.group({
        Type: [this.eNote?.REC?.Referral?.Type ?? ''],
        Note: [this.eNote?.REC?.Referral?.Note ?? ''],
      }),
      FollowUp: this.fb.group({
        Appt: [this.eNote?.REC?.FollowUp?.Appt ?? ''],
        Tests: [this.eNote?.REC?.FollowUp?.Tests ?? ''],
        Fam: [this.eNote?.REC?.FollowUp?.Fam ?? ''],
        Note: [this.eNote?.REC?.FollowUp?.Note ?? ''],
      })
    });
  }
  private generateGoalGroup(): FormGroup {
    return this.fb.group({
      PHQ9: this.fb.group({
        Init: [this.eNote?.Goal?.PHQ9?.Init ?? 0, Validators.min(0)],
        Crnt: [this.eNote?.Goal?.PHQ9?.Crnt ?? 0, Validators.min(0)],
      }),
      GAD7: this.fb.group({
        Init: [this.eNote?.Goal?.GAD7?.Init ?? 0, Validators.min(0)],
        Crnt: [this.eNote?.Goal?.GAD7?.Crnt ?? 0, Validators.min(0)],
      }),
      WHO5: this.fb.group({
        Init: [this.eNote?.Goal?.WHO5?.Init ?? 0, Validators.min(0)],
        Crnt: [this.eNote?.Goal?.WHO5?.Crnt ?? 0, Validators.min(0)],
      }),
    });
  }

  private generateAxGroup(): FormGroup {
    return this.fb.group({
      Note: [this.eNote?.Ax?.Note ?? ''],
      PatientFeedback: this.fb.group({
        ShortTerm: this.fb.group({
          STAssessment: [""],
          STAdjustment: [""],
        }),
        LongTerm: this.fb.group({
          LTAssessment: [""],
          LTAdjustment: [""],
        }),
      }),
      Sx: this.fb.array([]),
      InitialReport: [this.eNote?.Ax?.InitialReport ?? ''],
      SubstanceUse: [this.eNote?.Ax?.SubstanceUse ?? 0, Validators.min(0)],
      RiskOfHarm: [this.eNote?.Ax?.RiskOfHarm ?? 0, Validators.min(0)],
      Physical: [this.eNote?.Ax?.Physical ?? 0, Validators.min(0)],
      Functional: [this.eNote?.Ax?.Functional ?? 0, Validators.min(0)],
      MH: this.generateDynamicGroup(this.eNote?.Ax?.MH),
      Psych: this.generateDynamicGroup(this.eNote?.Ax?.Psych),
    });
  }
  private generateDynamicGroup(data: any): FormGroup {
    const group: any = {};
    Object.keys(data ?? {}).forEach(key => {
      group[key] = [data[key] ?? 1, Validators.min(1)];
    });
    return this.fb.group(group);
  }

  // Example method to change status
  changeStatus(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus: any = String(selectElement.value);
    const id = String(selectElement.id); console.log('id', id, 'newStatus', newStatus);
    this.medAdhrSrvc.updateStatus(id, newStatus);
  }
  getColor(controlName: string): string {
    const value = this.eForm.get(controlName)?.value;
    // Logic to determine color based on value
    if (value === '1') {
      return 'lightblue'; // Example color
    }
    // Add more conditions as needed
    return 'transparent'; // Default color
  }
  changeAx(event: any) {
    const controlName = event.target.id;
    const value = event.target.value;
    this.eForm.get(controlName)?.setValue(value, { emitEvent: false }); // Update the form control value without emitting an event
    const selectElement = event.target as HTMLSelectElement;
    //const newStatus: any = String(selectElement.value);
    //const id = String(selectElement.id); //console.log('selectElement', selectElement, 'id', id, 'newStatus', newStatus);
    this.oaxSrvc.updateState(String(selectElement.id), Number(selectElement.value));
    //this.oaxSrvc.updateState(`${sectionPath}.${key}`, numericValue);

    const axMH = this.eForm.get('Ax.MH');
    if (axMH) {
      this.AxMHTlt = 0;
      for (const key in axMH.value) { this.AxMHTlt = parseInt(this.AxMHTlt) + parseInt(axMH.value[key]); };
      this.encntr.note.Ax.MHTLT = this.AxMHTlt;
    }
    const axPsych = this.eForm.get('Ax.Psych');
    if (axPsych) {
      this.AxPsychTlt = 0;
      for (const key in axPsych.value) { this.AxPsychTlt = parseInt(this.AxPsychTlt) + parseInt(axPsych.value[key]); };
      this.encntr.note.Ax.PsychTLT = this.AxPsychTlt;
    }
  }
  changeMedClass(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus: any = String(selectElement.value);
    const id = String(selectElement.id);
    const prefix = 'medClass';
    const indexPart = id.substring(prefix.length);
    console.log('id', id, 'newStatus', newStatus, 'classes', this.classes);
    const class_ = this.classes.find((item: any) => item.ClassName === newStatus);
    console.log('newStatus', newStatus, 'indexPart', indexPart, 'classes', this.classes, 'class_', class_, 'icd10Code', class_.CommonICD10CMCodes);
    this.fb.group({ ['medICD10CMCode' + indexPart]: [class_.CommonICD10CMCodes, Validators.required] });
    //this.oaxSrvc.updateState(id, newStatus);
  }
  backClicked() { this._location.back(); };

  private popup(event: { point: { index: number } }): void {
    this.dialog.open(AssessmentResultsComponent, {
      width: '100%',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      data: { patient: this.patient, assessment: this.encntrs[event.point.index] }
    });
  }

  private updateChartProgress() {
    const popup = (event: { point: { index: number } }): void => {
      console.log("event.point.index:", event.point.index, "this.encntrs:", this.encntrs);
      if (this.displayPromSummary) {
        this.dialog.open(AssessmentResultsComponent, {
          width: '100%',
          enterAnimationDuration: '1000ms',
          exitAnimationDuration: '500ms',
          data: { patient: this.patient, assessment: this.encntrs[event.point.index] }
        });
      }
      this.encntrPntr(this.encntrs[event.point.index]);
    };
    this.chartOptions = {
      chart: {
        type: 'column',  // Specify the chart type
        // @ts-ignore
        zoomType: 'xy'   // Correct placement of 'zoomType'
      },
      title: { text: 'Comprehensive Treatment Plan Progress' },
      xAxis: [{
        title: { text: 'Encounter Dates' }, type: "datetime",
        categories: this['eDates'],
        crosshair: true
      }],
      yAxis: [{ // Tertiary yAxis for WHO-5, Functional Impact, and Physical Appearance
        labels: { format: '{value}', },
        title: { text: 'Well-being Score', },
        min: 0,
        max: 100,
        opposite: true
      }, { // Primary yAxis for PHQ9 and GAD7 severity
        labels: { format: '{value}', },
        title: { text: 'Symptom Severity', },
        min: 0,
        max: 15 // Assuming a scale of 0-10 for simplicity
      }, { // Secondary yAxis for goal progress
        title: { text: 'Goal Progress (%)', },
        labels: { format: '{value} %', },
        min: 0,
        max: 100,
        opposite: true
      }],
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td><td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        valueDecimals: 2
      },
      //plotOptions: { },
      plotOptions: {
        column: {
          stacking: 'normal', // Enable stacking
          shadow: false,
          dataLabels: { enabled: true },
          borderWidth: 0
        },
        series: { cursor: 'pointer', marker: { lineWidth: 1 }, point: { events: { click: popup } } }
      },
      legend: {
        //layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 75,
        y: 25,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      series: [//      series: this.eD_
        {
          name: this.svrtyD_[0].name,
          type: 'column',
          yAxis: 1,
          data: this.svrtyD_[0].data, // Example data
          tooltip: { valueSuffix: ' severity level' },
          stack: 'severity'
        }, {
          name: this.svrtyD_[1].name,
          type: 'column',
          yAxis: 1,
          data: this.svrtyD_[1].data, // Example data
          tooltip: { valueSuffix: ' severity level' },
          stack: 'severity'

        }, {
          name: 'Physical Health Status',
          type: 'column',
          yAxis: 1,
          data: this.svrtyD_[1].data, // Example data
          tooltip: { valueSuffix: ' severity level' },
          stack: 'ADL'
        }, {
          name: 'Functional Indepandence',
          type: 'column',
          yAxis: 1,
          data: this.svrtyD_[1].data, // Example data
          tooltip: { valueSuffix: ' severity level' },
          stack: 'ADL'

        }, {
          name: this.svrtyD_[2].name,
          type: 'column',
          yAxis: 0,
          data: this.svrtyD_[2].data, // Example data
          tooltip: { valueSuffix: ' well-being score' },
          stack: 'well'

        }, {
          name: 'Short-term Goal',
          type: 'spline',
          yAxis: 2,
          data: [30, 40], // Example data
          tooltip: { valueSuffix: '% achieved' }

        }, {
          name: 'Long-term Goal',
          type: 'spline',
          yAxis: 2,
          data: [20, 30], // Example data
          tooltip: { valueSuffix: '% achieved' }
        }]
    };
    /************************** */
  }

  private updateChartTrend() {
    //if (!this.encntrs || this.encntrs.length < 1) { console.error('No encounters to display'); return; }
    if (!this.eD_ || !this.eDates || this.eD_.length < 1) { console.error('No encounters or date data to display in the chart'); return; }
    console.log("Data ready for chart:", this.eD_, this.eDates);
    const popup = (event: { point: { index: number } }): void => {
      console.log("event.point.index:", event.point.index, "this.encntrs:", this.encntrs);
      if (this.displayPromSummary) {
        this.dialog.open(AssessmentResultsComponent, {
          width: '100%',
          enterAnimationDuration: '1000ms',
          exitAnimationDuration: '500ms',
          data: { patient: this.patient, assessment: this.encntrs[event.point.index] }
        });
      }
      this.encntrPntr(this.encntrs[event.point.index]);
    };
    // @ts-ignore
    this.chart = Highcharts.chart('container', {
      chart: {
        renderTo: 'container',
        scrollablePlotArea: { minWidth: 700 }
      },
      title: { text: 'Comprehensive Assessment Trend' },
      //title: { text: this.prom_.N, align: 'center' },
      // Other chart configurations...
      xAxis: [{
        title: { text: 'Encounter Dates' }, type: "datetime",
        itemStyle: { font: '8pt Roboto,Arial,sans-serif', color: '#A0A0A0' },
        categories: this.eDates,
        accessibility: { description: 'Encounter dates' }
      }],
      yAxis: [{ title: { text: 'Assessment(s)' }, labels: { align: 'left', x: 0, y: 11, format: '{value:.,0f}' }, showFirstLabel: false }],
      legend: { align: 'left', verticalAlign: 'top', borderWidth: 0 },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
          '<td style="text-align: right"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
      },/**/
      plotOptions: { series: { cursor: 'pointer', marker: { lineWidth: 1 }, point: { events: { click: popup } } } },
      series: this.eD_
    });
  }

  private encntrPntr(encounter: any): void {
    if (this.currentEDate !== encounter.eDate) {
      console.log("viewing new encounter");
      this.uSrvc.selectEncounter(encounter);
    }
  }

  dialogRef: any;
  editPatientDemo(pd_: object) { this.OpenPopup(pd_); }
  OpenPopup(pd_: object): any { console.log(pd_); }

  private loadPatientData(patientId: string): Observable<any> {
    this.isLoading = true;
    return this.aSrvc.GetPatientEncntrs(patientId).pipe(
      tap(result => this.processPatientData(result)),
      catchError(error => {
        this.errors = error; console.log('this.errors', this.errors); this.isLoading = false; return of([]);
      })
    );
  }
  private processPatientData(patient: any): void {
    // Assuming 'patient' is the result received from the service
    if (!patient) { this.isLoading = false; return; } console.log('patient', patient);
    this.encntrs = patient.encounters; console.log("this.encntrs", this.encntrs);
    for (let e = 0; e < this.encntrs.length; e++) {
      const eD_ = this.encntrs[e];
      const eDate = eD_.eDate;
      this['eDates'].push(eDate);
      this.tDates[e] = eD_["T"];
      let xPrm: any = this.promChartGen.transform(eD_, 'pipeFilter');
      //console.log('eD_.prom', eD_.prom, 'xPrm', xPrm, 'promSummary', eD_['promSummary']);
      for (let l = 0; l < xPrm.length; l++) {
        this.eD[xPrm[l].k] = []; this.eD[xPrm[l].k].Tlt = [];
        this.svrtyD[xPrm[l].k] = []; this.svrtyD[xPrm[l].k].severity = [];
      }
    }
    console.log('this.eD', this.eD, 'this.tDates', this.tDates, 'this.svrtyD', this.svrtyD);
    let kChk: any = [];
    for (let e = 0; e < this.encntrs.length; e++) {
      kChk = [];
      let xPrm: any = this.promChartGen.transform(this.encntrs[e], 'pipeFilter'); //console.log('xPrm', xPrm);
      let ePrms_: any = this.encntrs[e].promKeys;
      for (let l = 0; l < xPrm.length; l++) {
        kChk.push(xPrm[l].k);
        this.eD[xPrm[l].k].push(xPrm[l].r);
        this.eD[xPrm[l].k].Tlt.push(xPrm[l].t);
        this.svrtyD[xPrm[l].k].severity.push(xPrm[l].svr);
      } let missKAry: any = [];
      if (kChk.length !== ePrms_.length) {
        let missingK = ""; let kFound = false; missKAry = [];
        for (let k = 0; k < ePrms_.length; k++) {
          kFound = false; missingK = ePrms_[k];
          for (let k_ = 0; k_ < kChk.length; k_++) { if (kChk[k_] === missingK) { kFound = true; } }
          if (!kFound) { missKAry.push(missingK); }
        }
        if (missKAry.length > 0) {
          for (let mk_ = 0; mk_ < missKAry.length; mk_++) {
            this.eD[missKAry[mk_]].Tlt.push("");
            this.svrtyD[missKAry[mk_]].severity.push("");
          }
        }
      }
    }
    //console.log("this.svrtyD", this.svrtyD);
    this['eD_'] = [];
    this.svrtyD_ = [];
    for (const key of Object.keys(this.eD)) {
      this['eD_'].push({ name: key, data: this.eD[key].Tlt });
      this.svrtyD_.push({ name: key, data: this.svrtyD[key].severity });
    }
    console.log('this.eD', this.eD, 'this.tDates', this.tDates, 'this.svrtyD', this.svrtyD);
    this.patient = patient; // Assuming 'patient' contains the data you need
    this.updateChartTrend();
    this.updateChartProgress();
  }

  ngOnInit(): void {
    console.log("NotesEncountersComponent ngOnInit: this.eForm", this.eForm);
    this.stf = this.uSrvc.getLS_item('stf'); //console.log("stf", this.stf);
    this.currentEDate = "";
    this.handleRouteParams();
    this.subscribeToSelectedEncounter();
  }
  private handleRouteParams(): void {
    this.subscription.add(
      this.route.paramMap.pipe(
        switchMap(params => this.fetchPatientAndConfig(params.get('id'))),
        catchError(error => {
          this.handleError(error);
          // Return a fallback object structure that matches the expected type
          // to ensure further operations do not fail due to unexpected null values.
          return of({ patient: null, config: null });
        })
      ).subscribe(({ patient, config }) => { this.initializePatientData(patient, config); })
    );
  }
  private fetchPatientAndConfig(patientId: string | null): Observable<{ patient: any, config: any }> {
    if (!patientId) { return of({ patient: null, config: null }); }
    return forkJoin({ patient: this.loadPatientData(patientId), config: this.aSrvc.getCnfg(this.stf.cID).pipe(catchError(_error => of(null))) });
  }
  private initializePatientData(patient: any, config: any): void {
    if (patient && config) {
      this.cmpy = config[0]; console.log('enocounter this.cmpy:', this.cmpy);
      this.cnfg = this.cmpy.Cnfg;
      this.mpCnfg = this.cmpy.Military;
      this.spCnfg = this.cmpy.serviceProfile;
      this.socialCnfg = this.cmpy.SymptomCnfg;
      this.psyCnfg = this.cmpy.Psychological;
      this.psyMVCnfg = this.cmpy.PsychologicalMilitaryVeteran;
      this.psyMACnfg = this.cmpy.PsychologicalMilitaryActive;
      this.tpCnfg = this.cmpy.TreatmentPlan; //console.log('tpCnfg:', this.tpCnfg);
      this.tpPsyc = this.tpCnfg.PsychotherapyServices; //console.log('tpPsyc:', this.tpPsyc);
      this.axCnfg = this.cmpy.Assessments; console.log('encounter notes axCnfg:', this.axCnfg);
      const medsMngmt = this.cmpy.medManagement.prescribedMedications;
      this.routeOfAdministration = medsMngmt.routeOfAdministration;
      this.classes = medsMngmt.MedicationClasses; //console.log('classes:', this.classes);
      this.frequencyOptions = medsMngmt.frequencyOptions;
      this.medCompliance = medsMngmt.compliance.adherenceLevel;
      this.sympCnfg = this.cmpy.SymptomCnfg;
      this.medMngt = this.cmpy.medManagement;
      this.patient = patient; //console.log("notes this.patient", this.patient);
      let pDm: any = this.pDetails.transform(this.patient.demographics); //console.log(pDm);
      this.pName = pDm[0]; this.patient.Age = pDm[1]; this.pDOB = pDm[2];
      this.encntrs = this.patient.encounters; console.log("this.encntrs", this.encntrs);
      this.encntrPntr(this.encntrs[this.encntrs.length - 1]);
      this.patientRdy = true;
      this.isLoading = false;
    }
  }
  private handleError(error: any): void { this.errors = error; console.error(this.errors); }
  private subscribeToSelectedEncounter(): void {
    this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => {
        if (encounter) {
          this.encntr = encounter;
          //console.log("encounterNotes this.encntr", this.encntr);
          this.currentEDate = this.encntr.eDate;
          this.eNote = this.encntr.note; console.log('encounterNotes this.eNote:', this.eNote);
          this.AxMHTlt = 0;
          this.AxPsychTlt = 0;
          this.eForm = this.fb.group({
            Details: this.fb.group({
              Date: [this.eNote.Details.Date, Validators.required],
              Time: [this.eNote.Details.Time, Validators.required],
              Duration: [this.eNote.Details.Duration, Validators.required],
              DurationOther: [this.eNote.Details.DurationOther],
              EncounterType: [this.eNote.Details.EncounterType, Validators.required],
              DepartmentType: [this.eNote.Details.DepartmentType, Validators.required],
              Reason: [this.eNote.Details.Reason],
              Problems: [this.eNote.Details.Problems],
              Note: [this.eNote.Details.Note],
            }),
            Summary: this.fb.group({
              Note: [this.eNote.Summary.Note],
            }),
            Feedback: this.fb.group({
              Note: [this.eNote.Feedback.Note],
            }),
            PatientFeedback: this.fb.group({
              ShortTerm: this.fb.group({
                STAssessment: [""],
                STAdjustment: [""],
              }),
              LongTerm: this.fb.group({
                LTAssessment: [""],
                LTAdjustment: [""],
              }),
            }),
            REC: this.fb.group({
              Note: [this.eNote.REC.Note],
              Intervention: [this.eNote.REC.Intervention],
              Medications: this.fb.array([]),
              Education: [this.eNote.REC.Education],
              Referral: this.fb.group({
                Type: [this.eNote.REC.Referral.Type],
                Note: [this.eNote.REC.Referral.Note],
              }),
              FollowUp: this.fb.group({
                Appt: [this.eNote.REC.FollowUp.Appt],
                Tests: [this.eNote.REC.FollowUp.Tests],
                Fam: [this.eNote.REC.FollowUp.Fam],
                Note: [this.eNote.REC.FollowUp.Note],
              }),
            }),
            /*
            Goal: this.fb.group({
              PHQ9: this.fb.group({
                Init: [this.eNote.Goal.PHQ9.Init !== "" ? this.eNote.Goal.PHQ9.Init : 0],
                Crnt: [this.eNote.Goal.PHQ9.Crnt !== "" ? this.eNote.Goal.PHQ9.Crnt : 0],
              }),
              GAD7: this.fb.group({
                Init: [this.eNote.Goal.GAD7.Init !== "" ? this.eNote.Goal.GAD7.Init : 0],
                Crnt: [this.eNote.Goal.GAD7.Crnt !== "" ? this.eNote.Goal.GAD7.Crnt : 0],
              }),
              WHO5: this.fb.group({
                Init: [this.eNote.Goal.WHO5.Init !== "" ? this.eNote.Goal.WHO5.Init : 0],
                Crnt: [this.eNote.Goal.WHO5.Crnt !== "" ? this.eNote.Goal.WHO5.Crnt : 0],
              }),
            }),
            */
            Ax: this.fb.group({
              Note: [this.eNote.Ax.Note],
              Sx: this.fb.array([]),
              InitialReport: [this.eNote.Ax.InitialReport],
              SubstanceUse: [parseInt(this.eNote.Ax.SubstanceUse)],
              RiskOfHarm: [parseInt(this.eNote.Ax.RiskOfHarm)],
              Physical: [parseInt(this.eNote.Ax.Physical)],
              Functional: [parseInt(this.eNote.Ax.Functional)],
              MH: this.fb.group({
                Appearance: [this.eNote.Ax.MH.Appearance !== "" ? this.eNote.Ax.MH.Appearance : 1],
                Behavior: [this.eNote.Ax.MH.Behavior !== "" ? this.eNote.Ax.MH.Behavior : 1],
                Speech: [this.eNote.Ax.MH.Speech !== "" ? this.eNote.Ax.MH.Speech : 1],
                Mood: [this.eNote.Ax.MH.Mood !== "" ? this.eNote.Ax.MH.Mood : 1],
                ThoughtProcess: [this.eNote.Ax.MH.ThoughtProcess !== "" ? this.eNote.Ax.MH.ThoughtProcess : 1],
                ThoughtContent: [this.eNote.Ax.MH.ThoughtContent !== "" ? this.eNote.Ax.MH.ThoughtContent : 1],
                Perceptual: [this.eNote.Ax.MH.Perceptual !== "" ? this.eNote.Ax.MH.Perceptual : 1],
                Orientation: [this.eNote.Ax.MH.Orientation !== "" ? this.eNote.Ax.MH.Orientation : 1],
                Cognition: [this.eNote.Ax.MH.Cognition !== "" ? this.eNote.Ax.MH.Cognition : 1],
                Insight: [this.eNote.Ax.MH.Insight !== "" ? this.eNote.Ax.MH.Insight : 1],
                //Note: [this.eNote.Ax.MH.Note !== ""??1],
              }),
              Psych: this.fb.group({
                Anxiety: [this.eNote.Ax.Psych.Anxiety !== "" ? this.eNote.Ax.Psych.Anxiety : 1],
                Depression: [this.eNote.Ax.Psych.Depression !== "" ? this.eNote.Ax.Psych.Depression : 1],
                MoodSwings: [this.eNote.Ax.Psych.MoodSwings !== "" ? this.eNote.Ax.Psych.MoodSwings : 1],
                Sleep: [this.eNote.Ax.Psych.Sleep !== "" ? this.eNote.Ax.Psych.Sleep : 1],
                Fatigue: [this.eNote.Ax.Psych.Fatigue !== "" ? this.eNote.Ax.Psych.Fatigue : 1],
                Concentration: [this.eNote.Ax.Psych.Concentration !== "" ? this.eNote.Ax.Psych.Concentration : 1],
                Appetite: [this.eNote.Ax.Psych.Appetite !== "" ? this.eNote.Ax.Psych.Appetite : 1],
                Irritability: [this.eNote.Ax.Psych.Irritability !== "" ? this.eNote.Ax.Psych.Irritability : 1],
                Panic: [this.eNote.Ax.Psych.Panic !== "" ? this.eNote.Ax.Psych.Panic : 1],
                Paranoia: [this.eNote.Ax.Psych.Paranoia !== "" ? this.eNote.Ax.Psych.Paranoia : 1],
                Hallucinations: [this.eNote.Ax.Psych.Hallucinations !== "" ? this.eNote.Ax.Psych.Hallucinations : 1],
                Delusions: [this.eNote.Ax.Psych.Delusions !== "" ? this.eNote.Ax.Psych.Delusions : 1],
                Suicidal: [this.eNote.Ax.Psych.Suicidal !== "" ? this.eNote.Ax.Psych.Suicidal : 1],
                OtherSymptoms: [this.eNote.Ax.Psych.OtherSymptoms !== "" ? this.eNote.Ax.Psych.OtherSymptoms : 1],
                //OtherSymptomsNote: [this.eNote.Ax.Psych.OtherSymptomsNote !== "" ? this.eNote.Ax.Psych.Anxiety:1],
              }),
            }),
          });
          this.populateMedications(this.eNote.REC.Medications); // Populate the Medications form array
          this.populateSxs(this.eNote.Ax.Sx); // Populate the Sx form array
          this.monitorFormChangesUpdate();
          console.log("this.eForm", this.eForm);
          this.oaxSrvc.updateState("SubstanceUse", parseInt(this.eNote.Ax.SubstanceUse));
          this.oaxSrvc.updateState("Physical", parseInt(this.eNote.Ax.Physical));
          this.oaxSrvc.updateState("Functional", parseInt(this.eNote.Ax.Functional));
          this.oaxSrvc.updateState("RiskOfHarm", parseInt(this.eNote.Ax.RiskOfHarm));
          for (const key in this.eNote.Ax.MH) {
            this.oaxSrvc.updateState(key, parseInt(this.eNote.Ax.MH[key]));
            this.AxMHTlt = parseInt(this.AxMHTlt) + parseInt(this.eNote.Ax.MH[key]);
            //console.log("eNote.Ax.MH key: ",key,"value: ",parseInt(this.eNote.Ax.MH[key]),"this.AxMHTlt: ",this.AxMHTlt);
          };
          for (const key in this.eNote.Ax.Psych) {//console.log("eNote.Ax.Psych key: ",key,"value: ",parseInt(this.eNote.Ax.Psych[key]));
            this.oaxSrvc.updateState(key, parseInt(this.eNote.Ax.Psych[key]));
            this.AxPsychTlt = parseInt(this.AxPsychTlt) + parseInt(this.eNote.Ax.Psych[key]);
            //console.log("eNote.Ax.Psych key: ",key,"value: ",parseInt(this.eNote.Ax.Psych[key]),"this.AxPsychTlt: ",this.AxPsychTlt);
          };
          this.encntr.note.Ax.MHTLT = this.AxMHTlt;
          this.encntr.note.Ax.PsychTLT = this.AxPsychTlt;
          this.AxMHTrgt = Object.keys(this.encntr.note.Ax.MH).length;
          this.encntr.note.Ax.MHTrgt = this.AxMHTlt;
          this.AxPsychTrgt = Object.keys(this.encntr.note.Ax.Psych).length;
          this.encntr.note.Ax.PsychTrgt = this.AxPsychTlt;
          this.monitorFormChanges();
        }
      })
    );
  }

  updateEForm() {
    this.isLoading = true;
    //console.log("Update eForm.value: ", this.eForm.value);
    let udData: any = [];
    udData.note = this.uSrvc.replaceNullWithEmptyString(this.eForm.value);
    console.log("udData.note", udData.note);
    JSON.stringify(udData)
    let eHistory: any = this.encntr.history;
    console.log("updateEForm BEFORE this.encntr", this.encntr);
    let rsp: any;
    this.aSrvc.encntrUD(this.encntr.id, udData.note).subscribe(
      (result) => { rsp = result; console.log(rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        if (rsp.status === "success") {
          this.encntr = rsp.encounter;
          this.encntr.history = eHistory; console.log("updateEForm AFTER this.encntr", this.encntr);
          this.uSrvc.selectEncounter(this.encntr);
          this.toastr.success(rsp.message);
        }
      }
    )
  };
  uploadEForm() { };

  /*++++++++++++++++++++++++++++++++++++++++++++++++*/
  // Assuming this.encntr is now an observable or you have a method to observe its changes
  // If this.encntr is not an observable, you would need to create one, for example, using a BehaviorSubject

  private monitorFormChangesUpdate(): void {
    this.setupFormChangeMonitoring('Assessments.MentalStatusExamination', total => this.asmtSrvc.updateMentalStatusTotal(total));
    this.setupFormChangeMonitoring('Assessments.PsychiatricSymptom', total => this.asmtSrvc.updatePsychiatricSymptomTotal(total));
  }

  private setupFormChangeMonitoring(path: string, updateFn: (total: number) => void): void {
    const formControl = this.eForm.get(path);/*

  if (!formControl) {
    console.error(`${path} is undefined`);
    return;
  }

  // Assuming `this.encntrChangesObservable()` and `this.encntr` are properly typed
  const encntrChanges = this.encntrChangesObservable();

  const subscription = combineLatest([
    formControl.valueChanges.pipe(startWith(formControl.value)),
    encntrChanges.pipe(startWith(this.encntr))
  ])
  .pipe(
    // Explicitly annotate the types of the parameters for the map function
    map(([values, _]: [{ [key: string]: unknown }, any]) => this.calculateTotal(values)),
    tap(total => updateFn(total))
    )
  .subscribe({
    next: () => {}, // Optionally handle the next event
    error: err => console.error(`Error monitoring ${path}:`, err),
    complete: () => {} // Optionally handle the completion
  });

  // Add the subscription to a subscription collection to manage unsubscription
  this.subscription.add(subscription);*/
  }

  private calculateTotal(values: { [key: string]: unknown }): number {
    return Object.values(values).reduce((acc: number, value: any) => {
      const parsedValue = parseInt(value, 10);
      return acc + (!isNaN(parsedValue) ? parsedValue : 0);
    }, 0);
  }

  // Placeholder for encntr changes observable
  private encntrChangesObservable(): Observable<any> {
    // Implement this based on how you can observe changes to `this.encntr`
    // For example, if `this.encntr` is a BehaviorSubject, you would simply return `this.encntr.asObservable()`
    return of(null); // Placeholder, replace with actual implementation
  }
  /*++++++++++++++++++++++++++++++++++++++++++++++++*/
  /**
 * Finds the path of a form control by its name.
 * @param control The form group or form array to search within.
 * @param controlName The name of the control to find.
 * @returns An array of strings representing the path to the control, or null if not found.
 */
  // Assuming this is within an Angular component or service class
  findControlPath(control: AbstractControl, controlName: string): string[] | null {
    if (control instanceof FormGroup || control instanceof FormArray) {
      if (control.get(controlName)) { return [controlName]; }
      const keys = control instanceof FormGroup ? Object.keys(control.controls) : control.controls.map((_, index) => String(index));
      for (const key of keys) {
        const childControl = control.get(key);
        if (childControl) {
          const childPath = this.findControlPath(childControl, controlName);
          if (childPath) { return [key, ...childPath]; }
        }
      }
    }
    return null;
  }

  //onSelectChange(questionCN: string, newValue: number): void {
  //  changeAx(event: any) {
  //const controlName = event.target.id;
  //const value = event.target.value;
  onSelectChange(questionCN: string, event: any): void {
    const newValue = event.target.value;
    const control: any = this.findControlPath(this.eForm, questionCN);
    console.log('control:', control, 'newValue:', newValue); // Output: ['addresses', '0', 'city']// Output: ['addresses', '0', 'city']
    if (control) { control.setValue(newValue, { emitEvent: true }); }
  }
  onSliderChange(controlName: string, event: any): void {
    const newValue = event.target.value;
    const path = this.findControlPath(this.eForm, controlName);
    console.log(controlName); // Output: ['addresses', '0', 'city']
    console.log(newValue); // Output: ['addresses', '0', 'city']
    console.log(path); // Output: ['addresses', '0', 'city']
    if (path) {
      const fullPath = path.join('.'); // Convert the path array to a dot-separated string path
      const control = this.eForm.get(fullPath); // Use the string path to get the form control
      if (control) { control.setValue(String(newValue)); }
      else { console.error(`Form control at path ${fullPath} not found.`); }
    }
    else { console.error(`Form control ${controlName} not found.`); }
  }
  /**
   * Recursively searches for and updates a form control value within a FormGroup or FormArray.
   * @param groupOrArray The current FormGroup or FormArray instance.
   * @param path Array of strings representing the path to the form control.
   * @param newValue The new value to set for the form control.
   */
  updateFormControlValue(groupOrArray: FormGroup | FormArray, path: string[], newValue: number) {
    const currentPath = path.shift(); // Remove and return the first path segment.
    if (!currentPath || !groupOrArray) return; // Path is empty or group/array is undefined.
    // If path is fully traversed, update the control's value.
    if (path.length === 0) {
      const control = groupOrArray.get(currentPath);
      if (control) { control.setValue(newValue); }
      else { console.error(`Form control ${currentPath} not found.`); }
      return;
    }

    // Recurse into the next level of the FormGroup or FormArray.
    const nextGroupOrArray = groupOrArray.get(currentPath);
    if (nextGroupOrArray instanceof FormGroup || nextGroupOrArray instanceof FormArray) {
      this.updateFormControlValue(nextGroupOrArray, path, newValue);
    } else { console.error(`Expected FormGroup or FormArray at ${currentPath}, found ${typeof nextGroupOrArray}.`); }
  }
  /********************** */
  private monitorFormChanges(): void {
    // Enhanced logic to monitor changes
    const axGroup = this.eForm.get('Ax');
    if (axGroup) {
      this.subscription.add(axGroup.valueChanges.pipe(debounceTime(300)).subscribe((values: any) => this.handleAxChanges(values)));
    }
  }
  handleAxChanges(values: any): void {
    // Enhanced change handling
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null && key !== 'Sx') {
        // Handle case when value is an object
        this.processChangeForObjectValue(key, value);
      } else if (typeof value === 'number') {
        // Process numeric values directly
        this.processChangeForNumericValue(key, value);
      }
    });
  }

  processChangeForObjectValue(key: string, value: any): void {
    // Logic to process changes when value is an object
    // Example: Convert to a number or string as needed
    if (typeof value === 'object') {
      //console.log('key: ', key, 'value: ' , value);
      //let tempObj: any = value; let newValue: string = tempObj.target.value; value = newValue; 

      Object.entries(value).forEach(([key, newValue]) => {
        //console.log('key: ', key, 'value: ', newValue);
        //console.log("Change Happened `${sectionPath}.${key}`", `${sectionPath}.${key}`, "value:", value);
        const numericValue = Number(newValue);
        if (!isNaN(numericValue)) {
          this.updateStateAndUI(key, numericValue);
          this.updateSelectAndSliderValue(key, numericValue);
        } else {
          console.error(`Invalid number: ${value}`);
        }
      })
    }
  }

  processChangeForNumericValue(key: string, value: number): void {
    this.updateStateAndUI(key, value);
    this.updateSelectAndSliderValue(key, value);
  }

  updateStateAndUI(key: string, value: number): void {
    // Example logic to update application state and UI
    //console.log(`Updating state for ${key} with value ${value}`);
    // Update state service and UI components as needed
  }

  /********************** */
  /**
   * Updates the value of a select dropdown and an input range slider by their IDs.
   * @param elementIdBase The base ID of the elements. The select has this ID, and the slider's ID is expected to be `{elementIdBase}_slider`.
   * @param newValue The new value to set for both elements.
   */
  updateSelectAndSliderValue(elementIdBase: string, newValue: any): void {
    // Update the select element
    //console.log("elementIdBase: ", elementIdBase, "newValue: ", newValue);
    const selectElement = document.getElementById(elementIdBase) as HTMLSelectElement;
    if (selectElement) {
      selectElement.value = newValue;
    } else { console.warn(`Select element with ID '${elementIdBase}' not found.`); }
    // Update the input range slider
    const sliderElement = document.getElementById(`${elementIdBase}_slider`) as HTMLInputElement;
    if (sliderElement) {
      sliderElement.value = newValue;
    } else { console.warn(`Slider element with ID '${elementIdBase}_slider' not found.`); }
  }

  // Dynamically add controls to the MH or Psych FormArrays as needed
  addControlToAx(type: 'MH' | 'Psych', control: FormGroup): void {
    (this.eForm.get(`Ax.${type}`) as FormArray).push(control);
  }

  get Meds_(): FormArray { return this.eForm.get('REC.Medications') as FormArray; }
  addMed() {
    this.Meds_.push(this.fb.group({
      medName: ['', Validators.required],
      medDosage: ['', Validators.required],
      medFrequency: ['', Validators.required],
      medRoute: ['', Validators.required],
      medClass: ['', Validators.required],
      medStartDate: ['', Validators.required],
      medEndDate: ['', Validators.required],
      medPurpose: ['', Validators.required],
      medICD10CMCode: ['', Validators.required],
      medNDC: ['', Validators.required],
      medSideEffects: ['', Validators.required],
      medAdherenceLevel: ['', Validators.required],
      medReasonsNonAdherence: ['', Validators.required],
    }));
  }
  private populateMedications(medications: any[]): void {
    if (Array.isArray(medications)) { medications.forEach((med) => { this.Meds_.push(this.createMedicationFormGroup(med)); }); }
    else { console.error('Expected an array of medications, but received:', medications); }
  }

  private createMedicationFormGroup(med: any): FormGroup {
    return this.fb.group({
      medName: [med.medName, Validators.required],
      medDosage: [med.medDosage, Validators.required],
      medFrequency: [med.medFrequency, Validators.required],
      medRoute: [med.medRoute, Validators.required],
      medClass: [med.medClass, Validators.required],
      medStartDate: [med.medStartDate, Validators.required],
      medEndDate: [med.medEndDate, Validators.required],
      medPurpose: [med.medPurpose, Validators.required],
      medICD10CMCode: [med.medICD10CMCode, Validators.required],
      medNDC: [med.medNDC, Validators.required],
      medSideEffects: [med.medSideEffects, Validators.required],
      medAdherenceLevel: [med.medAdherenceLevel, Validators.required],
      medReasonsNonAdherence: [med.medReasonsNonAdherence, Validators.required],
    });
  }

  get Sx_(): FormArray { return this.eForm.get('Ax.Sx') as FormArray; }
  addSx() {
    this.Sx_.push(this.fb.group({
      sxName: ['', Validators.required],
      sxOnsetDate: ['', Validators.required],
      sxSeverity: ['', Validators.required],
      sxDescription: ['', Validators.required],
      sxDurationVal: ['', Validators.required],
      sxDurationUnit: ['', Validators.required],
      sxFrequencyTime: ['', Validators.required],
      sxFrequencyPeriod: ['', Validators.required],
      sxLocationBodyPart: ['', Validators.required],
      sxLocationLaterality: ['', Validators.required],
      sxProgression: ['', Validators.required],
      sxImpactOnDailyActivities: ['', Validators.required],
      sxTriggers: ['', Validators.required],
      sxRelievingFactors: ['', Validators.required],
      sxAssociatedSymptoms: ['', Validators.required],
      sxCharacter: ['', Validators.required],
      //['medNote' + l_]: ['', Validators.required],
    }));
  }
  private populateSxs(sxs: any[]): void {
    if (Array.isArray(sxs)) { sxs.forEach((sx) => { this.Sx_.push(this.createSxFormGroup(sx)); }); }
    else { console.error('Expected an array of Symptoms, but received:', sxs); }
  }
  private createSxFormGroup(sx: any): FormGroup {
    return this.fb.group({
      sxName: [sx.sxName, Validators.required],
      sxOnsetDate: [sx.sxOnsetDate, Validators.required],
      sxSeverity: [sx.sxSeverity, Validators.required],
      sxDescription: [sx.sxDescription, Validators.required],
      sxDurationVal: [sx.sxDurationVal, Validators.required],
      sxDurationUnit: [sx.sxDurationUnit, Validators.required],
      sxFrequencyTime: [sx.sxFrequencyTime, Validators.required],
      sxFrequencyPeriod: [sx.sxFrequencyPeriod, Validators.required],
      sxLocationBodyPart: [sx.sxLocationBodyPart, Validators.required],
      sxLocationLaterality: [sx.sxLocationLaterality, Validators.required],
      sxProgression: [sx.sxProgression, Validators.required],
      sxImpactOnDailyActivities: [sx.sxImpactOnDailyActivities, Validators.required],
      sxTriggers: [sx.sxTriggers, Validators.required],
      sxRelievingFactors: [sx.sxRelievingFactors, Validators.required],
      sxAssociatedSymptoms: [sx.sxAssociatedSymptoms, Validators.required],
      sxCharacter: [sx.sxCharacter, Validators.required],
    });
  }

  ngOnDestroy(): void { this.subscription.unsubscribe(); if (this.speechRecognition) { this.stopListening(); } }

}
