import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Encounter } from 'src/app/Store/Model/type.Interfeaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-writeups',
  templateUrl: './writeups.component.html',
  styleUrls: ['./writeups.component.css']
})

export class WriteupsComponent implements OnInit, OnChanges {
  @Input() patient: any;
  @Input() encntr: any;
  @Input() cmpy: any;

  private subscription: Subscription = new Subscription();
  errors: any = null;
  isLoading: boolean = true;
  patientRdy: boolean = false;

  stf: any;
  loc: any;
  dpt: any;
  cnfg: any;
  tpCnfg: any;
  noteCnfg: any;
  templates: any;
  selectedTemplate: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService
  ) {
    const stf = localStorage.getItem('stf');
    if (stf) {
      this.stf = JSON.parse(stf);
      this.loc = JSON.parse(localStorage.getItem('loc') || '{}');
      this.dpt = JSON.parse(localStorage.getItem('dpt') || '{}');
    }
  }

  ngOnInit() {
    this.initializeComponent();
    this.subscribeToSelectedEncounter();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['encntr'] && !changes['encntr'].firstChange) {
      this.updateEncounter(this.encntr);
    }
  }

  private initializeComponent() {
    this.cnfg = this.cmpy?.Cnfg;
    this.tpCnfg = this.cmpy?.TreatmentPlan;
    this.noteCnfg = this.cmpy?.NoteTemplates;
    this.templates = this.cmpy?.NoteTemplates;
    this.selectedTemplate = this.noteCnfg?.[0];

    this.patientRdy = true;
  }

  private subscribeToSelectedEncounter() {
    this.subscription.add(this.uSrvc.selectedEncounter$.subscribe(encounter => { if (encounter) { this.updateEncounter(encounter); } }));
  }
  private updateEncounter(encounter: any) {
    this.encntr = encounter; //console.log('WriteupComsponent updateEncounter encntr: ', this.encntr);
    //console.log('WriteupComsponent updateEncounter templates: ', this.templates);
    if (this.encntr?.initAx) { this.selectedTemplate.id = 1; }//console.log("show initAx");
    else if (this.encntr?.progressAx) { this.selectedTemplate.id = 2; }//console.log("show progressAx");
    else if (this.encntr?.interventionAx) { this.selectedTemplate.id = 3; }//console.log("show interventionAx");
    else if (this.encntr?.dischargeAx !== null) { this.selectedTemplate.id = 4; }//console.log("show dischargeAx");*/
    //this.initializeForm();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}