import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { AuthService } from '../../../service/auth.service';
import { UtilitiesService } from '../../../service/utilities.service';
import { GroupSession } from '../../../Store/Model/type.Interfeaces';

@Component({
  selector: 'app-sessions-page',
  templateUrl: './sessions-page.component.html',
  styleUrls: ['./sessions-page.component.css']
})
export class SessionsPageComponent {
  errors: any = null;
  isLoading: boolean = true;
  cmpy: any; locs: any; loc: any; dpts: any; dpt: any; group_sessions: any;sTypes_: any;
  stf: any;

  isArray(val: any): boolean { return typeof val === 'object'; }
  showSession(type:any){}

  constructor(
    private route: ActivatedRoute, private router: Router, public uSrvc: UtilitiesService, public aSrvc: AuthService) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); this.router.navigate(['login/:id']); }
    else {
      if (stf === null) { console.log("redirect"); this.router.navigate(['login/:id']); }
      else {
        this.stf = JSON.parse(stf); console.log(this.stf);
      }
    }
  }
  /*
  showSession(sVw:any){this.sTypes_=[];let sVw_:any;
    if(sVw==='Type'){sVw_=this.group_sessions.reduce<{[k:string]:GroupSession[]}>((acc,val)=>((acc[val.Type]||(acc[val.Type]=[])).push(val),acc),{});}
    else if(sVw==='Department'){
      sVw_=this.group_sessions.reduce<{[k:string]:GroupSession[]}>((acc,val)=>((acc[val.dptN]||(acc[val.dptN]=[])).push(val),acc),{});}
    else if(sVw==='Location'){sVw_=this.group_sessions.reduce<{[k:string]:GroupSession[]}>((acc,val)=>((acc[val.Location.N]||(acc[val.Location.N]=[])).push(val),acc),{});}
    // @ts-ignore
    var xobj:any;for(let [key, value] of Object.entries(sVw_)){xobj={Header:key,Department:value[0].dptN,TheSessions:value};this.sTypes_.push(xobj);}console.log(this.sTypes_);
  }
  */
  ngOnInit(): void {
    const [lid, did, gid] = this.route.snapshot.paramMap.get('id')!.split('-');console.log(lid,did,gid);
    this.aSrvc.getDpt(this.stf.cID,lid,did).subscribe(
      (result: any) => { this.dpt = result;},
      (error: { error: any; }) => { this.errors = error.error; console.log(this.errors); },
      () => {
        console.log('session page this.dpt:', this.dpt);this.group_sessions=this.dpt.group_sessions;
      }
    )
  }
}
