import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';

//import {Location} from '@angular/common';
import { UtilitiesService } from '../../service/utilities.service';
import { AuthService } from '../../service/auth.service';
import { ToastrService } from 'ngx-toastr'
import { Staff } from '../../Store/Model/type.Interfeaces';
import { GroupSession } from '../../Store/Model/type.Interfeaces';

@Component({
  selector: 'app-cmpy-configure',
  templateUrl: './cmpy-configure.component.html',
  styleUrls: ['./cmpy-configure.component.css']
})
export class CmpyConfigureComponent implements OnInit {
  @Output() addEvt = new EventEmitter();
  rsp: any;auth: any; errors: any = null;
  cmpy:any;locs:any;loc:any;lID:any;dpts:any;dpt:any;dID:any;rptDepart:any;stf:any;stfs:Staff[]=[];
  sessions: any[]=[];
  theSessions: any[]=[];
  sessionTypes_: any[]=[];
  sessionTypes: any[]=[];
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      public fb:FormBuilder,
      private toastr: ToastrService,
      public uSrvc: UtilitiesService,
      public aSrvc: AuthService,
  ){
      console.log(localStorage);
      const stf_:any=localStorage.getItem('stf');const stfs:any=localStorage.getItem('stfs');
      if(stf_===null){console.log("redirect");this.router.navigate(['patients']);}
      else{
        this.stf = JSON.parse(stf_);console.log(this.stf);
        this.stfs = JSON.parse(stfs);console.log(this.stfs);
        const c:any=localStorage.getItem('cmpy');const c_:any=JSON.parse(c);this.cmpy = c_;console.log(this.cmpy);
        const locs:any=localStorage.getItem('locs');this.locs = JSON.parse(locs);console.log(this.locs);
        const loc:any=localStorage.getItem('loc');this.loc = JSON.parse(loc);console.log(this.loc);
        this.dpts=this.loc.Departments;console.log(this.dpts);
        const dpt:any=localStorage.getItem('dpt_');this.dpt = JSON.parse(dpt);console.log(this.dpt);
      }
    }
  
  handleAdd(formInfo: any) {
    const tempItem: object = {
      N: formInfo.N,
      oN: formInfo.oN,
      Date: formInfo.Date + ' ' + formInfo.Time,
      Notes: formInfo.Notes
    };
    this.addEvt.emit(tempItem);
  }
  ngOnInit() {
    //this.cmpy.cID;
    //const snapshot:any=this.route.snapshot;console.log('snapshot',snapshot);
    let url:any=this.route.snapshot.url;url = url[1].path;console.log('url',url);
    const tID:any=this.route.snapshot.paramMap.get('id');console.log(tID);
    let lID = this.stf.lID;let dID = this.stf.dID;
    if (url==='l') {lID=this.uSrvc.getLid(tID);}
    if (url==='d') {lID=this.uSrvc.getLid(tID);dID=this.uSrvc.getDid(tID);}
    console.log(lID+'|'+dID);
    this.loc=this.locs.find((loc: { lID: any; }) => loc.lID === lID);console.log(this.loc);
    this.dpts=this.loc.Departments;//console.log(this.dpts);
    this.dpt=this.dpts.find((dpt: { dID: any; }) => dpt.dID === dID);console.log(this.dpt);
    this.aSrvc.GetLocStf( lID).subscribe(
      (result) => { this.responseHandler(result); this.stfs = result; },
      (error) => { this.errors = error.error; },
      () => {console.log('this.stfs',this.stfs);localStorage.setItem('stfs', JSON.stringify(this.stfs));}
    );

  }
  responseHandler(data: any) {/* this.token.handleData(data.atoken); */} // Handle response
}