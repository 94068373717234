<div class="container-fluid" style="padding:0.0625rem;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm" *ngIf="eDpt.N">
        <h4 class="fw-normal mb-1 pb-1" style="letter-spacing: 1px;">{{eDpt.N}}: Department Profile</h4>
        <div class="col-12" style="margin:0;padding:0;">
            <a routerLink="/cmpyAdmin/{{lID}}-{{dID}}" title="Back" class="btn btn-light" role="button"
                style="float:left;"><i class="bi bi-arrow-left-square"></i></a>
            <button type="submit" class="btn" title="Update Department Profile" style="float:right;" (click)="eFormUD()"><i
                    class="bi bi-save"></i></button>
        </div>
        <div class="row editDiv">
            <div class="col-md-12">
                <label for="N" class="form-label">Name</label>
                <input type="text" class="form-control" id="N" [value]="eDpt.N" formControlName="N">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-3">
                <label for="P1" class="form-label">Primary Phone Number</label>
                <input type="tel" class="form-control" id="P1" (blur)="updatePhn($event)"
                    [value]="eDpt.Adr.P1" formControlName="P1">
            </div>
            <div class="col-md-3">
                <label for="TF" class="form-label">Toll-Free Phone Number</label>
                <input type="tel" class="form-control" id="TF" (blur)="updatePhn($event)"
                    [value]="eDpt.Adr.TF" formControlName="TF">
            </div>
            <div class="col-md-3">
                <label for="F" class="form-label">Fax Phone Number</label>
                <input type="tel" class="form-control" id="F" (blur)="updatePhn($event)"
                    [value]="eDpt.Adr.F" formControlName="F">
            </div>
            <div class="col-md-3">
                <label for="E" class="form-label">Email</label>
                <input type="email" class="form-control" id="E" [value]="eDpt.Adr.E" formControlName="E">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="A" class="form-label">Address</label>
                <input type="text" class="form-control" id="A" [value]="eDpt.Adr.A" formControlName="A">
            </div>
            <div class="col-md-6">
                <label for="A2" class="form-label">Address 2</label>
                <input type="text" class="form-control" id="A2" [value]="eDpt.Adr.A2" formControlName="A2">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" [value]="eDpt.Adr.C" formControlName="C">
            </div>
            <div class="col-md-4">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" [value]="eDpt.Adr.S" formControlName="S">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="Z" class="form-label">Zip</label>
                <input type="text" class="form-control" id="Z" [value]="eDpt.Adr.Z" formControlName="Z">
            </div>
        </div>
        <div class="col-md-4">
            <label for="tSlct">Department Type</label>
            <select class="form-select" id="tSlct" formControlName="T">
                <option value="">Select Department Type</option>
                <option [value]="t_.T" [selected]="t_.T === eDpt.T" *ngFor="let t_ of eLoc.DType">{{ t_.TLong }}</option>
            </select>
        </div>
        <div class="row editDiv">
            <h5>Primary Contacts:</h5>
            <div class="col-md-3">
                <label for="pNSlct">Nursing</label>
                <select class="form-select" id="pNSlct" (change)="chgNursing($event)" formControlName="pN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pPSlct">Provider</label>
                <select class="form-select" id="pPSlct" (change)="chgProvider($event)" formControlName="pP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Provider" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pASlct">Admin</label>
                <select class="form-select" id="pASlct" (change)="chgAdmin($event)" formControlName="pA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pISlct">IT</label>
                <select class="form-select" id="pISlct" (change)="chgIT($event)" formControlName="pI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <h5>Secondary Contacts:</h5>
            <div class="col-md-3">
                <label for="sNSlct">Nursing</label>
                <select class="form-select" id="sNSlct" (change)="chgNursing($event)" formControlName="sN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sPSlct">Provider</label>
                <select class="form-select" id="sPSlct" (change)="chgProvider($event)" formControlName="sP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Provider" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sASlct">Admin</label>
                <select class="form-select" id="sASlct" (change)="chgAdmin($event)" formControlName="sA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sISlct">IT</label>
                <select class="form-select" id="sISlct" (change)="chgIT($event)" formControlName="sI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
        </div>
    </form>
</div>