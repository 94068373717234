import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-filter-popup',
  templateUrl: './patient-filter-popup.component.html',
  styleUrls: ['./patient-filter-popup.component.css']
})
export class PatientFilterPopupComponent implements OnInit {
  filterForm!: FormGroup;
  cmpy: any;stf: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public filter: any
  ) {
    const stf: any = localStorage.getItem('stf');
      this.stf = JSON.parse(stf); //console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
  }

  ngOnInit(): void {
    this.initializeForm(this.filter);
  }

  private initializeForm(f_:any): void {
    this.filterForm = this.fb.group({
      Date: [f_.filter.Date || null], // Use null for clearer intention when no value is provided
      dateRange: [f_.filter.dateRange || ''],
      ageRange: [f_.filter.ageRange || ''],
      gender: [f_.filter.gender || ''],
      sortBy: [f_.filter.sortBy], // Default sort, adjust as needed
      prom: [f_.filter.prom], // Assuming default value is an empty string if not provided
      showVets: [f_.filter.showVets], // Assuming boolean, adjust default as needed
      showNotes: [f_.filter.showNotes], // Same assumption as above
      minimum: [f_.filter.minimum],
      mild: [f_.filter.mild],
      moderate: [f_.filter.moderate],
      severe: [f_.filter.severe],
    });
    console.log(this.filterForm);
  }
}
