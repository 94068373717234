<div class="container-fluid" style="padding:0.125rem;">
    <a routerLink="/cmpyAdmin" title="Back" class="btn btn-light" Role="button" style="float:left;"><i class="bi bi-arrow-left-square"></i></a>
    <h3>{{cmpy.N }}: Configuration</h3>
    <div class="category">
        <h5>Company:</h5>
        <table class="table align-middle mb-0 bg-white">
            <thead class="bg-light"><tr><th>Name</th><th>Contacts</th><th>Address</th><th>Phone</th><th>Type</th><th>Action</th></tr></thead>
            <tbody>
            <tr>
                <td>{{cmpy.N}}</td>
                <td>
                    <span>Admin: {{cmpy.Contacts.Primary.Admin}}</span><br>
                    <span>Nursing: {{cmpy.Contacts.Primary.Nursing}}</span><br>
                    <span>Provider: {{cmpy.Contacts.Primary.Provider}}</span><br>
                    <span>IT: {{cmpy.Contacts.Primary.IT}}</span><br>
                </td>
                <td><i class="bi bi-geo-alt"></i> {{cmpy.Adr.A}}, {{cmpy.Adr.A2}}<br>{{cmpy.Adr.C}}, {{cmpy.Adr.S}}, {{loc.Adr.Z }}</td>
                <td><i class="bi bi-telephone"></i> {{cmpy.Adr.P1}}<br>Fax:{{cmpy.Adr.F }}</td>
                <td>{{loc.TLong}}</td>
                <td>
                    <a routerLink="/editCmpy" class="btn btn-success"><i class="bi bi-pencil"></i></a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="category">
        <h5>Location:</h5>
        <table class="table align-middle mb-0 bg-white">
            <thead class="bg-light"><tr><th>Name</th><th>Contacts</th><th>Address</th><th>Phone</th><th>Type</th><th>Action</th></tr></thead>
            <tbody>
            <tr>
                <td>{{loc.N}}</td>
                <td>
                    <span>Admin: {{loc.Contacts.Primary.Admin}}</span><br>
                    <span>Nursing: {{loc.Contacts.Primary.Nursing}}</span><br>
                    <span>Provider: {{loc.Contacts.Primary.Provider}}</span><br>
                    <span>IT: {{loc.Contacts.Primary.IT}}</span><br>
                </td>
                <td><i class="bi bi-geo-alt"></i> {{loc.Adr.A}}, {{loc.Adr.A2}}<br>{{loc.Adr.C}}, {{loc.Adr.S}}, {{loc.Adr.Z }}</td>
                <td><i class="bi bi-telephone"></i> {{loc.Adr.P1}}<br>Fax:{{loc.Adr.F }}</td>
                <td>{{loc.TLong}}</td>
                <td><a routerLink="/editLoc/{{loc.lID}}" class="btn btn-success"><i class="bi bi-pencil"></i></a></td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="category">
        <h5>Department: <a routerLink="editDpt/n" class="btn btn-primary"><i class="bi bi-building-add"></i> Add</a> </h5>
        <table class="table align-middle mb-0 bg-white">
            <thead class="bg-light"><tr><th>Name</th><th>Contacts</th><th>Address</th><th>Phone</th><th>Type</th><th>Action</th></tr></thead>
            <tbody *ngFor="let d_ of dpts">
                <tr>
                    <td>{{d_.N}}</td>
                    <td>
                        <span>Admin: {{d_.Contacts.Primary.Admin}}</span><br>
                        <span>Nursing: {{d_.Contacts.Primary.Nursing}}</span><br>
                        <span>Provider: {{d_.Contacts.Primary.Provider}}</span><br>
                        <span>IT: {{d_.Contacts.Primary.IT}}</span><br>
                    </td>
                    <td><i class="bi bi-geo-alt"></i> {{d_.Adr.A}}, {{d_.Adr.A2}}<br>{{d_.Adr.C}}, {{d_.Adr.S}}, {{d_.Adr.Z}}</td>
                    <td><i class="bi bi-telephone"></i> {{d_.Adr.P1}}<br>Fax:{{d_.Adr.F }}</td>
                    <td>{{d_.TLong}}</td>
                    <td>
                        <a routerLink="/editDpt/e/{{d_.lID}}-{{d_.dID}}" class="btn btn-success"><i class="bi bi-pencil"></i></a>
                        <a routerLink="/editDpt/d/{{d_.lID}}-{{d_.dID}}" class="btn btn-danger"><i class="bi bi-x-square"></i></a>
                    </td>
                </tr>
                <tr *ngIf="uSrvc.isArray(d_.GroupSessions)">
                    <td colspan="6">
                        <div class="category">
                            <h5>Group Sessions: <a routerLink="/editSession/N" class="btn btn-primary"><i class="bi bi-calendar-plus"></i> Add</a> </h5>
                            <table class="table align-middle mb-0 bg-white">
                                <thead class="bg-light"><tr><th>Name</th><th>Schedule</th><th>Contacts</th><th>Address</th><th>Phone</th><th>Type</th><th>Action</th></tr></thead>
                                <tbody>
                                    <tr *ngFor="let g_ of d_.GroupSessions">
                                        <td>{{g_.N}}</td>
                                        <td>{{g_.Schedule.sDate}} - {{g_.Schedule.eDate}}, Time: {{g_.Schedule.sTime}}<br>Duration: {{g_.Schedule.duration}}, Frequency: {{g_.Schedule.frequency}}</td>
                                        <td>
                                            <span>Admin: {{g_.Contacts.Primary.Admin}}</span><br>
                                            <span>Nursing: {{g_.Contacts.Primary.Nursing}}</span><br>
                                            <span>Provider: {{g_.Contacts.Primary.Provider}}</span><br>
                                            <span>IT: {{g_.Contacts.Primary.IT}}</span><br>
                                            <span *ngFor="let c1_ of g_.Coordinators"><i class="bi bi-person-gear"></i> Coordinator: {{c1_.sID}}</span><br>
                                            <!--span *ngFor="let cp_ of g_.Contacts"><i class="bi bi-person-gear"></i> Coordinator: {{c_.sID}}</span><br!-->
                                        </td>
                                        <td>{{g_.N}}<br>{{g_.Location.A}} - {{g_.Location.A2}}</td>
                                        <td><i class="bi bi-telephone"></i> {{g_.Location.P1}}<br>Fax:{{g_.Location.F }}</td>
                                        <td>{{g_.TLong}}</td>
                                        <td>
                                            <a routerLink="/editGS/e/{{g_.lID}}-{{g_.dID}}-{{g_.gID}}" class="btn btn-success"><i class="bi bi-pencil"></i></a>
                                            <a routerLink="/editGS/d/{{g_.lID}}-{{g_.dID}}-{{g_.gID}}" class="btn btn-danger"><i class="bi bi-x-square"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="category">
        <h5>Staff: <a routerLink="/editStf/c" class="btn btn-primary"><i class="bi bi-person-add"></i> Add</a> </h5>
        <table class="table align-middle mb-0 bg-white">
            <thead class="bg-light">
                <tr>
                    <th>Name</th><th>Access Level</th><th>Role</th><th>ID</th><th>Location</th><th>Department</th><th>Phone</th><th>Email</th><th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let stf_ of stfs">
                    <td>{{stf_.fN}} {{stf_.lN}}</td>
                    <td>{{stf_.AL}}</td>
                    <td>{{stf_.R}}</td>
                    <td>{{stf_.sID}}</td>
                    <td><i class="bi bi-geo-alt"> {{stf_.lID}}</i></td>
                    <td><i class="bi bi-pin-map"> {{stf_.dID}}</i></td>
                    <td><i class="bi bi-telephone"> {{stf_.wP}}</i></td>
                    <td><i class="bi bi-envelope"> {{stf_.E}}</i></td>
                    <td>
                        <a routerLink="/editStf/e/{{stf_.lID}}-{{stf_.dID}}-{{stf_.sID}}" class="btn btn-success"><i class="bi bi-pencil"></i></a>
                        <a routerLink="/editStf/d/{{stf_.lID}}-{{stf_.dID}}-{{stf_.sID}}" class="btn btn-danger"><i class="bi bi-x-square"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>