import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '../../../Store/Model/type.Interfeaces';
import { Appointment } from '../../../Store/Model/type.Interfeaces';
import { Patient } from '../../../Store/Model/type.Interfeaces';
import { ToastrService } from 'ngx-toastr'
import { AuthService } from '../../../service/auth.service';


@Component({
  selector: 'app-deactivation',
  templateUrl: './deactivation.component.html',
  styleUrls: ['./deactivation.component.css']
})
export class DeactivationComponent implements OnInit{
  isAssessmentMenuActv: boolean = true;
  locations: Location[]=[];
  lID:any;
  location:any;
  department:any;
  dID:any;
  Apt: any;
  staff:any;
  aID:any;
  Q_: any;
  qck_: boolean= false
  currentQ_: any;
  theAppts:Appointment[]=[];
  @Output() ActivateEvt = new EventEmitter();
  @Output() updateEvt = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private authSrvc: AuthService,
    private router: Router,
    private toastr: ToastrService,
    ) {}
  
  deactivAssessment = this.builder.group({
    userID: this.builder.control('', Validators.required),
    password: this.builder.control('', Validators.required)
  });

  //Deactivate(theApt: object, labelName: string, newValue: string) {this.updateEvt.emit({theApt: theApt,labelName: labelName,newValue: newValue}); }
  deactive() {  
    console.log(localStorage);
    if (this.deactivAssessment.valid) {
      this.authSrvc.GetStf(this.deactivAssessment.value.userID).subscribe(item => {
        const res_: any = item;
        this.staff = res_[0]; console.log(this.staff);
        if (this.staff.password === this.deactivAssessment.value.password) {
          if (this.staff.isactive) {
            this.router.navigate(['axAppointments/' + this.staff.locID + '/' + this.staff.dptID]);
          }
        }
      });
    }
}  

  ngOnInit() {
  //  this.company=testCompany;console.log(this.company[0]);
    //const tID:any=this.route.snapshot.paramMap.get('id');
    //this.lID=tID.substring(0,10);this.dID=tID.substring(11,15);
    //this.aID=tID.substring(16,tID.length-2);console.log(tID,this.lID,this.dID,this.aID);
    const Q_:any=localStorage.getItem('Q_');
    this.Q_ = JSON.parse(Q_);console.log(this.Q_);
    //if (this.Q_.ID==="PHQ9"&&this.Q_.question[0].value==='0'&&this.Q_.question[1].value==='0'){this.qck_=true;}else{this.qck_=false;}
    const Apt:any=localStorage.getItem('Apt');
    this.Apt = JSON.parse(Apt);console.log(this.Apt);
    this.currentQ_=localStorage.getItem('currentQ_');


    //this.location=testLocations.find(location=>location.ID===this.lID);console.log(this.location);
    //this.department=this.location.Departments.find((Departments: { ID: string; }) => Departments.ID === this.dID);console.log(this.department);

    /***
    this.http.get<any>('../../assets/dataApmts.json').subscribe(data => {
      this.theAppts=data;console.log(this.theAppts);
      this.patient=this.theAppts.find((Patient: {MRN: string;}) => Patient.MRN === aID);console.log(this.patient);
    }); /***/
  }

}
