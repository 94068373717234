import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { AppointmentMonitorService } from '../../../service/appointment-monitor.service';
import { SocketService } from '../../../service/socket.service';
import { AppointmentService } from 'src/app/service/appointment.service';
import { TimeMonitorService } from '../../../service/time-monitor.service';


@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // Optimize performance by reducing change detection runs
})
export class AppointmentsComponent implements OnInit {
  private subscriptions = new Subscription();
  appointmentsStatus$!: Observable<boolean[]>;
  errors: any = null;
  rsp: any; auth: any;
  header: boolean = false;
  //  [x: string]: any;
  rows: any[] = [{ Appt_DT_TM: "", AptStatus: "", RESOURCE_1: "", APPT_SYNONYM_FREE: "", NAME_FULL_FORMATTED: "", MRN: "", BIRTH_DT_TM: "", PCP_NAME: "", PROBLEMS: "" }];
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  chrtCnfg: any = [];
  stf: any;
  isLoading: boolean = true;
  isLoading$!: Observable<boolean>;
  appointments$!: Observable<any[]>;
  appointmentStatus$!: Observable<boolean[]>;
  theAppts: any[] = [];

  private subscription: Subscription = new Subscription();
  message: string = 'Waiting for appointment time...';

  @Output() updateEvt = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private appointmentMonitorService: AppointmentMonitorService,
    private timeMonitorService: TimeMonitorService,
    private aSrvc: AuthService,
    private apptSrvc: AppointmentService,
    //private dialog: MatDialog,
    private socketService: SocketService
  ) {
    this.timeMonitorService.setAppointmentTime('2024-04-17T15:00:00Z');
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log("loc", this.loc);
      const dpt: any = localStorage.getItem('dpt'); this.dpt = JSON.parse(dpt); console.log("dpt", this.dpt);
    }
  }

  dateNow: Date = new Date();
  formattedDate = this.dateNow.toDateString();
  handleDelete(theApt: object) { console.log(theApt); }
  handleUpdate(theApt: object, labelName: string, newValue: string) {
    this.updateEvt.emit({ theApt: theApt, labelName: labelName, newValue: newValue });
  }
  sendPatient(appointment: object) { console.log(appointment); }
  ngOnInit(): void {
    this.lID = this.route.snapshot.paramMap.get('param1');
    this.dID = this.route.snapshot.paramMap.get('param2'); console.log(this.lID, this.dID);
    this.initializeAppointments();
    //this.listenToAppointmentTimes();
    this.appointmentStatus$ = this.appointmentMonitorService.getAppointmentsStatus();
    console.log('ngOnInit:', this.theAppts);
  }
  private initializeAppointments(): void {
    // This single observable will handle the fetching of appointments and will be unsubscribed automatically.
    this.isLoading = true; // Assume loading starts here
    const appointmentSubscription = this.apptSrvc.getSortedAppointments(this.lID, this.dID)
      .subscribe({
        next: (appointments) => {
          this.theAppts = appointments; // Assign fetched appointments directly
          console.log('initializeAppointments:', this.theAppts);
          this.isLoading = false; // Set loading false on data receipt

        },
        error: (error) => {
          console.error("Failed to fetch appointments: ", error);
          this.isLoading = false; // Ensure loading is also set to false on error
        }
      });

    // Add the subscription to the component's subscription list to manage lifecycle
    this.subscriptions.add(appointmentSubscription);
  }
  loadAppointmentTimes(): void {
    // Simulate fetching appointment times from a server
    const appointmentTimes: Date[] = [
      new Date(new Date().getTime() + 10000), // 10 seconds from now
      new Date(new Date().getTime() + 20000), // 20 seconds from now
    ];
    this.appointmentMonitorService.setAppointmentTimes(appointmentTimes);
  }
  /***
  private initializeIds(): void {
    const tID: any = this.route.snapshot.paramMap.get('id');
    if (tID !== null) {
      this.lID = this.appointmentService.getLid(tID);
      this.dID = this.appointmentService.getDid(tID);
    } else {
      this.lID = localStorage.getItem('currentLID');
      this.dID = localStorage.getItem('currentDID');
    }
    localStorage.setItem('currentLID', this.lID);
    localStorage.setItem('currentDID', this.dID);
  }
  /****/

  //theApptsTest: any[] = [];
  /*
  fetchAppointments(): void {
    this.isLoading = true;
    this.isLoading$ = this.apptSrvc.isLoading$; // Assuming isLoading$ is an observable that tracks loading state.
    this.appointments$ = this.apptSrvc.getSortedAppointments(this.lID, this.dID);
    this.apptSrvc.getSortedAppointments(this.lID, this.dID)
      .subscribe({
        next: (appointments) => {
          const theAppts = appointments; // Assuming you want to store the appointments in an array
          console.log('fetchAppointments:', theAppts); this.theAppts = theAppts;
          this.isLoading = false;
        },
        error: (error) => console.error("Failed to fetch appointments: ", error)
      });
  }
  */
  getCnfrmAppointments() {
    this.isLoading = true;
    this.isLoading$ = this.apptSrvc.isLoading$; // Assuming isLoading$ is an observable that tracks loading state.
    this.appointments$ = this.apptSrvc.getSortedAppointments(this.lID, this.dID);
    //let aData: any; console.log(this.lID, this.dID);
    this.apptSrvc.getSortedAppointments(this.lID, this.dID)
      .subscribe({
        next: (appointments) => {
          const theAppts = appointments; // Assuming you want to store the appointments in an array
          console.log('fetchAppointments:', theAppts); this.theAppts = theAppts;
          this.isLoading = false;
        },
        error: (error) => console.error("Failed to fetch appointments: ", error)
      });
  }

  // Utility function to convert time to 24-hour format (military time) if not already
  convertToStandardTime(time: string): string {
    // Log the received time for debugging
    console.log(time);
    // Extract hours and minutes from the time string
    const timeParts = time.match(/(\d{1,2}):(\d{2})/);
    if (!timeParts) return time; // Return the original time if it doesn't match the expected pattern
    let [_, hoursStr, minutes] = timeParts;
    let hoursInt = parseInt(hoursStr, 10); // Convert the hours part to an integer for comparison
    // Determine AM or PM period and adjust hours to 12-hour format
    let period = 'AM';
    if (hoursInt >= 12) {
      period = 'PM';
      if (hoursInt > 12) hoursInt -= 12; // Convert to 12-hour format for PM times except for 12 PM itself
    } else if (hoursInt === 0) {
      hoursInt = 12; // Convert 00 hours to 12 AM
    }

    // Ensure hours are represented as a string with leading 0 if needed
    const hoursFinal = hoursInt.toString().padStart(2, '0');

    // Construct and return the converted time string with AM or PM
    return `${hoursFinal}:${minutes} ${period}`;
  }
  private listenToSocketEvents(): void {
    this.subscriptions.add(
      this.socketService.on('apmntStatusChange').subscribe(
        data => {
          console.log(data);
          // Update your appointment data here
        },
        error => {
          console.error('Socket error:', error);
        }
      )
    );
  }
  private listenToAppointmentTimes(): void {
    this.appointmentsStatus$ = this.appointmentMonitorService.getAppointmentsStatus();
    this.subscriptions.add(
      this.appointmentMonitorService.isAppointmentTime().subscribe((isAppointment: any) => {
        this.message = isAppointment ? 'It is appointment time!' : 'Waiting for appointment time...';
      })
    );
  }

  private listenToAppointmentStatusChanges(): void {
    // Ensures the socket is connected before subscribing to events
    this.socketService.connect();

    // Subscribes to the AppointmentStatusChanged event from the SocketService
    const appointmentStatusChangeSubscription = this.socketService.subscribeToAllAppointments()
      .subscribe({
        next: (data: any) => {
          console.log('AppointmentStatusChanged received:', data);
          // Find the index of the appointment with the matching id in the theAppts array
          const index = this.theAppts.findIndex(appt => appt.id === data.id);

          // If the appointment is found, update it directly
          if (index !== -1) {
            // Assuming 'data' contains the updated status and possibly other updated fields
            this.theAppts[index] = { ...this.theAppts[index], ...data };

            // Optionally, if you need to trigger change detection manually (e.g., when using OnPush strategy)
            // this.cdRef.markForCheck(); // cdRef is a reference to ChangeDetectorRef injected into your component

            // Note: The spread operator ({...}) is used to create a new object with the updated properties
            // This ensures that we are not mutating the original object directly, which is a good practice in Angular applications
          }
        },
        error: (error) => console.error('Error receiving AppointmentStatusChanged:', error)
      });
    this.subscriptions.add(appointmentStatusChangeSubscription);
  }
  getAllAppointments() {
    this.isLoading = true;
    let aData: any; console.log(this.lID, this.dID);
    this.aSrvc.GetApptDpt(this.lID, this.dID).subscribe(
      (result) => { aData = result; },
      (error) => { this.errors = error.error; console.log(this.errors); this.isLoading = true; },
      () => {
        //this.theAppts = [];
        this.theAppts = _.sortBy(aData, ['Time']); console.log("getAllAppointments(): ", this.theAppts);
        this.isLoading = false;
      }
    );
  }
  sendMessage() {
    this.socketService.emit('apmntStatusChange', { message: 'Hello, Socket.IO' });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe(); // Properly unsubscribe to avoid memory leaks
    this.subscription.unsubscribe();
    this.socketService.disconnect();
  }

}

