import { Component, OnInit, OnChanges, SimpleChanges, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notes-progress',
  templateUrl: './notes-progress.component.html',
  styleUrls: ['./notes-progress.component.css']
})
export class NotesProgressComponent implements OnInit, OnChanges {
  @Input() patient: any;
  @Input() encntr: any;
  @Input() cmpy: any;

  private subscription: Subscription = new Subscription();
  isLoading: boolean = true;
  errors: any = null;

  eForm: FormGroup;
  patientRdy: boolean = false;
  progressAx: any;

  cnfg: any;
  tpCnfg: any;
  noteCnfg: any;
  PHQ9Cnfg: any;
  GAD7Cnfg: any;
  WHO5Cnfg: any;
  PhysicalCnfg: any;
  FunctionalCnfg: any;

  selectedTemplate: any;
  promMeasures_: any = [];

  currentLevel: number = 15;
  targetLevel: number = 40;

  stf: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private renderer: Renderer2
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf) {
      this.stf = JSON.parse(stf);
      console.log(this.stf);
    }
    this.eForm = this.fb.group({
      PsychosocialFactors: ['', Validators.required],
      AnticipatedOutcomes: ['', Validators.required],
      MonitoringAndEvaluationPlan: ['', Validators.required],
      UtilizationReviewConsiderations: ['', Validators.required],
      FollowUpPlan: ['', Validators.required],
      ComplexityFactors: ['', Validators.required],
      PreliminaryDiagnosis: this.fb.group({
        ClinicalImpression: ['', Validators.required],
        Justification: ['', Validators.required],
        ICD10CMCodes: ['', Validators.required],
        JCodesConsiderations: ['', Validators.required],
      }),
      Prognosis: this.fb.group({
        Description: ['', Validators.required],
        Justification: ['', Validators.required],
      }),
      ClinicalAssessment: this.fb.group({
        ClinicalObservations: this.fb.group({
          MentalHealthStatus: ['', Validators.required],
          PsychologicalHealth: ['', Validators.required],
          SubstanceUse: ['', Validators.required],
          PhysicalHealth: ['', Validators.required],
          FunctionalCapacity: ['', Validators.required],
          Overall: ['', Validators.required],
        }),
        PatientReportedOutcomeMeasures: this.fb.group({}),
      }),
      PreliminaryTreatmentPlan: this.fb.group({
        ProposedTreatment: ['', Validators.required],
        Justification: ['', Validators.required],
        ICD10CMCodes: ['', Validators.required],
        TreatmentModality: ['', Validators.required],
        ProposedMedication: this.fb.array([
          this.fb.group({
            Dosage: [''],
            Interval: [''],
            Frequency: [''],
            ICD10CMCodes: [''],
            Justification: [''],
            PossibleSideEffects: [''],
            Medication: [''],
          })
        ])
      }),
      PresentingSymptoms: this.fb.array([
        this.fb.group({
          SymptomName: [''],
          Onset: [''],
          Duration: [''],
          Severity: [''],
          Description: ['']
        })
        // Add more groups if necessary
      ])
    });
  }
  ngOnInit() {
    this.initializeComponent();
    this.subscribeToSelectedEncounter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['encntr'] && !changes['encntr'].firstChange) {
      this.updateEncounter(this.encntr);
    }
  }

  private initializeComponent() {
    this.cnfg = this.cmpy?.Cnfg;
    this.tpCnfg = this.cmpy?.TreatmentPlan;
    this.noteCnfg = this.cmpy?.NoteTemplates;
    this.selectedTemplate = this.noteCnfg?.[0];

    const eLength = this.patient?.encounters.length;
    this.encntr = this.patient?.encounters[eLength - 1];
    this.updateEncounter(this.encntr);

    this.patientRdy = true;
  }

  private subscribeToSelectedEncounter() {
    this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => {
        if (encounter) {
          this.updateEncounter(encounter);
        }
      })
    );
  }

  private updateEncounter(encounter: any) {
    this.encntr = encounter;
    if (this.encntr?.progressAx) {
      this.progressAx = this.encntr.progressAx.original.AssessmentNotes;
    }
    this.initializeForm();
  }

  private initializeForm() {
    if (!this.progressAx) return;

    this.promMeasures_ = Object.keys(this.progressAx.ClinicalAssessment.PatientReportedOutcomeMeasures[0] || {});
    const formGroups: any = {};
    this.promMeasures_.forEach((measure: string | number) => {
      formGroups[measure] = this.fb.group({
        TotalScore: [this.progressAx.ClinicalAssessment.PatientReportedOutcomeMeasures[0][measure]?.TotalScore, Validators.required],
        SummaryAssessment: [this.progressAx.ClinicalAssessment.PatientReportedOutcomeMeasures[0][measure]?.SummaryAssessment, Validators.required],
      });
    });

    this.eForm = this.fb.group({
      PsychosocialFactors: [this.progressAx.PsychosocialFactors, Validators.required],
      AnticipatedOutcomes: [this.progressAx.AnticipatedOutcomes, Validators.required],
      MonitoringAndEvaluationPlan: [this.progressAx.MonitoringAndEvaluationPlan, Validators.required],
      UtilizationReviewConsiderations: [this.progressAx.UtilizationReviewConsiderations, Validators.required],
      FollowUpPlan: [this.progressAx.FollowUpPlan, Validators.required],
      ComplexityFactors: [this.progressAx.ComplexityFactors, Validators.required],
      PreliminaryDiagnosis: this.fb.group({
        ClinicalImpression: [this.progressAx.PreliminaryDiagnosis.ClinicalImpression, Validators.required],
        Justification: [this.progressAx.PreliminaryDiagnosis.Justification, Validators.required],
        ICD10CMCodes: [this.progressAx.PreliminaryDiagnosis.ICD10CMCodes, Validators.required],
        JCodesConsiderations: [this.progressAx.PreliminaryDiagnosis.JCodesConsiderations, Validators.required],
      }),
      Prognosis: this.fb.group({
        Description: [this.progressAx.Prognosis.Description, Validators.required],
        Justification: [this.progressAx.Prognosis.Justification, Validators.required],
      }),
      ClinicalAssessment: this.fb.group({
        ClinicalObservations: this.fb.group({
          MentalHealthStatus: [this.progressAx.ClinicalAssessment.ClinicalObservations.MentalHealthStatus, Validators.required],
          PsychologicalHealth: [this.progressAx.ClinicalAssessment.ClinicalObservations.PsychologicalHealth, Validators.required],
          SubstanceUse: [this.progressAx.ClinicalAssessment.ClinicalObservations.SubstanceUse, Validators.required],
          PhysicalHealth: [this.progressAx.ClinicalAssessment.ClinicalObservations.PhysicalHealth, Validators.required],
          FunctionalCapacity: [this.progressAx.ClinicalAssessment.ClinicalObservations.FunctionalCapacity, Validators.required],
          Overall: [this.progressAx.ClinicalAssessment.ClinicalObservations.Overall, Validators.required],
        }),
        PatientReportedOutcomeMeasures: this.fb.group(formGroups),
      }),
      PreliminaryTreatmentPlan: this.fb.group({
        ProposedTreatment: [this.progressAx.PreliminaryTreatmentPlan.ProposedTreatment, Validators.required],
        Justification: [this.progressAx.PreliminaryTreatmentPlan.Justification, Validators.required],
        ICD10CMCodes: [this.progressAx.PreliminaryTreatmentPlan.ICD10CMCodes, Validators.required],
        TreatmentModality: [this.progressAx.PreliminaryTreatmentPlan.TreatmentModality, Validators.required],
        ProposedMedication: this.fb.array([]),
      }),
      PresentingSymptoms: this.fb.array([]),
    });

    this.populatePresentingSymptoms(this.progressAx.PresentingSymptoms);
    this.populateProposedMedication(this.progressAx.PreliminaryTreatmentPlan.ProposedMedication);
  }

  private populatePresentingSymptoms(symptoms: any[]) {
    if (!Array.isArray(symptoms)) { console.error('Expected symptoms to be an array, but received:', symptoms); return; }
    const symptomsArray = this.eForm.get('PresentingSymptoms') as FormArray;
    symptoms.forEach(symptom => { symptomsArray.push(this.createSymptomFormGroup(symptom)); });
  }
  private createSymptomFormGroup(symptom: any): FormGroup {
    return this.fb.group({
      Onset: [symptom.Onset || '', Validators.required],
      Duration: [symptom.Duration || '', Validators.required],
      Severity: [symptom.Severity || '', Validators.required],
      Description: [symptom.Description || '', Validators.required],
      SymptomName: [symptom.PrimarySymptom || symptom.SecondarySymptom || symptom.ThirdSymptom || '', Validators.required]
    });
  }
  get presentingSymptomsControls() { return (this.eForm?.get('PresentingSymptoms') as FormArray)?.controls; }

  private populateProposedMedication(medications: any[]) {
    if (!Array.isArray(medications)) { console.error('Expected medications to be an array, but received:', medications); return; }
    const medicationsArray = this.eForm.get('PreliminaryTreatmentPlan')?.get('ProposedMedication') as FormArray;
    medications.forEach(medication => { medicationsArray.push(this.createMedicationFormGroup(medication)); });
  }
  private createMedicationFormGroup(medication: any): FormGroup {
    return this.fb.group({
      Dosage: [medication.Dosage || '', Validators.required],
      Interval: [medication.Interval || '', Validators.required],
      Frequency: [medication.Frequency || '', Validators.required],
      ICD10CMCodes: [medication.ICD10CMCodes || '', Validators.required],
      Justification: [medication.Justification || '', Validators.required],
      PossibleSideEffects: [medication.PossibleSideEffects || '', Validators.required],
      Medication: [medication.PrimaryMedication || medication.SecondaryMedication || medication.ThirdMedication || '', Validators.required]
    });
  }
  get proposedMedicationControls() { return (this.eForm?.get('PreliminaryTreatmentPlan')?.get('ProposedMedication') as FormArray)?.controls; }

  get patientReportedOutcomeMeasures(): FormArray { return this.eForm.get('ClinicalAssessment')?.get('PatientReportedOutcomeMeasures') as FormArray || new FormArray([]); }
  getClassForValue(value: number, Ax: string): string {
    if (Ax === "PHQ9") {
      if (value <= this.PHQ9Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PHQ9Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PHQ9Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PHQ9Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "GAD7") {
      if (value <= this.GAD7Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.GAD7Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.GAD7Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.GAD7Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WHO5") {
      if (value <= this.WHO5Cnfg.minimalMax) { return 'Urgent'; }
      else if (value <= this.WHO5Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.WHO5Cnfg.betterMax) { return 'Optimal'; }
    }
    if (Ax === "Physical") {
      if (value <= this.PhysicalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PhysicalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PhysicalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PhysicalCnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WellBeing") {
      if (value <= this.FunctionalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.FunctionalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.FunctionalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.FunctionalCnfg.severeMax) { return 'Urgent'; }
    }
    return 'Urgent';
  }

  updateEForm() {
    this.isLoading = true;
    const udData = this.uSrvc.replaceNullWithEmptyString(this.eForm.value); console.log("this.eForm.value", this.eForm.value, "udData", udData);

    const udDataTreatment = {
      'headers': {},
      'original': {
        'AssessmentNotes': {
          'ClinicianName': udData.ClinicianName,
          "ClinicalEncounterDate": this.progressAx.ClinicalEncounterDate,
          "ClinicalEncounterTime": this.progressAx.ClinicalEncounterTime,
          'Prognosis': {
            'Description': udData.Prognosis.Description,
            'Justification': udData.Prognosis.Justification,
          },
          'PreliminaryDiagnosis': {
            'ClinicalImpression': udData.PreliminaryDiagnosis.ClinicalImpression,
            'JCodesConsiderations': udData.PreliminaryDiagnosis.JCodesConsiderations,
            'ICD10CMCodes': udData.PreliminaryDiagnosis.ICD10CMCodes,
            'Justification': udData.PreliminaryDiagnosis.Justification,
          },
          'PreliminaryTreatmentPlan': {
            'ProposedTreatment': udData.PreliminaryTreatmentPlan.ProposedTreatment,
            'TreatmentModality': udData.PreliminaryTreatmentPlan.TreatmentModality,
            'ICD10CMCodes': udData.PreliminaryTreatmentPlan.ICD10CMCodes,
            'Justification': udData.PreliminaryTreatmentPlan.Justification,
          },
          'FollowUpPlan': udData.FollowUpPlan,
          'MonitoringAndEvaluationPlan': udData.MonitoringAndEvaluationPlan,
          'UtilizationReviewConsiderations': udData.UtilizationReviewConsiderations,
          'AnticipatedOutcomes': udData.AnticipatedOutcomes,
          'ComplexityFactors': udData.ComplexityFactors,
          'PsychosocialFactors': udData.PsychosocialFactors,
          'EmergencyContact': {
            'name': '',
            'phoneNumber': '',
            'relationship': '',
          },
          'InsuranceInformation': {
            'provider': '',
            'groupNumber': '',
            'policyNumber': '',
          },
          'ContactInformation': {
            'email': '',
            'address': '',
            'phoneNumber': '',
          },
          'PatientInformation': {
            'age': '',
            'gender': '',
            'name': {
              'lastname': '',
              'firstname': '',
              'middlename': '',
            },
            'dob': {
              'DD': '',
              'MM': '',
              'YYYY': '',
            },
            'phoneNumber': '',
          },
          'ClinicalAssessment': {
            'ClinicalObservations': {
              'Overall': udData.ClinicalAssessment.ClinicalObservations.Overall,
              'SubstanceUse': udData.ClinicalAssessment.ClinicalObservations.SubstanceUse,
              'PhysicalHealth': udData.ClinicalAssessment.ClinicalObservations.PhysicalHealth,
              'FunctionalCapacity': udData.ClinicalAssessment.ClinicalObservations.FunctionalCapacity,
              'MentalHealthStatus': udData.ClinicalAssessment.ClinicalObservations.MentalHealthStatus,
              'PsychologicalHealth': udData.ClinicalAssessment.ClinicalObservations.PsychologicalHealth
            },
            'PatientReportedOutcomeMeasures': [
              {
                'GAD7': {
                  'TotalScore': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.GAD7.TotalScore,
                  'SummaryAssessment': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.GAD7.TotalScore
                },
                'PHQ9': {
                  'TotalScore': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.PHQ9.TotalScore,
                  'SummaryAssessment': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.PHQ9.TotalScore
                },
                'WHO5': {
                  'TotalScore': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.WHO5.TotalScore,
                  'SummaryAssessment': udData.ClinicalAssessment.PatientReportedOutcomeMeasures.WHO5.TotalScore
                }
              }
            ]
          }
        }
      },
    };
    console.log("udDataTreatment", udDataTreatment);
    //JSON.stringify(udData)
    /*
    let rsp: any;
    this.aSrvc.encntrUD(this.encntr.id, udData.note).subscribe(
      (result) => { rsp = result; console.log(rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        if (rsp.status === "success") {
          this.encntr = rsp.encounter;
          this.toastr.success(rsp.message);
        }
      }
    )
    */
  };
  uploadEForm() {
  };

}
