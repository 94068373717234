import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';

@Component({
  selector: 'app-appointment-detail-page',
  templateUrl: './appointment-detail-page.component.html',
  styleUrls: ['./appointment-detail-page.component.css']
})

export class AppointmentDetailPageComponent implements OnInit {
  isLoading: boolean = true;
  rsp: any; auth: any; errors: any = null;
  Status: any;
  aStatus: any = ['Confirmed', 'No Show', 'Cancelled', 'Delayed']
  udData: object = {};
  eForm: FormGroup;

  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;stf: any;
  patients: any; patient: any;
  apt_: any;
  @Output() addEvt = new EventEmitter();
  constructor(
    private route: ActivatedRoute,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    public fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public aData: any
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log("locs", this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log("loc", this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt_'); this.dpt = JSON.parse(dpt); console.log("dpt", this.dpt);
    }
    this.eForm = this.fb.group({ statSlct: [''] });
  }

  aptForm = this.fb.group({
    statSlct: ['']
  });

  eFormUD() {
    this.isLoading = true;
    //console.log("this.eForm.value", this.eForm.value);
    let details_ = this.apt_.Details;
    details_.status = this.eForm.value.statSlct;//console.log("this.eForm.value", this.eForm.value);
    this.udData = { "aID": this.apt_.aID, 'Status': this.eForm.value.statSlct, 'Details': details_ };
    let udData = JSON.stringify({ "aID": this.apt_.aID, 'Status': this.eForm.value.statSlct, 'Details': details_ });
    this.aSrvc.ApptUD(udData).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp); },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        const cData = this.rsp[0];
        if (cData.status === "success") {
          this.toastr.success(cData.message); this.isLoading = false;
          return true;
        }
      }
    )
  };

  ngOnInit() {
    this.apt_ = this.aData.appointment; console.log(this.aData); console.log(this.apt_)
  }
}