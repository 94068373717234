<div class="container-fluid p-1">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <header *ngIf="patientRdy">
        <div class="row">
            <div class="col-1">
                <a (click)="backClicked()" style="padding-right:.5rem;font-size: 2rem;float: left;"><i
                        class="bi bi-arrow-left-square"></i> </a>
            </div>
            <div class="col-9">
                <h4 style="display: inline-block;">{{patient.name}} | {{patient.dob}}, {{patient.Age}}
                    | ID: {{patient.patient_id}} ||| {{encntr.T}}, {{encntr.eDate}}</h4>
            </div>
            <div class="col-2">
                <div class="btn-group float-end" role="group" aria-label="Chart group">
                    <input type="radio" class="btn-check" name="assessment" id="ax-trend" autocomplete="off"
                        (change)="toggleChartType('ax-trend')" checked>
                    <label class="btn btn-outline-primary" for="ax-trend"><i class="bi bi-graph-up"></i></label>
                    <input type="radio" class="btn-check" name="assessment" id="ax-progress" autocomplete="off"
                        (change)="toggleChartType('ax-progress')">
                    <label class="btn btn-outline-primary" for="ax-progress"><i
                            class="bi bi-bar-chart-line"></i></label>
                    <input type="radio" class="btn-check" name="assessment" id="ax-chrt-hide" autocomplete="off"
                        (change)="toggleChartType('ax-chrt-hide')">
                    <label class="btn btn-outline-primary" for="ax-chrt-hide"><i class="bi bi-eye-slash"></i></label>
                    <input type="checkbox" class="btn-check" id="displayPromSummary" [(ngModel)]="displayPromSummary"
                        autocomplete="off">
                    <label class="btn btn-outline-primary" for="displayPromSummary"><i
                            class="bi bi-display"></i></label>
                </div>
            </div>
        </div>
    </header>
    <div class="row">
        <figure [class.hidden]="chartType !== 'ax-trend'" class="highcharts-figure" style="height: fit-content;">
            <div id="container" style="height:18rem;"></div>
        </figure>
        <highcharts-chart [class.hidden]="chartType !== 'ax-progress'" [Highcharts]="Highcharts"
            [options]="chartOptions" style="width: 100%; height:18rem;"></highcharts-chart>
    </div>
    <div class="row" *ngIf="patientRdy">
        <div class="col-12">
            <div class="nav-item-Location">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pDemographics-tab" data-toggle="tooltip" data-bs-target="#pDemographics"
                            title="Patient Demographics" data-bs-toggle="tab" href="#pDemographics" role="tab"
                            aria-controls="pDemographics">Demographics</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pHistory-tab" data-toggle="tooltip" data-bs-target="#pHistory"
                            title="Patient History" data-bs-toggle="tab" href="#pHistory" role="tab"
                            aria-controls="pHistory">History</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pEncounters-tab" data-toggle="tooltip" data-bs-target="#pEncounters"
                            title="Encounter Note" data-bs-toggle="tab" href="#pEncounters" role="tab"
                            aria-controls="pEncounters">Encounter</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pWriteups-tab" data-toggle="tooltip" data-bs-target="#pWriteups"
                            title="Patient Write-Up" data-bs-toggle="tab" href="#pWriteups" role="tab"
                            aria-controls="pWriteups">Write-Up</a>
                    </li>
                    <!--li class="nav-item" role="presentation">
                        <a class="nav-link" id="pTreatmentPlan-tab" data-toggle="tooltip"
                            data-bs-target="#pTreatmentPlan" title="Patient Treatment Plan" data-bs-toggle="tab"
                            href="#pTreatmentPlan" role="tab" aria-controls="pTreatmentPlan">Treatment Plan</a>
                    </li!-->
                </ul>
            </div>
            <div id="pEncounters-Page" class="tab-content">
                <div id="pEncounters" class="tab-pane row fade notePage" role="tabpanel"
                    aria-labelledby="pEncounters-tab">
                    <form id="eForm" class="row g-1 tab-content" [formGroup]="eForm" (ngSubmit)="updateEForm()"
                        *ngIf="eD_.T != 'PC'">
                        <div class="col-md-6 text-start">
                            <button (click)="startListening()" title="Start Listening" [disabled]="isListening"
                                class="btn btn-success me-1"><i class="bi bi-ear"></i></button>
                            <button (click)="stopListening()" title="Stop Listening" [disabled]="!isListening"
                                class="btn btn-danger me-1"><i class="bi bi-stop-btn"></i></button>
                            <button (click)="clearTranscript()" title="Clear Transcript" [disabled]="!isListening"
                                class="btn btn-secondary me-1"><i class="bi bi-x-square"></i></button>
                            <p>Transcript: {{ transcript }}</p>
                            <p *ngIf="error" class="error">{{ error }}</p>
                        </div>
                        <div class="col-md-6 text-end">
                            <button title="Save Encounter Assessments" type="submit" class="btn btn-success me-2"
                                style="padding: .125rem;"><i class="bi bi-save"></i>Save</button>
                            <button title="Upload Encounter Assessments" type="submit" class="btn btn-info"
                                (click)="uploadEForm()" style="padding: .125rem;"><i
                                    class="bi bi-upload"></i>Upload</button>
                        </div>
                        <div formGroupName="Details" class="row editGrpDiv">
                            <div class="col-md-3">
                                <label for="EncounterType">Encounter Type</label>
                                <select class="form-select" id="EncounterType" formControlName="EncounterType">
                                    <option value="">Select Encounter Type</option>
                                    <option *ngFor="let o_ of axCnfg.encounterTypes" [value]="o_.CPTCode">{{ o_.CN }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group" style="display: inline-block;">
                                    <label for="eDate">Date</label><input type="date" class="form-control" id="eDate"
                                        formControlName="Date">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group" style="display: inline-block;">
                                    <label for="eTime">Time</label><input type="time" class="form-control" id="eTime"
                                        formControlName="Time">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="Duration">Duration</label>
                                <select class="form-select" id="Duration" formControlName="Duration">
                                    <option value="">Select Duration</option>
                                    <option *ngFor="let o_ of axCnfg.duration" [value]="o_.min">
                                        {{ o_.min }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Reason" class="form-label">Reason:</label>
                                <textarea class="form-control" id="Reason" formControlName="Reason"></textarea>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="Problems" class="form-label">Problems:</label>
                                <textarea class="form-control" id="Problems" formControlName="Problems"></textarea>
                            </div>
                        </div>
                        <div class="accordion accordion-flush" id="pEncounters-AccordionFlush">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree" aria-expanded="false"
                                        aria-controls="flush-collapseThree">{{ axCnfg.PatientFeedback.Lbl}}</button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse"
                                    data-bs-parent="#pEncounters-AccordionFlush">
                                    <div class="accordion-body">
                                        <div class="editGrpDiv row" style="background-color: #8000802b;
                                    border-radius: .5rem;" [formGroupName]=" axCnfg.PatientFeedback.GN">
                                            <div class="col-sm-12"
                                                style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.5rem;background-color: #f8f8fff5;"
                                                *ngFor="let fb_ of axCnfg.PatientFeedback.Type;"
                                                [formGroupName]=" fb_.GN">
                                                <h4>{{fb_.GN}} - {{fb_.Lbl}}</h4>
                                                <div class="col-6"
                                                    style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.5rem;background-color: #f8f8fff5;"
                                                    *ngFor="let q_ of fb_.Questions; let q = index">
                                                    <div [appBackgroundColorById]="q_.CN"
                                                        style="border-radius: 0.5rem;">
                                                        <h5>{{ q_.Text }}</h5>
                                                        <label [attr.for]="q_.CN">{{ q_.Lbl }}</label>
                                                        <select class="form-select" [id]="q_.CN"
                                                            [formControlName]="q_.CN" (change)="changeAx($event)">
                                                            <option value="">{{q_.Lbl}}</option>
                                                            <option *ngFor="let o_ of q_.Opt" [value]="o_.val">{{
                                                                o_.des }}</option>
                                                        </select>
                                                        <!--input [id]="q_.CN +'_slider'" type="range"
                                                        class="form-range sliderD sliderD::-webkit-slider-thumb sliderD::-webkit-slider-thumb::after sliderD::-moz-range-thumb sliderD::-moz-range-thumb::after"
                                                        [min]="ax_.LikertScale[0].val"
                                                        [max]="ax_.LikertScale[ax_.LikertScale.length - 1].val"
                                                        [step]="1" [formControlName]="q_.CN"
                                                        (change)="changeAx($event)"!-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        Observations: Sysmptom(s) - Risk - Substance Use - Physical - Functional
                                        Capacity -
                                        Mental - Psychiatric
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse"
                                    data-bs-parent="#pEncounters-AccordionFlush">
                                    <div class="accordion-body">
                                        <div class="editGrpDiv row" [formGroupName]="axCnfg.GN">
                                            <label class="col-md-12" style=" width:100%;">{{ axCnfg.des }}:</label>
                                            <!-- Symptoms -->
                                            <div class="col-md-12">
                                                <label for="Note" class="form-label">Clinical Assessment:</label>
                                                <textarea class="form-control" id="Note"
                                                    title="Comprehensive evaluation of the patient's mental health using standardized diagnostic tools. Identification of symptoms and their impact on daily life."
                                                    formControlName="Note"></textarea>
                                            </div>
                                            <div class="col-md-12 editGrpDiv" formArrayName="Sx">
                                                <label
                                                    style="display:inline-flex !important;width: min-content;">Symptoms:</label>
                                                <button type="button" class="btn i" title="Add Symptom"
                                                    (click)="addSx()">
                                                    <i class="bi bi-plus-square"></i>
                                                </button>
                                                <div *ngFor="let symptom of Sx_.controls; let i = index"
                                                    [formGroupName]="i" class="editGrpDiv row">
                                                    <div class="col-md-3">
                                                        <label for="sxName{{i}}" class="form-label">Symptom
                                                            Name:</label>
                                                        <input type="text" class="form-control" id="sxName{{i}}"
                                                            formControlName="sxName" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxOnsetDate{{i}}" class="form-label">First
                                                            Noticed/Onset
                                                            Date:</label>
                                                        <input type="date" class="form-control" id="sxOnsetDate{{i}}"
                                                            formControlName="sxOnsetDate" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="sxSeverity{{i}}">Severity</label>
                                                        <select class="form-select" id="sxSeverity{{i}}"
                                                            formControlName="sxSeverity">
                                                            <option value="">Select Severity</option>
                                                            <option
                                                                *ngFor="let s_ of sympCnfg.GenericSymptomSeverityScale"
                                                                [value]="s_.Number"> {{ s_.Number }} - {{ s_.Description
                                                                }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label for="sxDescription{{i}}"
                                                            class="form-label">Description:</label>
                                                        <textarea class="form-control textarea" id="sxDescription{{i}}"
                                                            formControlName="sxDescription"></textarea>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxDurationVal{{i}}"
                                                            class="form-label">Duration:</label>
                                                        <input type="text" class="form-control" id="sxDurationVal{{i}}"
                                                            formControlName="sxDurationVal" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxDurationUnit{{i}}">Duration Unit</label>
                                                        <select class="form-select" id="sxDurationUnit{{i}}"
                                                            formControlName="sxDurationUnit">
                                                            <option value="">Select Duration Unit</option>
                                                            <option *ngFor="let unit of sympCnfg.duration.unit"
                                                                [value]="unit"> {{ unit }} </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxFrequencyTime{{i}}"
                                                            class="form-label">Frequency:</label>
                                                        <input type="text" class="form-control"
                                                            id="sxFrequencyTime{{i}}"
                                                            formControlName="sxFrequencyTime" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxFrequencyPeriod{{i}}">Frequency Period</label>
                                                        <select class="form-select" id="sxFrequencyPeriod{{i}}"
                                                            formControlName="sxFrequencyPeriod">
                                                            <option value="">Select Frequency Period</option>
                                                            <option [value]="o_"
                                                                *ngFor="let o_ of sympCnfg.frequency.period"> {{ o_ }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxLocationBodyPart{{i}}" class="form-label">Location
                                                            On
                                                            Body:</label>
                                                        <input type="text" class="form-control"
                                                            id="sxLocationBodyPart{{i}}"
                                                            formControlName="sxLocationBodyPart" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxLocationLaterality{{i}}">Frequency Period</label>
                                                        <select class="form-select" id="sxLocationLaterality{{i}}"
                                                            formControlName="sxLocationLaterality">
                                                            <option value="">Select Lateral Location</option>
                                                            <option [value]="o_"
                                                                *ngFor="let o_ of sympCnfg.location.laterality"> {{ o_
                                                                }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxProgression{{i}}">Progression</label>
                                                        <select class="form-select" id="sxProgression{{i}}"
                                                            formControlName="sxProgression">
                                                            <option value="">Select Progression</option>
                                                            <option [value]="o_"
                                                                *ngFor="let o_ of sympCnfg.progression"> {{
                                                                o_ }} </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label for="sxImpactOnDailyActivities{{i}}">Impact On Daily
                                                            Activities</label>
                                                        <select class="form-select" id="sxImpactOnDailyActivities{{i}}"
                                                            formControlName="sxImpactOnDailyActivities">
                                                            <option value="">Select Impact</option>
                                                            <option [value]="o_"
                                                                *ngFor="let o_ of sympCnfg.impactOnDailyActivities"> {{
                                                                o_
                                                                }} </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="sxTriggers{{i}}" class="form-label">Description of
                                                            any
                                                            known triggers or aggravating factors:</label>
                                                        <textarea class="form-control textarea" id="sxTriggers{{i}}"
                                                            formControlName="sxTriggers"></textarea>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="sxRelievingFactors{{i}}"
                                                            class="form-label">Description
                                                            of any known relieving factors or actions:</label>
                                                        <textarea class="form-control textarea"
                                                            id="sxRelievingFactors{{i}}"
                                                            formControlName="sxRelievingFactors"></textarea>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="sxAssociatedSymptoms{{i}}" class="form-label">List
                                                            of
                                                            any associated symptoms:</label>
                                                        <textarea class="form-control textarea"
                                                            id="sxAssociatedSymptoms{{i}}"
                                                            formControlName="sxAssociatedSymptoms"></textarea>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="sxCharacter{{i}}" class="form-label">Behavioral
                                                            Observations of this symptom:</label>
                                                        <textarea class="form-control textarea" id="sxCharacter{{i}}"
                                                            formControlName="sxCharacter"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 riskAssessmentContainer"
                                                *ngFor="let ax_ of axCnfg.Harm">
                                                <div [appBackgroundColorById]="ax_.CN" style="border-radius: 0.5rem;">
                                                    <label [attr.for]="ax_.CN">{{ax_.des}}</label>
                                                    <select class="form-select" [id]="ax_.CN" [formControlName]="ax_.CN"
                                                        (change)="changeAx($event)">
                                                        <option value="">{{ax_.Lbl}}</option>
                                                        <option *ngFor="let o_ of ax_.Options" [value]="o_.val">{{
                                                            o_.des }}
                                                        </option>
                                                    </select>
                                                    <input [id]="ax_.CN + '_slider'" type="range"
                                                        class="form-range riskAssessmentSliderD"
                                                        [max]="ax_.Options[0].val"
                                                        [min]="ax_.Options[ax_.Options.length - 1].val" [step]="1"
                                                        [formControlName]="ax_.CN" (change)="changeAx($event)">
                                                </div>
                                            </div>
                                            <div class="col-md-6 healthAssessmentContainer"
                                                style="border-radius: 0.5rem;" *ngFor="let ax_ of axCnfg.Appearance">
                                                <div [appBackgroundColorById]="ax_.CN" style="border-radius: 0.5rem;">
                                                    <label [attr.for]="ax_.CN">{{ax_.des}}</label>
                                                    <select class="form-select" [id]="ax_.CN" [formControlName]="ax_.CN"
                                                        (change)="changeAx($event)">
                                                        <option value="">{{ax_.Lbl}}</option>
                                                        <option *ngFor="let o_ of ax_.Options" [value]="o_.val">{{
                                                            o_.des }}
                                                        </option>
                                                    </select>
                                                    <input [id]="ax_.CN + '_slider'" type="range"
                                                        class="form-range healthSliderD" [min]="ax_.Options[0].val"
                                                        [max]="ax_.Options[ax_.Options.length - 1].val" [step]="1"
                                                        [formControlName]="ax_.CN" (change)="changeAx($event)">
                                                </div>
                                            </div>
                                            <div class="editGrpDiv row"
                                                style="background-color: #8000802b; border-radius: .5rem;"
                                                *ngFor="let ax_ of axCnfg.Behavior" [formGroupName]="ax_.GN">
                                                <h3 *ngIf="ax_.GN == 'MH'">{{ ax_.des }} Score: {{ AxMHTlt }} |
                                                    Short-Term
                                                    Goal: {{AxMHTrgt}} | Long-Term Goal: {{AxMHTrgt}}</h3>
                                                <h3 *ngIf="ax_.GN == 'Psych'">{{ ax_.des }} Score: {{ AxPsychTlt }} |
                                                    Short-Term Goal: {{AxPsychTrgt}} | Long-Term Goal: {{AxPsychTrgt}}
                                                </h3>
                                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2"
                                                    style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.5rem;background-color: #f8f8fff5;"
                                                    *ngFor="let q_ of ax_.Questions; let q = index">
                                                    <div [appBackgroundColorById]="q_.CN"
                                                        style="border-radius: 0.5rem;">
                                                        <label [attr.for]="q_.CN">{{ q_.CN }}</label>
                                                        <select class="form-select" [id]="q_.CN"
                                                            [formControlName]="q_.CN" (change)="changeAx($event)">
                                                            <option value="">{{q_.Lbl}}</option>
                                                            <option *ngFor="let o_ of ax_.LikertScale" [value]="o_.val">
                                                                {{
                                                                o_.des }}</option>
                                                        </select>
                                                        <input [id]="q_.CN +'_slider'" type="range"
                                                            class="form-range sliderD sliderD::-webkit-slider-thumb sliderD::-webkit-slider-thumb::after sliderD::-moz-range-thumb sliderD::-moz-range-thumb::after"
                                                            [min]="ax_.LikertScale[0].val"
                                                            [max]="ax_.LikertScale[ax_.LikertScale.length - 1].val"
                                                            [step]="1" [formControlName]="q_.CN"
                                                            (change)="changeAx($event)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                        aria-controls="flush-collapseTwo"> Intervention(s): Medication(s) - Education -
                                        Referral(s) - Follow-Up(s) </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                    data-bs-parent="#pEncounters-AccordionFlush">
                                    <div class="accordion-body" formGroupName="REC">
                                        <div class="col-md-12 editGrpDiv">
                                            <label for="RECNote" class="form-label">Note:</label>
                                            <textarea class="form-control" id="RECNote"
                                                formControlName="Note"></textarea>
                                        </div>
                                        <div class="col-md-12 editGrpDiv" formArrayName="Medications">
                                            <label
                                                style="display:inline-flex !important;width: min-content;">Medications:</label>
                                            <button type="button" class="btn i" title="Add Medication"
                                                (click)="addMed()"><i class="bi bi-plus-square"></i></button>
                                            <div *ngFor="let medication of Meds_.controls; let i = index"
                                                [formGroupName]="i" class="editGrpDiv row">
                                                <div class="col-md-4">
                                                    <label for="medName{{i}}" class="form-label">Name</label>
                                                    <input type="text" id="medName{{i}}" class="form-control"
                                                        formControlName="medName">
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="medDosage{{i}}" class="form-label">Dosage</label>
                                                    <input type="text" id="medDosage{{i}}" class="form-control"
                                                        formControlName="medDosage">
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="medFrequency{{i}}">Frequency</label>
                                                    <select class="form-select" id="medFrequency{{i}}"
                                                        formControlName="medFrequency">
                                                        <option value="">Select Frequency</option>
                                                        <option *ngFor="let o_ of frequencyOptions"
                                                            [value]="o_.abbreviation">{{o_.term}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="medRoute{{i}}">Routes Of Administration</label>
                                                    <select class="form-select" id="medRoute{{i}}"
                                                        formControlName="medRoute">
                                                        <option value="">Select Administration Route</option>
                                                        <option *ngFor="let o_ of routeOfAdministration"
                                                            [value]="o_.Code">
                                                            {{o_.Description}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="medStartDate{{i}}" class="form-label">Start Date</label>
                                                    <input type="date" class="form-control" id="medStartDate{{i}}"
                                                        formControlName="medStartDate" />
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="medEndDate{{i}}" class="form-label">End Date</label>
                                                    <input type="date" class="form-control" id="medEndDate{{i}}"
                                                        formControlName="medEndDate" />
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="medPurpose{{i}}" class="form-label">Reason for the
                                                        medication:</label>
                                                    <textarea class="form-control textarea" id="medPurpose{{i}}"
                                                        formControlName="medPurpose"></textarea>
                                                </div>
                                                <div class="col-md-2">
                                                    <label for="medClass{{i}}">Class</label>
                                                    <select class="form-select" id="medClass{{i}}"
                                                        formControlName="medClass" (change)="changeMedClass($event)">
                                                        <option value="">Select Class</option>
                                                        <option *ngFor="let o_ of classes" [value]="o_.ClassName">
                                                            {{o_.ClassName}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="medICD10CMCode{{i}}" class="form-label">ICD-10-CM
                                                        Code</label>
                                                    <input type="text" id="medICD10CMCode{{i}}" class="form-control"
                                                        formControlName="medICD10CMCode">
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="medNDC{{i}}" class="form-label">National Drug
                                                        Code</label>
                                                    <input type="text" id="medNDC{{i}}" class="form-control"
                                                        formControlName="medNDC">
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="medSideEffects{{i}}" class="form-label">Experienced or
                                                        potential side effects:</label>
                                                    <textarea class="form-control textarea" id="medSideEffects{{i}}"
                                                        formControlName="medSideEffects"></textarea>
                                                </div>
                                                <div class="col-md-2" id="medAdherenceLeveldiv{{i}}">
                                                    <label for="medAdherenceLevel{{i}}">Adherence Level</label>
                                                    <select class="form-select" id="medAdherenceLevel{{i}}"
                                                        formControlName="medAdherenceLevel">
                                                        <option value="">Select Level</option>
                                                        <option *ngFor="let o_ of medCompliance" [value]="o_">{{o_}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-5">
                                                    <label for="medReasonsNonAdherence{{i}}" class="form-label">Reasons
                                                        for
                                                        not taking the medication as prescribed:</label>
                                                    <textarea class="form-control textarea"
                                                        id="medReasonsNonAdherence{{i}}"
                                                        formControlName="medReasonsNonAdherence"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <!--div class="col-md-12 editGrpDiv"
                                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                                        <label for="Intervention">Intervention</label>
                                        <select class="form-select" in="Intervention" formControlName="Intervention">
                                            <option value="">Select Intervention Type</option>
                                            <option *ngFor="let o_ of tpCnfg.InterventionOptions" [value]="o_.ID">{{
                                                o_.val }}
                                            </option>
                                        </select>
                                    </div!-->
                                        <div class="col-md-12 editGrpDiv"
                                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                                            <label for="Education">Patient Education</label>
                                            <select class="form-select" in="Education" formControlName="Education">
                                                <option value="">Select Patient Education Type</option>
                                                <option *ngFor="let o_ of tpCnfg.EducationalInterventions"
                                                    [value]="o_.ID">
                                                    {{ o_.Type }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 editGrpDiv"
                                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;"
                                            formGroupName="Referral">
                                            <label for="ReferralType">Referral Made</label>
                                            <select class="form-select" in="ReferralType" formControlName="Type">
                                                <option value="">Select Referral Type</option>
                                                <option *ngFor="let o_ of tpCnfg.ReferralInterventions" [value]="o_.ID">
                                                    {{
                                                    o_.Type }}</option>
                                            </select>
                                            <div class="col-md-12">
                                                <label for="ReferralNote" class="form-label">Referral Note:</label>
                                                <textarea class="form-control" id="ReferralNote"
                                                    formControlName="Note"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12 editGrpDiv row" formGroupName="FollowUp">
                                            <div class="col-md-4"
                                                style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                                                <label for="Appt">FollowUp Appointments</label>
                                                <select class="form-select" in="Appt" formControlName="Appt">
                                                    <option value="">Select FollowUp Appointments Type</option>
                                                    <option *ngFor="let o_ of tpCnfg.FollowUpInterventions"
                                                        [value]="o_.ID">
                                                        {{ o_.Type }}</option>
                                                </select>
                                            </div>
                                            <!--div class="col-md-4"
                                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                                            <label for="Tests">Additional Testing Or Assessments</label>
                                            <select class="form-select" in="Tests" formControlName="Tests">
                                                <option value="">Select Additional Testing Or Assessments</option>
                                                <option *ngFor="let o_ of tpCnfg.AdditionalTestingOrAssessmentsOptions"
                                                    [value]="o_.ID">
                                                    {{ o_.val }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-4"
                                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                                            <label for="Fam">Patient Family Involvement</label>
                                            <select class="form-select" in="Fam" formControlName="Fam">
                                                <option value="">Select Patient Family Involvement Type</option>
                                                <option *ngFor="let o_ of tpCnfg.PatientFamilyInvolvementOptions"
                                                    [value]="o_.ID">{{
                                                    o_.val }}
                                                </option>
                                            </select>
                                        </div!-->
                                            <div class="col-md-12">
                                                <label for="FollowUpNote" class="form-label">Follow-Up Note:</label>
                                                <textarea class="form-control" id="FollowUpNote"
                                                    formControlName="Note"></textarea>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div id="pDemographics-Page" class="tab-content"><app-demographic id="pDemographics"
                    class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="pDemographics-tab"
                    [patient]="patient" [cmpy]="cmpy" *ngIf="patientRdy"></app-demographic></div>
            <div id="pHistory-Page" class="tab-content"><app-history id="pHistory" class="tab-pane row fade notePage"
                    role="tabpanel" aria-labelledby="pHistory-tab" [patient]="patient" [cmpy]="cmpy"
                    *ngIf="patientRdy"></app-history></div>
            <div id="writeups-Page" class="tab-content"><app-writeups id="pWriteups" class="tab-pane row fade notePage"
                    role="tabpanel" aria-this.eNoteledby="pWriteups-tab" [patient]="patient" [encntr]="encntr"
                    [cmpy]="cmpy" *ngIf="patientRdy"></app-writeups></div>
            <!--div id="treatmentPlan-Page" class="tab-content"><app-notes-treatment-plan id="pTreatmentPlan"
                    class="tab-pane row fade notePage" role="tabpanel" aria-this.eNoteledby="pTreatmentPlan-tab"
                    [patient]="patient" [encntr]="encntr" [cmpy]="cmpy" *ngIf="patientRdy"></app-notes-treatment-plan></div!-->
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Create New Report</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</div>