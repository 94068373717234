import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-location-editor',
  templateUrl: './location-editor.component.html',
  styleUrls: ['./location-editor.component.css']
})
export class LocationEditorComponent implements OnInit {
  isLoading: boolean = true;
  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any; stf: any; stfs: any;
  eLoc: any; eLID: any; eDpt: any; eDID: any; eGS: any; eGID: any;
  cnfg_: any = [];
  cnfgChrt_: any = [];
  cntctP_: any = [];
  cntctS_: any = [];
  dbData: any = [];
  cnfg_UD: any = [];
  eData!: any;
  nData!: any;
  udData: object = {};
  eForm!: FormGroup;

  constructor(
    private route: ActivatedRoute, private router: Router, public fb: FormBuilder,
    public uSrvc: UtilitiesService,
    public aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    console.log(localStorage);
    const stf_: any = localStorage.getItem('stf'); const stfs: any = localStorage.getItem('stfs');
    if (stf_ === null) { console.log("redirect"); this.router.navigate(['patients']); }
    else {
      this.stf = JSON.parse(stf_); console.log(this.stf);
      this.stfs = JSON.parse(stfs); console.log(this.stfs);
      const c: any = localStorage.getItem('cmpy'); const c_: any = JSON.parse(c); this.cmpy = c_; console.log(this.cmpy);
      this.eLoc = this.cmpy.Locations.find((location: { lID: any; }) => location.lID === this.lID);//console.log(this.loc);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log(this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log(this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt_'); this.dpt = JSON.parse(dpt); console.log(this.dpt);
      this.eForm = this.fb.group({
        A: [''],
        A2: [''],
        S: [''],
        C: [''],
        Z: [''],
        P1: [''],
        TF: [''],
        F: [''],
        E: [''],
        N: [''],
        note: [''],
        pI: [''],
        pA: [''],
        pN: [''],
        pP: [''],
        sI: [''],
        sA: [''],
        sN: [''],
        sP: [''],
      });
    }
  }

  eFormUD() {
    this.isLoading = true;
    console.log("this.eForm.value", this.eForm.value);
    this.udData = {
      "id": this.dbData.id,
      'N': this.eForm.value.N,
      'note': this.eForm.value.note,
      'Adr': {
        'A': this.eForm.value.A,
        'A2': this.eForm.value.A2,
        'C': this.eForm.value.C,
        'S': this.eForm.value.S,
        'Z': this.eForm.value.Z,
        'P1': this.eForm.value.P1,
        'TF': this.eForm.value.TF,
        'F': this.eForm.value.F,
        'E': this.eForm.value.E
      },
      'Contacts': {
        "Primary": {
          "IT": this.eForm.value.pI,
          "Admin": this.eForm.value.pA,
          "Nursing": this.eForm.value.pN,
          "Provider": this.eForm.value.pP
        },
        "Secondary": {
          "IT": this.eForm.value.sI,
          "Admin": this.eForm.value.sA,
          "Nursing": this.eForm.value.sN,
          "Provider": this.eForm.value.sP
        }
      }
    };
    console.log("this.udData", this.udData);
    this.aSrvc.LocUD(this.udData).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        const lData = this.rsp[0];
        if (lData.status === "success") { this.toastr.success(lData.message); }
      }
    )
  };
  updatePhn($event: any) {
    console.log("$event: ", $event.target.id, $event.target.value);
    this.eData[$event.target.id] = this.uSrvc.formatPhone($event.target.value);
  }
  chgProvider($event: any) { console.log($event); }
  chgNursing($event: any) { console.log($event); }
  chgAdmin($event: any) { console.log($event); }
  chgIT($event: any) { console.log($event); }

  ngOnInit(): void {
    console.log(localStorage);
    this.isLoading = true;
    const task_: any = this.route.snapshot.url[1].path; console.log(task_);
    const tID: any = this.route.snapshot.paramMap.get('id'); console.log('tID', tID);
    this.lID = localStorage.getItem('currentLID');
    this.eLID = this.uSrvc.getLid(tID);

    this.aSrvc.LocGet(this.eLID).subscribe(
      (result) => { this.responseHandler(result); this.dbData = result; },
      (error) => { this.errors = error.error; },
      () => {
        const l_ = this.dbData[0]; this.eLoc = l_; this.dbData = l_;
        console.log('this.eLoc', this.eLoc);
        let xl_ = JSON.parse(l_.Adr); this.eLoc.Adr = xl_;
        xl_ = JSON.parse(l_.Contacts); this.eLoc.Contacts = xl_;
        xl_ = JSON.parse(l_.Cnfg); this.eLoc.Cnfg = xl_;
        xl_ = JSON.parse(l_.DType); this.eLoc.DType = xl_;
        xl_ = JSON.parse(l_.Roles); this.eLoc.Roles = xl_;
        xl_ = JSON.parse(l_.AL); this.eLoc.AL = xl_;
        xl_ = JSON.parse(l_.LPs); this.eLoc.LPs = xl_;
        console.log('this.eLoc', this.eLoc);
        this.cntctP_ = this.eLoc.Contacts.Primary;
        this.cntctS_ = this.eLoc.Contacts.Secondary;

        this.eForm = this.fb.group({
          N: [this.eLoc.N],
          note: [this.eLoc.note],
          A: [this.eLoc.Adr.A],
          A2: [this.eLoc.Adr.A2],
          S: [this.eLoc.Adr.S],
          C: [this.eLoc.Adr.C],
          Z: [this.eLoc.Adr.Z],
          P1: [this.eLoc.Adr.P1],
          TF: [this.eLoc.Adr.TF],
          F: [this.eLoc.Adr.F],
          E: [this.eLoc.Adr.E],
          pI: [this.cntctP_.IT],
          pA: [this.cntctP_.Admin],
          pN: [this.cntctP_.Nursing],
          pP: [this.cntctP_.Provider],
          sI: [this.cntctS_.IT],
          sA: [this.cntctS_.Admin],
          sN: [this.cntctS_.Nursing],
          sP: [this.cntctS_.Provider],
        });
        this.isLoading = false;
      }
    );
  }
  responseHandler(data: any) { /* this.token.handleData(data.atoken); */ }// Handle response
}
