<section style="background-color: whitesmoke;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card" style="background-color: whitesmoke; padding: .125rem;">
          <div class="row g-0" style="background-color: whitesmoke; border-radius: 1rem;">
            <div class="col-md-12">
              <mat-progress-bar mode="indeterminate" *ngIf="isSigningIn"></mat-progress-bar>
              <h1 class="mb-3 font-weight-normal text-center">Sign in: Dashboard</h1>
            </div>
            <div class="col-md-6 col-lg-5 d-none d-md-block">
              <img
                src="../../../assets/_images/imsMPCLogo1.jpg"
                alt="login form"
                class="img-fluid animated-image"
                style="border-radius: 9rem; scale: 1.25; float: left; position: relative; padding: 3rem;"
              />
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center loading-bar-container">
              <div class="card-body p-4 p-lg-5 text-black">
                <form class="form-signin" [formGroup]="signinForm" (ngSubmit)="onSubmit()">
                  <div *ngIf="errors?.E" class="alert alert-danger mt-3">{{ errors?.E }}</div>
                  <div *ngIf="errors?.pswrd" class="alert alert-danger mt-3">{{ errors?.pswrd }}</div>
                  <div *ngIf="errors?.error" class="alert alert-danger mt-3">{{ errors?.error }}</div>

                  <div class="form-floating mb-4">
                    <input
                      type="email"
                      class="form-control form-control-lg"
                      id="E"
                      placeholder="Email address"
                      formControlName="E"
                      style="font-size: 1rem !important;"
                    />
                    <label class="form-label" for="E" style="font-size: 1rem !important;">Email address</label>
                  </div>

                  <div class="form-floating mb-4">
                    <input
                      type="password"
                      class="form-control form-control-lg"
                      id="pswrd"
                      placeholder="Password"
                      formControlName="pswrd"
                      style="font-size: 1rem !important;"
                    />
                    <label class="form-label" for="pswrd" style="font-size: 1rem !important;">Password</label>
                  </div>

                  <div class="pt-1 mb-12">
                    <button type="submit" class="btn btn-block btn-primary" [disabled]="isSigningIn">Log in</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
