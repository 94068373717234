<div class="container-fluid">
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
            <h4 class="fw-normal mb-1" style="margin-top:2rem;">Hello {{Apt.N}},<br>
                Thank you for patience in answering all these questions.
            </h4>
            <h4 class="fw-normal mb-1" style="margin-top: 1rem;">Please return this device to the from deck or you may give it to your nurse when they come get you.<br>
            </h4>
        </div>
        <div class="col-sm-1"></div>
    </div>
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
            <form class="example-form mt-3 pt-3" [formGroup]="deactivAssessment" (ngSubmit)="deactive()">
                <h5 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px;">Enter your ID and password to update the appointment list</h5>
                <div class="form-outline mb-4">
                    <input type="text" id="userID" class="form-control form-control-lg" formControlName="userID"/><label class="form-label" for="userID">Username</label>
                </div>
                <div class="form-outline mb-4">
                    <input type="password" id="password" class="form-control form-control-lg" formControlName="password"/><label class="form-label" for="password">Password</label>
                </div>
                <div class="pt-1 ms-12">
                    <div class="pt-1 mb-12"><button mat-raised-button color="primary">Restart</button></div>
                </div>
            </form>
        </div>
    </div>
</div>
