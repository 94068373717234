import { GroupSession } from './Store/Model/type.Interfeaces';
import { Patient } from './Store/Model/type.Interfeaces';

function getRandomInt(max:number) {return Math.floor(Math.random() * max);}
export const SessionData1: GroupSession[]=[
  {
    "gID": "00000001",
    "lID": "PA00010001",
    "dID": "0004",
    "cID": "PA0001",
    "N": "Cognitive-Behavioral Therapy",
    "Description": "This session focuses on cognitive-behavioral therapy techniques to address negative thought patterns in depression. Clients will engage in group discussions and complete CBT worksheets to identify and challenge their distorted thoughts.",
    "T": "Depression Support","TLong": "Depression Support",
    "MaterialsNeeded": {
      "m1": "CBT worksheets",
      "m2": "markers",
      "m3": "whiteboard"
    },
    "Location": {
      "Adr": {
        "A": "1234 Liberty Lane",
        "A2": "Suite PC-001",
        "C": "Bethlehem",
        "S": "PA",
        "Z": "18020",
        "P1": "(610) 555-2001",
        "TF": "(888) 555-2001",
        "F": "(610) 555-2001",
        "E": "info@HCN00010002PC0001.com"
      },
      "rID": "00000001",
      "N": "Therapy Room",
    },
    "Schedule": {
      "sDate": "2023-08-21",
      "eDate": "2024-02-21",
      "sTime": "0900",
      "eTime": "1030",
      "duration": "90",
      "frequency": "weekly",
    },
    "Contacts": [
      {"Type": "primary","ID": "imsJTM"},
      {"Type": "hc","ID": "imsHM"},
      {"Type": "admin","ID": "imsJB"},
      {"Type": "IT","ID": "imsJTM"}
    ],
    "Coordinators": {
      "c1": "imsmpc01",
      "c2": "imsmpc02",
    },
    "MaxSeats": 32,
    "Seats": {
      "s01": "0001",
      "s02": "0003",
      "s03": "0005",
      "s04": "0007",
    },
    "MaxWaitList": 4,
    "WaitList": {
      "w01": "0008",
      "w02": "0002",
    },
    "note": "",
  },
  {
    "gID": "00000002",
    "lID": "PA00010002",
    "dID": "0003",
    "cID": "PA0001",
    "N": "Cognitive-Behavioral Therapy for Addition Recovery",
    "Description": "• Description: This group session employs CBT techniques to address addiction triggers and cravings. Clients engage in discussions and activities focused on identifying and managing addictive behaviors.",
    "T": "Addiction Recovery","TLong": "Addiction Recovery",
    "MaterialsNeeded": {
      "m1": "CBT materials",
      "m2": "flipcharts",
      "m3": "markers"
    },
    "Location": {
      "Adr": {
        "A": "1234 Liberty Lane",
        "A2": "Suite PC-001",
        "C": "Bethlehem",
        "S": "PA",
        "Z": "18020",
        "P1": "(610) 555-2001",
        "TF": "(888) 555-2001",
        "F": "(610) 555-2001",
        "E": "info@HCN00010002PC0001.com"
      },
      "rID": "00000001",
      "N": "Therapy Room",
    },
    "Schedule": {
      "sDate": "2023-05-14",
      "eDate": "2024-02-21",
      "sTime": "1300",
      "eTime": "1500",
      "duration": "120",
      "frequency": "weekly",
    },
    "Contacts": [
      {"Type": "primary","ID": "imsJTM"},
      {"Type": "hc","ID": "imsHM"},
      {"Type": "admin","ID": "imsJB"},
      {"Type": "IT","ID": "imsJTM"}
    ],
    "Coordinators": {
      "c1": "imsmpc01",
      "c2": "imsmpc02",
    },
    "MaxSeats": 16,
    "Seats": {
      "01": "0002",
      "02": "0004",
      "03": "0007",
      "04": "0008",
      "05": "0011",
    },
    "MaxWaitList": 4,
    "WaitList": {
      "01": "0001",
      "02": "0010",
    },
    "note": "",
  },
{
    "gID": "00010001",
    "lID": "PA00010002",
    "dID": "0003",
    "cID": "PA0001",
    "N": "Peer Support Group",
    "Description": "Description: In this session, clients with depression come together to share their experiences, coping strategies, and provide mutual support. It provides a safe space for open discussions and peer connections.",
    "T": "Depression Support","TLong": "Depression Support",
    "MaterialsNeeded": {
      "m1": "Comfortable seating",
      "m2": "tissues"
    },
    "Location": {
      "Adr": {
        "A": "1234 Liberty Lane",
        "A2": "Suite PC-001",
        "C": "Bethlehem",
        "S": "PA",
        "Z": "18020",
        "P1": "(610) 555-2001",
        "TF": "(888) 555-2001",
        "F": "(610) 555-2001",
        "E": "info@HCN00010002PC0001.com"
      },
      "rID": "00010001",
      "N": "Support Group Room",
    },
    "Schedule": {
      "sDate": "2023-02-14",
      "eDate": "2024-02-14",
      "sTime": "1000",
      "eTime": "1200",
      "duration": "120",
      "frequency": "weekly",
    },
    "Contacts": [
      {"Type": "primary","ID": "imsJTM"},
      {"Type": "hc","ID": "imsHM"},
      {"Type": "admin","ID": "imsJB"},
      {"Type": "IT","ID": "imsJTM"}
    ],
    "Coordinators": {
      "c1": "imsmpc01",
      "c2": "imsmpc02",
    },
    "MaxSeats": 8,
    "Seats": {
      "01": "0001",
      "02": "0004",
      "03": "0006",
      "04": "0009",
      "05": "0011"
    },
    "MaxWaitList": 4,
    "WaitList": {
      "01": "0002",
      "02": "0003",
      "03": "0010"
    },
    "note": "",
  },
{
    "gID": "00010002",
    "lID": "PA00010002",
    "dID": "0003",
    "cID": "PA0001",
    "N": "Specific Anxiety Disorder",
    "Description": "This support group focuses on specific anxiety disorders (e.g., social anxiety, generalized anxiety disorder). Clients with similar conditions share experiences, challenges, and strategies for managing their specific anxiety issues.",
    "T": "Anxiety Management","TLong": "Anxiety Management",
    "MaterialsNeeded": {
      "m1": "Relevant educational materials",
      "m2": "group discussion prompts"
    },
    "Location": {
      "Adr": {
        "A": "1234 Sumpter Blvd.",
        "A2": "Support Group Room",
        "C": "Bethlehem",
        "S": "PA",
        "Z": "18020",
        "P1": "(610) 555-2001",
        "TF": "(888) 555-2001",
        "F": "(610) 555-2001",
        "E": "info@HCN00010002PC0001.com"
      },
      "rID": "00010001",
      "N": "Support Group Room",
    },
    "Schedule": {
      "sDate": "2023-06-14",
      "eDate": "2024-12-21",
      "sTime": "1300",
      "eTime": "1430",
      "duration": "90",
      "frequency": "weekly",
    },
    "Contacts": [
      {"Type": "primary","ID": "imsJTM"},
      {"Type": "hc","ID": "imsHM"},
      {"Type": "admin","ID": "imsJB"},
      {"Type": "IT","ID": "imsJTM"}
    ],
    "Coordinators": {
      "c1": "imsmpc01",
      "c2": "imsmpc02",
    },
    "MaxSeats": 16,
    "Seats": {
      "01": "0001",
      "02": "0002",
      "03": "0003",
      "04": "0004",
      "05": "0005",
    },
    "MaxWaitList": 4,
    "WaitList": {
      "04": "0004",
      "05": "0005",
    },
    "note": "",
  },
{
    "gID": "00010003",
    "lID": "PA00010002",
    "dID": "0003",
    "cID": "PA0001",
    "N": "Dual-Diagnosis Group",
    "Description": "This group is designed for clients dealing with co-occurring mental health and substance use disorders. It offers support, education, and coping strategies specific to dual-diagnosis challenges.",
    "T": "Addiction Recovery","TLong": "Addiction Recovery",
    "MaterialsNeeded": {
      "m1": "Dual-diagnosis educational materials",
      "m2": "group discussion prompts"
    },
    "Location": {
      "Adr": {
        "A": "1234 Sumpter Blvd.",
        "A2": "Support Group Room",
        "C": "Bethlehem",
        "S": "PA",
        "Z": "18020",
        "P1": "(610) 555-2001",
        "TF": "(888) 555-2001",
        "F": "(610) 555-2001",
        "E": "info@HCN00010002PC0001.com"
      },
      "rID": "00010001",
      "N": "Support Group Room",
    },
    "Schedule": {
      "sDate": "2022-03-14",
      "eDate": "2024-02-21",
      "sTime": "1500",
      "eTime": "1430",
      "duration": "90",
      "frequency": "weekly",
    },
    "Contacts": [
      {"Type": "primary","ID": "imsJTM"},
      {"Type": "hc","ID": "imsHM"},
      {"Type": "admin","ID": "imsJB"},
      {"Type": "IT","ID": "imsJTM"}
    ],
    "Coordinators": {
      "c1": "imsmpc01",
      "c2": "imsmpc02",
    },
    "MaxSeats": 16,
    "Seats": {
      "01": "0002",
      "02": "0004",
      "03": "0006",
      "04": "0008",
      "05": "0005",
    },
    "MaxWaitList": 4,
    "WaitList": {
      "01": "0001",
      "03": "0009",
      "05": "0010",
    },
    "note": "",
  }
];
export const patientsData: any[]=[
  {
      "MRN":'0001',
      "dob": '1946-05-11',
      "age": 73,
      "prom": [
        {
          "N": "PHQ9-Data",
          "ID": "PHQ9",
          "Data": [5,2,4,3,2,3],
          "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
        },
         {
          "N": "GAD-7-Data",
          "ID": "GAD-7",
          "Data": [2,4,3,2,3,5],
          "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
         },
         {
          "N": "PAIN-Data",
          "ID": "PAIN",
          "Data": [3,5,2,4,3,2],
          "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
         }
        ],
      "phq9":getRandomInt(10),
      "gad7":getRandomInt(10),
      "sbqr":getRandomInt(10),
      "pain":getRandomInt(10),
      "fatigue": getRandomInt(10),
      "LAPMT": '2023-06-09',
      "NAPMT": '2024-01-09',
      "iNotes": [
        {
          "iAssessmentDate": "2022-05-01",
          "iTitle": "Assessment Report",
          "iDemographics": "Mark Anderson is a 47-year-old male military veteran. He served in the U.S. Marine Corps for 20 years, with an honorable discharge in 2019. Mr. Anderson currently resides in the community and works as a security consultant.",
          "iReferral": "The purpose of this assessment is to evaluate the mental health and social support needs of Mark Anderson, a veteran experiencing symptoms of post-traumatic stress disorder (PTSD) and depression. Based on this assessment, it is recommended that he be referred to a group therapy session for veterans and receive medication management to address these concerns.",
          "iPresenting": "Mr. Anderson reported experiencing symptoms consistent with PTSD, including intrusive thoughts, nightmares, hypervigilance, and emotional numbing. He also described feelings of persistent sadness, irritability, and a sense of detachment from his family and friends. These symptoms have been present for several years, but they have worsened since his retirement from the military.\nMark's wife expressed concern about his emotional state and its impact on their relationship. He has been avoiding social activities and has difficulty sleeping, which is affecting his job performance. Mr. Anderson's goal is to regain control of his mental health and improve his overall well-being.",
          "iMedicalHistory": "Mr. Anderson has no significant medical conditions and is not taking any medications.\nHe is up to date on recommended vaccinations.",
          "iMilitaryHistory": "Mark Anderson served in the U.S. Marine Corps from 1999 to 2019, holding various roles and deploying to combat zones multiple times. He received an honorable discharge upon retirement. He is eligible for veterans' benefits but has not utilized them for mental health services.",
          "iSocialHistory": "Mark is married and has two children. His wife has been supportive but is concerned about his emotional well-being.\nHe has isolated himself from most social interactions and has lost touch with fellow veterans.",
          "iMedicationManagement": "Mr. Anderson will be evaluated by a psychiatrist to discuss the appropriateness of medication for managing his PTSD and depressive symptoms.\nIf medication is prescribed, regular follow-up appointments will be scheduled to monitor treatment response and side effects.",
          "iIBHAssessment": "Mark Anderson's presentation is consistent with post-traumatic stress disorder (PTSD) and comorbid major depressive disorder. His symptoms have significantly impacted his daily functioning, including his job performance and family relationships.",
          "iSafetyAssessment": "During the assessment, Mark Anderson did not express any thoughts of self-harm or suicide. However, his symptoms and social isolation require close monitoring. He will be encouraged to reach out to the group therapy facilitator or mental health professional if his emotional distress escalates.",
          "iRecommendation": "It is recommended that Mr. Anderson receive a comprehensive treatment plan that includes both medication management and group therapy for veterans:",
          "iGroupTherapy": "Mr. Anderson will be referred to a local veterans' support group led by mental health professionals experienced in working with veterans.\nThe group therapy sessions will provide him with the opportunity to connect with other veterans who have experienced similar traumas and offer mutual support.",
          "iFollowUp": "Mark Anderson is scheduled for a follow-up appointment on October 2, 2023, to assess his progress, evaluate the effectiveness of medication management, and ensure his continued engagement in group therapy. Frequent check-ins will be conducted to monitor his well-being and treatment response.",
          "sID": "JTM",
        },
        {
          "iAssessmentDate": "2022-10-02",
          "iTitle": "Session Assessment Follow-Up Report",
          "iDemographics": "Mark Anderson is a 47-year-old male military veteran. He served in the U.S. Marine Corps for 20 years, with an honorable discharge in 2019. Mr. Anderson resides in the community and works as a security consultant.",
          "iReferral": "Mark Anderson was referred for assessment due to symptoms consistent with post-traumatic stress disorder (PTSD) and major depressive disorder. The initial assessment on September 9, 2023, recommended a comprehensive treatment plan, including medication management and group therapy for veterans.",
          "iPresenting": "",
          "iMedicalHistory": "",
          "iMilitaryHistory": "",
          "iSocialHistory": "",
          "iMedicationManagement": "Mark Anderson was evaluated by a psychiatrist, Dr. Smith, who prescribed sertraline (Zoloft) for the management of his PTSD and depressive symptoms.\nMr. Anderson has been compliant with his medication and has not experienced any adverse effects. He reports that his mood has stabilized, and he feels less overwhelmed by intrusive thoughts and nightmares.",
          "iIBHAssessment": "Mark reported that his sleep patterns have improved, and he no longer experiences frequent nightmares.\nHis relationships with his family have improved, and he is actively engaged in household activities.\nMark continues to work as a security consultant, and he has seen a positive impact on his job performance and overall well-being.",
          "iSafetyAssessment": "Mark Anderson reported no recent thoughts of self-harm or suicide. He reiterated that he would reach out to the group therapy facilitator or mental health professional if he experiences any escalation in emotional distress.\nHe has been regularly communicating with his wife, and she is supportive of his treatment plan..",
          "iRecommendation": "Continue with the current medication regimen as prescribed by Dr. Smith, and schedule regular follow-up appointments for medication management.\nEncourage Mark Anderson to maintain his attendance in group therapy sessions, as they have proven to be beneficial in addressing his feelings of isolation and improving his mental health.\nContinue to monitor Mr. Anderson's safety and emotional well-being, emphasizing the importance of open communication with his support network, including his wife:",
          "iGroupTherapy": "Mr. Anderson attended two group therapy sessions for veterans as recommended. He expressed initial hesitation but found the experience beneficial.\nHe reported that being among fellow veterans who have faced similar challenges provided a sense of understanding and camaraderie.\nMr. Anderson felt more connected and less isolated after attending these sessions.",
          "iFollowUp": "Mark Anderson is scheduled for a follow-up appointment in six weeks, on November 13, 2023, to assess his progress and evaluate the continued effectiveness of his treatment plan. Frequent check-ins will be conducted to ensure his well-being and treatment response.",
          "sID": "JTM",
        },
      ],
      "sNotes": [
        {
          "SessionDate": "2022-06-01",
          "Title": "Group Therapy Session 1 Report",
          "SessionName": "Group Therapy Session 1",
          "SessionFacilitator": "Dr. Laura Sanchez, LCSW",
          "SessionOverview": "Mark Anderson attended his first group therapy session for veterans on September 16, 2023. The session, led by Dr. Laura Sanchez, LCSW, focused on providing a safe and supportive environment for veterans to share their experiences and emotions related to trauma and adjustment to civilian life.",
          "ParticipantObservations": "Mark initially appeared reserved and hesitant to share his thoughts and experiences.\nAs the session progressed, he gradually opened up and shared some of his struggles with post-traumatic stress disorder (PTSD) and depression.\nMark expressed his feelings of isolation and the impact of his symptoms on his relationships with family and friends.\nHe listened actively to other veterans' stories and provided empathetic responses and support.",
          "GroupDynamics": "Mark's presence contributed positively to the group dynamics. His active participation and empathy toward others created a sense of camaraderie within the group.\nThe group members shared their coping strategies, and Mark expressed a willingness to explore new ways of managing his symptoms.",
          "TherapistObservations": "Dr. Sanchez noted Mark's initial hesitation but was encouraged by his willingness to engage and support others in the group.\nShe emphasized the importance of continued attendance and encouraged Mark to take advantage of the shared experiences and support within the group.",
          "Recommendations": "Mark should continue attending group therapy sessions to build on the progress he has made during the first session.\nEncourage Mark to actively participate in discussions and share his experiences and coping strategies with the group.\nMonitor Mark's ongoing progress in group therapy and assess the impact on his overall well-being",
          "sID": "JTM",
        },
        {
          "SessionDate": "2022-07-01",
          "Title": "Group Therapy Session 2 Report",
          "SessionName": "Group Therapy Session 2",
          "SessionFacilitator": "Dr. Laura Sanchez, LCSW",
          "SessionOverview": "Mark Anderson attended his second group therapy session for veterans on September 30, 2023. This session, also led by Dr. Laura Sanchez, LCSW, continued to provide a supportive environment for veterans to share and explore their experiences related to trauma and emotional well-being.",
          "ParticipantObservations": "Mark appeared more relaxed and engaged in the session compared to the first session.\nHe shared his progress since the last session, emphasizing the positive impact of group therapy on his emotional well-being.\nMark discussed some coping strategies he had implemented, such as mindfulness exercises and journaling, which he learned from the group.",
          "GroupDynamics": "Mark's contributions continued to be valuable to the group, and his empathetic responses and support were well-received.\nOther group members acknowledged the positive changes they observed in Mark since he began attending group therapy.",
          "TherapistObservations": "Dr. Sanchez noted Mark's increased comfort level within the group and his willingness to share personal experiences and coping strategies.\nShe commended Mark's progress in implementing new techniques to manage his symptoms and encouraged him to continue exploring additional strategies.",
          "Recommendations": "Mark should maintain his attendance in group therapy sessions, as he is benefiting from the supportive environment and shared experiences.\nEncourage Mark to continue sharing his progress and coping strategies with the group, as it can inspire and support others.\nMonitor Mark's continued improvement and assess the long-term impact of group therapy on his mental health and well-being.",
          "sID": "JTM",
        },
      ],
      "sLAPMT": '',
      "sNAPMT": '',
  },{
    "MRN":'0002',
    "N": 'Sarah Patient2',
    "dob": '1981-05-11',
    "age": 42,
    "prom": [
      {
        "N": "PHQ9-Data",
        "ID": "PHQ9",
        "Data": [2,4,3,2,3,5],
        "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
      },
        {
        "N": "FATIGUE-Data",
        "ID": "FATIGUE",
        "Data": [5,2,2,4,3,3],
        "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
      },
        {
        "N": "PAIN-Data",
        "ID": "PAIN",
        "Data": [3,5,2,4,3,2],
        "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
        }
      ],
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iAssessmentDate": "2023-02-20",
      "iTitle": "Assessment Report",
      "iDemographics": "Sarah Johnson is a 42-year-old female who resides in the local community. She works as an administrative assistant and lives with her husband and two teenage children.",
      "iReferral": "The purpose of this assessment is to evaluate the mental health and social support needs of Mark Anderson, a veteran experiencing symptoms of post-traumatic stress disorder (PTSD) and depression. Based on this assessment, it is recommended that he be referred to a group therapy session for veterans and receive medication management to address these concerns.",
      "iPresenting": "Sarah presented with complaints of chronic pain and persistent fatigue that have been affecting her daily life for the past two years. She described the pain as a constant dull ache in her lower back and shoulders, which occasionally intensifies to sharp, shooting pains. The fatigue she experiences is overwhelming and often leads to difficulty concentrating and low motivation. These symptoms have interfered with her ability to enjoy social activities, maintain her household, and perform optimally at work.",
      "iMedicalHistory": "Sarah has a history of lower back pain, for which she has sought medical attention. She has been diagnosed with lumbar degenerative disc disease and fibromyalgia. Sarah has tried various pain management strategies, including physical therapy and over-the-counter pain relievers, with limited success. She is not currently taking any prescription medications.",
      "iMilitaryHistory": "",
      "iSocialHistory": "Sarah is married to her high school sweetheart, and they have two children aged 15 and 17. Her husband works as an IT consultant, and her children are both in high school. Sarah reports having a strong support system in her family but feels guilty about how her symptoms have limited her ability to actively participate in family activities.",
      "iIBHAssessment": "Sarah's presentation is indicative of a complex interplay between physical health, chronic pain, and emotional well-being. Her diagnosis of fibromyalgia suggests that her pain may be accompanied by emotional distress, such as anxiety and depression. The chronic nature of her symptoms and their impact on her daily functioning emphasize the need for a comprehensive assessment and a holistic approach to care.",
      "iRecommendation": "It is recommended that Sarah undergo a comprehensive treatment plan that addresses both her physical and emotional well-being:\n\nPsychological Support:\nSarah is encouraged to engage in psychotherapy to address the emotional aspects of her condition, including anxiety and depression.\nCognitive-behavioral therapy (CBT) or mindfulness-based stress reduction (MBSR) techniques may be beneficial in improving her coping strategies.\n\nLifestyle Modifications:\nSarah should explore lifestyle modifications, such as gentle exercise, yoga, and dietary changes, to manage her symptoms.\nIncorporating stress management techniques into her daily routine can also be beneficial.",
      "iMedicationManagement": "Sarah should continue working with her primary care physician and pain management specialist to explore medication options that can help alleviate her chronic pain and fatigue.\nRegular follow-up appointments should be scheduled to monitor treatment response and adjust medications as needed.",
      "iSafetyAssessment": "During the assessment, Sarah denied any thoughts of self-harm or suicide. However, her emotional distress and chronic pain require close monitoring. She will be encouraged to reach out to her therapist or healthcare provider if her emotional well-being deteriorates.",
      "iGroupTherapy": "Sarah will benefit from participation in a chronic pain support group where she can connect with individuals facing similar challenges and share coping strategies.",
      "iFollowUp": "Sarah is scheduled for a follow-up appointment on October 15, 2023, to assess her progress, evaluate the effectiveness of her treatment plan, and ensure her continued engagement in therapy and pain management strategies. Frequent check-ins will be conducted to monitor her well-being and treatment response.",
      "sID": "JTM",
    },
    {
      "iAssessmentDate": "2023-03-31",
      "iTitle": "Integrated Behavioral Health Follow-Up Report",
      "iDemographics": "Sarah Johnson is a 42-year-old female who resides in the local community. She works as an administrative assistant and lives with her husband and two teenage children.",
      "iReferral": "",
      "iPresenting": "",
      "iMedicalHistory": "",
      "iMilitaryHistory": "",
      "iSocialHistory": "",
      "iMedicationManagement": "Sarah has continued working closely with her primary care physician and pain management specialist to explore medication options. She reports that the prescribed medications have helped in reducing the intensity of her pain and improving her overall energy levels.\nShe has been diligent in attending regular follow-up appointments and has experienced no adverse effects from the medications.",
      "iIBHAssessment": "\n\nPsychological Support:\nSarah actively engaged in psychotherapy, focusing on cognitive-behavioral therapy (CBT) techniques. She reports increased awareness of her emotional responses and has developed coping strategies to manage anxiety and depression.\nShe expresses satisfaction with her therapist's guidance and feels that therapy has contributed significantly to her emotional well-being.\n\nLifestyle Modifications:\nSarah has incorporated gentle exercise, including daily walks and yoga, into her routine. She reports improved flexibility and a reduction in pain associated with inactivity.\nShe has also made dietary changes, emphasizing anti-inflammatory foods, and has noted positive changes in her energy levels and overall health.",
      "iSafetyAssessment": "During the follow-up session, Sarah reported no thoughts of self-harm or suicide. Her emotional well-being appears stable, and she continues to utilize coping strategies learned during therapy.",
      "iRecommendation": "Continue with the current medication regimen as prescribed by Dr. Smith, and schedule regular follow-up appointments for medication management.\nEncourage Mark Anderson to maintain his attendance in group therapy sessions, as they have proven to be beneficial in addressing his feelings of isolation and improving his mental health.\nContinue to monitor Mr. Anderson's safety and emotional well-being, emphasizing the importance of open communication with his support network, including his wife:",
      "iGroupTherapy": "Sarah has been actively participating in the chronic pain support group. She finds solace in connecting with others who share similar experiences and values the mutual support provided by the group.\nParticipation in the support group has positively influenced her emotional well-being and has reduced feelings of isolation.",
      "iFollowUp": "Sarah is scheduled for her next follow-up appointment on May 15, 2023, to continue assessing her progress and the effectiveness of her treatment plan. Continued engagement in therapy, adherence to medication, and the maintenance of lifestyle modifications are encouraged.",
      "sID": "JTM",
    },
    {
      "iAssessmentDate": "2023-06-19",
      "iTitle": "Integrated Behavioral Health Follow-Up Report",
      "iDemographics": "Sarah Johnson is a 42-year-old female who resides in the local community. She works as an administrative assistant and lives with her husband and two teenage children.",
      "iReferral": "",
      "iPresenting": "",
      "iMedicalHistory": "",
      "iMilitaryHistory": "",
      "iSocialHistory": "",
      "iMedicationManagement": "Sarah reports consistent improvements in pain management with the prescribed medications. She continues to attend regular follow-up appointments with her healthcare providers.\nMedication adjustments have not been necessary, indicating stable symptom control.",
      "iIBHAssessment": "Summary of Previous Assessment:\nSarah initially presented with chronic pain and persistent fatigue, impacting her daily life for the past two years. A comprehensive treatment plan was recommended, focusing on medical management, psychological support, lifestyle modifications, and participation in a chronic pain support group.\n\nProgress Since Previous Follow-Up:\nIn the 45 days since the last follow-up, Sarah has continued to make positive strides in her journey to manage chronic pain and improve her emotional well-being.\n\nPsychological Support:\nSarah has maintained her commitment to therapy, practicing CBT techniques to manage emotional distress effectively. She has reported increased resilience in the face of challenging situations.\nHer therapist notes Sarah's dedication to her mental health and her active participation in therapy sessions.\n\nLifestyle Modifications:\nSarah's commitment to her lifestyle modifications remains strong. She maintains her exercise routine, which has contributed to better physical function and reduced pain intensity.\nDietary changes continue to positively impact her energy levels and overall health.",
      "iSafetyAssessment": "During the follow-up session, Sarah reported no thoughts of self-harm or suicide. Her emotional well-being remains stable, and she has effectively incorporated stress management techniques into her daily life.",
      "iRecommendation": "",
      "iGroupTherapy": "Sarah continues to attend the chronic pain support group and has taken on a more active Role within the group, providing support to newer members.\nThe support group remains a valuable source of emotional support and shared coping strategies.",
      "iFollowUp": "Sarah is scheduled for her next follow-up appointment on May 15, 2023, to continue assessing her progress and the effectiveness of her treatment plan. Continued engagement in therapy, adherence to medication, and the maintenance of lifestyle modifications are encouraged.",
      "sID": "JTM",
    }
  ],
  "sNotes": [],
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0003',
  "N": 'John Patient2',
  "dob": '1949-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [3,5,11,12,10,18],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [5,2,4,3,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0004',
  "N": 'John Patient3',
  "dob": '1950-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,4,3,2,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
"gad7": getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0005',
  "N": 'John Patient4',
  "dob": '1952-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,0,1],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
  "gad7": getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0006',
  "N": 'John Patient6',
  "dob": '1946-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,1],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
"gad7":getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN": "0007",
  "N": "Dennis Patient7",
  "dob": "1946-02-03",
  "age": 83,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,3,3,2,3],
      "Date": ["2020-02-01", "2020-04-01", "2021-02-01", "2021-06-01","2021-02-01", "2022-08-01", "2022-12-01","2023-04-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
"gad7": getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0008',
  "N": 'Alice Patient8',
  "dob": '1952-07-11',
  "age": 71,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
"gad7": getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0009',
  "N": 'John Patient9',
  "dob": '1946-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
"gad7": getRandomInt(10),
  "sbqr": getRandomInt(10),
  "pain": getRandomInt(10),
  "fatigue": getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0010',
  "N": 'John Patient10',
  "dob": '1946-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
  "gad7":getRandomInt(10),
  "sbqr":getRandomInt(10),
  "pain":getRandomInt(10),
  "fatigue":getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
},{
  "MRN":'0011',
  "N": 'John Patient11',
  "dob": '1946-05-11',
  "age": 73,
  "prom": [
    {
      "N": "PHQ9-Data",
      "ID": "PHQ9",
      "Data": [5,2,4,3,2,3],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"]
    },
     {
      "N": "GAD-7-Data",
      "ID": "GAD-7",
      "Data": [2,4,3,2,3,5],
      "Date": ["2021-02-01", "2021-08-01", "2022-02-01", "2022-08-01","2023-02-01", "2023-08-01"],
     },
     {
      "N": "PAIN-Data",
      "ID": "PAIN",
      "Data": [3,5,2,4,3,2],
      "Date": ["2021-02-01", "2021-08-01", "2022-05-01", "2022-08-01","2023-02-01", "2023-08-01"],
     }
    ],
  "phq9":getRandomInt(10),
  "gad7":getRandomInt(10),
  "sbqr":getRandomInt(10),
  "pain":getRandomInt(10),
  "fatigue":getRandomInt(10),
  "LAPMT": '2023-06-09',
  "NAPMT": '2024-01-09',
  "iNotes": [
    {
      "iDate": "2022-05-01",
      "iNote": "This is test #1",
      "sID": "JTM",
    },
    {
      "iDate": "2022-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
    {
      "iDate": "2023-08-01",
      "iNote": "This is test #3",
      "sID": "JTM",
    },
  ],
  "sNotes": '',
  "sLAPMT": '',
  "sNAPMT": '',
}
];
