<div class="container-fluid" style="padding:0.0625rem;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm" *ngIf="eGS.N">
        <h4 class="fw-normal mb-1 pb-1" style="letter-spacing: 1px;">{{eGS.N}}: Group Session Profile</h4>
        <div class="col-12" style="margin:0;padding:0;">
            <a routerLink="/cmpyAdmin/{{lID}}-{{dID}}" title="Back" class="btn btn-light" role="button"
                style="float:left;"><i class="bi bi-arrow-left-square"></i></a>
            <button type="submit" class="btn" title="Update Group Session Profile" style="float:right;"
                (click)="eFormUD()"><i class="bi bi-save"></i></button>
        </div>
        <div class="row editDiv">
            <div class="col-md-8">
                <label for="N" class="form-label">Name</label>
                <input type="text" class="form-control" id="N" [value]="eGS.N" formControlName="N">
            </div>
            <div class="col-md-4">
                <label for="tSlct">Group Type</label>
                <select class="form-select" id="tSlct" formControlName="T">
                    <option value="">Select Group Type</option>
                    <option [value]="t_.T" [selected]="t_.T === eGS.T" *ngFor="let t_ of eDpt.GSType">{{ t_.TLong }}
                    </option>
                </select>
            </div>
            </div>
        <div class="row editDiv">
            <div class="col-md-12">
                <label for="Des" class="form-label">Description</label>
                <textarea class="form-control textarea" id="Des" aria-label="Group Session Description"
                    [value]="eGS.Description" formControlName="Des"></textarea>
            </div>
        </div>
        <div class="row editDiv">
            <div class="row editDiv col-md-4">
                <h5>Schedule:</h5>
                <div class="form-floating">
                    <input type="text" class="form-control" id="sDate" [value]="eGS.Schedule.sDate" placeholder="Start Date" formControlName="sDate"><label for="sDate">Start Date:</label>
                </div>
                <div class="form-floating">
                    <input type="text" class="form-control" id="sTime" [value]="eGS.Schedule.sTime" placeholder="Start Time" formControlName="sTime"><label for="sTime">Start Time:</label>
                </div>
                <div class="form-floating">
                    <input type="text" class="form-control" id="eDate" [value]="eGS.Schedule.eDate" placeholder="End Date" formControlName="eDate"><label for="eDate" class="form-label">End Date:</label>
                </div>
                <div class="form-floating">
                    <input type="text" class="form-control" id="eTime" [value]="eGS.Schedule.eTime" placeholder="End Time" formControlName="eTime"><label for="eTime" class="form-label">End Time:</label>
                </div>
                <div class="form-floating">
                    <input type="text" class="form-control" id="duration" placeholder="Durations" [value]="eGS.Schedule.duration" formControlName="duration"><label for="duration" class="form-label">Durations:</label>
                </div>
                <div class="form-floating">
                    <input type="text" class="form-control" id="frequency" placeholder="Frequency" [value]="eGS.Schedule.frequency" formControlName="frequency"><label for="frequency" class="form-label">Frequency:</label>
                </div>
            </div>
            <div class="col-md-4">
                <h5>Materials Needed:<a title="Add Additions Material to List" class="btn btn-success" role="button"
                    style="float:right; padding:0.5rem; margin-right: 0.5rem;" ><i class="bi bi-plus"></i></a>
                </h5>
                <table class="table align-middle mb-0 bg-white">
                    <tbody>
                        <tr  *ngFor="let m_ of materials_; index as i">
                            <td>{{i + 1}}: </td>
                            <td><input type="text" class="form-control" [value]="m_.Description" formControlName="ml{{i}}"></td>
                            <td><a title="Remove This Material From List" class="btn btn-danger" id="materials_{{i}}" style="float:right; padding:0.5rem;" (click)="rmThis($event)"><i class="bi bi-x-square"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
                <h5>Seats:<a title="Add Additions Material to List" class="btn btn-success" role="button"
                    style="float:right; padding:0.5rem; margin-right: 0.5rem;" ><i class="bi bi-plus"></i></a>
                </h5>
                <table class="table align-middle mb-0 bg-white">
                    <tbody>
                        <tr  *ngFor="let s_ of seats_; index as i">
                            <td>{{i + 1}}: </td>
                            <td><input type="text" class="form-control" [value]="s_.pID" formControlName="seats_{{i}}"></td>
                            <td><a title="Remove This Patient From Group" class="btn btn-danger" id="seats_{{i}}" style="float:right; padding:0.5rem;" (click)="rmThis($event)"><i class="bi bi-x-square"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
                <h5>Wait Listed:<a title="Add Additions Material to List" class="btn btn-success" role="button"
                    style="float:right; padding:0.5rem; margin-right: 0.5rem;" ><i class="bi bi-plus"></i></a>
                </h5>
                <table class="table align-middle mb-0 bg-white">
                    <tbody>
                        <tr  *ngFor="let w_ of waitList_; index as i">
                            <td>{{i + 1}}: </td>
                            <td><input type="text" class="form-control" [value]="w_.pID" formControlName="waitList_{{i}}"></td>
                            <td><a title="Remove This Patient From Wait List" class="btn btn-danger" id="waitList_{{i}}" style="float:right; padding:0.5rem;" (click)="rmThis($event)"><i class="bi bi-x-square"></i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-3">
                <label for="P1" class="form-label">Primary Phone Number</label>
                <input type="tel" class="form-control" id="P1" (blur)="updatePhn($event)" [value]="eGS.Location.P1"
                    formControlName="P1">
            </div>
            <div class="col-md-3">
                <label for="TF" class="form-label">Toll-Free Phone Number</label>
                <input type="tel" class="form-control" id="TF" (blur)="updatePhn($event)" [value]="eGS.Location.TF"
                    formControlName="TF">
            </div>
            <div class="col-md-3">
                <label for="F" class="form-label">Fax Phone Number</label>
                <input type="tel" class="form-control" id="F" (blur)="updatePhn($event)" [value]="eGS.Location.F"
                    formControlName="F">
            </div>
            <div class="col-md-3">
                <label for="E" class="form-label">Email</label>
                <input type="email" class="form-control" id="E" [value]="eGS.Location.E" formControlName="E">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="A" class="form-label">Address</label>
                <input type="text" class="form-control" id="A" [value]="eGS.Location.A" formControlName="A">
            </div>
            <div class="col-md-6">
                <label for="A2" class="form-label">Roon:</label>
                <input type="text" class="form-control" id="A2" [value]="eGS.Location.A2" formControlName="A2">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" [value]="eGS.Location.C" formControlName="C">
            </div>
            <div class="col-md-4">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" [value]="eGS.Location.S" formControlName="S">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="Z" class="form-label">Zip</label>
                <input type="text" class="form-control" id="Z" [value]="eGS.Location.Z" formControlName="Z">
            </div>
        </div>
        <div class="row editDiv">
            <h5>Primary Contacts:</h5>
            <div class="col-md-3">
                <label for="pNSlct">Nursing</label>
                <select class="form-select" id="pNSlct" (change)="chgNursing($event)" formControlName="pN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }}
                        {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pPSlct">Provider</label>
                <select class="form-select" id="pPSlct" (change)="chgProvider($event)" formControlName="pP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Provider" *ngFor="let s_ of stfs">{{ s_.fN
                        }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pASlct">Admin</label>
                <select class="form-select" id="pASlct" (change)="chgAdmin($event)" formControlName="pA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }}
                        {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pISlct">IT</label>
                <select class="form-select" id="pISlct" (change)="chgIT($event)" formControlName="pI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{
                        s_.lN }}</option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <h5>Secondary Contacts:</h5>
            <div class="col-md-3">
                <label for="sNSlct">Nursing</label>
                <select class="form-select" id="sNSlct" (change)="chgNursing($event)" formControlName="sN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }}
                        {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sPSlct">Provider</label>
                <select class="form-select" id="sPSlct" (change)="chgProvider($event)" formControlName="sP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Provider" *ngFor="let s_ of stfs">{{ s_.fN
                        }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sASlct">Admin</label>
                <select class="form-select" id="sASlct" (change)="chgAdmin($event)" formControlName="sA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }}
                        {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sISlct">IT</label>
                <select class="form-select" id="sISlct" (change)="chgIT($event)" formControlName="sI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{
                        s_.lN }}</option>
                </select>
            </div>
        </div>
    </form>
</div>