<div id="theContainer" class="container-fluid">
    <div id="theRow" class="row">
        <div class="col-md-2" style="height: fit-content;">
            <div id="patientCount" class="col">
                <div class="hdr"><p class="hdr">Location:</p>
                    <div class="rw">
                        <select class="form-select form-select-sm mb-.25" aria-label="Small select example" [(ngModel)]="selLoc" name="Locations" (change)="locChanged(selLoc)" style="background-color:transparent;">
                            <option value="{{cmpy.N}}">{{cmpy.N}}</option>;
                            <option *ngFor="let l of cmpy.Locations" [title]="l.N" [value]="l.ID">{{l.N}}</option>
                         </select>
                    </div>
                    <div class="rw">
                        <select class="form-select form-select-sm mb-.25" aria-label="Small select example" [(ngModel)]="selDept" name="Departments" style="background-color:transparent;">
                            <option value="All">All Departments</option>;
                            <option *ngFor="let d of listDept" [title]="d.N" [value]="d.ID">{{d.N}}</option>;
                        </select>
                    </div>
                </div>
                <div class="hdr"><p class="hdr">Date:</p>
                    <div class="rw" style="height:fit-content;">
                        <input type="date" name="dbDate" class="form-control" id="dbDate" [(ngModel)]="selDate" [value]="dbDateVal" style="background-color:transparent;">
                    </div>
                </div>
                <div class="hdr"><p class="hdr">Encounters | Assessments:</p>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">{{selDate}}:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pToday}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aToday}}</h5></div>
                        </div>
                    </div>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><select class="form-select form-select-sm mb-.25" aria-label="Small select example" [(ngModel)]="selSummary" name="summaryRange" style="background-color:transparent;"><option *ngFor="let o of summaryRange" [title]="o.title" [value]="o.value">{{o.viewValue}}</option>;
                            </select></div>
                            <div class="col-3"><h5 class="hdr">{{pAvg}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aAvg}}</h5></div>
                        </div>
                    </div>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><select class="form-select form-select-sm mb-.25" aria-label="Small select example" [(ngModel)]="selDateRange" name="dateRange" style="background-color:transparent;"><option *ngFor="let o of dateRange" [title]="o.title" [value]="o.value">{{o.viewValue}}</option>;
                            </select></div>
                            <div class="col-3"><h5 class="hdr">{{pYTD}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aYTD}}</h5></div>
                        </div>
                    </div>
                </div>
                <div class="hdr"><p class="rhdr">Red Alerts:</p>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">{{selDate}}:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pTodayRed}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aTodayRed}}</h5></div>
                        </div>
                    </div>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">{{selSummary}}:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pAvgRed}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{pAvgRed}}</h5></div>
                        </div>
                    </div>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">{{selDateRange}}:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pYTDRed}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aYTDRed}}</h5></div>
                        </div>
                    </div>
                </div>
                    <div class="hdr">
                        <div class="row">
                            <div class="col-1"></div>
                                <div class="col-10" style="padding: 0;margin: 0;">
                                    <div class="btn-group" Role="group" aria-label="Dashboard View">
                                        <input type="radio" class="btn-check" name="dbVw" id="br1" autocomplete="off" checked>
                                        <label class="btn btn-outline-primary" for="br1">Date</label>
                                        <input type="radio" class="btn-check" name="dbVw" id="br2" autocomplete="off">
                                        <label class="btn btn-outline-primary" for="br2">Evaluated</label>
                                        <input type="radio" class="btn-check" name="dbVw" id="br3" autocomplete="off">
                                        <label class="btn btn-outline-primary" for="br3">Range</label>
                                    </div>
                                </div>
                            <div class="col-1"></div>
                        </div>
                        <p class="hdr">By Age Group:</p>
                        <div class="rw">
                            <div class="row">
                                <div class="col-6"><span class="hdr">65 and over:</span></div>
                                <div class="col-3"><h5 class="hdr">{{pSeniors}}</h5></div>
                                <div class="col-3"><h5 class="hdr">{{aSeniors}}</h5></div>
                            </div>
                        </div>
                        <div class="rw">
                            <div class="row">
                                <div class="col-6"><span class="hdr">26-64:</span></div>
                                <div class="col-3"><h5 class="hdr">{{pAdult}}</h5></div>
                                <div class="col-3"><h5 class="hdr">{{aAdult}}</h5></div>
                            </div>
                        </div>
                        <div class="rw" *ngIf="pYoungAdult>0">
                            <div class="row">
                                <div class="col-6"><span class="hdr">18-25:</span></div>
                                <div class="col-3"><h5 class="hdr">{{pYoungAdult}}</h5></div>
                                <div class="col-3"><h5 class="hdr">{{aYoungAdult}}</h5></div>
                            </div>
                        </div>
                        <div class="rw" *ngIf="pAdolescents>0">
                            <div class="row">
                                <div class="col-6"><span class="hdr">18-25:</span></div>
                                <div class="col-3"><h5 class="hdr">{{pAdolescents}}</h5></div>
                                <div class="col-3"><h5 class="hdr">{{aAdolescents}}</h5></div>
                            </div>
                        </div>
                        <div class="rw" *ngIf="pChildren>0">
                            <div class="row">
                                <div class="col-6"><span class="hdr">18-25:</span></div>
                                <div class="col-3"><h5 class="hdr">{{pChildren}}</h5></div>
                                <div class="col-3"><h5 class="hdr">{{aChildren}}</h5></div>
                            </div>
                        </div>
                    </div>
                <div class="hdr"><p class="hdr">By Gender:</p>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">Female:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pFemale}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aFemale}}</h5></div>
                        </div>
                    </div>
                    <div class="rw">
                        <div class="row">
                            <div class="col-6"><span class="hdr">Male:</span></div>
                            <div class="col-3"><h5 class="hdr">{{pMale}}</h5></div>
                            <div class="col-3"><h5 class="hdr">{{aMale}}</h5></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10 row" style="height: fit-content;padding: 0;margin: 0;">
            <div class="row" style="height: fit-content;">
                <div class="col-md-8">
                    <figure class="highcharts-figure"><div id="container1" style="height:20rem;"></div></figure>
                </div>
                <div class="col-md-4">
                    <figure class="highcharts-figure"><div id="container2" style="height:20rem;"></div></figure>
                </div>
            </div>
            <div class="row" style="height: fit-content;">
                <div class="col-md-8">
                    <figure class="highcharts-figure"><div id="container3" style="height:20rem;"></div></figure>
                </div>
                <div class="col-md-4">
                    <figure class="highcharts-figure"><div id="container4" style="height:20rem;"></div></figure>
                </div>
            </div>
        </div>
    </div>
</div>
