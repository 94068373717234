import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promTotal'
})
export class PromTotalPipe implements PipeTransform {

  transform(prom:any, args?: any): [number,number,number] {let r_:number=0;let t_:number=0;let p_:number=0;let s_:number=0;
    /* console.log(prom); */
    for(let i=0; i<prom.length; i++){r_ = parseInt(prom[i]['r']);t_=t_+r_;if(r_>0){p_++;};s_=s_+prom[i]['t'];}
    //for(let i=0; i<prom.length; i++){r_ = prom[i]['r'];t_=t_+r_;if(r_>0){p_++;};s_=s_+Math.ceil(prom[i]['t']);}
    return [t_,p_,s_];
  }

}
