// environment.ts (demo)
const environmentHost = `demo-backend.myhealthregistry.care`;
const environmentSocketHost = `demo-socket.myhealthregistry.care`;

export interface Environment {
  production: boolean;
  apiBaseUrl: string;
  sanctumUrl: string;
  socketUrl: string;
  apiVersion: string;
  retryAttempts: number;
  requestTimeout: number;
  logErrorsToConsole: boolean;
  errorReportingServiceUrl: string;
  enableFeatureX: boolean;
  monitoring?: {
    sentryDsn?: string;
    googleAnalyticsKey?: string;
  };
}

export const environment: Environment = {
  production: true,
  apiBaseUrl: `https://${environmentHost}/api`,
  sanctumUrl: `https://${environmentHost}`, 
  socketUrl: `https://${environmentSocketHost}/socket`,

  apiVersion: `v1`, // Version control for future-proofing
  retryAttempts: 3, // Retry requests 3 times upon failure
  requestTimeout: 10000,
  // Add monitoring and logging configurations
  logErrorsToConsole: true,  // No error logging to console in production
  errorReportingServiceUrl: `https://error-logging-service.myhealthregistry.care`,  // Error reporting service

  // Feature flags
  enableFeatureX: true, // Toggle features on/off for development
  
  // Example of adding monitoring or analytics services
  monitoring: {
    sentryDsn: `https://your-sentry-dsn`, // Sentry for error tracking
    googleAnalyticsKey: `UA-XXXXXX-1` // Google Analytics for production tracking
  }
  
};
