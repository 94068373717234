<div class="container-fluid"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="row" *ngIf="!isLoading">
        <div class="col-10">
            <h3>{{loc.N }}: {{dpt.N }} - {{formattedDate}} - Appointments: {{appointments.length}}</h3>
        </div>
        <div class="col-md-2 text-end">
            <div class="btn-group" role="group" aria-label="Appointment Actions">
                <button title="Refresh Today's Appointments: Confirmed Only" (click)="getCnfrmAppointments()"
                    class="btn btn-outline-success"><i class="bi bi-clock-history"></i></button>
                <button title="Refresh Today's Appointments: All" (click)="getAllAppointments()"
                    class="btn btn-success"><i class="bi bi-clock"></i></button>
            </div>
            <div class="d-flex" style="float: right;" *ngIf="apptManualAccess">
                <label for="csvFileUpload" class="file-upload-btn"
                    title="Manually Download Any New or Updated Appointments"><i class="bi bi-cloud-download"></i>
                    <input type="file" #fileImportInput id="csvFileUpload" (change)="chkForNewAppointments($event)"
                        accept=".csv" style="display: none;">
                </label>
            </div>
        </div>
    </div>
    <table class="appointment-list table table-striped align-middle mb-0 bg-white" *ngIf="appointments.length > 0">
        <thead class="bg-light">
            <tr>
                <th>Time</th>
                <th class="cntr">Status</th>
                <th>Patient</th>
                <th>Details</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="5"><ng-template #timeForAppointment><span class="time-for-appointment">It's
                            time!</span></ng-template></td>
            </tr>
            <tr *ngFor="let a of appointments; let i = index">
                <td>
                    <!--p class="apt-notes fw-normal mb-1">{{ a.Date }}</p!-->
                    <p class="apt-notes fw-bold mb-1">{{ a.Time }}</p>
                    <div *ngIf="appointmentsStatus$ | async as statuses; else notTimeYet">
                        <div *ngIf="statuses[i]; then timeForAppointment else notTimeYet">It's time!</div>
                    </div>
                    <ng-template #notTimeYet>
                        <p class="apt-notes fw-bold mb-1">Not Yet</p>
                    </ng-template>
                </td>
                <td class="cntr" [ngClass]="getClass(a.Status)">
                    <a title="Update This Appointment to: No Show" (click)="openInterventionUpdateDialog(a)"
                        class="btn fw-bold mb-1">{{a.Status}}</a></td>
                <td>
                    <!--a routerLink="/patientDetail/{{a.Patient.pID}}" class="w-100">
                    <div class="d-flex align-items-center">
                        <div class="ms-3"!-->
                    <p class="fw-bold mb-1">{{a.Patient.fullName}}<br>{{a.Patient.DOB}}<br>{{a.Patient.pID}}</p>
                    <!--/div>
                    </div>
                    </a!-->
                </td>
                <td>
                    <p class="fw-normal mb-1">{{a.Details.reason}}</p>
                    <p class="fw-normal mb-1">{{a.Details.resource}}</p>
                </td>
                <!--td>
                    <p class="apt-notes fw-bold mb-1">{{a.Details.problems}}</p>
                    <p class="apt-notes fw-bold mb-1">{{a.Details.pcp}}</p>
                </td!-->
                <td><a title="Update This Appointment to: No Show" (click)="statusUpdate(a)"
                        class="btn btn-danger"><mat-icon>update</mat-icon></a></td>
            </tr>
            <tr *ngIf="appointments.length === 0">
                <td colspan="5">No appointments found.</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="appointments.length === 0" class="text-center py-2">
        <p>No appointments to display.</p>
    </div>
</div>