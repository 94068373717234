import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Staff } from '../../../Store/Model/type.Interfeaces';

@Component({
  selector: 'app-assessment-signin',
  templateUrl: './assessment-sign-in.component.html',
  styleUrls: ['./assessment-sign-in.component.css'],
})
export class AssessmentSigninComponent implements OnInit {
  activeMenu!: string;
  errors: any = null;
  rsp: any; auth: any;
  cmpy: any; locs: any; loc: any; dpt: any; stf: any; stfs: any;
  signinForm: FormGroup;
  isSigningIn: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    //this.signinForm = this.fb.group({ E: [''], pswrd: [''], });
    this.signinForm = this.fb.group({
      E: ['', [Validators.required, Validators.email]],
      pswrd: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.isSigningIn = true;
    /*
    this.aSrvc.signin(this.signinForm.value).subscribe(
      (result) => {
        this.handleResponse(result);
        this.isSigningIn = false;
      },
      (error) => {
        this.toastr.error(error.error.message || 'Login failed');
        this.isSigningIn = false;
      }
    );
    */
  }

  private handleResponse(result: any) {
    if (!result || !result.staff) {
      this.toastr.error('Invalid server response');
      this.isSigningIn = false;
      return;
    }
    console.log(result);
    const stf: Staff = result.staff; this.stf = stf; console.log(this.stf);
    const loc: any = result.loc[0]; this.loc = loc; console.log(this.loc);
    const dpt: any = result.dpt[0]; this.dpt = dpt; console.log(this.dpt);
    const cmpy: any = result.cmpy[0]; this.cmpy = cmpy; console.log(this.cmpy);
    const auth: any = result.authorisation; this.auth = auth; //console.log(this.auth);
    localStorage.setItem('stf', JSON.stringify(this.stf));
    const lID = stf.lID; localStorage.setItem('currentLID', lID);
    const dID = stf.dID; localStorage.setItem('currentDID', dID);
    localStorage.setItem('showNote', 'false');
    if (stf.R <= '0004') {
      this.aSrvc.GetLocationCnfg(stf.cID + "-ALL").subscribe(
        (result) => { this.cmpy = result; },
        (error) => { this.errors = error.error; console.log(this.errors); this.toastr.error(this.errors.message); },
        () => {
          const c_ = this.cmpy[0]; this.cmpy = c_;
          let x_ = JSON.parse(c_.Adr); this.cmpy.Adr = x_;
          x_ = JSON.parse(c_.Cnfg); this.cmpy.Cnfg = x_;
          x_ = JSON.parse(c_.Contacts); this.cmpy.Contacts = x_;
          x_ = JSON.parse(c_.LType); this.cmpy.LType = x_;
          localStorage.setItem('locs', JSON.stringify(this.locs));
          this.locs = this.cmpy.Locations;
          for (let l = 0; l < this.locs.length; l++) {
            let l_ = this.locs[l];
            let xl_ = JSON.parse(l_.Adr); this.locs[l].Adr = xl_;
            xl_ = JSON.parse(l_.Contacts); this.locs[l].Contacts = xl_;
            xl_ = JSON.parse(l_.Cnfg); this.locs[l].Cnfg = xl_;
            xl_ = JSON.parse(l_.DType); this.locs[l].DType = xl_;
            xl_ = JSON.parse(l_.Roles); this.locs[l].Roles = xl_;
            xl_ = JSON.parse(l_.AL); this.locs[l].AL = xl_;
            xl_ = JSON.parse(l_.LPs); this.locs[l].LPs = xl_;
            //console.log('Departments',this.locs[l].Departments);
            for (let d = 0; d < this.locs[l].Departments.length; d++) {
              let d_ = this.locs[l].Departments[d];
              let xd_ = JSON.parse(d_.Adr); this.locs[l].Departments[d].Adr = xd_;
              xd_ = JSON.parse(d_.Contacts); this.locs[l].Departments[d].Contacts = xd_;
              xd_ = JSON.parse(d_.Cnfg); this.locs[l].Departments[d].Cnfg = xd_;
              xd_ = JSON.parse(d_.GSType); this.locs[l].Departments[d].GSType = xd_;
              if (typeof d_.GroupSessions === 'object') {
                for (let g = 0; g < this.locs[l].Departments[d].GroupSessions.length; g++) {
                  let g_ = this.locs[l].Departments[d].GroupSessions[g];
                  let xg_ = JSON.parse(g_.Location); this.locs[l].Departments[d].GroupSessions[g].Location = xg_;
                  xg_ = JSON.parse(g_.Contacts); this.locs[l].Departments[d].GroupSessions[g].Contacts = xg_;
                  xg_ = JSON.parse(g_.Cnfg); this.locs[l].Departments[d].GroupSessions[g].Cnfg = xg_;
                  xg_ = JSON.parse(g_.Schedule); this.locs[l].Departments[d].GroupSessions[g].Schedule = xg_;
                  xg_ = JSON.parse(g_.MaterialsNeeded); this.locs[l].Departments[d].GroupSessions[g].MaterialsNeeded = xg_;
                  xg_ = JSON.parse(g_.Coordinators); this.locs[l].Departments[d].GroupSessions[g].Coordinators = xg_;
                  xg_ = JSON.parse(g_.Seats); this.locs[l].Departments[d].GroupSessions[g].Seats = xg_;
                  xg_ = JSON.parse(g_.WaitList); this.locs[l].Departments[d].GroupSessions[g].WaitList = xg_;
                }
              }
            }
          }
          this.cmpy.Locations = this.locs; console.log('cmpy', this.cmpy, 'locs', this.locs);
          localStorage.setItem('locs', JSON.stringify(this.locs));
        }
      );
    }
    else {
      localStorage.setItem('dpt', JSON.stringify(this.dpt));
      localStorage.setItem('loc', JSON.stringify(this.loc));
      localStorage.setItem('locs', JSON.stringify(''));
    }
    localStorage.setItem('cmpy', JSON.stringify(this.cmpy));
    //this.authState.setAuthState(true, 'assessment'); // or another signInMethod based on your logic
    //this.tknService.saveToken(auth.token);
    this.navigateToNextPage(result.staff);
    console.log(localStorage, this.cmpy);
  }

  private navigateToNextPage(staff: any) {
    this.router.navigate(['axAppointments/' + staff.lID + '/' + staff.dID]);
  }

}
