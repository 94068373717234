import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentDataService {
  private appointmentsSource = new BehaviorSubject<any[]>([]);
  public appointments$ = this.appointmentsSource.asObservable();

  constructor() { }

  updateAppointmentsData(newData: any[]) {
    this.appointmentsSource.next(newData);
    console.log("updateAppointmentsData: ",this.appointments$);
  }
}
