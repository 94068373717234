<div class="container">
    <div class="card inner-main">
      <div class="card-header">
        User Profile
      </div>
      <div class="card-body">
        <p class="card-text">First Name: <strong>{{stf.fN}}</strong></p>
        <p class="card-text">Last Name: <strong>{{stf.lN}}</strong></p>
        <p class="card-text">Email: <strong>{{stf.E}}</strong></p>
      </div>
    </div>
  </div>