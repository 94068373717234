import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as Highcharts from 'highcharts';
import BulletModule from 'highcharts/modules/bullet';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';

import { tap, switchMap, catchError } from 'rxjs/operators';
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { PromChartGenPipe } from '../../../thePipes/prom-chart-gen.pipe';
import { AssessmentResultsComponent } from '../assessment-results/assessment-results.component';
import { PatientDetailsPipe } from 'src/app/thePipes/patient-details.pipe';
import { Encounter } from 'src/app/Store/Model/type.Interfeaces';

BulletModule(Highcharts);
@Component({
  selector: 'app-patient-notes-page',
  templateUrl: './patient-notes-page.component.html',
  styleUrls: ['./patient-notes-page.component.css']
})
export class PatientNotesPageComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  errors: any = null;
  isLoading: boolean = true;

  iForm: FormGroup | any;
  sForm: FormGroup | any;
  tForm: FormGroup | any;

  iNotes: any = []; iNotesD: any = []; iNote: any = []; iNv: any = [];
  sNotes: any = []; sNotesD: any = []; sNote: any = []; sNv: any = [];
  oNotes: any = []; oNotesD: any = []; oNote: any = []; oNv: any = [];
  tNotes: any = []; tNotesD: any = []; tNote: any = []; tNv: any = [];
  cmpy: any; cnfg: any; mpCnfg: any; ppCnfg: any; spCnfg: any; sympCnfg: any; medMngt: any; socialCnfg: any;
  tpCnfg: any;
  tpPsyc: any;
  tpCMS: any;
  stf: any;

  patient: any;
  encntrs: Encounter[] = [];
  patientHistory: any;
  patientChrtRdy: boolean = false;
  patientRdy: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;
  chart: Highcharts.Chart | undefined;
  bulletChart: Highcharts.Chart | undefined;

  bootstrap: any;
  promRd: string = '';
  prom_ID: string = 'PHQ9';
  prom: any = [];
  prom_: any = [];
  eD: any = [];
  eD_: any = [];
  eDates: any = [];
  eData: any = [];
  tDates: any = [];
  updateFlag = false;
  selectEncounter_: any = [];

  constructor(private route: ActivatedRoute, private router: Router,
    private uSrvc: UtilitiesService, private aSrvc: AuthService,
    public fb: FormBuilder, private _location: Location,
    private promChartGen: PromChartGenPipe, public dialog: MatDialog, private pDetails: PatientDetailsPipe) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
    }
  }

  initializeForms() {
    this.iForm = this.fb.group({
      iTitle: [''],
      AssessmentDate: [''],
      Reason: [''],
      Problems: [''],
      Referral: [''],
      Presenting: [''],
      MedicalHistory: [''],
      MilitaryHistory: [''],
      SocialHistory: [''],
      MedicationManagement: [''],
      IBHAssessment: [''],
      SafetyAssessment: [''],
      iRecommendation: [''],
      GroupTherapy: [''],
      FollowUp: [''],
    });
    this.sForm = this.fb.group({
      sTitle: [''],
      SessionDate: [''],
      Name: [''],
      Facilitator: [''],
      Overview: [''],
      ParticipantObservation: [''],
      GroupDynamic: [''],
      TherapistObservation: [''],
      Recommendation: [''],
    });
    console.log("initializeForms ");
  }

  newIForm() {
    console.log("Create new Notes this Encounter Date ", this.iForm.value);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yr = today.getFullYear();
  }
  saveIForm() {
    console.log("Save new Notes this Encounter Date ", this.iForm.value);
    this.aSrvc.EncntrSV(this.patient, this.iForm.value).subscribe(
      (result) => { console.log(result); }
    );
  }
  updateIForm() {
    console.log("Update Note: ", this.iForm.value);
    console.log("aDate: ", this.iForm.value.AssessmentDate);
    const Es_: Encounter[] = this.patient.encounters; console.log("encounter: ", Es_);
    let e_: any = Es_.find((E_: { eDate: String; }) => E_.eDate === this.iForm.value.AssessmentDate
    );
    console.log(e_);
    e_.note = this.iForm.value;
    console.log(e_);
    this.aSrvc.EncntrUD(e_).subscribe(
      (result) => { console.log(result); }
    );
  }
  uploadIForm() {
    console.log('Upload IBH notes to EHR');
    this.aSrvc.EncntrUL(this.patient, this.iForm.value).subscribe(
      (result) => { console.log(result); }
    );
  }
  newSForm() {
    console.log("Create new Notes this Session Date ", this.iForm.value);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yr = today.getFullYear();
    this.sNote.Date = yr + "-" + mm + "-" + dd;
  }
  saveSForm() {
    console.log("Save new Notes this Session Date ", this.sForm.value);
    this.aSrvc.SessionSV(this.patient, this.sForm.value).subscribe(
      (result) => { console.log(result); }
    );
  }
  updateSForm() {
    console.log("Update Notes for this Session ", this.sForm.value);
    this.aSrvc.SessionUD(this.patient, this.sForm.value).subscribe(
      (result) => { console.log(result); }
    );
  }
  uploadSForm() {
    console.log('Upload Session notes to EHR');
    this.aSrvc.SessionUL(this.patient, this.sForm.value).subscribe(
      (result) => { console.log(result); }
    );
  }

  backClicked() { this._location.back(); };
  previRpt() { const pPg = this['iNv'].pPg; const cPg = this['iNv'].cPg; const nPg = this['iNv'].nPg; this['iNv'].pPg = cPg; this['iNv'].cPg = pPg; this['iNv'].nPg = nPg - 1; if (this['iNv'].nPg <= -1) { this['iNv'].nPg = 1; } this['iNote'] = this['iNotes'][this['iNv'].cPg]; }
  nextiRpt() { const pPg = this['iNv'].pPg; const cPg = this['iNv'].cPg; const nPg = this['iNv'].nPg; this['iNv'].pPg = cPg; this['iNv'].cPg = nPg; this['iNv'].nPg = nPg + 1; if (this['iNv'].nPg === this['iNotes'].length) { this['iNv'].nPg = 0; } this['iNote'] = this['iNotes'][this['iNv'].cPg]; }
  prevsRpt() { const pPg = this['sNv'].pPg; const cPg = this['sNv'].cPg; const nPg = this['sNv'].nPg; this['sNv'].pPg = cPg; this['sNv'].cPg = pPg; this['sNv'].nPg = nPg - 1; if (this['sNv'].nPg <= -1) { this['sNv'].nPg = 1; } this['sNote'] = this['sNotes'][this['sNv'].cPg]; }
  nextsRpt() { const pPg = this['sNv'].pPg; const cPg = this['sNv'].cPg; const nPg = this['sNv'].nPg; this['sNv'].pPg = cPg; this['sNv'].cPg = nPg; this['sNv'].nPg = nPg + 1; if (this['sNv'].nPg === this['sNotes'].length) { this['sNv'].nPg = 0; } this['sNote'] = this['sNotes'][this['sNv'].cPg]; }

  private chartUpdate() {
    if (!this.encntrs || this.encntrs.length < 1) return;
    var dialog = this.dialog;
    let pD_ = this.patient; //console.log(pD_);
    let E_ = this.encntrs; console.log("chartUpdate", E_);
    //let iNotesD_ = this.iNotesD; let sNotesD_ = this.sNotesD; console.log('iNotesD_:', iNotesD_, 'sNotesD_:', sNotesD_);
    let T_ = this['tDates']; //console.log(T_);
    let eD_ = this['eD_'];
    function popup(this: any) {
      console.log("chartUpdate popup E_", E_);
      dialog.open(AssessmentResultsComponent, {
        width: '100%', enterAnimationDuration: '1000ms', exitAnimationDuration: '500ms',
        data: { pD_: pD_, assessment: E_[this.index] }
      }); onChartClick(this.index);
    }
    const onChartClick = (index: number) => {
      
      // @ts-ignore
      this.selectEncounter_ = E_[index.point.index];//console.log('onChartClick:',index,index.point.index,this.selectEncounter_);
      //this.uSrvc.selectEncounter(this.selectEncounter_);

      // additional logic if necessary
    };
    if (this.encntrs.length >= 1) {
      console.log("this.chart enabled");
      // @ts-ignore
      this.chart = Highcharts.chart('container', {
        chart: { renderTo: 'container', scrollablePlotArea: { minWidth: 700 } },
        title: { text: this['prom_'].N, align: 'center' },
        xAxis: [{
          title: { text: 'Encounter Dates' }, type: "datetime",
          itemStyle: { font: '8pt Trebuchet MS,Verdana,sans-serif', color: '#A0A0A0' },
          categories: this['eDates'], accessibility: { description: 'encounter' }
        }],
        yAxis: [{ title: { text: this['prom_'].ID }, labels: { align: 'left', x: 0, y: 11, format: '{value:.,0f}' }, showFirstLabel: false }],//left y axis
        legend: { align: 'left', verticalAlign: 'top', borderWidth: 0 },
        tooltip: {
          shared: true, crosshairs: true,
          formatter: function (this: any) {onChartClick(this);
            //this.selectEncounter_ = E_[this.index];
            //this.uSrvc.selectEncounter(this.selectEncounter_);
      
            //console.log(T_, T_[this.x][0], E_);
            //if (T_[this.x][0] === "IBH") { console.log(iNotesD_[this.x][0]); }
            //if (T_[this.x][0] === "GS") { console.log(sNotesD_[this.x][0]); }
            // @ts-ignore
            return T_[this.x] + '-Encounter: ' + this.x + ' <br>' + this.y;
            //return T_[this.x] + '-Encounter: ' + this.x + ' <br>' + this.y + ' <br>' + "{series.name}|{point.y}";
          }
        },
        plotOptions: {
          series: { cursor: 'pointer', className: 'popup-on-click', marker: { lineWidth: 1 }, point: { events: { click: popup } } }
          //series: { cursor: 'pointer', className: 'popup-on-click', marker: { lineWidth: 1 }, point: { events: { click: popup } } }
        },
        series: this['eD_']
      });
    }
    //this.bulletChartUpdate();
  }
  private bulletChartUpdate(): void {
    if (!this.encntrs || this.encntrs.length < 1) return;

    const bulletChartOptions: Highcharts.Options = {
      chart: {
        renderTo: 'container1', // Ensure this ID matches your container's ID
        type: 'bullet',
        scrollablePlotArea: { minWidth: 700 },
        inverted: true,
        marginLeft: 135
      },
      title: { text: "Bullet test" },
      xAxis: {
        categories: ['<span class="hc-cat-title">Revenue</span><br/>U.S. $ (1,000s)']
      },
      // @ts-ignore
      yAxis: {
        plotBands: [{
          from: 0,
          to: 150,
          color: '#666'
        }, {
          from: 150,
          to: 225,
          color: '#999'
        }, {
          from: 225,
          to: 9e9,
          color: '#bbb'
        }],
        title: null
      },
      // @ts-ignore
      series: [{ data: [{ y: 275, target: 250 }] }],
      plotOptions: {
        series: {
          // @ts-ignore
          pointPadding: 0.25,
          borderWidth: 0,
          color: '#000',
          targetOptions: {
            width: '200%'
          }
        }
      },
      // Other configurations...
    };
    //this.bulletChart = Highcharts.chart(bulletChartOptions);
  }
  dialogRef: any;
  editPatientDemo(pd_: object) { this.OpenPopup(pd_); }
  OpenPopup(pd_: object): any {
    console.log(pd_);
  }

  private loadPatientData(patientId: string): Observable<any> {
    this.isLoading = true;

    return this.aSrvc.GetPatientEncntrs(patientId).pipe(
      tap(result => this.processPatientData(result)),
      catchError(error => {
        this.errors = error;
        console.log('this.errors', this.errors);
        this.isLoading = false;
        return of([]);
      })
    );
  }
  private processPatientData(patient: any): void {
    // Assuming 'patient' is the result received from the service
    if (!patient) { this.isLoading = false; return; }
    //console.log('processPatientData', patient);
    this.encntrs = patient.encounters;
    for (let e = 0; e < this.encntrs.length; e++) {
      const eD_ = this.encntrs[e];
      const eDate = eD_.eDate;
      this['eDates'].push(eDate);
      this['iNotesD'][eDate] = []; this['sNotesD'][eDate] = []; this['oNotesD'][eDate] = [];
      this['tDates'][eDate] = []; this['tDates'][eDate].push(eD_["T"]);
      if (eD_["T"] === "IBH") { this['iNotes'].push(eD_.note); this['iNotesD'][eDate].push(eD_.note); }
      else if (eD_["T"] === "GS") { this['sNotes'].push(eD_.note); this['sNotesD'][eDate].push(eD_.note); }
      else { this['oNotes'].push(""); this['oNotesD'][eDate].push(""); }
      let xPrm: any = this.promChartGen.transform(eD_.prom, 'pipeFilter');
      //console.log('eD_.prom', eD_.prom,'xPrm', xPrm);
      for (let l = 0; l < xPrm.length; l++) { this.eD[xPrm[l].k] = []; this.eD[xPrm[l].k].Tlt = []; }
    }
    let kChk: any = [];
    //console.log('processPatientData - this.encntrs', this.encntrs);
    for (let e = 0; e < this.encntrs.length; e++) {
      kChk = [];
      let xPrm: any = this.promChartGen.transform(this.encntrs[e].prom, 'pipeFilter');
      let ePrms_: any = this.encntrs[e].promKeys;
      for (let l = 0; l < xPrm.length; l++) {
        kChk.push(xPrm[l].k);
        this.eD[xPrm[l].k].push(xPrm[l].r);
        this.eD[xPrm[l].k].Tlt.push(xPrm[l].t);
      } let missKAry: any = [];
      if (kChk.length !== ePrms_.length) {
        let missingK = ""; let kFound = false; missKAry = [];
        for (let k = 0; k < ePrms_.length; k++) {
          kFound = false; missingK = ePrms_[k];
          for (let k_ = 0; k_ < kChk.length; k_++) { if (kChk[k_] === missingK) { kFound = true; } }
          if (!kFound) { missKAry.push(missingK); }
        }
        if (missKAry.length > 0) { for (let mk_ = 0; mk_ < missKAry.length; mk_++) { this.eD[missKAry[mk_]].Tlt.push(""); } }
      }
    }
    this['eD_'] = [];
    for (const key of Object.keys(this.eD)) { this['eD_'].push({ name: key, data: this.eD[key].Tlt }); }
    //console.log('processPatientData - this[eD_]', this['eD_']);
    _.reverse(this['iNotes']);
    //console.log("processPatientData - this['iNotes']:", this['iNotes']);
    this.iNote.iTitle = "";
    this.iNote.Date = "";
    this.iNote.reason = "";
    this.iNote.problems = "";
    this.iNote.Referral = "";
    this.iNote.Presenting = "";
    this.iNote.MedicalHistory = "";
    this.iNote.MilitaryHistory = "";
    this.iNote.SocialHistory = "";
    this.iNote.MedicationManagement = "";
    this.iNote.IBHAssessment = "";
    this.iNote.SafetyAssessment = "";
    this.iNote.iRecommendation = "";
    this.iNote.GroupTherapy = "";
    this.iNote.FollowUp = "";
    //console.log("processPatientData - this['iNote']", this['iNote']);
    if (this['iNotes'].length > 0) {
      this['iNv'].cPg = 0; this['iNv'].nPg = 1; this['iNv'].pPg = this['iNotes'].length - 1;//console.log(this.iNv);
      this['iNote'] = this['iNotes'][this['iNv'].cPg];
    }
    //console.log("processPatientData End- this['iNote']", this['iNote']);
    this.iForm = new FormGroup({
      iTitle: new FormControl(this.iNote.iTitle ?? ''),
      AssessmentDate: new FormControl(this.iNote.Date ?? ''),
      Reason: new FormControl(this.iNote.reason ?? ''),
      Problems: new FormControl(this.iNote.problems ?? ''),
      Referral: new FormControl(this.iNote.Referral ?? ''),
      Presenting: new FormControl(this.iNote.Presenting ?? ''),
      MedicalHistory: new FormControl(this.iNote.MedicalHistory ?? ''),
      MilitaryHistory: new FormControl(this.iNote.MilitaryHistory ?? ''),
      SocialHistory: new FormControl(this.iNote.SocialHistory ?? ''),
      MedicationManagement: new FormControl(this.iNote.MedicationManagement ?? ''),
      IBHAssessment: new FormControl(this.iNote.IBHAssessment ?? ''),
      SafetyAssessment: new FormControl(this.iNote.SafetyAssessment ?? ''),
      iRecommendation: new FormControl(this.iNote.iRecommendation ?? ''),
      GroupTherapy: new FormControl(this.iNote.GroupTherapy ?? ''),
      FollowUp: new FormControl(this.iNote.FollowUp ?? ''),
    });

    if (this['sNotes'].length > 0) {
      this['sNv'].cPg = 0; this['sNv'].nPg = 1; this['sNv'].pPg = this['sNotes'].length - 1;//console.log(this.sNv);
      this['sNote'] = this['sNotes'][this['sNv'].cPg]; let sN = this['sNote']; //console.log(sN);
      this.sForm = new FormGroup({
        sTitle: new FormControl(this.sNote.Title ?? ''),
        SessionDate: new FormControl(this.sNote.Date ?? ''),
        Name: new FormControl(this.sNote.Name ?? ''),
        Facilitator: new FormControl(this.sNote.Facilitator ?? ''),
        Overview: new FormControl(this.sNote.Overview ?? ''),
        ParticipantObservation: new FormControl(this.sNote.ParticipantObservation ?? ''),
        GroupDynamic: new FormControl(this.sNote.GroupDynamic ?? ''),
        TherapistObservation: new FormControl(this.sNote.TherapistObservation ?? ''),
        Recommendation: new FormControl(this.sNote.Recommendation ?? ''),
      });
    }
    //console.log('processPatientData End: patient', patient);
    //console.log('processPatientData End: patient.encounters', patient.encounters);

    // Additional data processing...
    //this.populateForms(patient);
    //this.chartUpdate(this.patientRdy);
    this.patient = patient; // Assuming 'patient' contains the data you need
    this.encntrs = patient.encounters; // Replace with your actual property
  }

  ngOnInit(): void {
    //console.log('test 1');console.log(this.route);console.log(this.route.snapshot.paramMap);

    this.subscription.add(
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          const id = params.get('id');
          return id ? this.loadPatientData(id) : of(null);
        }),
        catchError(error => {
          this.errors = error; console.log(this.errors);
          return of(null);
        })
      ).subscribe(patient => {
        this.isLoading = false;
        if (patient) {
          //this.patientForm.patchValue(patient); // Uncomment if you need to patch values to a form
          this.aSrvc.getCnfg(this.stf.cID).subscribe(
            (result) => { this.cmpy = result; },
            (error) => { this.errors = error.error; console.log(this.errors); },
            () => {
              const cmpy = this.cmpy[0]; this.cmpy = cmpy; console.log('notes this.cmpy:', this.cmpy);
              this.cnfg = this.cmpy.Cnfg;
              this.mpCnfg = this.cmpy.MilitaryProfile;
              this.ppCnfg = this.cmpy.PsychologicalProfile;
              this.spCnfg = this.cmpy.serviceProfile;
              this.socialCnfg = this.cmpy.SocialProfile;
              this.tpCnfg = this.cmpy.TreatmentPlan;
              this.tpPsyc = this.cmpy.TreatmentPlan.PsychotherapyServices;
              this.tpCMS = this.cmpy.TreatmentPlan.CurrentStatusAssessment[0];console.log('this.tpCMS:', this.tpCMS);
              this.sympCnfg = this.cmpy.SymptomProfile;
              this.medMngt = this.cmpy.medManagement;
              this.patient = patient; console.log("notes this.patient", this.patient);
              this.encntrs = this.patient.encounters; // Replace with your actual property
              this.initializeForms();
              this.chartUpdate(); this.bulletChartUpdate();
              //const selectedEncounter = this.encntrs[this.encntrs.length - 1];
              this.selectEncounter_ = this.encntrs[this.encntrs.length - 1];
              this.uSrvc.selectEncounter(this.selectEncounter_);

              this.patientRdy = true;
            }
          )
        }
      })
    );

    this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => {
        if (encounter) {
          console.log(encounter);
          if (encounter.T === "IBH") { this.iNote = encounter.note; }
          else if (encounter.T === "GS") { this.sNote = encounter.note; }
          // Logic to handle the selected encounter
        }
      })
    );


  }
  ngOnDestroy(): void { this.subscription.unsubscribe(); }

}

