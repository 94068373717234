import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patientDetails'
})
export class PatientDetailsPipe implements PipeTransform {

  transform(patient: []): [string, number|null, string|null] {
    const pD: any = patient; //console.log(pD);
    let name = pD.name.f+' '+pD.name.l;
    if (pD.name.m !==''){name = pD.name.f+' '+pD.name.m+' '+pD.name.l;}

    const cY = new Date().getFullYear();const cM = new Date().getMonth();const cD = new Date().getDate();
    //const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
    //if (!dateRegex.test(dob)) {console.error('Invalid date format. Please provide the DOB in yyyy-mm-dd format.');
    //  return [name, null, null];
    //}// Check if the DOB format is valid
    //const dobDate = new Date(dob);// Parse the DOB string into a Date object
    let year = parseInt(pD.dob.y);// Extract year, month, and day
    let month = parseInt(pD.dob.m); // Months are zero-based, so add 1
    let day = parseInt(pD.dob.d);
    const age = cY - year -(cM < month || (cM === month && cD < day) ? 1 : 0);//console.error([name, age, dob]);
    return [name, age, pD.dob.y+'-'+pD.dob.m+'-'+pD.dob.d];
  }

}
