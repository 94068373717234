<div class="container-fluid" style="padding:0.0625rem;">
    <!--highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
        style="width: 100%; height: 400px; display: block;">
    </highcharts-chart>
    <div class="row" style="margin:.125rem 0;width:100%;float:left;">
        <div class="col-md-2">
            <label for="symptomSelect">Symptoms</label>
            <select class="form-select" id="symptomSelect" (change)="onSymptomSelectChange($event)">
                <option value="">Select symptoms</option>
                <option [value]="r_" *ngFor="let r_ of templates.options.symptoms">{{ r_ }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="treatmentModalities">Treatment Modalities</label>
            <select class="form-select" id="treatmentModalities" (change)="onTreatmentModalitiesSelectChange($event)">
                <option value="">Select Treatment Modalities</option>
                <option [value]="r_" *ngFor="let r_ of templates.options.treatmentModalities">{{ r_ }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="treatmentPlan">Treatment Plan Type</label>
            <select class="form-select" id="treatmentPlan" (change)="onTreatmentPlanSelectChange($event)">
                <option value="">Select Treatment Plan Type</option>
                <option [value]="r_" *ngFor="let r_ of templates.options.treatmentPlanOptions">{{ r_ }}</option>
            </select>
        </div>
        <div class="col-md-2">
            <label for="complexityFactors">Complexity Factors</label>
            <select class="form-select" id="complexityFactors" (change)="onComplexityFactorsSelectChange($event)">
                <option value="">Select Treatment Modalities</option>
                <option [value]="r_" *ngFor="let r_ of templates.options.complexityFactors">{{ r_ }}</option>
            </select>
        </div>

        <div class="col-md-4">
            <button title="Save Write Up" type="submit" class="btn" style="float:right;"><i
                    class="bi bi-save"></i> Save</button>
        </div>
    </div!-->
    <div class="row" style="margin:.125rem 0;width:100%;float:left;">
        <div *ngIf="predictedICD10Codes.length">
            <!-- Add this in your template where you want to show the predictions -->
            <div *ngIf="predictedICD10Codes.length">
                <h5>Predicted ICD-10 Codes:</h5>
                <ul>
                    <li *ngFor="let code of predictedICD10Codes">
                        {{ code }}
                        <button (click)="confirmCode(code)">Confirm</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-9"><button type="button" class="btn btn-primary" (click)="predictICD10()"
            style="float: left;">Predict ICD-10 Code</button>
        <div class="row" style="margin:.125rem 0;width:100%;float:left;">
            <div *ngIf="predictedICD10Codes.length">
                <!-- Add this in your template where you want to show the predictions -->
                <div *ngIf="predictedICD10Codes.length">
                    <h5>Predicted ICD-10 Codes:</h5>
                    <ul>
                        <li *ngFor="let code of predictedICD10Codes">
                            {{ code }}
                            <button (click)="confirmCode(code)">Confirm</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm" *ngIf="patientRdy"
        style="padding:0.5rem;height:85vh;overflow-y:scroll;">
        <div class="editGrpDiv row">
            <div class="col-md-12">
                <button title="Update Encounter Notes" type="submit" class="btn" style="float: right;"
                    (click)="updateEForm()"><i class="bi bi-save"></i> Save</button>
                <button title="Save and Upload Encounter Notes" type="submit" class="btn" style="float: right;"
                    (click)="uploadEForm()"><i class="bi bi-upload"></i> Upload</button>
            </div>
            <div class="col-md-12 editGrpDiv">
                <h3>Diagnostic Assessment</h3>
                <textarea class="form-control textarea" id="DiagnosticAssessment"
                    title="Comprehensive evaluation of the patient's mental health using standardized diagnostic tools. Identification of symptoms and their impact on daily life."
                    aria-label="Diagnostic Assessment" formControlName="DiagnosticAssessment"></textarea>
            </div>
            <div class="row" formGroupName="Treatment">
                <div class="col-md-5 editGrpDiv">
                    <label for="Modality">Treatment Modality</label>
                    <select class="form-select" id="Modality" (change)="onSelectService($event)"
                        formControlName="Modality">
                        <option value="">Select Modality</option>
                        <option *ngFor="let ps_ of tpCnfg.TreatmentModalities" [value]="ps_.ID">{{ ps_.Type }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3 editGrpDiv" *ngIf="selectedService">
                    <label for="ICD10Code">ICD-10 Code</label>
                    <select class="form-select" id="ICD10Code" formControlName="ICD10Code">
                        <option value="">Select ICD-10 Code</option>
                        <option *ngFor="let icd10_ of selectedService.AssociatedICD10Codes" [value]="icd10_">
                            {{ icd10_ }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2 editGrpDiv">
                    <label for="PlannedSessions" class="form-label">Planned Sessions:</label><input type="text"
                        class="form-control" id="PlannedSessions" formControlName="PlannedSessions" />
                </div>
                <div class="col-md-2 editGrpDiv">
                    <label for="AttendedSessions" class="form-label">Attended Sessions:</label><input type="text"
                        class="form-control" id="AttendedSessions" formControlName="AttendedSessions" />
                </div>
                <div class="col-md-12 editGrpDiv" *ngIf="selectedService">
                    <p>Description: {{ selectedService.des }}</p>
                    <p>CPT Code: {{ selectedService.CPTCode }}</p>
                </div>
                <!-- Medications -->
                <div class="editGrpDiv" formArrayName="Medications"><label>Pharmacotherapy Treatment Plan</label>
                    <h5>Medications:</h5>
                    <button type="button" class="btn i" title="Add Medication" (click)="addMed()"><i
                            class="bi bi-plus-square"></i></button>
                    <div *ngFor="let medication of Meds_.controls; let i = index" [formGroupName]="i"
                        class="editGrpDiv row">
                        <div class="col-md-4">
                            <label for="medName{{i}}" class="form-label">Name</label>
                            <input type="text" id="medName{{i}}" class="form-control" formControlName="medName">
                        </div>
                        <div class="col-md-2">
                            <label for="medDosage{{i}}" class="form-label">Dosage</label>
                            <input type="text" id="medDosage{{i}}" class="form-control" formControlName="medDosage">
                        </div>
                        <div class="col-md-3">
                            <label for="medFrequency{{i}}">Frequency</label>
                            <select class="form-select" id="medFrequency{{i}}" formControlName="medFrequency">
                                <option value="">Select Frequency</option>
                                <option *ngFor="let o_ of frequencyOptions" [value]="o_.abbreviation">{{o_.term}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="medRoute{{i}}">Routes Of Administration</label>
                            <select class="form-select" id="medRoute{{i}}" formControlName="medRoute">
                                <option value="">Select Administration Route</option>
                                <option *ngFor="let o_ of routeOfAdministration" [value]="o_.Code"> {{o_.Description}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label for="medStartDate{{i}}" class="form-label">Start Date</label>
                            <input type="date" class="form-control" id="medStartDate{{i}}"
                                formControlName="medStartDate" />
                        </div>
                        <div class="col-md-6">
                            <label for="medEndDate{{i}}" class="form-label">End Date</label>
                            <input type="date" class="form-control" id="medEndDate{{i}}" formControlName="medEndDate" />
                        </div>
                        <div class="col-md-4">
                            <label for="medPurpose{{i}}" class="form-label">Reason for the medication:</label>
                            <textarea class="form-control textarea" id="medPurpose{{i}}"
                                formControlName="medPurpose"></textarea>
                        </div>
                        <div class="col-md-2">
                            <label for="medClass{{i}}">Class</label>
                            <select class="form-select" id="medClass{{i}}" formControlName="medClass"
                                (change)="changeMedClass($event)">
                                <option value="">Select Class</option>
                                <option *ngFor="let o_ of classes" [value]="o_.ClassName">{{o_.ClassName}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="medICD10CMCode{{i}}" class="form-label">ICD-10-CM Code</label>
                            <input type="text" id="medICD10CMCode{{i}}" class="form-control"
                                formControlName="medICD10CMCode">
                        </div>
                        <div class="col-md-3">
                            <label for="medNDC{{i}}" class="form-label">National Drug Code</label>
                            <input type="text" id="medNDC{{i}}" class="form-control" formControlName="medNDC">
                        </div>
                        <div class="col-md-5">
                            <label for="medSideEffects{{i}}" class="form-label">Experienced or potential side
                                effects:</label>
                            <textarea class="form-control textarea" id="medSideEffects{{i}}"
                                formControlName="medSideEffects"></textarea>
                        </div>
                        <div class="col-md-2" id="medAdherenceLeveldiv{{i}}">
                            <label for="medAdherenceLevel{{i}}">Adherence Level</label>
                            <select class="form-select" id="medAdherenceLevel{{i}}" formControlName="medAdherenceLevel">
                                <option value="">Select Level</option>
                                <option *ngFor="let o_ of medCompliance" [value]="o_">{{o_}}</option>
                            </select>
                        </div>
                        <div class="col-md-5">
                            <label for="medReasonsNonAdherence{{i}}" class="form-label">Reasons for not taking the
                                medication as prescribed:</label>
                            <textarea class="form-control textarea" id="medReasonsNonAdherence{{i}}"
                                formControlName="medReasonsNonAdherence"></textarea>
                        </div>
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="Diagnosis">
                    <h4 style="font-weight: bold !important;float: left;">Diagnosis Assessment:</h4>
                    <div class="col-md-8 editGrpDiv">
                        <label for="DSM5Diagnoses" class="form-label">DSM-5 Diagnoses:</label>
                        <textarea class="form-control textarea" id="DSM5Diagnoses"
                            formControlName="DSM5Diagnoses"></textarea>
                    </div>
                    <div class="col-md-4 editGrpDiv">
                        <label for="ICD10Codes" class="form-label">ICD-10 Codes</label>
                        <input type="text" id="ICD10Codes" class="form-control" formControlName="ICD10Codes">
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="Goal">
                    <h4 style="font-weight: bold !important;float: left;">Goals:</h4>
                    <div class="editGrpDiv row">
                        <div class="col-md-10"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="ShortTerm" class="form-label">Short Term:</label>
                            <textarea class="form-control textarea" id="ShortTerm"
                                formControlName="ShortTerm"></textarea>
                        </div>
                        <div class="col-md-2"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="ShortTermDate" class="form-label">Short-Term Date:</label>
                            <input type="date" class="form-control" id="ShortTermDate"
                                formControlName="ShortTermDate" />
                        </div>
                        <div class="col-md-6"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="ShortTermProgression">Short-Term Progression</label>
                            <select class="form-select" id="ShortTermProgression"
                                formControlName="ShortTermProgression">
                                <option value="">Select Progression Level</option>
                                <option [value]="o_.num" *ngFor="let o_ of axCnfg.ProgressEvaluation">{{ o_.des }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="ShortTermAdjustment">Reason for Short-Term Adjustment</label>
                            <select class="form-select" id="ShortTermAdjustment" formControlName="ShortTermAdjustment">
                                <option value="">Select Adjustment Reasoning</option>
                                <option [value]="o_.val" *ngFor="let o_ of axCnfg.AdjustmentProtocolsOptions">{{ o_.des
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="editGrpDiv row">
                        <div class="col-md-10"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="LongTerm" class="form-label">Long Term:</label>
                            <textarea class="form-control textarea" id="LongTerm" formControlName="LongTerm"></textarea>
                        </div>
                        <div class="col-md-2"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="LongTermDate" class="form-label">Long-Term Date:</label>
                            <input type="date" class="form-control" id="LongTermDate" formControlName="LongTermDate" />
                        </div>
                        <div class="col-md-6"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="LongTermProgression">Long-Term Progression</label>
                            <select class="form-select" id="LongTermProgression" formControlName="LongTermProgression">
                                <option value="">Select Progression Level</option>
                                <option [value]="o_.num" *ngFor="let o_ of axCnfg.ProgressEvaluation">{{ o_.des }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="LongTermAdjustment">Reason for Long-Term Adjustment</label>
                            <select class="form-select" id="LongTermAdjustment" formControlName="LongTermAdjustment">
                                <option value="">Select Adjustment Reasoning</option>
                                <option [value]="o_.val" *ngFor="let o_ of axCnfg.AdjustmentProtocolsOptions">{{ o_.des
                                    }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12 editGrpDiv">
                        <table class="appointment-list table table-striped align-middle mb-0 bg-white">
                            <thead class="bg-light">
                                <tr>
                                    <th style="text-align: center;">PROM:</th>
                                    <th style="text-align: center;">Current</th>
                                    <th style="text-align: center;">Initial</th>
                                    <th style="text-align: center;">Short-term</th>
                                    <th style="text-align: center;">Long-term</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr formGroupName="PHQ9">
                                    <td>PHQ9</td>
                                    <td>
                                        <input type="range" class="form-range" id="PHQ9.Current"
                                            min="{{PHQ9Cnfg.minimalMin}}" max="{{PHQ9Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" value="10" formControlName="Current">
                                        <span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.PHQ9.Current').value, 'PHQ9')">{{eForm.get('Treatment.Goal.PHQ9.Current').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="PHQ9.Initial"
                                            min="{{PHQ9Cnfg.minimalMin}}" max="{{PHQ9Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="Initial">
                                        <span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.PHQ9.Initial').value, 'PHQ9')">{{eForm.get('Treatment.Goal.PHQ9.Initial').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="PHQ9.ShortTerm"
                                            min="{{PHQ9Cnfg.minimalMin}}" max="{{PHQ9Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="ShortTerm">
                                        <span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.PHQ9.ShortTerm').value, 'PHQ9')">{{eForm.get('Treatment.Goal.PHQ9.ShortTerm').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="PHQ9.LongTerm"
                                            min="{{PHQ9Cnfg.minimalMin}}" max="{{PHQ9Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="LongTerm">
                                        <span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.PHQ9.LongTerm').value, 'PHQ9')">{{eForm.get('Treatment.Goal.PHQ9.LongTerm').value}}</span>
                                    </td>
                                </tr>
                                <tr formGroupName="GAD7">
                                    <td>GAD-7</td>
                                    <td>
                                        <input type="range" class="form-range" id="GAD7.Current"
                                            min="{{GAD7Cnfg.minimalMin}}" max="{{GAD7Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" value="10" formControlName="Current"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.GAD7.Current').value, 'GAD7')">{{eForm.get('Treatment.Goal.GAD7.Current').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="GAD7.Initial"
                                            min="{{GAD7Cnfg.minimalMin}}" max="{{GAD7Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="Initial"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.GAD7.Initial').value, 'GAD7')">{{eForm.get('Treatment.Goal.GAD7.Initial').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="GAD7.ShortTerm"
                                            min="{{GAD7Cnfg.minimalMin}}" max="{{GAD7Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="ShortTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.GAD7.ShortTerm').value, 'GAD7')">{{eForm.get('Treatment.Goal.GAD7.ShortTerm').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="GAD7.LongTerm"
                                            min="{{GAD7Cnfg.minimalMin}}" max="{{GAD7Cnfg.severeMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="LongTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.GAD7.LongTerm').value, 'GAD7')">{{eForm.get('Treatment.Goal.GAD7.LongTerm').value}}</span>
                                    </td>
                                </tr>
                                <tr formGroupName="WHO5">
                                    <td>WHO-5</td>
                                    <td>
                                        <input type="range" class="form-range" id="WHO5.Current"
                                            min="{{WHO5Cnfg.minimalMin}}" max="{{WHO5Cnfg.betterMax}}" step="1"
                                            (input)="onValueChange($event)" value="10" formControlName="Current"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WHO5.Current').value, 'WHO5')">{{eForm.get('Treatment.Goal.WHO5.Current').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WHO5.Initial"
                                            min="{{WHO5Cnfg.minimalMin}}" max="{{WHO5Cnfg.betterMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="Initial"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WHO5.Initial').value, 'WHO5')">{{eForm.get('Treatment.Goal.WHO5.Initial').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WHO5.ShortTerm"
                                            min="{{WHO5Cnfg.minimalMin}}" max="{{WHO5Cnfg.betterMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="ShortTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WHO5.ShortTerm').value, 'WHO5')">{{eForm.get('Treatment.Goal.WHO5.ShortTerm').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WHO5.LongTerm"
                                            min="{{WHO5Cnfg.minimalMin}}" max="{{WHO5Cnfg.betterMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="LongTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WHO5.LongTerm').value, 'WHO5')">{{eForm.get('Treatment.Goal.WHO5.LongTerm').value}}</span>
                                    </td>
                                </tr>
                                <tr formGroupName="Physical">
                                    <td>Physical Appearance</td>
                                    <td>
                                        <input type="range" class="form-range" id="Physical.Current"
                                            min="{{PhysicalCnfg.yMin}}" max="{{PhysicalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" value="10" formControlName="Current"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.Physical.Current').value, 'Physical')">{{eForm.get('Treatment.Goal.Physical.Current').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="Physical.Initial"
                                            min="{{PhysicalCnfg.yMin}}" max="{{PhysicalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="Initial"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.Physical.Initial').value, 'Physical')">{{eForm.get('Treatment.Goal.Physical.Initial').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="Physical.ShortTerm"
                                            min="{{PhysicalCnfg.yMin}}" max="{{PhysicalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="ShortTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.Physical.ShortTerm').value, 'Physical')">{{eForm.get('Treatment.Goal.Physical.ShortTerm').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="Physical.LongTerm"
                                            min="{{PhysicalCnfg.yMin}}" max="{{PhysicalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="LongTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.Physical.LongTerm').value, 'Physical')">{{eForm.get('Treatment.Goal.Physical.LongTerm').value}}</span>
                                    </td>
                                </tr>
                                <tr formGroupName="WellBeing">
                                    <td>Well-being</td>
                                    <td>
                                        <input type="range" class="form-range" id="WellBeing.Current"
                                            min="{{FunctionalCnfg.yMin}}" max="{{FunctionalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" value="10" formControlName="Current"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WellBeing.Current').value, 'WellBeing')">{{eForm.get('Treatment.Goal.WellBeing.Current').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WellBeing.Initial"
                                            min="{{FunctionalCnfg.yMin}}" max="{{FunctionalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="Initial"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WellBeing.Initial').value, 'WellBeing')">{{eForm.get('Treatment.Goal.WellBeing.Initial').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WellBeing.ShortTerm"
                                            min="{{FunctionalCnfg.yMin}}" max="{{FunctionalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="ShortTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WellBeing.ShortTerm').value, 'WellBeing')">{{eForm.get('Treatment.Goal.WellBeing.ShortTerm').value}}</span>
                                    </td>
                                    <td>
                                        <input type="range" class="form-range" id="WellBeing.LongTerm"
                                            min="{{FunctionalCnfg.yMin}}" max="{{FunctionalCnfg.yMax}}" step="1"
                                            (input)="onValueChange($event)" formControlName="LongTerm"><span
                                            [ngClass]="getClassForValue(eForm.get('Treatment.Goal.WellBeing.LongTerm').value, 'WellBeing')">{{eForm.get('Treatment.Goal.WellBeing.LongTerm').value}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-8">
                        <label for="Note" class="form-label">Note:</label>
                        <textarea class="form-control textarea" id="Note" formControlName="Note"></textarea>
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="Assessment">
                    <h4 style="font-weight: bold !important;float: left;">Assessment:
                    </h4>
                    <div class="col-md-12 editGrpDiv">
                        <label for="PresentingProblem" class="form-label">Presenting Problem:</label>
                        <textarea class="form-control textarea" id="PresentingProblem"
                            formControlName="PresentingProblem"></textarea>
                    </div>
                    <div class="editGrpDiv row" formGroupName="RiskAssessment">
                        <h5 style="font-weight: bold !important;float: left;">Risks:
                        </h5>
                        <div class="col-md-4 editGrpDiv">
                            <label for="Suicidality" class="form-label">Suicidality:</label>
                            <textarea class="form-control textarea" id="Suicidality"
                                formControlName="Suicidality"></textarea>
                        </div>
                        <div class="col-md-4 editGrpDiv">
                            <label for="Homicidality" class="form-label">Homicidality</label>
                            <input type="text" id="Homicidality" class="form-control" formControlName="Homicidality">
                        </div>
                        <div class="col-md-4 editGrpDiv">
                            <label for="OtherRisks" class="form-label">Other Risks</label>
                            <input type="text" id="OtherRisks" class="form-control" formControlName="OtherRisks">
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="MentalStatus">
                        <h5 style="font-weight: bold !important;float: left;">Mental Status Examination:
                        </h5>
                        <div class="col-md-6 editGrpDiv">
                            <label for="Behavior" class="form-label">Behavior:</label>
                            <input type="text" id="Behavior" class="form-control" formControlName="Behavior">
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="Cognition" class="form-label">Cognition</label>
                            <input type="text" id="Cognition" class="form-control" formControlName="Cognition">
                        </div>
                        <div class="col-md-4 editGrpDiv">
                            <label for="Appearance" class="form-label">Appearance</label>
                            <input type="text" id="Appearance" class="form-control" formControlName="Appearance">
                        </div>
                        <div class="col-md-4 editGrpDiv">
                            <label for="MoodAffect" class="form-label">Mood and Affect</label>
                            <input type="text" id="MoodAffect" class="form-control" formControlName="MoodAffect">
                        </div>
                        <div class="col-md-4 editGrpDiv">
                            <label for="ThoughtContent" class="form-label">Thought Content</label>
                            <input type="text" id="ThoughtContent" class="form-control"
                                formControlName="ThoughtContent">
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ThoughtProcess" class="form-label">Thought Process</label>
                            <input type="text" id="ThoughtProcess" class="form-control"
                                formControlName="ThoughtProcess">
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="InsightJudgment" class="form-label">Insight and Judgment</label>
                            <input type="text" id="InsightJudgment" class="form-control"
                                formControlName="InsightJudgment">
                        </div>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="HistoryPresentingProblem" class="form-label">History of Presenting
                            Problem</label>
                        <textarea class="form-control textarea" id="HistoryPresentingProblem"
                            formControlName="HistoryPresentingProblem"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="PsychiatricHistory" class="form-label">Psychiatric History</label>
                        <textarea class="form-control textarea" id="PsychiatricHistory"
                            formControlName="PsychiatricHistory"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="SubstanceUseHistory" class="form-label">Substance Use History</label>
                        <textarea class="form-control textarea" id="SubstanceUseHistory"
                            formControlName="SubstanceUseHistory"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="MedicalHistory" class="form-label">Medical History</label>
                        <textarea class="form-control textarea" id="MedicalHistory"
                            formControlName="MedicalHistory"></textarea>
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="MonitoringEvaluation">
                    <h4 style="font-weight: bold !important;float: left;">Monitoring and Evaluation:</h4>
                    <div class="col-md-6 editGrpDiv">
                        <label for="OutcomeMeasures" class="form-label">Outcome Measures:</label>
                        <textarea class="form-control textarea" id="OutcomeMeasures"
                            formControlName="OutcomeMeasures"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="FollowUpSchedule" class="form-label">Follow-up Schedule:</label>
                        <textarea class="form-control textarea" id="FollowUpSchedule"
                            formControlName="FollowUpSchedule"></textarea>
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="Interventions">
                    <h4 style="font-weight: bold !important;float: left;">Interventions:</h4>
                    <div class="col-md-6 editGrpDiv">
                        <label for="Psychotherapy" class="form-label">Psychotherapy:</label>
                        <textarea class="form-control textarea" id="Psychotherapy"
                            formControlName="Psychotherapy"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="SupportServices" class="form-label">Support Services:</label>
                        <textarea class="form-control textarea" id="SupportServices"
                            formControlName="SupportServices"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="MedicationManagement" class="form-label">Medication Management:</label>
                        <textarea class="form-control textarea" id="MedicationManagement"
                            formControlName="MedicationManagement"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="LifestyleModifications" class="form-label">Lifestyle Modifications:</label>
                        <textarea class="form-control textarea" id="LifestyleModifications"
                            formControlName="LifestyleModifications"></textarea>
                    </div>
                </div>
                <div class="editGrpDiv row" formGroupName="EmergencyPlan">
                    <h4 style="font-weight: bold !important;float: left;">Emergency Plan:</h4>
                    <div class="col-md-6 editGrpDiv">
                        <label for="EmergencyContactInformation" class="form-label">Emergency Contact Information:</label>
                        <textarea class="form-control textarea" id="EmergencyContactInformation"
                            formControlName="EmergencyContactInformation"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="CrisisInterventionPlan" class="form-label">Crisis Intervention Plan:</label>
                        <textarea class="form-control textarea" id="CrisisInterventionPlan"
                            formControlName="CrisisInterventionPlan"></textarea>
                    </div>
                </div>
                <!--div class="editGrpDiv row" formGroupName="ConsentAndConfidentiality">
                    <h4 style="font-weight: bold !important;float: left;">Consent And Confidentiality:</h4>
                    <div class="col-md-6 editGrpDiv">
                        <label for="InformedConsent" class="form-label">Informed Consent:</label>
                        <textarea class="form-control textarea" id="InformedConsent"
                            formControlName="InformedConsent"></textarea>
                    </div>
                    <div class="col-md-6 editGrpDiv">
                        <label for="ConfidentialityAgreement" class="form-label">Confidentiality Agreement:</label>
                        <textarea class="form-control textarea" id="ConfidentialityAgreement"
                            formControlName="ConfidentialityAgreement"></textarea>
                    </div>
                </div!-->
                <div class="editGrpDiv row" formGroupName="DischargeCriteria">
                    <h4 style="font-weight: bold !important;float: left;">Discharge Criteria: Criteria for patient discharge, ensuring clinical stability, functional improvement, achievement of treatment goals, and adequate support systems.</h4>
                    <div class="editGrpDiv row" formGroupName="ClinicalStability">
                        <h4 style="font-weight: bold !important;float: left;">Clinical Stability:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="StableVitalSigns" class="form-label">StableVitalSigns:</label>
                            <textarea class="form-control textarea" id="StableVitalSigns"
                                formControlName="StableVitalSigns"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="SymptomReduction" class="form-label">SymptomReduction:</label>
                            <textarea class="form-control textarea" id="SymptomReduction"
                                formControlName="SymptomReduction"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="NoNeedForIntensiveCare" class="form-label">NoNeedForIntensiveCare:</label>
                            <textarea class="form-control textarea" id="NoNeedForIntensiveCare"
                                formControlName="NoNeedForIntensiveCare"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="FunctionalImprovement">
                        <h4 style="font-weight: bold !important;float: left;">Functional Improvement:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="DailyActivities" class="form-label">Daily Activities:</label>
                            <textarea class="form-control textarea" id="DailyActivities"
                                formControlName="DailyActivities"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="SelfCare" class="form-label">Self Care:</label>
                            <textarea class="form-control textarea" id="SelfCare" formControlName="SelfCare"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="AchievementOfTreatmentGoals">
                        <h4 style="font-weight: bold !important;float: left;">Achievement Of TreatmentGoals:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="MoodImprovement" class="form-label">Mood Improvement:</label>
                            <textarea class="form-control textarea" id="MoodImprovement"
                                formControlName="MoodImprovement"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="BehavioralImprovement" class="form-label">Behavioral Improvement:</label>
                            <textarea class="form-control textarea" id="BehavioralImprovement"
                                formControlName="BehavioralImprovement"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="CognitiveFunction" class="form-label">Cognitive Function:</label>
                            <textarea class="form-control textarea" id="CognitiveFunction"
                                formControlName="CognitiveFunction"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="SupportSystems">
                        <h4 style="font-weight: bold !important;float: left;">Support Systems:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="FamilySupport" class="form-label">Family Support:</label>
                            <textarea class="form-control textarea" id="FamilySupport"
                                formControlName="FamilySupport"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="CommunityResources" class="form-label">Community Resources:</label>
                            <textarea class="form-control textarea" id="CommunityResources" formControlName="CommunityResources"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="FollowUpPlan">
                        <h4 style="font-weight: bold !important;float: left;">Follow-Up Plan:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ScheduledAppointments" class="form-label">Scheduled Appointments:</label>
                            <textarea class="form-control textarea" id="ScheduledAppointments"
                                formControlName="ScheduledAppointments"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="MedicationManagement" class="form-label">Medication Management:</label>
                            <textarea class="form-control textarea" id="MedicationManagement"
                                formControlName="MedicationManagement"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="AccessToSupportServices" class="form-label">Access To Support Services:</label>
                            <textarea class="form-control textarea" id="AccessToSupportServices"
                                formControlName="AccessToSupportServices"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                    <div class="editGrpDiv row" formGroupName="PatientAndFamilyEducation">
                        <h4 style="font-weight: bold !important;float: left;">Patient And Family Education:</h4>
                        <div class="col-md-6 editGrpDiv">
                            <label for="UnderstandingOfTreatmentPlan" class="form-label">Understanding Of Treatment Plan:</label>
                            <textarea class="form-control textarea" id="UnderstandingOfTreatmentPlan"
                                formControlName="UnderstandingOfTreatmentPlan"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="RelapsePrevention" class="form-label">Relapse Prevention:</label>
                            <textarea class="form-control textarea" id="RelapsePrevention" formControlName="RelapsePrevention"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="EmergencyContacts" class="form-label">Emergency Contacts:</label>
                            <textarea class="form-control textarea" id="EmergencyContacts" formControlName="EmergencyContacts"></textarea>
                        </div>
                        <div class="col-md-6 editGrpDiv">
                            <label for="ICD10Codes" class="form-label">ICD-10 Codes:</label>
                            <textarea class="form-control textarea" id="ICD10Codes"
                                formControlName="ICD10Codes"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>