import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface AdherenceStatus {
  id: string; // Unique identifier for each object
  status: 'Always' | 'Often' | 'Sometimes' | 'Rarely' | 'Never' | undefined; // Example statuses
}

@Injectable({
  providedIn: 'root',
})

export class AdherenceStatusService {

//  private adherenceStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>('default');
//  public adherenceStatus$: Observable<string> = this.adherenceStatusSubject.asObservable();
  private adherenceStatusSource = new BehaviorSubject<AdherenceStatus[]>([]);
  adherenceStatus$ = this.adherenceStatusSource.asObservable();

  constructor() { }

  //updateStatus(status: string): void {    this.adherenceStatusSubject.next(status);  }

  updateStatus(id: string, status: 'Always' | 'Often' | 'Sometimes' | 'Rarely' | 'Never' | undefined): void {
    const currentStatuses = this.adherenceStatusSource.getValue();
    const index = currentStatuses.findIndex(item => item.id === id);
    if (index !== -1) {
      currentStatuses[index].status = status;
    } else {
      currentStatuses.push({ id, status });
    }
    this.adherenceStatusSource.next(currentStatuses);
  }
  
}
