import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'promChartGen' })
export class PromChartGenPipe implements PipeTransform {
  transform(E_: any, ...args: unknown[]): any {let chrt: any = [];
    const P_: any = E_.prom; const PSm_: any = E_.promSummary; //console.log('PromChartGenPipe P_:', P_,'PromChartGenPipe PSm_:', PSm_);
    for (const key of Object.keys(P_)) {
      const k_: any = key; let r_: number = 0; let srvty_: number = 0; let t_: number = 0; let p_: number = 0; let rChrt: any = [];
      for (let i = 0; i < P_[k_].length; i++) { r_ = P_[k_][i].r; rChrt.push(r_); t_ = t_ + r_; if (r_ > 0) { p_++; } }
      if (PSm_ !== null && PSm_ !== undefined && PSm_[k_].severityValue !== null && PSm_[k_].severityValue !== undefined) { srvty_ = PSm_[k_].severityValue; }else { srvty_ = 0 }
      chrt.push({ k: k_, r: rChrt, svr: srvty_, t: t_, p: p_ });
    } //console.log(chrt);
    return chrt;
  }
}
