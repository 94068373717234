import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './authentication/dashboard/sign-in/sign-in.component';
import { SignupComponent } from './authentication/dashboard/signup/signup.component';
import { AssessmentSigninComponent } from './authentication/assessment/sign-in/assessment-sign-in.component';
import { StaffProfileComponent } from './authentication/dashboard/staff-profile/staff-profile.component';
import { PatientsPageComponent } from './modules/patient/patients-page/patients-page.component';
import { ProfileEditorComponent } from './modules/patient/profile-editor/profile-editor.component';
import { NotesEncountersComponent } from './modules/patient/notes-encounters/notes-encounters.component';
import { PatientNotesPageComponent } from './modules/patient/patient-notes-page/patient-notes-page.component';
import { AppointmentsPageComponent } from './modules/patient/appointments-page/appointments-page.component';
import { AppointmentDetailPageComponent } from './modules/patient/appointment-detail-page/appointment-detail-page.component';
import { LocationsPageComponent } from './modules/dashboard/locations-page/locations-page.component';
import { LocationDetailPageComponent } from './modules/dashboard/location-detail-page/location-detail-page.component';
import { SessionsPageComponent } from './modules/dashboard/sessions-page/sessions-page.component';
import { SessionDetailPageComponent } from './modules/dashboard/session-detail-page/session-detail-page.component';
import { CompanyDashboardComponent } from './modules/dashboard/company-dashboard/company-dashboard.component';
import { CmpyDashboardComponent } from './configurations/cmpy-dashboard/cmpy-dashboard.component';
import { CmpyConfigureComponent } from './configurations/cmpy-configure/cmpy-configure.component';
import { CompanyEditorComponent } from './configurations/company-editor/company-editor.component';
import { LocationEditorComponent } from './configurations/location-editor/location-editor.component';
import { DepartmentEditorComponent } from './configurations/department-editor/department-editor.component';
import { SessionsEditorComponent } from './configurations/sessions-editor/sessions-editor.component';
import { StaffEditorComponent } from './configurations/staff-editor/staff-editor.component';
import { EmployeesPageComponent } from './configurations/employees-page/employees-page.component';
import { EmployeeDetailPageComponent } from './configurations/employee-detail-page/employee-detail-page.component';
import { AppointmentsComponent } from './modules/assessments/appointments/appointments.component';
import { ConfirmationComponent } from './modules/assessments/confirmation/confirmation.component';
import { QuestionnaireComponent } from './modules/assessments/questionnaire/questionnaire.component';
import { ReviewComponent } from './modules/assessments/review/review.component';
import { DeactivationComponent } from './modules/assessments/deactivation/deactivation.component';

// Define constants for paths and titles
export const ROUTES = {
  login: 'login',
  register: 'register',
  profile: 'profile',
  home: 'home',
  locations: 'locations',
  patients: 'patients',
  appointments: 'appointments',
  employees: 'employees',
  error404: '**'
};

export const TITLES = {
  login: 'My-Health-Registry: Signin',
  register: 'Register',
  profile: 'Staff Profile',
  home: 'Dashboard',
  locations: 'Locations',
  patients: 'Patient Results',
  appointments: 'Patient Appointments',
  employees: 'Staffs',
  error404: 'Page Not Found'
};

const routes: Routes = [
  { path: '', redirectTo: `/${ROUTES.login}`, pathMatch: 'full', title: TITLES.login },
  { path: ROUTES.login, component: SigninComponent, title: TITLES.login },
  { path: 'login/:id', component: SigninComponent, pathMatch: 'full', title: "My-Health-Registry: Signinn" },
  { path: 'axSignin', component: AssessmentSigninComponent, pathMatch: 'full', title: "Assessment Signin" },
  { path: 'register', component: SignupComponent, title: "Register" },
  { path: 'profile', component: StaffProfileComponent, title: "Staff Profile" },
  { path: 'home', component: CompanyDashboardComponent, pathMatch: 'full', title: "Dashboard" },
  { path: 'patients', component: PatientsPageComponent, pathMatch: 'full', title: "Patient Results" },
  { path: 'patients/:param1/:param2', component: PatientsPageComponent, title: "Patient Results" },
  { path: 'patientNotes/:id', component: PatientNotesPageComponent, title: "Patient Notes" },
  { path: 'patient/:id', component: NotesEncountersComponent, title: "Patient" },
  { path: 'patientEncounter/:id', component: NotesEncountersComponent, title: "Patient Encounter" },
  { path: 'patientEdit/:id', component: ProfileEditorComponent, title: "Patient Editor" },
  { path: 'appointments', component: AppointmentsPageComponent, pathMatch: 'full', title: "Patient Appointments" },
  { path: 'appointments/:param1/:param2', component: AppointmentsPageComponent, pathMatch: 'full', title: "Patient Appointments" },
  { path: 'locations', component: LocationsPageComponent, pathMatch: 'full', title: "Locations" },
  { path: 'location/:param1', component: LocationDetailPageComponent, title: "Location" },
  { path: 'appointmentEditor/:id', component: AppointmentDetailPageComponent },
  { path: 'sessions', component: SessionsPageComponent, title: "Group Sessions" },
  { path: 'sessions/:id', component: SessionDetailPageComponent, title: "Group Session" },
  { path: 'axAppointments/:param1/:param2', component: AppointmentsComponent, pathMatch: 'full', title: "Assessment Appointments" },
  { path: 'axConfirmation/:param1/:param2/:param3/:param4', component: ConfirmationComponent, pathMatch: 'full', title: "Assessment Patient Confirmation" },
  { path: 'axActivation/:param1/:param2/:param3', component: QuestionnaireComponent, pathMatch: 'full', title: "Assessment Activation" },
  { path: 'axReview/:param1/:param2/:param3', component: ReviewComponent, pathMatch: 'full', title: "Assessment Patient Review" },
  { path: 'axDeactivation/:id', component: DeactivationComponent, pathMatch: 'full', title: "Assessment Reset" },
  { path: 'cmpyAdmin', component: CmpyDashboardComponent, pathMatch: 'full', title: "Company Profile" },
  { path: 'cmpyAdmin/c/:id', component: CmpyConfigureComponent, title: "Company Profile" },
  { path: 'cmpyAdmin/l/:id', component: CmpyConfigureComponent, title: "Location Profile" },
  { path: 'cmpyAdmin/d/:id', component: CmpyConfigureComponent, title: "Department Profile" },
  { path: 'cmpyAdmin/g/:id', component: CmpyConfigureComponent, title: "Group Session Profile" },
  { path: 'editCmpy', component: CompanyEditorComponent, title: "Company Profile Editor" },
  { path: 'editLoc/:id', component: LocationEditorComponent, title: "Location Profile Editor" },
  { path: 'editDpt/e/:id', component: DepartmentEditorComponent, pathMatch: 'full', title: "Department Profile Editor" },
  { path: 'editDpt/d/:id', component: DepartmentEditorComponent, pathMatch: 'full', title: "Department Profile Editor" },
  { path: 'editDpt/n/:id', component: DepartmentEditorComponent, pathMatch: 'full', title: "Department Profile Editor" },
  { path: 'editStf/e/:id', component: StaffEditorComponent, pathMatch: 'full', title: "Staff Profile Editor" },
  { path: 'editStf/d/:id', component: StaffEditorComponent, pathMatch: 'full', title: "Staff Deactivation" },
  { path: 'editGS/e/:id', component: SessionsEditorComponent, pathMatch: 'full', title: "Group Session Profile Editor" },
  { path: 'editGS/d/:id', component: SessionsEditorComponent, pathMatch: 'full', title: "Group Session Profile Editor" },
  { path: 'editGS/n/:id', component: SessionsEditorComponent, pathMatch: 'full', title: "Group Session Profile Editor" },
  { path: 'employees', component: EmployeesPageComponent, pathMatch: 'full', title: "Staffs" },
  { path: 'employees/:id', component: EmployeeDetailPageComponent, title: "Staff" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
