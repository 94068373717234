<div class="container-fluid" style="padding:0.0625rem;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm" *ngIf="cmpy.N">
        <h4 class="fw-normal mb-1 pb-1" style="letter-spacing: 1px;">{{cmpy.N}}: Company Profile</h4>
        <div class="col-12" style="margin:0;padding:0;">
            <a routerLink="/cmpyAdmin/{{lID}}-{{dID}}" title="Back" class="btn btn-light" role="button"
                style="float:left;"><i class="bi bi-arrow-left-square"></i></a>
            <button type="submit" class="btn" title="Update Company Profile" style="float:right;" (click)="eFormUD()"><i
                    class="bi bi-save"></i></button>
        </div>
        <div class="row editDiv">
            <div class="col-md-12">
                <label for="N" class="form-label">Name</label>
                <input type="text" class="form-control" id="N" [value]="cmpy.N" formControlName="N">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-3">
                <label for="P1" class="form-label">Primary Phone Number</label>
                <input type="tel" class="form-control" id="P1" (blur)="updatePhn($event)"
                    [value]="cmpy.Adr.P1" formControlName="P1">
            </div>
            <div class="col-md-3">
                <label for="TF" class="form-label">Toll-Free Phone Number</label>
                <input type="tel" class="form-control" id="TF" (blur)="updatePhn($event)"
                    [value]="cmpy.Adr.TF" formControlName="TF">
            </div>
            <div class="col-md-3">
                <label for="F" class="form-label">Fax Phone Number</label>
                <input type="tel" class="form-control" id="F" (blur)="updatePhn($event)"
                    [value]="cmpy.Adr.F" formControlName="F">
            </div>
            <div class="col-md-3">
                <label for="E" class="form-label">Email</label>
                <input type="email" class="form-control" id="E" [value]="cmpy.Adr.E" formControlName="E">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="A" class="form-label">Address</label>
                <input type="text" class="form-control" id="A" [value]="cmpy.Adr.A" formControlName="A">
            </div>
            <div class="col-md-6">
                <label for="A2" class="form-label">Address 2</label>
                <input type="text" class="form-control" id="A2" [value]="cmpy.Adr.A2" formControlName="A2">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" [value]="cmpy.Adr.C" formControlName="C">
            </div>
            <div class="col-md-4">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" [value]="cmpy.Adr.S" formControlName="S">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="Z" class="form-label">Zip</label>
                <input type="text" class="form-control" id="Z" [value]="cmpy.Adr.Z" formControlName="Z">
            </div>
        </div>
        <div class="row editDiv">
            <h5>Configuration:</h5>
            <div class="col-md-4">
                <label for="idInit" class="form-label">ID Initials</label>
                <input type="text" class="form-control" aria-label="ID Initials" id="idInit" 
                    [value]="cnfg_.idInit" formControlName="idInit">
            </div>
            <div class="col-md-4">
                <label for="eMUrl" class="form-label">Email URL</label>
                <input type="text" class="form-control" aria-label="Email URL" id="eMUrl"
                    [value]="cnfg_.eMUrl" formControlName="eMUrl">
            </div>
            <div class="col-md-4">
                <label for="dfltPswd" class="form-label">Default Password</label>
                <input type="text" class="form-control" aria-label="Default Password" id="dfltPswd" 
                    [value]="cnfg_.dfltPswd" formControlName="dfltPswd">
            </div>
        </div>
        <div class="row editDiv">
            <h5>Patient Dashboard Chart Configuration:</h5>
            <div class="col-md-4">
                <label for="Title" class="form-label">Chart Title</label>
                <input type="text" class="form-control" aria-label="Title" id="Title" 
                    [value]="cnfgChrt_.Title" formControlName="Title">
            </div>
            <div class="col-md-4">
                <label for="dataLBL" class="form-label">Data Set</label>
                <input type="text" class="form-control" aria-label="Data Set" id="dataLBL" 
                    [value]="cnfgChrt_.dataLBL" formControlName="dataLBL">
            </div>
            <div class="col-md-4">
                <label for="detailsPage" class="form-label">Patient Detail Page</label>
                <input type="text" class="form-control" aria-label="Patient Detail Page" id="detailsPage" 
                    [value]="cnfgChrt_.detailsPage" formControlName="detailsPage">
            </div>
            <div class="col-md-2" style="border: 1px dotted gray;">
                <label for="xTitle" class="form-label">X-Axis Title</label>
                <input type="text" class="form-control" aria-label="X-Axis Title" id="xTitle" 
                    [value]="cnfgChrt_.xTitle" formControlName="xTitle">
                <label for="xMax" class="form-label">X-Axis Max</label>
                <input type="text" class="form-control" aria-label="X-Axis Max" id="xMax" 
                    [value]="cnfgChrt_.xMax" formControlName="xMax">
                <label for="xMin" class="form-label">X-Axis Min</label>
                <input type="text" class="form-control" aria-label="X-Axis Min" id="xMin" 
                    [value]="cnfgChrt_.xMin" formControlName="xMin">
            </div>
            <div class="col-md-2" style="border: 1px dotted gray;">
                <label for="yTitle" class="form-label">Y-Axis Title</label>
                <input type="text" class="form-control" aria-label="Y-Axis Title" id="yTitle" 
                    [value]="cnfgChrt_.yTitle" formControlName="yTitle">
                <label for="yMax" class="form-label">Y-Axis Max</label>
                <input type="text" class="form-control" aria-label="Y-Axis Max" id="yMax" 
                    [value]="cnfgChrt_.yMax" formControlName="yMax">
                <label for="yMin" class="form-label">Y-Axis Min</label>
                <input type="text" class="form-control" aria-label="Y-Axis Min" id="yMin" 
                    [value]="cnfgChrt_.yMin" formControlName="yMin">
            </div>
            <div class="col-md-2" style="border: 1px dotted gray;">
                <label for="zTitle" class="form-label">Z-Axis Title</label>
                <input type="text" class="form-control" aria-label="Z-Axis Title" id="zTitle" 
                    [value]="cnfgChrt_.zTitle" formControlName="zTitle">
                <label for="zMax" class="form-label">Z-Axis Max</label>
                <input type="text" class="form-control" aria-label="Z-Axis Max" id="zMax" 
                    [value]="cnfgChrt_.zMax" formControlName="zMax">
                <label for="zMin" class="form-label">Z-Axis Min</label>
                <input type="text" class="form-control" aria-label="Z-Axis Min" id="zMin" 
                    [value]="cnfgChrt_.zMin" formControlName="zMin">
            </div>
            <div class="col-md-2" style="border: 1px dotted rgb(0, 255, 0);background-color: rgb(0, 255, 0);padding: .25rem .25rem;">
                <label for="safeLBL" class="form-label">Save Zone Title</label>
                <input type="text" class="form-control" aria-label="Save Zone Title" id="safeLBL" 
                    [value]="cnfgChrt_.safeLBL" formControlName="safeLBL">
                <label for="safeMax" class="form-label">Save Zone Max</label>
                <input type="text" class="form-control" aria-label="Save Zone Max" id="safeMax" 
                    [value]="cnfgChrt_.safeMax" formControlName="safeMax">
                <label for="safeMin" class="form-label">Save Zone Min</label>
                <input type="text" class="form-control" aria-label="Save Zone Min" id="safeMin" 
                    [value]="cnfgChrt_.safeMin" formControlName="safeMin">
            </div>
            <div class="col-md-2" style="border: 1px dotted rgb(255, 255, 0);background-color: rgb(255, 255, 0);padding: .25rem .25rem;">
                <label for="warnLBL" class="form-label">Warning Zone Title</label>
                <input type="text" class="form-control" aria-label="Warning Zone Title" id="warnLBL" 
                    [value]="cnfgChrt_.warnLBL" formControlName="warnLBL">
                <label for="warnMax" class="form-label">Warning Zone Max</label>
                <input type="text" class="form-control" aria-label="Warning Zone Max" id="warnMax" 
                    [value]="cnfgChrt_.warnMax" formControlName="warnMax">
                <label for="warnMin" class="form-label">Warning Zone Min</label>
                <input type="text" class="form-control" aria-label="Warning Zone Min" id="warnMin" 
                    [value]="cnfgChrt_.warnMin" formControlName="warnMin">
            </div>
            <div class="col-md-2" style="border: 1px dotted rgb(255, 0, 0);background-color: rgb(255, 0, 0);padding: .25rem .25rem;">
                <label for="alarmLBL" class="form-label" style="font-weight: bold;color: white;">Alarm Zone Title</label>
                <input type="text" class="form-control" aria-label="Alarm Zone Title" id="alarmLBL" 
                    [value]="cnfgChrt_.alarmLBL" formControlName="alarmLBL">
                <label for="alarmMax" class="form-label" style="font-weight: bold;color: white;">Alarm Zone Max</label>
                <input type="text" class="form-control" aria-label="Alarm Zone Max" id="alarmMax" 
                    [value]="cnfgChrt_.alarmMax" formControlName="alarmMax">
                <label for="alarmMin" class="form-label" style="font-weight: bold;color: white;">Alarm Zone Min</label>
                <input type="text" class="form-control" aria-label="Alarm Zone Min" id="alarmMin" 
                    [value]="cnfgChrt_.alarmMin" formControlName="alarmMin">
            </div>
        </div>
        <div class="row editDiv">
            <h5>Primary Contacts:</h5>
            <div class="col-md-3">
                <label for="pNSlct">Nursing</label>
                <select class="form-select" id="pNSlct" (change)="chgNursing($event)" formControlName="pN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pPSlct">Provider</label>
                <select class="form-select" id="pPSlct" (change)="chgProvider($event)" formControlName="pP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Provider" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pASlct">Admin</label>
                <select class="form-select" id="pASlct" (change)="chgAdmin($event)" formControlName="pA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="pISlct">IT</label>
                <select class="form-select" id="pISlct" (change)="chgIT($event)" formControlName="pI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctP_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <h5>Secondary Contacts:</h5>
            <div class="col-md-3">
                <label for="sNSlct">Nursing</label>
                <select class="form-select" id="sNSlct" (change)="chgNursing($event)" formControlName="sN">
                    <option value="">Select Nursing</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Nursing" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sPSlct">Provider</label>
                <select class="form-select" id="sPSlct" (change)="chgProvider($event)" formControlName="sP">
                    <option value="">Select Provider</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Provider" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sASlct">Admin</label>
                <select class="form-select" id="sASlct" (change)="chgAdmin($event)" formControlName="sA">
                    <option value="">Select Admin</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.Admin" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="sISlct">IT</label>
                <select class="form-select" id="sISlct" (change)="chgIT($event)" formControlName="sI">
                    <option value="">Select IT</option>
                    <option [value]="s_.sID" [selected]="s_.sID === cntctS_.IT" *ngFor="let s_ of stfs">{{ s_.fN }} {{ s_.lN }}</option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-12">
                <label for="note" class="form-label">Notes</label>
                <textarea class="form-control textarea" id="note" aria-label="Company Notes" [value]="cmpy.note"
                    formControlName="note"></textarea>
            </div>
        </div>
    </form>
</div>