import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import { AuthService } from '../../../service/auth.service';
import { UtilitiesService } from '../../../service/utilities.service';

interface SelectInterfeace {
    title: string;
    value: string;
    viewValue: string;
}
@Injectable({providedIn: 'root'})
@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.css']
})
export class CompanyDashboardComponent implements OnInit {
    cmpy:any;locs:any;loc:any;dpts:any;dpt:any;rptDepart:any;
    stf:any;
    selSummary!: string;
    selDateRange!: string;
    selLoc!: string;
    selDept: string='All';
    selDate: string='2023-10-14';
    dbDateVal: string='2023-10-14';

    listLoc:any = [];
    listDept: any= [];
    dateRange: SelectInterfeace[] = [
        {value: 'WTD', title: 'Week-to-Date', viewValue: 'WTD'},
        {value: 'MTD', title: 'Month-to-Date', viewValue: 'MTD'},
        {value: 'QTD', title: 'Quarter-to-Date', viewValue: 'QTD'},
        {value: 'YTD', title: 'Year-to-Date', viewValue: 'YTD'},
        {value: 'All', title: 'All', viewValue: 'All'},
      ];
    summaryRange: SelectInterfeace[] = [
    {value: 'Average', title: 'Average', viewValue: 'Average'},
    {value: 'PeeK', title: 'Peek', viewValue: 'Peek'},
    {value: 'Low', title: 'Low', viewValue: 'Low'},
    ];
    constructor(
        private srvc: UtilitiesService,
        private authSrvc: AuthService,
        private toastr:ToastrService,
        private router: Router,
        private builder: FormBuilder,
        ){
            const stf_:any=localStorage.getItem('stf');
            if(stf_===null){console.log("redirect");this.router.navigate(['login/:id']);}
            else{
                this.stf = JSON.parse(stf_);console.log(this.stf);
                const cmpy:any=localStorage.getItem('cmpy');this.cmpy = JSON.parse(cmpy);console.log(this.cmpy);
                const locs:any=localStorage.getItem('locs');this.locs = JSON.parse(locs);console.log(this.locs);
                const loc:any=localStorage.getItem('loc');this.loc = JSON.parse(loc);console.log(this.loc);
                const dpt:any=localStorage.getItem('dpt_');this.dpt = JSON.parse(dpt);console.log(this.dpt);
            }
        }

    Highcharts:typeof Highcharts=Highcharts;
    chrt_:Highcharts.Chart | undefined;

    prom_ID:string='PHQ9';
    dptRpt:any=[];
    prom:any=[];
    prom_:any=[];
    updateFlag = false;
    pToday:number = 85;
    pAvg:number = 115;
    pYTD:number = 3500;
    pTodayRed:number = 11;
    pAvgRed:number = 15;
    pYTDRed:number = 95;
    aTodayRed:number = 11;
    aAvgRed:number = 11;
    aYTDRed:number = 11;
    pSeniors:number = 30;
    pAdult:number = 55;
    pYoungAdult:number = 23;
    pAdolescents:number=0;
    pChildren:number=0;
    aSeniors:number = 30;
    aAdult:number = 55;
    aYoungAdult:number = 23;
    aAdolescents:number=0;
    aChildren:number=0;
    pFemale:number = 25;
    pMale:number = 60;
    aFemale:number = 25;
    aMale:number = 60;
    aToday:number = 95;
    aAvg:number = 130;
    aYTD:number = 4500;

    dptRptDate_:any=[];
    dptRptTotal_:any=[];
    dptRptFemale_:any=[];
    dptRptMale_:any=[];

    locChanged(locID: any){ console.log(locID);
        this.locs=this.cmpy.Locations;console.log(this.locs);
        const loc_=this.cmpy.Locations.find((location: { ID: any; }) => location.ID === locID);
        this.listDept=loc_.Departments;console.log(this.listDept);
        this.selDept='All';console.log(this.selDept);
    }

    chartUpdate() {
        //var patients_=this.patients;//console.log(patients_);
        //var router_=this.router;
        //var show_=this.show;
        //var chartTitle=this.scatterChartTitle;
  // @ts-ignore
    this.chrt_ = Highcharts.chart('container1',{
        chart:{scrollablePlotArea:{minWidth:700}},title:{text:'Encounters',align: 'left'},    
        xAxis:{tickInterval:7*24 *3600*1000,/* one week */tickWidth:0,gridLineWidth:1,labels:{align:'left',x:3,y:-3}},
        yAxis:[
            {title:{text:null},labels:{align:'left',x:3,y: 16,format:'{value:.,0f}'},showFirstLabel:false},/* left y axis */
            {linkedTo:0,gridLineWidth:0,opposite:true,title:{text:null},labels:{align:'right',x:-3,y:16,format:'{value:.,0f}'},showFirstLabel:false}/* right y axis*/],
        legend:{align:'left',verticalAlign:'top',borderWidth:0},
        tooltip:{shared:true,crosshairs:true},
        plotOptions:{series:{cursor:'pointer',className:'popup-on-click',marker:{lineWidth:1}}},
        series: [
            {name:'Scheduled',data:this.dptRpt["0001"].Total },
            {name:'Actual',data:this.dptRpt["0001"].Actual.Total},
            {name:'NoShow',color:'#FF0000',data:this.dptRpt["0001"].NoShow.Total}
            ]
        });

    // @ts-ignore
    this.chrt_ = Highcharts.chart('container2',{
        chart:{plotBackgroundColor:null,plotBorderWidth:null,plotShadow:false,type:'pie'},
        title:{text:'Encounters by Specialty',align:'left'},
        tooltip:{pointFormat:'{series.name}: <b>{point.percentage:.1f}%</b>'},
        accessibility:{point:{valueSuffix:'%'}},
        plotOptions:{pie:{allowPointSelect: true,cursor:'pointer',dataLabels:{enabled:false},showInLegend:true}},
        series:[{
            name:'Department',colorByPoint: true,
            data: [
                {name:'Primary Care',y: 33.00,sliced: true,selected: true},
                {name:'Internal',y: 27.00},
                {name:'Psychology',y: 19.00},
                {name:'Group Sessions',y: 12.00},
                {name:'Other',y: 9.00}]
            }]
        });
    // @ts-ignore
    this.chrt_ = Highcharts.chart('container3',{
        chart:{scrollablePlotArea:{minWidth:700}},title:{text:'Assessments',align: 'left'},    
        xAxis:{tickInterval:7*24 *3600*1000,/* one week */tickWidth:0,gridLineWidth:1,labels:{align:'left',x:3,y:-3}},
        yAxis:[
            {title:{text:null},labels:{align:'left',x:3,y: 16,format:'{value:.,0f}'},showFirstLabel:false},/* left y axis */
            {linkedTo:0,gridLineWidth:0,opposite:true,title:{text:null},labels:{align:'right',x:-3,y:16,format:'{value:.,0f}'},showFirstLabel:false}/* right y axis*/],
        legend:{align:'left',verticalAlign:'top',borderWidth:0},
        tooltip:{shared:true,crosshairs:true},
        plotOptions:{series:{cursor:'pointer',className:'popup-on-click',marker:{lineWidth:1}}},
        series: [
            {name:'Scheduled',
                data:[45, 47, 50, 43, 46, 48, 51, 44, 45, 49, 50, 45, 47, 46, 48, 50, 51, 43, 45, 47, 49, 51, 53, 52, 50, 48, 47, 45, 44, 46, 48, 49, 50, 45, 47, 48, 50, 52, 53, 45, 47, 46, 44, 43, 42, 45, 47, 48, 51, 50, 49]},
            {name:'Actual',
                data:[45, 47, 50, 17, 3, 20, 5, 8, 3, 11, 12, 11, 9, 17, 3, 17, 3, 20, 5, 8, 4, 6, 4, 12, 11, 9, 17, 3, 20, 3, 11, 12, 11, 9, 17, 3, 17, 12, 14, 9, 9, 4, 5, 7, 3, 8, 11, 9, 17, 3]},
            {name:'Slow',color:'#FFC100',
            data:[1, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 1, 0, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1]},
            {name:'Red Alert',color:'#FF0000',
            data:[0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1, 2, 1, 0, 2, 1, 0, 1, 2, 1, 0, 1]}
            ]
        });
    // @ts-ignore
    this.chrt_ = Highcharts.chart('container4',{
        chart:{plotBackgroundColor:null,plotBorderWidth:null,plotShadow:false,type:'pie'},
        title:{text:'Assessments by Department',align:'left'},
        tooltip:{pointFormat:'{series.name}: <b>{point.percentage:.1f}%</b>'},
        accessibility:{point:{valueSuffix:'%'}},
        plotOptions:{pie:{allowPointSelect: true,cursor:'pointer',dataLabels:{enabled:false},showInLegend:true}},
        series:[{
            name:'Department',colorByPoint: true,
            data: [
                {name:'Primary Care',y: 33.00,sliced: true,selected: true},
                {name:'Internal',y: 27.00},
                {name:'Psychology',y: 19.00},
                {name:'Group Sessions',y: 12.00},
                {name:'Other',y: 9.00}]
            }]
        });
    };
    ngOnInit(): void {
        this.selLoc=this.cmpy.N;
        this.selDept='ALL';this.selSummary='Average';this.selDateRange='MTD';
        this.authSrvc.getRptLoc(this.stf.lID).subscribe(item=>{
            const res:any=item;console.log(res);
            this.rptDepart = res[0].Locations[0].Departments.find((Department: { ID: any; }) => Department.ID === this.dpt.ID);
            console.log(this.rptDepart);
            const dpts=res[0].Locations[0];
            for (var $d=0;$d<dpts.Departments.length;$d++ ){
                let dpt_=dpts.Departments[$d];//console.log(dpt_);
                let dptID=dpt_.ID;
                this.dptRpt[dpt_.ID]=[];
                this.dptRpt[dpt_.ID].Date=[];
                this.dptRpt[dpt_.ID].Total=[];
                this.dptRpt[dpt_.ID].Female=[];
                this.dptRpt[dpt_.ID].Male=[];
                this.dptRpt[dpt_.ID].NoShow=[];
                this.dptRpt[dpt_.ID].NoShow.Total=[];
                this.dptRpt[dpt_.ID].NoShow.Female=[];
                this.dptRpt[dpt_.ID].NoShow.Male=[];
                this.dptRpt[dpt_.ID].Canceled=[];
                this.dptRpt[dpt_.ID].Canceled.Total=[];
                this.dptRpt[dpt_.ID].Canceled.Female=[];
                this.dptRpt[dpt_.ID].Canceled.Male=[];
                this.dptRpt[dpt_.ID].Actual=[];
                this.dptRpt[dpt_.ID].Actual.Total=[];
                this.dptRpt[dpt_.ID].Actual.Female=[];
                this.dptRpt[dpt_.ID].Actual.Male=[];

                let tlt_:any=1;
                let act_:any=1;

                for (var $r=0;$r<dpt_.Reports.length;$r++ ){
                    let rpt_=dpt_.Reports[$r];//console.log(rpt_);
                    tlt_=parseInt(rpt_.Scheduled.All.Total)*this.srvc.getRandomInt(175);
                    this.dptRpt[dptID].Date.push(rpt_.Date);
                    this.dptRpt[dptID].Total.push(parseInt(tlt_));
                    this.dptRpt[dptID].Female.push(parseInt(tlt_)*.45);
                    this.dptRpt[dptID].Male.push(parseInt(rpt_.Scheduled.Male.Total)*.55);
                    this.dptRpt[dptID].NoShow.Total.push(parseInt(tlt_)*.07);
                    this.dptRpt[dptID].NoShow.Female.push(parseInt(rpt_.NoShow.Female.Total)*3);
                    this.dptRpt[dptID].NoShow.Male.push(parseInt(rpt_.NoShow.Male.Total)*1.5);
                    let rndm= this.srvc.getRandomPrcnt();act_=tlt_ * rndm;//console.log(tlt_,rndm,act_)
                    this.dptRpt[dptID].Actual.Total.push(act_) ;
                    this.dptRpt[dptID].Actual.Female.push(parseInt(rpt_.Actual.Female.Total)*3);
                    this.dptRpt[dptID].Actual.Male.push(parseInt(rpt_.Actual.Male.Total)*1.5);
                    /*** 
                    this.dptRpt[dptID].Total[$r]=rpt_.Scheduled.All.Total;
                    this.dptRpt[dptID].Female[$r]=rpt_.Scheduled.Female.Total;
                    this.dptRpt[dptID].Male[$r]=rpt_.Scheduled.Male.Total;
                    /*** */
                }
            }
                //console.log(this.dptRptDate_);
                //console.log(this.dptRptTotal_);
                //console.log(this.dptRptFemale_);
                //console.log(this.dptRptMale_);
            console.log(this.dptRpt);
            this.chartUpdate();
                                
        });
        this.toastr.success('Good Afternoon ',this.stf.fN);
    }
}
