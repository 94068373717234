<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h4 class="fw-normal mb-1">Hello {{Apt.Patient.fullName}} | Date of Birth: {{Apt.Patient.DOB}},
                <br>Thank you for answering these questions.<br>Please Confirm your answers by pressing the green submit
                button:
            </h4>
            <table class="table table-striped align-middle mb-0 bg-white">
                <thead class="bg-light">
                    <tr>
                        <th>Question</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let a of Q_.question">
                        <td *ngIf="a.value">{{a.text}}</td>
                        <td *ngIf="a.value">{{a.answer}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <a routerLink="/assessmentActivation/{{lID}}-{{dID}}-{{aID}}" title="Activate Patient Assessment"
                class="btn btn-danger" style="width: 100%;"> <i class="bi bi-arrow-left-square"></i>Restart
                Assessment</a>
        </div>
        <div class="col-sm-8">
            <a class="btn btn-success" title="Activate Patient Assessment" style="width: 100%;"
                (click)="submitResults()">Submit Results <i class="bi bi-arrow-right-square"></i></a>
        </div>
    </div>
</div>