import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../../service/auth.service';
// staff interface
export class staff {
  fN: any;
  lN: any;
  E: any;
}
@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.css']
})
export class StaffProfileComponent implements OnInit {
  stf!: staff;
  constructor(public aSrvc: AuthService) {
      this.aSrvc.profileStaff(this.stf).subscribe((data: any) => {
      this.stf = data;
    });
  }
  ngOnInit() {}
}