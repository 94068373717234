<header>
  <a style="padding-right:.5rem;font-size: 1.25rem;"><i class="bi bi-arrow-left-square"></i> </a>
</header>
<div class="card textcenter mt-3">
  <div class="card-body">
    <p class="fw-bold mb-1">Patient: {{apt_.Patient.fullName}} | Date of Birth: {{apt_.Patient.DOB}} | MRN:
      {{apt_.Patient.pID}}</p>
    <p class="fw-bold mb-1">Appointment Time: {{ apt_.Details.dateTime | date: 'medium' }} | Current Status:
      {{apt_.Status}}</p>
    <p class="fw-normal mb-1">Appointment Reason: {{apt_.Details.reason}}</p>
    <p class="fw-bold mb-1">Appointment with: {{apt_.Details.resource}}</p>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm">
      <div class="col-md-4">
        <label for="statSlct">Appointment Status</label>
        <select class="form-select" id="statSlct" name="Status" formControlName="statSlct">
          <option value="">Select Appointment Status</option>
          <option [value]="s_.id" *ngFor="let s_ of cmpy.Cnfg.apptStatus">{{ s_.name }}</option>
        </select>
      </div>
      <div class="col-md-8">
        <button mat-icon-button type="submit" title="Update Appointment Status" style="float:right;padding: 0;margin: .5rem;" (click)="eFormUD()"><mat-icon>save</mat-icon></button>
      </div><!-- form-group -->
    </form>
  </div><!-- card-body -->
</div><!-- card-primary -->