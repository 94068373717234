<div class="card textcenter mt-3">
    <div class="card-body">
        <!--p class="fw-bold mb-1">Patient: {{pD_.name}} | MRN: {{pD_.patient_id}} | Date of Birth: {{pD_.dob}} | Age: {{pD_.Age}}</p!-->
        <p class="fw-bold mb-1">Encounter Date: {{ePrmD_.eDate | date: 'MM-dd-YYYY'}} | Type: {{vEtype}} | Physician Seen: {{ePrmD_.location.PCP}} </p>
        <p class="fw-bold mb-1"><button title="Upload Assessment" type="submit" class="btn"
                style="font-weight:bold;color:red;" (click)="updateAssessment(pD_,ePrmD_)"
                *ngIf=" ePrmD_.status['EHR-UL']==''"><i class="bi bi-upload"></i> Upload Assessment</button> EHR-Upload: {{ePrmD_.status['EHR-UL'] | date: 'MM-dd'}} | by {{ePrmD_.status['EHR-UL-sID'] }}
        </p>
        <ul class="list-group list-group-flush" style="overflow-y: scroll;height: 25vh;" *ngIf="vEnote">
            <li class="list-group-item" *ngIf="vPresenting"><p class="fw-bold mb-1">Presented: </p>{{ePrmD_.note.Presenting}}</li>
            <li class="list-group-item" *ngIf="vIBHAssessment"><p class="fw-bold mb-1">Assessment: </p>{{ePrmD_.note.IBHAssessment}}</li>
            <li class="list-group-item" *ngIf="vMedManagement"><p class="fw-bold mb-1">Medication Management: </p>{{ePrmD_.note.MedicationManagement}}</li>
            <li class="list-group-item" *ngIf="vTherapistObservation"><p class="fw-bold mb-1">Therapist Observation: </p>{{ePrmD_.note.TherapistObservation}}</li>
            <li class="list-group-item" *ngIf="vRecommendation"><p class="fw-bold mb-1">Recommendation: </p>{{ePrmD_.note.Recommendation}}</li>
        </ul>
        <div class="col-md-4"></div>
        <div style="height:50vh;overflow-y: scroll;">
            <mat-tab-group>
                <mat-tab label={{a_.hdr}} *ngFor="let a_ of aD">
                    <table class="appointment-list table table-striped align-middle mb-0 bg-white">
                        <thead class="bg-light"><tr><th style="width:40%;">Questions</th><th>Score</th><th>Time (seconds)</th></tr></thead>
                        <tbody>
                            <tr *ngFor="let r of a_.results">
                                <td><span class="fw-normal mb-1">{{r.q}}</span></td>
                                <td><span class="fw-bold mb-1">{{r.r}}: {{r.v}}</span></td>
                                <td><span class="fw-normal mb-1">{{r.t}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div><!-- card-body -->
</div><!-- card-primary -->