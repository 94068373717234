<div class="container-fluid" style="padding:0.0625rem;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <h4 class="fw-normal mb-1 pb-1" style="letter-spacing: 1px;margin: .25rem;">Staff Editor</h4>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="eForm" *ngIf="eSTF">
        <div class="col-12" style="margin:0;padding:0;">
            <a routerLink="/cmpyAdmin/{{lID}}-{{dID}}" title="Back" class="btn btn-light" role="button"
                style="float: left;padding: 0;margin: .5rem;"><i class="bi bi-arrow-left-square"></i></a>
            <button type="submit" class="btn" title="Create Staff Profile" style="float:right;padding: 0;margin: .5rem;"
                (click)="eFormSV()" *ngIf="nwStf"><i class="bi bi-person-plus"></i></button>
            <button type="submit" class="btn" title="Update Staff Profile" style="float:right;padding: 0;margin: .5rem;"
                (click)="eFormUD()" *ngIf="!nwStf"><i class="bi bi-save"></i></button>
        </div>
        <div class="row editDiv">
            <div class="col-md-4">
                <label for="fName" class="form-label">First name</label>
                <input type="text" class="form-control" placeholder="First name" aria-label="First name" id="fName"
                    name="fN" [value]="eSTF.fN" formControlName="fN">
            </div>
            <div class="col-md-4">
                <label for="mName" class="form-label">Middle name</label>
                <input type="text" class="form-control" placeholder="Middle name" aria-label="Middle name" id="mN"
                    name="mN" [value]="eSTF.mN" formControlName="mN">
            </div>
            <div class="col-md-4">
                <label for="lName" class="form-label">Last name</label>
                <input type="text" class="form-control" placeholder="Last name" aria-label="Last name" id="lName"
                    name="lN" [value]="eSTF.lN" formControlName="lN">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6 row"><span>Date of Birth</span>
                <div class="col-md-4">
                    <label for="dbY" class="form-label">Year</label>
                    <input type="text" class="form-control" placeholder="YYYY" aria-label="Year" id="dbY" name="dbY"
                        [value]="eSTF.dbY" formControlName="dbY">
                </div>
                <div class="col-md-4">
                    <label for="dbM" class="form-label">Month</label>
                    <input type="text" class="form-control" placeholder="MM" aria-label="Month" id="dbM" name="dbM"
                        [value]="eSTF.dbM" formControlName="dbM">
                </div>
                <div class="col-md-4">
                    <label for="dbD" class="form-label">Date</label>
                    <input type="text" class="form-control" placeholder="DD" aria-label="Date" id="dbD" name="dbD"
                        [value]="eSTF.dbD" formControlName="dbD">
                </div>
            </div>
            <div class="col-md-6 row"><span>Gender</span>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gndr" id="gndrF" value="F"
                            formControlName="gndr">
                        <label class="form-check-label" for="gndrF">Female</label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gndr" id="gndrM" value="M"
                            formControlName="gndr">
                        <label class="form-check-label" for="gndrM">Male</label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="gndr" id="gndrO" value="O"
                            formControlName="gndr">
                        <label class="form-check-label" for="gndrO">Other</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-4">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="actvStf" [value]="eSTF.actv"
                        formControlName="actv">
                    <label class="form-check-label" for="actvStf">Active</label>
                </div>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Activation Date</mat-label>
                    <input matInput [matDatepicker]="actvD" placeholder="Choose a date" [value]="eSTF.actvD"
                        formControlName="actvD">
                    <mat-hint>YYYY/MM/DD</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="actvD"></mat-datepicker-toggle>
                    <mat-datepicker #actvD mat-datepicker-format="YYYY-MM-DD"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Deactivation Date</mat-label>
                    <input matInput [matDatepicker]="dactvD" placeholder="Choose a date" [value]="eSTF.dactvD"
                        formControlName="dactvD">
                    <mat-hint>YYYY/MM/DD</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="dactvD"></mat-datepicker-toggle>
                    <mat-datepicker #dactvD mat-datepicker-format="YYYY-MM-DD"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-4">
                <label for="locSelect">Location</label>
                <select class="form-select" id="locSelect" (change)="chgLoc($event)" formControlName="lID">
                    <option value="">Select Location</option>
                    <option [value]="l_.lID" [selected]="l_.lID === eSTF.lID" *ngFor="let l_ of locs">{{ l_.N }}
                    </option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="dptSelect">Department</label>
                <select class="form-select" id="dptSelect" (change)="chgDpt($event)" formControlName="dID">
                    <option value="">Select Departments</option>
                    <option [value]="d_.dID" [selected]="d_.dID === eSTF.dID" *ngFor="let d_ of eLoc.Departments">{{
                        d_.N }}</option>
                </select>
            </div>
            <div class="col-md-4" *ngIf="enableGS">
                <label for="gsSelect">Group Session</label>
                <select class="form-select" id="gsSelect" (change)="chgGS($event)" formControlName="gID">
                    <option value="">Select Group Session</option>
                    <option [value]="g_.gID" [selected]="g_.gID === eSTF.gID" *ngFor="let g_ of eDpt.GroupSessions">{{
                        g_.N }}</option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-4">
                <label for="rSlct">Role</label>
                <select class="form-select" id="rSlct" (change)="chgRole($event)" formControlName="R">
                    <option value="">Select Staff Role</option>
                    <option [value]="r_.L" [selected]="r_.L === eSTF.R" *ngFor="let r_ of eLoc.Roles">{{ r_.N }}
                    </option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="alSlct">Access Level</label>
                <select class="form-select" id="alSlct" name="AL" formControlName="AL">
                    <option value="">Select Access Level</option>
                    <option [value]="a_.L" *ngFor="let a_ of eLoc.AL">{{ a_.D }}</option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="iPgSlct">Initial Landing Page</label>
                <select class="form-select" id="iPgSlct" name="iPg" formControlName="iPg">
                    <option value="">Select Initial Landing Page</option>
                    <option [value]="s_.R" [selected]="s_.R === eSTF.iPg" *ngFor="let s_ of eLoc.LPs">{{ s_.T }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-3">
                <label for="cP" class="form-label">Mobile Phone</label>
                <input type="tel" class="form-control" id="cP" name="cP" (blur)="updatePhn($event)" [value]="eSTF.cP"
                    formControlName="cP">
            </div>
            <div class="col-md-3">
                <label for="wP" class="form-label">Work Phone</label>
                <input type="tel" class="form-control" id="wP" name="wp" (blur)="updatePhn($event)" [value]="eSTF.wP"
                    formControlName="wP">
            </div>
            <div class="col-md-3">
                <label for="E" class="form-label">Email</label>
                <input type="email" class="form-control" id="E" name="E" [value]="eSTF.E" formControlName="E">
            </div>
            <div class="col-md-3">
                <label for="pswrd" class="form-label">Password</label>
                <input type="password" class="form-control" id="pswrd" name="pswrd" [value]="eSTF.pswrd"
                    formControlName="pswrd">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="A" class="form-label">Address</label>
                <input type="A" class="form-control" id="A" placeholder="1234 Main St" name="A" [value]="eDpt.Adr.A"
                    formControlName="A">
            </div>
            <div class="col-md-6">
                <label for="A2" class="form-label">Address 2</label>
                <input type="text" class="form-control" id="A2" name="A2" [value]="eDpt.Adr.A2" formControlName="A2">
            </div>
        </div>
        <div class="row editDiv">
            <div class="col-md-6">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" name="C" [value]="eDpt.Adr.C" formControlName="C">
            </div>
            <div class="col-md-4">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" name="S" [value]="eDpt.Adr.S" formControlName="S">
                    <option selected>Choose...</option>
                    <option>...</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="isZip" class="form-label">Zip</label>
                <input type="text" class="form-control" id="isZip" name="isZip" [value]="eDpt.Adr.Z"
                    formControlName="Z">
            </div>
        </div>
    </form>
</div>