// src/app/patient/profile-form.component.ts

import { Component, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.css']
})
export class ProfileEditorComponent implements OnInit {
  [x: string]: any;
  private subscription: Subscription = new Subscription();
  isLoading: boolean = true;
  @Input() patient: any;
  @Input() cmpy: any;
  @Input() eDate: any;
  stf: any;
  patientForm!: FormGroup;
  patientRdy: boolean = false;
  isVeteran = false;
  pHstry: any;
  pSoc: any;
  psycH: any;
  psycMH: any;
  pMil: any;
  pMedical: any;
  pMeds: any;
  cnfg: any;
  socialCnfg: any; spCnfg: any; mpCnfg: any; sympCnfg: any; medMngt: any;
  ppCnfg: any; vpCnfg: any; apCnfg: any;
  errors: any = null;
  transformedArray: any[] = [];
  selectedYear!: number;
  SubstanceUseInfo: boolean = false;
  psychologicalHistoryForm!: FormGroup;
  psychologicalMitlitaryHistoryForm!: FormGroup;

  onYearSelected(year: number): void {
    this.selectedYear = year;
  }

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    //@Inject(MAT_DIALOG_DATA) public pData: any
  ) {
    this.patientRdy = false;
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else {
      this.stf = JSON.parse(stf);
      //console.log("editor -constructor- this.patient:", this.patient,"- this.cmpy", this.cmpy);
    }

  }
  //psychological_mitlitary_history
  createForm2() {
    this.psychologicalMitlitaryHistoryForm = this.fb.group({});
    this.vpCnfg.forEach((section: any) => {
      console.log(section);
      const sectionForm = this.createSection(section.Questions);
      this.psychologicalMitlitaryHistoryForm.addControl(section.Type, sectionForm);
      this.initializeSectionFormControls(section.Type, 'psycH', sectionForm);
    });
  }
  createForm() {
    this.psychologicalHistoryForm = this.fb.group({});
    this.ppCnfg.forEach((section: any) => {
      const sectionForm = this.createSection(section.Questions);
      this.psychologicalHistoryForm.addControl(section.Type, sectionForm);
      this.initializeSectionFormControls(section.Type, 'psycH', sectionForm);
    });
  }
  private initializeSectionFormControls(sectionType: string, patKey: string, sectionForm: FormGroup) {
    const sectionData = this[patKey][sectionType];//console.log(this[patKey]);
    if (!sectionData) { console.error(`No data found for section: ${sectionType}`); return; }
    Object.keys(sectionData).forEach(key => {
      if (sectionForm.contains(key)) { sectionForm.get(key)?.setValue(sectionData[key]); }
      else { console.warn(`Form control for key '${key}' not found in section '${sectionType}'`); }
    });
  }
  createSection(questions: any[]): FormGroup {
    const group: any = {};
    questions.forEach(question => { group[question.ControlName] = this.createQuestion(question); });
    //console.log(this.fb.group(group));
    return this.fb.group(group);
  }

  createQuestion(question: any): any {
    let control;
    if (question.Option && question.Option.length > 0) {
      // Assuming it's a dropdown/select control
      control = [null, Validators.required];
    } else {
      // For other types of controls like text input
      control = ['', Validators.required];
    }
    return control;
  }
  ngOnInit() {
    console.log("editor -ngOnInit- this.patient:", this.patient, "editor -ngOnInit- this.cmpy", this.cmpy);
    this.cnfg = this.cmpy.Cnfg;
    this.socialCnfg = this.cmpy.SocialProfile; console.log("socialCnfg", this.socialCnfg);
    this.mpCnfg = this.cmpy.MilitaryProfile; console.log("mpCnfg", this.mpCnfg);
    this.ppCnfg = this.cmpy.PsychologicalProfile; console.log("ppCnfg", this.ppCnfg);
    this.vpCnfg = this.cmpy.PsychologicalProfileMilitaryVeteran; console.log("vpCnfg", this.vpCnfg);
    this.apCnfg = this.cmpy.PsychologicalProfileMilitaryActive; console.log("apCnfg", this.apCnfg);
    this.sympCnfg = this.cmpy.SymptomProfile; console.log("sympCnfg", this.sympCnfg);
    this.medMngt = this.cmpy.medManagement; console.log("medMngt", this.medMngt);
    this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => {
        if (encounter) {
          console.log(encounter);
          console.log("the editor:");
          this.pHstry = encounter.history; console.log("this.pHstry:", this.pHstry);
          this.pSoc = this.pHstry.social_history; console.log("this.pSoc:", this.pSoc);
          this.psycH = this.pHstry.psychological_history; console.log("this.psycH:", this.psycH);
          this.psycMH = this.pHstry.psychological_military_history; console.log("this.psycMH:", this.psycMH);
          this.createForm();
          this.initializeForm();
          this.checkStaffAccess();
          console.log("profile editor -ngOnInit- this.patientForm", this.patientForm);
        }
      })
    );
  }

  private checkStaffAccess() {
    // Replace direct localStorage access with service method
    //  this.router.navigate(['login/:id']);
    //} else {
    // Fetch configurations using service
    this.fetchConfigurations();
    //}
  }

  private fetchConfigurations() {

    console.log('this.patientForm:', this.patientForm);
    if (this.patient.encounters[0].history.psychological_history !== null) {
      if (this.patient.encounters[0].history.psychological_history.SubstanceUse !== null) { this.SubstanceUseInfo = true; }
    }
    this.patientRdy = true;
  }

  onEnlistmentStatusChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const serviceStatus = String(selectElement.value);
    this.isVeteran = serviceStatus === 'Non-Military/Did Not Serve' ? false : true;
  }

  getSubstanceUseControls(): FormGroup {
    const substanceUseGroup = this.fb.group({});
    this.socialCnfg.socialHistory[2].substanceUse.questions.forEach((question: any) => {
      const questionGroup = this.fb.group({}); // Create a group for each question
      questionGroup.addControl(question.type, this.fb.control('', this.getValidators(question.status)));// Add the main question control
      // Handle follow-up questions, if any
      if (question.followUp && question.followUp.length > 0) {
        const followUpGroup = this.fb.group({});
        question.followUp.forEach((followUpQuestion: any) => {
          followUpGroup.addControl(followUpQuestion.type, this.fb.control('', this.getValidators(followUpQuestion.status)));
        });
        questionGroup.addControl('FollowUp', followUpGroup);
      }
      substanceUseGroup.addControl(question.type, questionGroup);// Add the question group to the main substanceUseGroup
    });
    return substanceUseGroup;
  }

  private getValidators(status: string) { return status === 'required' ? [Validators.required] : []; }

  getLivingConditionsControls(): { [key: string]: any } {
    const group: { [key: string]: any } = {};
    this.socialCnfg.socialHistory[1].questions.forEach((control: { type: string | number; validators: any; }) => { group[control.type] = ['']; });
    return group;
  }
  initializeForm(): void {
    //const e_: any = this.patient.encounters.find((E_: { eDate: String; }) => E_.eDate === eDate);
    //console.log(e_);

    const [dobY, dobM, dobD] = this.patient.demographics.dob!.split('-');
    this.patientForm = this.fb.group({
      fName: [this.patient.demographics.name.f, Validators.required],
      mName: [this.patient.demographics.name.m, Validators.required],
      lName: [this.patient.demographics.name.l, Validators.required],
      comm_method: this.fb.group({
        hp: [this.patient.comm_method.hp],
        wp: [this.patient.comm_method.wp],
        cp: [this.patient.comm_method.cp, Validators.required],
        E: [this.patient.comm_method.E, Validators.required],
        pmoc: [this.patient.comm_method.pmocE, Validators.required],
        // Add more address fields with validators
      }),
      address: this.fb.group({
        A: [this.patient.address.A, Validators.required],
        A2: [this.patient.address.A2, Validators.required],
        C: [this.patient.address.C, Validators.required],
        S: [this.patient.address.S, Validators.required],
        Z: [this.patient.address.Z, Validators.required],
        // Add more address fields with validators
      }),
      demographics: this.fb.group({
        dobY: [dobY, Validators.required],
        dobM: [dobM, Validators.required],
        dobD: [dobD, Validators.required],
        gender: [this.patient.demographics.gender, Validators.required],
        race: [this.patient.demographics.race, Validators.required],
        ethnicity: [''],
        eyeColor: [this.patient.demographics.eColor],
        hairColor: [this.patient.demographics.hColor],
        weight: [this.patient.demographics.weightlbs],
        heightCM: [''],
        heightFt: [''],
        heightIn: [''],
        // Add more demographics fields with validators
      }),
      social_history: this.fb.group({
        preferredLanguage: [this.pSoc.preferredLanguage, Validators.required],
        maritalStatus: [this.pSoc.maritalStatus, Validators.required],
        education: [this.pSoc.education],
        occupation: [this.pSoc.occupation],
        religiousAffiliation: [this.pSoc.religiousAffiliation],
        MilitarySrvcStatus: [this.pSoc.MilitarySrvcStatus, Validators.required],
        culturalBackground: [this.pSoc.culturalBackground],
        eChildren: this.fb.array([]),
        eContacts: this.fb.array([]),
        socialSupport: this.fb.array([]),
        substanceUse: this.fb.group({
          tobaccoUse: this.fb.group({
            tobaccoUseStatus: [this.pSoc.substanceUse.tobaccoUse.tobaccoUseStatus, Validators.required],
            tobaccoUseType: [this.pSoc.substanceUse.tobaccoUse.tobaccoUseType],
            tobaccoUseFrequency: [this.pSoc.substanceUse.tobaccoUse.tobaccoUseFrequency],
            tobaccoUseHelp: [this.pSoc.substanceUse.tobaccoUse.tobaccoUseHelp],
          }),
          alcoholUse: this.fb.group({
            alcoholUseStatus: [this.pSoc.substanceUse.alcoholUse.alcoholUseStatus, Validators.required],
            alcoholUseFrequency: [this.pSoc.substanceUse.alcoholUse.alcoholUseFrequency],
            alcoholUseQuantity: [this.pSoc.substanceUse.alcoholUse.alcoholUseQuantity],
            alcoholUseHelp: [this.pSoc.substanceUse.alcoholUse.alcoholUseHelp],
          }),
          recreationalDrugsUse: this.fb.group({
            recreationalDrugsUseStatus: [this.pSoc.substanceUse.recreationalDrugsUse.recreationalDrugsUseStatus, Validators.required],
            recreationalDrugsUseType: [this.pSoc.substanceUse.recreationalDrugsUse.recreationalDrugsUseType],
            recreationalDrugsUseFrequency: [this.pSoc.substanceUse.recreationalDrugsUse.recreationalDrugsUseFrequency],
            recreationalDrugsUseHelp: [this.pSoc.substanceUse.recreationalDrugsUse.recreationalDrugsUseHelp],
          }),
        }),
        LivingConditions: this.fb.group({
          residenceType: [this.pSoc.LivingConditions.residenceType, Validators.required],
          stableHousing: [this.pSoc.LivingConditions.stableHousing],
          livingDuration: [this.pSoc.LivingConditions.livingDuration],
          safetyMeasures: [this.pSoc.LivingConditions.safetyMeasures],
          currentResidence: [this.pSoc.LivingConditions.currentResidence],
          livingArrangements: [this.pSoc.LivingConditions.livingArrangements],
          accessibilityFeatures: [this.pSoc.LivingConditions.accessibilityFeatures],
          otherLivingConsiderations: [this.pSoc.LivingConditions.otherLivingConsiderations],
        }),
      }),
      military_history: this.fb.group({
        enlistmentYear: ['', Validators.required],
        dischargeYear: ['', Validators.required],
        honorableDischarge: ['', Validators.required],
        serviceYears: ['', Validators.required],
        retirementYear: ['', Validators.required],
        serviceBranch: ['', Validators.required],
        CombatExperience: this.fb.group({
          CombatExperienceStatus: ['', Validators.required],
          CombatExperienceCmnts: this.fb.array([])
        }),
        veteransBenefitsEligible: ['', Validators.required],
        //MilitarySpecificSupport
        MilitarySpecificSupport: this.fb.group({
          MilitarySpecificSupportStatus: ['', Validators.required],
          MilitarySpecificSupportCmnts: this.fb.array([])
        }),
        // Add more medical history fields with validators
      }),
      medical_history: this.fb.group({
        Conditions: this.fb.array([]),
        Allergies: this.fb.array([]),
        // Add more medical history fields with validators
      }),
      symptoms: this.fb.array([]),
      medications: this.fb.array([]),
      psychological_history: this.psychologicalHistoryForm,
      psychological_military_history: this.psychologicalMitlitaryHistoryForm,
      /*psychological_historyv1: this.fb.group({
        GeneralMentalHealth: this.fb.group({
          Status: [this.psycH.GenMentalHealth.Status, Validators.required],
          MoodChange: [this.psycH.GenMentalHealth.MoodChange, Validators.required],
          Concern: [this.psycH.GenMentalHealth.Concern, Validators.required],
          Cmnts: this.fb.array([])
        }),
        MentalHealth: this.fb.group({
          Current: [this.psycH.MentalHealth.Current, Validators.required],
          Challenges: [this.psycH.MentalHealth.Challenges, Validators.required],
          Impact: [this.psycH.MentalHealth.Impact, Validators.required],
        }),
        GoalsAndExpectations: this.fb.group({
          Goal: ['', Validators.required],
          FocusArea: ['', Validators.required],
          IdealOutcome: ['', Validators.required],
        }),
        DigitalWellness: this.fb.group({
          TimeSpentOnline: ['', Validators.required],
          OnlineHabits: ['', Validators.required],
          ProCon: ['', Validators.required],
        }),
        CurrentLifeSituations: this.fb.group({
          Status: ['', Validators.required],
          Impact: ['', Validators.required],
          Challenge: ['', Validators.required],
        }),
        LifeSatisfaction: this.fb.group({
          Status: ['', Validators.required],
          Fulfilling: ['', Validators.required],
          AreaToImprovement: ['', Validators.required],
        }),
        LifestyleAndStress: this.fb.group({
          Level: ['', Validators.required],
          MainSource: ['', Validators.required],
          CopingMethod: ['', Validators.required],
        }),
        StressAndCopingMechanisms: this.fb.group({ StressAndCopingMechanismsStatus: ['', Validators.required], StressAndCopingMechanismsCmnts: this.fb.array([]), }),
        SocialSupportAndRelationships: this.fb.group({ SocialSupportAndRelationshipsStatus: ['', Validators.required], SocialSupportAndRelationshipsCmnts: this.fb.array([]), }),
        FamilyMentalHealthHistory: this.fb.group({ FamilyMentalHealthHistoryStatus: ['', Validators.required], FamilyMentalHealthHistoryCmnts: this.fb.array([]), }),
        PastPsychiatricTreatment: this.fb.group({ PastPsychiatricTreatmentStatus: ['', Validators.required], PastPsychiatricTreatmentCmnts: this.fb.array([]) }),
        PsychologicalTreatmentHistory: this.fb.group({ PsychologicalTreatmentHistoryStatus: ['', Validators.required], PsychologicalTreatmentHistoryCmnts: this.fb.array([]) }),

        Military: this.fb.group({
          MilitaryServiceRelatedIssues: this.fb.group({ MilitaryServiceRelatedIssuesStatus: ['', Validators.required], MilitaryServiceRelatedIssuesCmnts: this.fb.array([]) }),
          PTSDandTrauma: this.fb.group({ PTSDandTraumaStatus: ['', Validators.required], PTSDandTraumaCmnts: this.fb.array([]) }),
          AdjustmentPostService: this.fb.group({ AdjustmentPostServiceStatus: ['', Validators.required], AdjustmentPostServiceCmnts: this.fb.array([]) }),
          ReintegrationChallenges: this.fb.group({ ReintegrationChallengesCmnts: this.fb.array([]) }),
          OccupationalAdjustment: this.fb.group({ OccupationalAdjustmentCmnts: this.fb.array([]) }),
        }),
        // Add more medical history fields with validators
      }),*/
      medical_insurances: this.fb.array([]),
    });
  }

  get eChildren_() { return this.patientForm.get('social_history.eChildren') as FormArray; }
  get eContacts_() { return this.patientForm.get('social_history.eContacts') as FormArray; }
  //get MilitarySrvcCmnts_() { return this.patientForm.get('social_history.MilitarySrvc.MilitarySrvcCmnts') as FormArray; }

  get CombatExperienceCmnts_() { return this.patientForm.get('military_history.CombatExperience.CombatExperienceCmnts') as FormArray; }
  get MilitarySpecificSupportCmnts_() { return this.patientForm.get('military_history.MilitarySpecificSupport.MilitarySpecificSupportCmnts') as FormArray; }
  get GeneralMentalHealthCmnts_() { return this.patientForm.get('psychological_history.GeneralMentalHealth.GeneralMentalHealthCmnts') as FormArray; }
  get MentalHealthStatusCmnts_() { return this.patientForm.get('psychological_history.MentalHealth.MentalHealthStatusCmnts') as FormArray; }
  get MilitaryServiceRelatedIssuesCmnts_() { return this.patientForm.get('psychological_history.MilitaryServiceRelatedIssues.MilitaryServiceRelatedIssuesCmnts') as FormArray; }
  get PTSDandTraumaCmnts_() { return this.patientForm.get('psychological_history.PTSDandTrauma.PTSDandTraumaCmnts') as FormArray; }
  get ReintegrationChallengesCmnts_() { return this.patientForm.get('psychological_history.ReintegrationChallenges.ReintegrationChallengesCmnts') as FormArray; }
  get PastPsychiatricTreatmentCmnts_() { return this.patientForm.get('psychological_history.PastPsychiatricTreatment.PastPsychiatricTreatmentCmnts') as FormArray; }
  get PsychologicalTreatmentHistoryCmnts_() { return this.patientForm.get('psychological_history.PsychologicalTreatmentHistory.PsychologicalTreatmentHistoryCmnts') as FormArray; }
  get AdjustmentPostServiceCmnts_() { return this.patientForm.get('psychological_history.AdjustmentPostService.AdjustmentPostServiceCmnts') as FormArray; }
  get CurrentLifeSituationsCmnts_() { return this.patientForm.get('psychological_history.CurrentLifeSituations.CurrentLifeSituationsCmnts') as FormArray; }
  get LifestyleAndStressCmnts_() { return this.patientForm.get('psychological_history.LifestyleAndStress.LifestyleAndStressCmnts') as FormArray; }
  get StressAndCopingMechanismsCmnts_() { return this.patientForm.get('psychological_history.StressAndCopingMechanisms.StressAndCopingMechanismsCmnts') as FormArray; }
  get SocialSupportAndRelationshipsCmnts_() { return this.patientForm.get('psychological_history.SocialSupportAndRelationships.SocialSupportAndRelationshipsCmnts') as FormArray; }
  get SubstanceUseCmnts_() { return this.patientForm.get('psychological_history.SubstanceUse.SubstanceUseCmnts') as FormArray; }
  get FamilyMentalHealthHistoryCmnts_() { return this.patientForm.get('psychological_history.FamilyMentalHealthHistory.FamilyMentalHealthHistoryCmnts') as FormArray; }
  get DigitalWellnessCmnts_() { return this.patientForm.get('psychological_history.DigitalWellness.DigitalWellnessCmnts') as FormArray; }
  get OccupationalAdjustmentCmnts_() { return this.patientForm.get('psychological_history.OccupationalAdjustment.OccupationalAdjustmentCmnts') as FormArray; }
  get LifeSatisfactionCmnts_() { return this.patientForm.get('psychological_history.LifeSatisfaction.LifeSatisfactionCmnts') as FormArray; }
  get GoalsAndExpectationsCmnts_() { return this.patientForm.get('psychological_history.GoalsAndExpectations.GoalsAndExpectationsCmnts') as FormArray; }

  get Conditions_() { return this.patientForm.get('medical_history.Conditions') as FormArray; }
  get Allergies_() { return this.patientForm.get('medical_history.Allergies') as FormArray; }
  get Symptoms_() { return this.patientForm.get('symptoms') as FormArray; }
  get medications() { return this.patientForm.get('medications') as FormArray; }
  get medicalInsurances() { return this.patientForm.get('medical_insurances') as FormArray; }

  add_eChildren() {
    const l_ = this.eChildren_.length; console.log(l_);
    this.eChildren_.push(this.fb.group({
      ['eChild_Name' + l_]: ['', Validators.required],
      ['eChild_Relationship' + l_]: ['', Validators.required],
      ['eChild_cP' + l_]: [''],
      ['eChild_E' + l_]: [''],
      ['eChild_Note' + l_]: [''],
    }));
  }

  add_eContact() {
    const l_ = this.eContacts_.length; console.log(l_);
    this.eContacts_.push(this.fb.group({
      ['ec_Name' + l_]: ['', Validators.required],
      ['ec_Relationship' + l_]: ['', Validators.required],
      ['ec_cP' + l_]: ['', Validators.required],
      ['ec_E' + l_]: ['', Validators.required],
      ['ec_hP' + l_]: [''],
      ['ec_wP' + l_]: [''],
      ['ec_pmoc' + l_]: ['', Validators.required]
    }));
  }

  addCondition() {
    const l_ = this.Conditions_.length; console.log(l_);
    this.Conditions_.push(this.fb.group({
      ['Condition' + l_]: ['', Validators.required],
      ['DiagnosisDate' + l_]: ['', Validators.required],
      ['TreatmentHistory' + l_]: ['', Validators.required],
      ['Complication' + l_]: ['', Validators.required],
    }));
  }

  addMedication() {
    const l_ = this.medications.length; console.log(l_);
    this.medications.push(this.fb.group({
      ['medName' + l_]: ['', Validators.required],
      ['medDosage' + l_]: ['', Validators.required],
      ['medFrequency' + l_]: ['', Validators.required],
      ['medPurpose' + l_]: ['', Validators.required],
      ['medStartDate' + l_]: ['', Validators.required],
      ['medEndDate' + l_]: ['', Validators.required],
      ['medSideEffects' + l_]: ['', Validators.required],
      ['medAdherenceLevel' + l_]: ['', Validators.required],
      ['medReasonsNonAdherence' + l_]: ['', Validators.required],
    }));
  }
  addAllergy() {
    const l_ = this.Allergies_.length; console.log(l_);
    this.Allergies_.push(this.fb.group({
      ['Allergy' + l_]: ['', Validators.required],
      ['OnsetDate' + l_]: ['', Validators.required],
      ['Reaction' + l_]: ['', Validators.required],
      ['ReactionSeverity' + l_]: ['', Validators.required],
    }));
  }
  addSymptom() {
    const l_ = this.Symptoms_.length; console.log(l_);
    this.Symptoms_.push(this.fb.group({
      ['sympName' + l_]: ['', Validators.required],
      ['sympOnsetDate' + l_]: ['', Validators.required],
      ['sympDurationVal' + l_]: ['', Validators.required],
      ['sympDurationUnit' + l_]: ['', Validators.required],
      ['sympSeverity' + l_]: ['', Validators.required],
      ['sympFrequencyTime' + l_]: ['', Validators.required],
      ['sympFrequencyPeriod' + l_]: ['', Validators.required],
      ['sympTriggers' + l_]: ['', Validators.required],
      ['sympRelievingFactors' + l_]: ['', Validators.required],
      ['sympAssociatedSymptoms' + l_]: ['', Validators.required],
      ['sympLocationBodyPart' + l_]: ['', Validators.required],
      ['sympLocationLaterality' + l_]: ['', Validators.required],
      ['sympCharacter' + l_]: ['', Validators.required],
      ['sympSeverity' + l_]: ['', Validators.required],
      ['sympProgression' + l_]: ['', Validators.required],
      ['sympImpactOnDailyActivities' + l_]: ['', Validators.required],
    }));
  }

  addGeneralMentalHealthCmnt() { this.GeneralMentalHealthCmnts_.push(this.fb.group({ ['GeneralMentalHealthCmnt' + this.GeneralMentalHealthCmnts_.length]: ['', Validators.required], })); }
  addMentalHealthStatusCmnt() { this.MentalHealthStatusCmnts_.push(this.fb.group({ ['MentalHealthStatusCmnt' + this.MentalHealthStatusCmnts_.length]: ['', Validators.required], })); }
  addMilitaryServiceRelatedIssuesCmnt() { this.MilitaryServiceRelatedIssuesCmnts_.push(this.fb.group({ ['MilitaryServiceRelatedIssuesCmnt' + this.MilitaryServiceRelatedIssuesCmnts_.length]: ['', Validators.required], })); }
  addCombatExperienceCmnt() { this.CombatExperienceCmnts_.push(this.fb.group({ ['CombatExperienceCmnt' + this.CombatExperienceCmnts_.length]: ['', Validators.required], })); }
  addPTSDandTraumaCmnt() { this.PTSDandTraumaCmnts_.push(this.fb.group({ ['PTSDandTraumaCmnt' + this.PTSDandTraumaCmnts_.length]: ['', Validators.required], })); }
  addReintegrationChallengesCmnt() { this.ReintegrationChallengesCmnts_.push(this.fb.group({ ['ReintegrationChallengesCmnt' + this.ReintegrationChallengesCmnts_.length]: ['', Validators.required], })); }
  addPastPsychiatricTreatmentCmnt() { this.PastPsychiatricTreatmentCmnts_.push(this.fb.group({ ['PastPsychiatricTreatmentCmnt' + this.PastPsychiatricTreatmentCmnts_.length]: ['', Validators.required], })); }
  addPsychologicalTreatmentHistoryCmnt() { this.PsychologicalTreatmentHistoryCmnts_.push(this.fb.group({ ['PsychologicalTreatmentHistoryCmnt' + this.PsychologicalTreatmentHistoryCmnts_.length]: ['', Validators.required], })); }
  addAdjustmentPostServiceCmnt() { this.AdjustmentPostServiceCmnts_.push(this.fb.group({ ['AdjustmentPostServiceCmnt' + this.AdjustmentPostServiceCmnts_.length]: ['', Validators.required], })); }
  addCurrentLifeSituationsCmnt() { this.CurrentLifeSituationsCmnts_.push(this.fb.group({ ['CurrentLifeSituationsCmnt' + this.CurrentLifeSituationsCmnts_.length]: ['', Validators.required], })); }
  addLifestyleAndStressCmnt() { this.LifestyleAndStressCmnts_.push(this.fb.group({ ['LifestyleAndStressCmnt' + this.LifestyleAndStressCmnts_.length]: ['', Validators.required], })); }
  addStressAndCopingMechanismsCmnt() { this.StressAndCopingMechanismsCmnts_.push(this.fb.group({ ['StressAndCopingMechanismsCmnt' + this.StressAndCopingMechanismsCmnts_.length]: ['', Validators.required], })); }
  addSocialSupportAndRelationshipsCmnt() { this.SocialSupportAndRelationshipsCmnts_.push(this.fb.group({ ['SocialSupportAndRelationshipsCmnt' + this.SocialSupportAndRelationshipsCmnts_.length]: ['', Validators.required], })); }
  addSubstanceUseCmnt() { this.SubstanceUseCmnts_.push(this.fb.group({ ['SubstanceUseCmnt' + this.SubstanceUseCmnts_.length]: ['', Validators.required], })); }
  addFamilyMentalHealthHistoryCmnt() { this.FamilyMentalHealthHistoryCmnts_.push(this.fb.group({ ['FamilyMentalHealthHistoryCmnt' + this.FamilyMentalHealthHistoryCmnts_.length]: ['', Validators.required], })); }
  addDigitalWellnessCmnt() { this.DigitalWellnessCmnts_.push(this.fb.group({ ['DigitalWellnessCmnt' + this.DigitalWellnessCmnts_.length]: ['', Validators.required], })); }
  addLifeSatisfactionCmnt() { this.LifeSatisfactionCmnts_.push(this.fb.group({ ['LifeSatisfactionCmnt' + this.LifeSatisfactionCmnts_.length]: ['', Validators.required], })); }
  addOccupationalAdjustmentCmnt() { this.OccupationalAdjustmentCmnts_.push(this.fb.group({ ['OccupationalAdjustmentCmnt' + this.OccupationalAdjustmentCmnts_.length]: ['', Validators.required], })); }
  addGoalsAndExpectationsCmnt() { this.GoalsAndExpectationsCmnts_.push(this.fb.group({ ['GoalsAndExpectationsCmnt' + this.GoalsAndExpectationsCmnts_.length]: ['', Validators.required], })); }

  /*
  addMilitarySrvcCmnt() {
    const l_ = this.MilitarySrvcCmnts_.length; console.log(l_);
    this.MilitarySrvcCmnts_.push(this.fb.group({
      ['MilitarySrvcCmnt' + l_]: ['', Validators.required],
    }));
  }*/
  addMedicalInsurance() {
    const l_ = this.medicalInsurances.length; console.log(l_);
    this.medicalInsurances.push(this.fb.group({
      ['Type' + l_]: ['', Validators.required],
      ['Provider' + l_]: ['', Validators.required],
      ['Policy_Number' + l_]: ['', Validators.required],
    }));
  }

  modifyKeys(data: any): any {
    const modifiedData: any = {};
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        modifiedData[key] = data[key].map((item: any) => this.modifyKeys(item));
      } else if (typeof data[key] === 'object') {
        modifiedData[key] = this.modifyKeys(data[key]);
      } else {
        const match = key.match(/(\d+)$/);
        const newKey = match ? key.replace(match[0], '') : key;
        modifiedData[newKey] = data[key];
      }
    });
    return modifiedData;
  }

  updatePForm() {
    console.log("Update patientForm: ", this.patientForm.value);
    const patientData = this.patientForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    this.aSrvc.patientUD(transformedPatientData).subscribe(
      (result) => { console.log(result); }
    );
  }
  submitForm() {
    console.log(this.patientForm.value);
    const patientData = this.patientForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    if (this.patientForm.valid) {
      const patientData = this.patientForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
  }

  submitFormOrg() {
    console.log(this.patientForm.value);
    const patientData = this.patientForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    /**
     * 
    if (this.patientForm.valid) {
      const patientData = this.patientForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
     * 
    */
  }

  predictedICD10Codes: string[] = [];
  predictICD10() {
    const patientData = this.patientForm.value;
    // Call the service to predict the ICD-10 codes
    this.aSrvc.predictICD10(patientData).subscribe(
      (codes) => {
        this.predictedICD10Codes = codes;
      },
      (error) => {
        console.error('Error predicting ICD-10 codes:', error);
      }
    );
  }
  confirmCode(code: string) {
    // Add logic to handle the confirmed code
    console.log('Confirmed code:', code);
    // Possibly add the code to a list of confirmed codes
  }

}
