<div class="container-fluid p-1"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <form id="eForm" class="row g-1 tab-content p-2" [formGroup]="pForm" (ngSubmit)="updatePForm()" *ngIf="patientRdy">
        <div class="col-md-12 text-end">
            <button title="Save Patient History" type="submit" class="btn btn-success me-2"
                style="padding: .125rem;"><i class="bi bi-save"></i>Save</button>
        </div>
                <!-- Social History -->
        <div class="row editGrpDiv" formGroupName="social">
            <h5 class="col-md-12">Social History:</h5>
            <div class="row editGrpDiv">
                <div class="col-md-4">
                    <label for="preferredLanguage">Preferred Language</label>
                    <select class="form-select" id="preferredLanguage" formControlName="preferredLanguage">
                        <option value="">Select Languages</option>
                        <option [value]="pl_" *ngFor="let pl_ of cnfg.preferredLanguages">
                            {{ pl_ }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="religiousAffiliation">Religious Affiliation</label>
                    <select class="form-select" id="religiousAffiliation" formControlName="religiousAffiliation">
                        <option value="">Select Religious Affiliation</option>
                        <option [value]="ra_.status" *ngFor="let ra_ of cnfg.religiousAffiliationOptions">{{ ra_.status
                            }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="EducationSelect">Educational Level</label>
                    <select class="form-select" id="EducationSelect" formControlName="education">
                        <option value="">Select Education Level</option>
                        <option [value]="el_.status" *ngFor="let el_ of cnfg.educationOptions">
                            {{ el_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="Marital_StatusSelect">Marital Status</label>
                    <select class="form-select" id="Marital_StatusSelect" formControlName="maritalStatus">
                        <option value="">Select Marital Status</option>
                        <option [value]="ms_.Opt" *ngFor="let ms_ of cnfg.maritalStatusOptions">{{ ms_.Opt }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="socialSupport">Social Support</label>
                    <select class="form-select" id="socialSupport" formArrayName="socialSupport">
                        <option value="">Select Your Social Support</option>
                        <option [value]="ss_.status" *ngFor="let ss_ of cnfg.socialSupportOptions">
                            {{ ss_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="occupation">Occupation</label>
                    <select class="form-select" id="occupation" formControlName="occupation">
                        <option value="">Select Occupation</option>
                        <option [value]="o_.status" *ngFor="let o_ of cnfg.occupationOptions">
                            {{ o_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                    <label for="militarySrvc">Enlistment Status</label>
                    <select class="form-select" id="militarySrvc" formControlName="MilitarySrvcStatus"
                        (change)="onEnlistmentStatusChange($event)">
                        <option value="">Select Enlistment Status</option>
                        <option [value]="opt_" *ngFor="let opt_ of milCnfg.MilitaryServiceStatus.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- Children -->
            <div class="editGrpDiv" formArrayName="eChildren">
                <h5>Children:</h5>
                <button type="button" class="btn i" title="Add Child" (click)="add_eChildren()"><i
                        class="bi bi-plus-square"></i></button>
                <div *ngFor="let child of eChildren_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-6">
                            <label [for]="'eChild_Name' + i" class="form-label">Name</label>
                            <input type="text" [id]="'eChild_Name' + i" class="form-control"
                                [formControlName]="'eChild_Name' + i">
                        </div>
                        <div class="col-md-3">
                            <label [for]="'eChild_Relationship' + i" class="form-label">Relationship</label>
                            <select class="form-select" [id]="'eChild_Relationship' + i"
                                [formControlName]="'eChild_Relationship' + i">
                                <option value="">Select Relationship</option>
                                <option [value]="rel_.value" *ngFor="let rel_ of cnfg.relationships.options">{{
                                    rel_.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label [for]="'eChild_cP' + i" class="form-label">Cell Phone</label>
                            <input type="phone" [id]="'eChild_cP' + i" class="form-control"
                                [formControlName]="'eChild_cP' + i">
                        </div>
                        <div class="col-md-6">
                            <label [for]="'eChild_E' + i" class="form-label">Email</label>
                            <input type="email" [id]="'eChild_E' + i" class="form-control"
                                [formControlName]="'eChild_E' + i">
                        </div>
                        <div class="col-md-6">
                            <label [for]="'eChild_Note' + i" class="form-label">Note:</label>
                            <textarea class="form-control textarea" [id]="'eChild_Note' + i"
                                [formControlName]="'eChild_Note' + i"></textarea>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Emergency Contacts -->
            <div class="editGrpDiv" formArrayName="eContacts">
                <h5>Emergency Contacts:</h5>
                <button type="button" class="btn i" title="Add Emergency Contact" (click)="add_eContact()"><i
                        class="bi bi-plus-square"></i></button>
                <div *ngFor="let contact of eContacts_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-8">
                            <label for="ec_N" class="form-label">Name</label>
                            <input type="text" id="ec_N" class="form-control" [formControlName]="'ec_Name' + i">
                        </div>
                        <div class="col-md-4">
                            <label for="ec_Relationship" class="form-label">Relationship</label>
                            <select class="form-select" id="ec_Relationship" [formControlName]="'ec_Relationship' + i">
                                <option value="">Select Relationship</option>
                                <option [value]="rel_.value" *ngFor="let rel_ of cnfg.relationships.options">{{
                                    rel_.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="ec_E" class="form-label">Email</label>
                            <input type="email" id="ec_E" class="form-control" [formControlName]="'ec_E' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_cP" class="form-label">Cell Phone</label>
                            <input type="phone" id="ec_cP" class="form-control" [formControlName]="'ec_cP' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_hP" class="form-label">Home Phone</label>
                            <input type="phone" id="ec_hP" class="form-control" [formControlName]="'ec_hP' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_wP" class="form-label">Work Phone</label>
                            <input type="phone" id="ec_wP" class="form-control" [formControlName]="'ec_wP' + i">
                        </div>
                        <div class="col-md-12 row"><span>Preferred Method of Contact</span>
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input class="btn-check" type="radio" [name]="'ec_pmoc' + i" [id]="'ec_pmocCP' + i"
                                    value="cp" [formControlName]="'ec_pmoc' + i">
                                <label class="btn btn-outline-primary" [for]="'ec_pmocCP' + i">Cell Phone </label>
                                <input class="btn-check" type="radio" [name]="'ec_pmoc' + i" [id]="'ec_pmocE' + i"
                                    value="e" [formControlName]="'ec_pmoc' + i">
                                <label class="btn btn-outline-primary" [for]="'ec_pmocE' + i">Email </label>
                                <input class="btn-check" type="radio" [name]="'ec_pmoc' + i" [id]="'ec_pmocHP' + i"
                                    value="hp" [formControlName]="'ec_pmoc' + i">
                                <label class="btn btn-outline-primary" [for]="'ec_pmocHP' + i">Home Phone </label>
                                <input class="btn-check" type="radio" [name]="'ec_pmoc' + i" [id]="'ec_pmocWP' + i"
                                    value="wp" [formControlName]="'ec_pmoc' + i">
                                <label class="btn btn-outline-primary" [for]="'ec_pmocWP' + i">Work Phone </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div formGroupName="LivingConditions" class="row editGrpDiv">
                <h5 style="width:100%;">Living Conditions:</h5>
                <div class="col-md-6" *ngFor="let t_ of socialCnfg.socialHistory[1].questions">
                    <label for="{{t_.type}}">{{t_.question}}</label>
                    <select class="form-select" id="{{t_.type}}" formControlName={{t_.type}}>
                        <option value="">Select</option>
                        <option [value]="opt_" *ngFor="let opt_ of t_.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
            </div>
            <div formArrayName="substanceUse" class="row editGrpDiv">
                <h5 style="width:100%;">{{socialCnfg.socialHistory[2].substanceUse.category}}:</h5>
                <div *ngIf="substanceUseInfo">
                    <div class="row editAIDiv" *ngFor="let comment of encntr.history.social.SubstanceUse">
                        <div class="col-md-6">{{comment.q}}</div>
                        <div class="col-md-6">{{comment.r}}</div>
                    </div>
                </div>

                <div class="col-md-4 editNestGrpDiv"
                    *ngFor="let t_ of socialCnfg.socialHistory[2].substanceUse.questions" formArrayName="{{t_.type}}">
                    <label for="{{t_.type}}">{{t_.question}}</label>
                    <select class="form-select" id="{{t_.type}}" formControlName={{t_.status}}>
                        <option value="">Select</option>
                        <option [value]="opt_" *ngFor="let opt_ of t_.options">
                            {{ opt_ }}
                        </option>
                    </select>
                    <!--div formGroupName="followUp" *ngIf="t_.followUp"!-->
                    <div *ngFor="let fup_ of t_.followUp">
                        <label for="{{fup_.type}}">{{fup_.question}}</label>
                        <select class="form-select" id="{{fup_.type}}" formControlName={{fup_.type}}>
                            <option value="">Select</option>
                            <option [value]="opt_" *ngFor="let opt_ of fup_.options">
                                {{ opt_ }}
                            </option>
                        </select>
                    </div>
                    <!--/div!-->
                </div>
            </div>
            <div class="row editGrpDiv" formGroupName="militaryHistory" *ngIf="isVeteran">
                <h5 class="col-md-12" style=" width:100%;">Military History:</h5>
                <div class="col-md-2">
                    <label for="A" class="form-label">Enlistment Year</label>
                    <input type="text" class="form-control" id="enlistmentYear" name="enlistmentYear"
                        formControlName="enlistmentYear">
                </div>
                <div class="col-md-2">
                    <label for="serviceBranch">Service Branch</label>
                    <select class="form-select" id="serviceBranch" formControlName="serviceBranch">
                        <option value="">Select Service Branch</option>
                        <option [value]="opt_" *ngFor="let opt_ of milCnfg.serviceBranch.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label for="retirementYear" class="form-label">Retirement Year</label>
                    <input type="text" class="form-control" id="retirementYear" name="retirementYear"
                        formControlName="retirementYear">
                    <!--app-year-picker (yearSelected)="onYearSelected($event)"></app-year-picker>
                <p>Selected Year:{{ selectedYear }}</p!-->
                </div>
                <div class="col-md-2">
                    <label for="dischargeYear" class="form-label">Discharge Year</label>
                    <input type="text" class="form-control" id="dischargeYear" name="dischargeYear"
                        formControlName="dischargeYear">
                </div>
                <div class="col-md-4 row"><span>Honorable Discharge</span>
                    <div class="col-md-6">
                        <div class="form-check">
                            <label class="form-control" for="honorableDischargeYes">YES <input class="form-label"
                                    type="radio" name="honorableDischarge" id="honorableDischargeYes" value="YES"
                                    formControlName="honorableDischarge"></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check">
                            <label class="form-control" for="honorableDischargeNO">NO <input class="form-label"
                                    type="radio" name="honorableDischarge" id="honorableDischargeNO" value="NO"
                                    formControlName="honorableDischarge"></label>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="A" class="form-label">Service Years</label>
                    <input type="text" class="form-control" id="serviceYears" name="serviceYears"
                        formControlName="serviceYears">
                </div>
                <div class="editGrpDiv" formArrayName="CombatExperience">
                    <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                        <label for="combatExp">Combat Experience</label>
                        <select class="form-select" id="combatExp" formControlName="Status">
                            <option value="">Select Experience</option>
                            <option [value]="opt_" *ngFor="let opt_ of spCnfg.CombatExperience.options">
                                {{ opt_ }}
                            </option>
                        </select>
                    </div>
                    <div class="row col-md-8" style="display:inline-block;vertical-align:top;" formArrayName="Cmnts">
                        <div class="col-md-4"><!--h5>Comments:</h5!-->
                            <button type="button" class="btn i" title="Add A Comment" style="width:fit-content;"
                                (click)="addCombatExperienceCmnt()"><i class="bi bi-plus-square"></i></button>
                        </div>
                        <div *ngFor="let Cmnts of CombatExperienceCmnts_?.controls; let i = index">
                            <div [formGroupName]="i" class="row editGrpDiv">
                                <div class="col-md-12">
                                    <label [for]="'Cmnts' + i" class="form-label">Comment:</label>
                                    <textarea class="form-control textarea" [id]="'Cmnts' + i"
                                        [formControlName]="'Cmnts' + i"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 row"><span>Veterans Benefits Eligible</span>
                    <div class="col-md-6">
                        <div class="form-check">
                            <label class="form-control" for="veteransBenefitsEligibleYes">YES <input class="form-label"
                                    type="radio" name="veteransBenefitsEligible" id="veteransBenefitsEligibleYes"
                                    value="YES" formControlName="veteransBenefitsEligible"></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check">
                            <label class="form-control" for="veteransBenefitsEligibleNO">NO <input class="form-label"
                                    type="radio" name="veteransBenefitsEligible" id="veteransBenefitsEligibleNO"
                                    value="NO" formControlName="veteransBenefitsEligible"></label>
                        </div>
                    </div>
                </div>
                <!--div class="editGrpDiv" formArrayName="MilitarySpecificSupport">
                <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                    <label for="militarySpecificSupport">use of military-specific support services</label>
                    <select class="form-select" id="militarySpecificSupport"
                        formControlName="MilitarySpecificSupportStatus">
                        <option value="">Select Use Of Support Services Status</option>
                        <option [value]="opt_" *ngFor="let opt_ of ppCnfg.MilitarySpecificSupport.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
                <div class="row col-md-8" style="display:inline-block;vertical-align:top;"
                    formArrayName="MilitarySpecificSupportCmnts">
                    <div class="col-md-4">
                        <button type="button" class="btn i" title="Add A Comment" style="width:fit-content;"
                            (click)="addMilitarySpecificSupportCmnt()">Add A Comment</button>
                    </div>
                    <div
                        *ngFor="let MilitarySpecificSupportCmnt of MilitarySpecificSupportCmnts_?.controls; let i = index">
                        <div [formGroupName]="i" class="row editGrpDiv">
                            <div class="col-md-12">
                                <label [for]="'MilitarySpecificSupportCmnt' + i" class="form-label">Comment:</label>
                                <textarea class="form-control textarea" [id]="'MilitarySpecificSupportCmnt' + i"
                                    [formControlName]="'MilitarySpecificSupportCmnt' + i"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div!-->
            </div>
        </div>
        <div class="row editGrpDiv" formGroupName="psychological">
            <h4 class="col-md-12" style=" width:100%;">Psychological History:</h4>
            <div class="row editGrpDiv" formGroupName="general">
                <h4 class="col-md-12" style=" width:100%;">General:</h4>
                <div class="editGrpDiv" *ngFor="let sel_ of psyCnfg; let i = index" [formArrayName]="sel_.T">
                    <h4>{{ sel_.D }}</h4>
                    <div class="col-md-12" style="display:inline-block;vertical-align:top;"
                        *ngFor="let q_ of sel_.Qs; let q = index">
                        <label [attr.for]="q_.C">{{ q_.Q }}</label>
                        <select class="form-select" [id]="q_.C" [formControlName]="q_.C">
                            <option value="">{{q_.L}}</option>
                            <option *ngFor="let o_ of q_.O" [value]="o_.v">
                                {{ o_.d }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row editGrpDiv" formGroupName="military">
                <h4 class="col-md-12" style=" width:100%;">Military - Veteran:</h4>
                <div class="editGrpDiv" *ngFor="let sel_ of psyMVCnfg; let i = index" [formArrayName]="sel_.T">
                    <h4>{{ sel_.D }}</h4>
                    <div class="col-md-12" style="display:inline-block;vertical-align:top;"
                        *ngFor="let q_ of sel_.Qs; let q = index">
                        <label [attr.for]="q_.C">{{ q_.Q }}</label>
                        <select class="form-select" [id]="q_.C" [formControlName]="q_.C">
                            <option value="">{{q_.L}}</option>
                            <option *ngFor="let o_ of q_.O" [value]="o_.v">
                                {{ o_.d }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <!-- Medical History -->
        <div class="editGrpDiv row" formGroupName="medical">
            <h5 class="col-md-12">Medical History:</h5>
            <div class="editGrpDiv" formArrayName="Conditions">
                <h5>Chronic Medical Conditions:</h5>
                <button type="button" class="btn i" title="Add Condition" (click)="addCondition()"><i
                        class="bi bi-plus-square"></i></button>
                <div *ngFor="let Condition of Conditions_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-3">
                            <label for="condition" class="form-label">Condition:</label>
                            <input type="text" class="form-control" id="condition"
                                [formControlName]="'Condition' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="TreatmentHistory" class="form-label">Treatment History:</label>
                            <textarea class="form-control textarea" id="TreatmentHistory"
                                [formControlName]="'TreatmentHistory' + i"></textarea>
                        </div>
                        <div class="col-md-3">
                            <label for="DiagnosisDate" class="form-label">Date of diagnosis:</label>
                            <input type="date" class="form-control" id="DiagnosisDate"
                                [formControlName]="'DiagnosisDate' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="Complication" class="form-label">Complications:</label>
                            <textarea class="form-control textarea" id="Complication"
                                [formControlName]="'Complication' + i"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="editGrpDiv" formArrayName="Allergies">
                <h5>Allergies:</h5>
                <button type="button" class="btn i" title="Add Allergy" (click)="addAllergy()"><i
                        class="bi bi-plus-square"></i></button>
                <div *ngFor="let Allergy of Allergies_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-3">
                            <label for="allergy" class="form-label">Allergy:</label>
                            <input type="text" class="form-control" id="allergy" [formControlName]="'Allergy' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="Reaction" class="form-label">Reaction:</label>
                            <textarea class="form-control textarea" id="Reaction"
                                [formControlName]="'Reaction' + i"></textarea>
                        </div>
                        <div class="col-md-3">
                            <label for="OnsetDate" class="form-label">Date of Onset:</label>
                            <input type="date" class="form-control" id="OnsetDate"
                                [formControlName]="'OnsetDate' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="ReactionSeverity" class="form-label">Reaction Severity:</label>
                            <textarea class="form-control textarea" id="ReactionSeverity"
                                [formControlName]="'ReactionSeverity' + i"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="editGrpDiv" formArrayName="symptoms"><!-- Symptoms -->
                <h5>Symptoms:</h5>
                <button type="button" class="btn i" title="Add Symptom" (click)="addSymptom()"><i class="bi bi-plus-square"></i></button>
                <div *ngFor="let Symptom of Symptoms_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-3">
                            <label [for]="'sympName' + i" class="form-label">Symptom Name:</label>
                            <input type="text" class="form-control" [id]="'sympName' + i"
                                [formControlName]="'sympName' + i" />
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympOnsetDate' + i" class="form-label">First Noticed /Onset Date:</label>
                            <input type="date" class="form-control" [id]="'sympOnsetDate' + i"
                                [formControlName]="'sympOnsetDate' + i" />
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympSeverity' + i">Severity</label>
                            <select class="form-select" [id]="'sympSeverity' + i"
                                [formControlName]="'sympSeverity' + i">
                                <option value="">Select Severity</option>
                                <option [value]="p_" *ngFor="let p_ of sympCnfg.severity">
                                    {{ p_ }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3">
                            <label [for]="'sympDurationVal' + i" class="form-label">Duration:</label>
                            <input type="text" class="form-control" [id]="'sympDurationVal' + i"
                                [formControlName]="'sympDurationVal' + i" />
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympDurationUnit' + i">Duration Unit</label>
                            <select class="form-select" [id]="'sympDurationUnit' + i"
                                [formControlName]="'sympDurationUnit' + i">
                                <option value="">Select Duration Unit</option>
                                <option [value]="unit" *ngFor="let unit of sympCnfg.duration.unit">
                                    {{ unit }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympFrequencyTime' + i" class="form-label"
                                title="Number of times the symptom occurs">Frequency:</label>
                            <input type="text" class="form-control" [id]="'sympFrequencyTime' + i"
                                [formControlName]="'sympFrequencyTime' + i" />
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympFrequencyPeriod' + i">Frequency Period</label>
                            <select class="form-select" [id]="'sympFrequencyPeriod' + i"
                                [formControlName]="'sympFrequencyPeriod' + i">
                                <option value="">Select Frequency Period</option>
                                <option [value]="f_" *ngFor="let f_ of sympCnfg.frequency.period">
                                    {{ f_ }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympLocationBodyPart' + i" class="form-label"
                                title="Specific body part or area where the symptom is located">Location On
                                Body:</label>
                            <input type="text" class="form-control" [id]="'sympLocationBodyPart' + i"
                                [formControlName]="'sympLocationBodyPart' + i" />
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympLocationLaterality' + i">Lateral Location</label>
                            <select class="form-select" [id]="'sympLocationLaterality' + i"
                                [formControlName]="'sympLocationLaterality' + i">
                                <option value="">Select Lateral Location</option>
                                <option [value]="ll_" *ngFor="let ll_ of sympCnfg.location.laterality">
                                    {{ ll_ }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label [for]="'sympProgression' + i">Progression</label>
                            <select class="form-select" [id]="'sympProgression' + i"
                                [formControlName]="'sympProgression' + i">
                                <option value="">Select Progression</option>
                                <option [value]="p_" *ngFor="let p_ of sympCnfg.progression">
                                    {{ p_ }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="sympImpactOnDailyActivities">Impact On Daily Activities</label>
                            <select class="form-select" [id]="'sympImpactOnDailyActivities' + i"
                                [formControlName]="'sympImpactOnDailyActivities' + i">
                                <option value="">Select Impact</option>
                                <option [value]="ida_" *ngFor="let ida_ of sympCnfg.impactOnDailyActivities">
                                    {{ ida_ }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <label [for]="'sympTriggers' + i" class="form-label">Description of any known triggers or
                                aggravating factors:</label>
                            <textarea class="form-control textarea" [id]="'sympTriggers' + i"
                                [formControlName]="'sympTriggers' + i"></textarea>
                        </div>
                        <div class="col-md-6">
                            <label [for]="'sympRelievingFactors' + i" class="form-label">Description of any known
                                relieving
                                factors or actions:</label>
                            <textarea class="form-control textarea" [id]="'sympRelievingFactors' + i"
                                [formControlName]="'sympRelievingFactors' + i"></textarea>
                        </div>
                        <div class="col-md-6">
                            <label [for]="'sympAssociatedSymptoms' + i" class="form-label">List of any associated
                                symptoms:</label>
                            <textarea class="form-control textarea" [id]="'sympAssociatedSymptoms' + i"
                                [formControlName]="'sympAssociatedSymptoms' + i"></textarea>
                        </div>
                        <div class="col-md-6">
                            <label [for]="'sympCharacter' + i" class="form-label">Additional descriptive characteristics
                                of
                                the symptom (e.g., sharp, dull, throbbing)":</label>
                            <textarea class="form-control textarea" [id]="'sympCharacter' + i"
                                [formControlName]="'sympCharacter' + i"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Medications -->
            <div class="col-md-12 editGrpDiv" formArrayName="Medications">
                <h5>Medications:</h5>
                <button type="button" class="btn i" title="Add Medication" (click)="addMed()"><i class="bi bi-plus-square"></i></button>
                <div *ngFor="let medication of Meds_.controls; let i = index" [formGroupName]="i"
                    class="editGrpDiv row">
                    <div class="col-md-4">
                        <label for="medName{{i}}" class="form-label">Name</label>
                        <input type="text" id="medName{{i}}" class="form-control" formControlName="medName">
                    </div>
                    <div class="col-md-2">
                        <label for="medDosage{{i}}" class="form-label">Dosage</label>
                        <input type="text" id="medDosage{{i}}" class="form-control" formControlName="medDosage">
                    </div>
                    <div class="col-md-3">
                        <label for="medFrequency{{i}}">Frequency</label>
                        <select class="form-select" id="medFrequency{{i}}" formControlName="medFrequency">
                            <option value="">Select Frequency</option>
                            <option *ngFor="let o_ of frequencyOptions" [value]="o_.abbreviation">{{o_.term}}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="medRoute{{i}}">Routes Of Administration</label>
                        <select class="form-select" id="medRoute{{i}}"
                            formControlName="medRoute">
                            <option value="">Select Administration Route</option>
                            <option *ngFor="let o_ of routeOfAdministration" [value]="o_.Code">
                                {{o_.Description}}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label for="medStartDate{{i}}" class="form-label">Start Date</label>
                        <input type="date" class="form-control" id="medStartDate{{i}}" formControlName="medStartDate" />
                    </div>
                    <div class="col-md-6">
                        <label for="medEndDate{{i}}" class="form-label">End Date</label>
                        <input type="date" class="form-control" id="medEndDate{{i}}" formControlName="medEndDate" />
                    </div>
                    <div class="col-md-4">
                        <label for="medPurpose{{i}}" class="form-label">Reason for the medication:</label>
                        <textarea class="form-control textarea" id="medPurpose{{i}}" formControlName="medPurpose"></textarea>
                    </div>
                    <div class="col-md-2">
                        <label for="medClass{{i}}">Class</label>
                        <select class="form-select" id="medClass{{i}}" formControlName="medClass" (change)="changeMedClass($event)">
                            <option value="">Select Class</option>
                            <option *ngFor="let o_ of classes" [value]="o_.ClassName">
                                {{o_.ClassName}}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="medICD10CMCode{{i}}" class="form-label">ICD-10-CM Code</label>
                        <input type="text" id="medICD10CMCode{{i}}" class="form-control"
                            formControlName="medICD10CMCode">
                    </div>
                    <div class="col-md-3">
                        <label for="medNDC{{i}}" class="form-label">National Drug Code</label>
                        <input type="text" id="medNDC{{i}}" class="form-control" formControlName="medNDC">
                    </div>
                    <div class="col-md-5">
                        <label for="medSideEffects{{i}}" class="form-label">Experienced or potential
                            side effects:</label>
                        <textarea class="form-control textarea" id="medSideEffects{{i}}"
                            formControlName="medSideEffects"></textarea>
                    </div>
                    <div class="col-md-2" id="medAdherenceLeveldiv{{i}}">
                        <label for="medAdherenceLevel{{i}}">Adherence Level</label>
                        <select class="form-select" id="medAdherenceLevel{{i}}" formControlName="medAdherenceLevel">
                            <option value="">Select Level</option>
                            <option *ngFor="let o_ of medCompliance" [value]="o_">{{o_}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="medReasonsNonAdherence{{i}}" class="form-label">Reasons for not taking the medication as
                            prescribed:</label>
                        <textarea class="form-control textarea" id="medReasonsNonAdherence{{i}}"
                            formControlName="medReasonsNonAdherence"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <!-- Medical Insurances -->
        <div class="editGrpDiv" formArrayName="medical_insurances">
            <h5>Medical Insurance:</h5>
            <button type="button" class="btn i" title="Add Medical Insurance" (click)="addMedicalInsurance()"><i
                    class="bi bi-plus-square"></i></button>
            <div *ngFor="let insurance of medicalInsurances.controls; let i = index">
                <div [formGroupName]="i" class="row editGrpDiv">
                    <div class="col-md-4">
                        <label for="Type" class="form-label">Type</label>
                        <input type="text" id="Type" class="form-control" [formControlName]="'Type' + i">
                    </div>
                    <div class="col-md-4">
                        <label for="Provider" class="form-label">Provider</label>
                        <input type="text" id="Provider" class="form-control" [formControlName]="'Provider' + i">
                    </div>
                    <div class="col-md-4">
                        <label for="Policy_Number" class="form-label">Policy Number</label>
                        <input type="text" id="Policy_Number" class="form-control"
                            [formControlName]="'Policy_Number' + i">
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>