import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  errors: any = null;
  constructor(
    public router: Router,
    public fb: FormBuilder,
    public aSrvc: AuthService
  ) {
    this.registerForm = this.fb.group({
      fN: [''],
      lN: [''],
      E: [''],
      pswrd: [''],
      pswrdC: [''],
    });
  }
  onSubmit() {
    this.aSrvc.register(this.registerForm.value).subscribe(
      (result) => {console.log(result);},
      (error) => {this.errors = error.error;},
      () => {
        this.registerForm.reset();
        this.router.navigate(['login']);
      }
    );
  }
  ngOnInit() { }
}