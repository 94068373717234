import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import * as Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
//import { exporting } from 'highcharts/modules/exporting';
Highcharts3D(Highcharts);
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
//exporting(Highcharts);

@Component({
  selector: 'app-locations-page',
  templateUrl: './locations-page.component.html',
  styleUrls: ['./locations-page.component.css']
})

export class LocationsPageComponent implements OnInit {
  errors: any = null;
  isLoading: boolean = true;
  cmpy: any; locs: any; loc: any; dpts: any; dpt: any; rptDepart: any;
  stf: any;

  isArray(val: any): boolean { return typeof val === 'object'; }

  constructor(private route: ActivatedRoute, private router: Router, public uSrvc: UtilitiesService, public aSrvc: AuthService) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); this.router.navigate(['login/:id']); }
    else {
      if (stf === null) { console.log("redirect"); this.router.navigate(['login/:id']); }
      else {
        this.stf = JSON.parse(stf); console.log(this.stf);
      }
    }
  }

  showLocation(item: object) { console.log(item); }
  ngOnInit(): void {
    this.aSrvc.getCmpy(this.stf.cID).subscribe(
      (result: any) => { this.cmpy = result;},
      (error: { error: any; }) => { this.errors = error.error; console.log(this.errors); },
      () => {
        this.locs=this.cmpy.locations;console.log('location page this.cmpy:', this.cmpy,'this.locs:', this.locs);
      }
    )
  }

}
