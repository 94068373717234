<div class="container-fluid"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8" *ngIf="!isLoading">
            <h3 class="mb-1">Confirm Appointment: </h3>
            <p class="fw-normal mb-1">Time: {{Apt.Details.dateTime | date: 'medium' }}</p>
            <p class="fw-normal mb-1">Patient: {{Apt.Patient.fullName}}</p>
            <p class="fw-normal mb-1">MRN: {{Apt.Patient.pID}}</p>
            <p class="fw-normal mb-1">Date of Birth: {{Apt.Patient.DOB}}</p>
            <p class="fw-normal mb-1">Healthcare Provider: {{Apt.Details.resource}}</p>
            <p class="fw-normal mb-1">Reason For Appointment: {{Apt.Details.reason}}</p>
        </div>
        <div class="col-sm-2"></div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <a routerLink="/axAppointments/{{loc.lID}}-{{dpt.dID}}" title="Deactivate Patient"
                class="btn btn-danger" style="width: 100%;"><i class="bi bi-arrow-left-square"></i> Back To
                Appointments</a>
        </div>
        <div class="col-sm-8" *ngIf="!isLoading">
            <a title="Activate Patient Assessment" class="btn btn-success" style="width: 100%;"
                (click)="activateAx(Apt)">Activate <i class="bi bi-arrow-right-square"></i></a>
        </div>
    </div>

</div>