<div class="container-fluid"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="row">
        <div class="col-8">
            <h3>{{dpt.N}} - {{theAppts[0].Date}} - Appointments: {{theAppts.length}}</h3>
        </div>
        <div class="col-md-4 text-end">
            <div class="btn-group" role="group" aria-label="Appointment Actions">
                <button title="Refresh Today's Appointments: Confirmed Only" (click)="getCnfrmAppointments()"
                    class="btn btn-outline-success">
                    <i class="bi bi-clock-history"></i>
                </button>
                <!--button title="Refresh Today's Appointments: All" (click)="getAllAppointments()"
                    class="btn btn-success">
                    <i class="bi bi-clock"></i>
                </button!-->
            </div>
        </div>
    </div>
    <div *ngIf="theAppts.length > 0; else noAppointments">
        <table class="appointment-list table table-striped align-middle mb-0 bg-white">
            <thead class="bg-light">
                <tr>
                    <th>Time</th>
                    <th class="cntr">Status</th>
                    <th>Patient</th>
                    <th>Details</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="5"><ng-template #timeForAppointment><span class="time-for-appointment">It's
                                time!</span></ng-template></td>
                </tr>
                <tr *ngFor="let a of theAppts; let i = index">
                    <td>
                        <p class="apt-notes fw-bold mb-1">{{a.Time}}</p>
                        <div *ngIf="appointmentsStatus$ | async as statuses">
                            <div *ngFor="let status of statuses; index as i">
                                <p>Appointment {{ i + 1 }}: <span [class.time-now]="status">It's time!</span></p>
                            </div>
                            <ng-template #notTimeYet>
                                <p class="apt-notes fw-bold mb-1">Not Yet</p>
                            </ng-template>
                        </div>
                    </td>
                    <td class="cntr">
                        <p class="fw-normal mb-1">{{a.Status}}</p>
                    </td>
                    <!--td>
                    <div class="d-flex align-items-center">
                        <div class="ms-3">
                            <p class="fw-bold mb-1">{{a.Patient.fullName}}<br>{{a.Patient.DOB}}<br>{{a.Patient.pID}}</p>
                        </div>
                    </div>
                </td!-->
                    <td>
                        <p class="fw-bold mb-1">{{a.Patient.fullName}}<br>{{a.Patient.DOB}}<br>{{a.Patient.pID}}</p>
                    </td>
                    <td>
                        <p class="apt-notes fw-bold mb-1">{{a.Details.reason}}</p>
                        <p class="apt-notes fw-bold mb-1">{{a.Details.resource}}</p>
                        <p class="apt-notes fw-normal mb-1">{{a.Details.problems}}</p>
                        <p class="apt-notes fw-normal mb-1">{{a.Details.pcp}}</p>
                    </td>
                    <!--td>
                    <a routerLink="/axConfirmation/{{loc.lID}}-{{dpt.dID}}-{{a.aID}}-A" class="btn btn-success"
                        (click)="sendPatient(a)"><i class="bi bi-play-btn"></i></a>
                    <a routerLink="/axConfirmation/{{loc.lID}}-{{dpt.dID}}-{{a.aID}}-D"
                        class="btn btn-danger"><i class="bi bi-x-square"></i></a>
                </td!-->
                    <td>
                        <a [routerLink]="['/axConfirmation', loc.lID, dpt.dID, a.aID, 'A']" class="btn btn-success"
                            (click)="sendPatient(a)">
                            <i class="bi bi-play-btn"></i>
                        </a>
                        <a [routerLink]="['/axConfirmation', loc.lID, dpt.dID, a.aID, 'D']" class="btn btn-danger">
                            <i class="bi bi-x-square"></i>
                        </a>
                    </td>
                </tr>
                <tr *ngIf="theAppts.length === 0">
                    <td colspan="5">No appointments found.</td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #noAppointments>
        <div class="text-center py-2">
            <p>No appointments to display.</p>
        </div>
    </ng-template>
</div>