<div class="container-fluid p-1">
    <form id="eForm" class="row g-1 tab-content p-2" [formGroup]="eForm" (ngSubmit)="updateEForm()">
        <div class="col-md-12 text-end">
            <button title="Save Notes and Update ICD-10 CM Codes" type="submit" class="btn btn-primary me-2" style="padding: .125rem;"><i class="bi bi-arrow-clockwise"></i>Update ICD-10</button>
            <button title="Save Notes" type="submit" class="btn btn-success me-2" style="padding: .125rem;"><i class="bi bi-save"></i></button>
            <button title="Upload Notes" type="submit" class="btn btn-info" (click)="uploadEForm()" style="padding: .125rem;"><i class="bi bi-upload"></i></button>
        </div>
        <div formGroupName="PreliminaryDiagnosis" class="editGrpDiv row">
            <h4 class="fw-bold">Preliminary Diagnosis: {{encntr.eDate}}</h4>
            <div class="col-md-6 mb-3">
                <label for="ClinicalImpression" class="form-label">Clinical Impression:</label>
                <textarea class="form-control" id="ClinicalImpression" formControlName="ClinicalImpression"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="Justification" class="form-label">Justification:</label>
                <textarea class="form-control" id="Justification" formControlName="Justification"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="ICD10CMCodes" class="form-label">ICD-10 CM Codes:</label>
                <textarea class="form-control" id="ICD10CMCodes" formControlName="ICD10CMCodes"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="JCodesConsiderations" class="form-label">J Codes Considerations:</label>
                <textarea class="form-control" id="JCodesConsiderations"
                    formControlName="JCodesConsiderations"></textarea>
            </div>
        </div>

        <div formGroupName="Prognosis" class="editGrpDiv row">
            <h4 class="fw-bold">Prognosis:</h4>
            <div class="col-md-6 mb-3">
                <label for="Description" class="form-label">Description:</label>
                <textarea class="form-control" id="Description" formControlName="Description"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="Justification" class="form-label">Justification:</label>
                <textarea class="form-control" id="Justification" formControlName="Justification"></textarea>
            </div>
        </div>

        <div formGroupName="ClinicalAssessment" class="editGrpDiv row">
            <h4 class="fw-bold">Clinical Assessment:</h4>

            <div formGroupName="ClinicalObservations" class="editGrpDiv row">
                <h4 class="fw-bold">Clinical Observations:</h4>
                <div class="col-md-6 mb-3">
                    <label for="MentalHealthStatus" class="form-label">Mental Health Status:</label>
                    <textarea class="form-control" id="MentalHealthStatus"
                        formControlName="MentalHealthStatus"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="PsychologicalHealth" class="form-label">Psychological Health:</label>
                    <textarea class="form-control" id="PsychologicalHealth"
                        formControlName="PsychologicalHealth"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="SubstanceUse" class="form-label">Substance Use:</label>
                    <textarea class="form-control" id="SubstanceUse" formControlName="SubstanceUse"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="PhysicalHealth" class="form-label">Physical Health:</label>
                    <textarea class="form-control" id="PhysicalHealth" formControlName="PhysicalHealth"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="FunctionalCapacity" class="form-label">Functional Capacity:</label>
                    <textarea class="form-control" id="FunctionalCapacity"
                        formControlName="FunctionalCapacity"></textarea>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="Overall" class="form-label">Overall:</label>
                    <textarea class="form-control" id="Overall" formControlName="Overall"></textarea>
                </div>
            </div>

            <div formArrayName="PatientReportedOutcomeMeasures" class="editGrpDiv row">
                <h4 class="fw-bold">Patient Reported Outcome Measures:</h4>
                <div *ngFor="let measure of promMeasures_" class="editGrpDiv col-md-6" [formGroupName]="measure">
                    <h4 class="fw-bold">{{ measure }}:</h4>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="TotalScore" class="form-label">Score:</label>
                            <textarea class="form-control" id="TotalScore" formControlName="TotalScore"></textarea>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="SummaryAssessment" class="form-label">Assessment:</label>
                            <textarea class="form-control" id="SummaryAssessment"
                                formControlName="SummaryAssessment"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="editGrpDiv row" formArrayName="PresentingSymptoms">
            <h4 class="fw-bold">Presenting Symptoms:</h4>
            <div *ngFor="let symptom of presentingSymptomsControls; let i = index" [formGroupName]="i" class="row mb-3">
                <div class="col-md-4">
                    <label class="form-label">Symptom Name</label>
                    <input type="text" class="form-control" formControlName="SymptomName" />
                </div>
                <div class="col-md-4">
                    <label class="form-label">Onset</label>
                    <input type="date" class="form-control" formControlName="Onset" />
                </div>
                <div class="col-md-4">
                    <label class="form-label">Duration</label>
                    <input type="text" class="form-control" formControlName="Duration" />
                </div>
                <div class="col-md-6">
                    <label class="form-label">Severity</label>
                    <textarea class="form-control" id="Severity" formControlName="Severity"></textarea>
                </div>
                <div class="col-md-6">
                    <label class="form-label">Description</label>
                    <textarea class="form-control" id="Description" formControlName="Description"></textarea>
                </div>
            </div>
        </div>
        <div formGroupName="PreliminaryTreatmentPlan" class="editGrpDiv row">
            <h4 class="fw-bold">Preliminary TreatmentPlan:</h4>

            <div class="col-md-6 mb-3">
                <label for="ProposedTreatment" class="form-label">Proposed Treatment:</label>
                <textarea class="form-control" id="ProposedTreatment" formControlName="ProposedTreatment"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="Justification" class="form-label">Justification:</label>
                <textarea class="form-control" id="Justification" formControlName="Justification"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="TreatmentModality" class="form-label">Treatment Modality:</label>
                <textarea class="form-control" id="TreatmentModality" formControlName="TreatmentModality"></textarea>
            </div>
            <div class="col-md-6 mb-3">
                <label for="ICD10CMCodes" class="form-label">ICD-10 CM Codes:</label>
                <textarea class="form-control" id="ICD10CMCodes" formControlName="ICD10CMCodes"></textarea>
            </div>
            <div class="editGrpDiv row" formArrayName="ProposedMedication">
                <h4 class="fw-bold">Proposed Medication:</h4>
                <div *ngFor="let medication of proposedMedicationControls; let i = index" [formGroupName]="i" class="row mb-3">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Medication</label>
                        <input type="text" class="form-control" formControlName="Medication" />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label class="form-label">Dosage</label>
                        <input type="text" class="form-control" formControlName="Dosage" />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label class="form-label">Frequency</label>
                        <input type="text" class="form-control" formControlName="Frequency" />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label class="form-label">ICD-10 CM Codes</label>
                        <input type="text" class="form-control" formControlName="ICD10CMCodes" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Justification</label>
                        <textarea class="form-control" id="Justification" formControlName="Justification"></textarea>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Possible Side Effects</label>
                        <textarea class="form-control" id="PossibleSideEffects"
                            formControlName="PossibleSideEffects"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="editGrpDiv row">
            <h4 class="fw-bold">Anticipated Outcomes:</h4>
            <textarea class="form-control" id="AnticipatedOutcomes" formControlName="AnticipatedOutcomes"></textarea>
        </div>

        <div class="editGrpDiv row">
            <h4 class="fw-bold">Complexity Factors:</h4>
            <textarea class="form-control" id="ComplexityFactors" formControlName="ComplexityFactors"></textarea>
        </div>

        <div class="editGrpDiv row">
            <h4 class="fw-bold">Psychosocial Factors:</h4>
            <textarea class="form-control" id="PsychosocialFactors" formControlName="PsychosocialFactors"></textarea>
        </div>

        <div class="editGrpDiv row">
            <h4 class="fw-bold">FollowUp Plan:</h4>
            <textarea class="form-control" id="FollowUpPlan" formControlName="FollowUpPlan"></textarea>
        </div>

        <div class="editGrpDiv row">
            <h4 class="fw-bold">Monitoring And Evaluation Plan:</h4>
            <textarea class="form-control" id="MonitoringAndEvaluationPlan"
                formControlName="MonitoringAndEvaluationPlan"></textarea>
        </div>

        <div class="editGrpDiv row">
            <h4 class="fw-bold">Utilization Review Considerations:</h4>
            <textarea class="form-control" id="UtilizationReviewConsiderations"
                formControlName="UtilizationReviewConsiderations"></textarea>
        </div>

    </form>
</div>