import { Component, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Encounter } from 'src/app/Store/Model/type.Interfeaces';


@Component({
  selector: 'app-notes-crisis-intervention',
  templateUrl: './notes-crisis-intervention.component.html',
  styleUrls: ['./notes-crisis-intervention.component.css']
})
export class NotesCrisisInterventionComponent implements OnInit {
  @Input() patient: any;
  @Input() encntr: any;
  @Input() cmpy: any;
  @Input() templates: any;

  treatmentForm!: FormGroup;
  isLoading: boolean = true;
  patientRdy: boolean = false;
  cnfg: any; spCnfg: any;
  encntrs: Encounter[] = [];
  sympCnfg: any;
  tpCnfg: any;
  noteCnfg: any;
  tpCMS: any;
  milCnfg: any;
  psyCnfg: any;
  psyMVCnfg: any;
  psyMACnfg: any;

  medMngt: any; socialCnfg: any;
  loc: any; lID: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc);//console.log("loc", this.loc);
      const dpt: any = localStorage.getItem('dpt'); this.dpt = JSON.parse(dpt);//console.log("dpt", this.dpt);
    }
  }
  ngOnInit() {
    console.log("ngOnInit -> notes-crisis-intervention: this.patient", this.patient);
    this.cnfg = this.cmpy.Cnfg;
    this.tpCnfg = this.cmpy.TreatmentPlan; //console.log("treatment -ngOnInit- tpCnfg", this.tpCnfg);
    this.noteCnfg = this.cmpy.NoteTemplates; //console.log("treatment -ngOnInit- noteCnfg", this.noteCnfg);
    this.templates = this.cmpy.NoteTemplates[2]; console.log("crisis-intervention template -ngOnInit- noteCnfg", this.templates);
  }
}
