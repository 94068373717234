<mat-sidenav-container>
  <mat-sidenav mode="side" [(opened)]="isSidenavOpen">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="signInMethod === 'main'">
      <li class="nav-item"><a routerLink="home" class="nav-link"><i class="bi bi-speedometer"></i> Dashboard</a></li>
      <li class="nav-item"><a routerLink="locations" class="nav-link"><i class="bi bi-hospital"></i> Locations</a></li>
      <li class="nav-item"><a routerLink="patients" class="nav-link"><i class="bi bi-people"></i> Patients</a></li>
      <li class="nav-item"><a routerLink="appointments" class="nav-link"><i class="bi bi-calendar3"></i> Appointments</a></li>
      <li class="nav-item"><a routerLink="sessions" class="nav-link"><i class="bi bi-easel"></i> Sessions</a></li>
    </ul>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Navbar content -->
    <div class="container-fluid toolbar" role="banner">
      <a class="navbar-brand" href="#"><img src="../assets/_images/imsMPCLogo1.jpg"
          style="width:2.5rem;padding:0;margin:0;margin-right:.25rem;border-radius:.5rem;" alt="Network Loco 1"></a>
      <button mat-icon-button (click)="isSidenavOpen = !isSidenavOpen" aria-label="Toggle sidenav">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="d-flex ms-auto align-items-center" style="float:right;">
        <a routerLink="cmpyAdmin/" class="nav-link" title="System Profile"><i class="bi bi-gear"
            *ngIf="signInMethod === 'main'"></i></a>
        <a class="nav-link p-2 text-dark" routerLink="/profile"
          *ngIf="isAuthenticated && signInMethod === 'main'"><i class="bi bi-person-lines-fill"></i></a>
        <button class="btn" title="Log Out" (click)="signOut()" *ngIf="isAuthenticated"><i
            class="bi bi-box-arrow-right"></i></button>
      </div>
    </div>
    <router-outlet></router-outlet> <!-- Angular Router content -->
  </mat-sidenav-content>
</mat-sidenav-container>
