import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-department-editor',
  templateUrl: './department-editor.component.html',
  styleUrls: ['./department-editor.component.css']
})

export class DepartmentEditorComponent implements OnInit {
  isLoading: boolean = true;
  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any; stf: any; stfs: any;
  eLoc: any; eLID: any; eDpt: any; eDID: any; eGS: any; eGID: any;
  cnfg_: any = [];
  cnfgChrt_: any = [];
  cntctP_: any = [];
  cntctS_: any = [];
  dbData: any = [];
  cnfg_UD: any = [];
  eData!: any;
  nData!: any;
  udData: object = {};
  eForm!: FormGroup;

  constructor(
    private route: ActivatedRoute, private router: Router, public fb: FormBuilder,
    public uSrvc: UtilitiesService,
    public aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    console.log(localStorage);
    const stf_: any = localStorage.getItem('stf'); const stfs: any = localStorage.getItem('stfs');
    if (stf_ === null) { console.log("redirect"); this.router.navigate(['patients']); }
    else {
      this.stf = JSON.parse(stf_); console.log(this.stf);
      this.stfs = JSON.parse(stfs); console.log(this.stfs);
      const c: any = localStorage.getItem('cmpy'); const c_: any = JSON.parse(c); this.cmpy = c_; console.log('this.cmpy',this.cmpy);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log('this.locs',this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log('this.loc',this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt_'); this.dpt = JSON.parse(dpt); console.log('this.dpt',this.dpt);
      this.eForm = this.fb.group({
        N: [''],
        note: [''],
        T: [''],
        A: [''],
        A2: [''],
        S: [''],
        C: [''],
        Z: [''],
        P1: [''],
        TF: [''],
        F: [''],
        E: [''],
        pI: [''],
        pA: [''],
        pN: [''],
        pP: [''],
        sI: [''],
        sA: [''],
        sN: [''],
        sP: [''],
      });
    }
  }

  eFormUD() {
    this.isLoading = true;
    console.log("this.eForm.value", this.eForm.value);
    let T:any = document.getElementById("tSlct");
    console.log(T.outerHTML);

    this.udData = {
      "id": this.dbData.id,
      'N': this.eForm.value.N,
      'T': this.eForm.value.T,
      'note': this.eForm.value.note,
      'Adr': {
        'A': this.eForm.value.A,
        'A2': this.eForm.value.A2,
        'C': this.eForm.value.C,
        'S': this.eForm.value.S,
        'Z': this.eForm.value.Z,
        'P1': this.eForm.value.P1,
        'TF': this.eForm.value.TF,
        'F': this.eForm.value.F,
        'E': this.eForm.value.E
      },
      'Contacts': {
        "Primary": {
          "IT": this.eForm.value.pI,
          "Admin": this.eForm.value.pA,
          "Nursing": this.eForm.value.pN,
          "Provider": this.eForm.value.pP
        },
        "Secondary": {
          "IT": this.eForm.value.sI,
          "Admin": this.eForm.value.sA,
          "Nursing": this.eForm.value.sN,
          "Provider": this.eForm.value.sP
        }
      }
    };
    console.log("this.udData", this.udData);
    this.aSrvc.DptUD(this.udData).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        const dData = this.rsp[0];
        if (dData.status === "success") { this.toastr.success(dData.message); }
      }
    )
  };
  updatePhn($event: any) {
    console.log("$event: ", $event.target.id, $event.target.value);
    this.eData[$event.target.id] = this.uSrvc.formatPhone($event.target.value);
  }

  chgLoc($event: any) {
    this.lID = $event.target.value; this.dID = "0001"; console.log(this.lID);
    this.loc = this.locs.find((location: { lID: any; }) => location.lID === this.lID); console.log(this.loc);
    this.dpt = this.loc.Departments.find((department: { ID: any; }) => department.ID === this.dID); console.log(this.dpt);
  }
  chgDpmt($event: any) {
    this.dID = $event.target.value; console.log(this.dID);
    this.dpt = this.loc.Departments.find((department: { ID: any; }) => department.ID === this.dID);
  }

  chgProvider($event: any) { console.log($event); }
  chgNursing($event: any) { console.log($event); }
  chgAdmin($event: any) { console.log($event); }
  chgIT($event: any) { console.log($event); }

  ngOnInit(): void {
    console.log(localStorage);
    this.isLoading = true;
    const task_: any = this.route.snapshot.url[1].path;console.log(task_);
    const tID: any = this.route.snapshot.paramMap.get('id');
    this.lID = localStorage.getItem('currentLID'); this.dID = localStorage.getItem('currentDID'); 
    if (task_ === "e"||task_ === "d") {this.eLID = this.uSrvc.getLid(tID); this.eDID = this.uSrvc.getDid(tID); }
    else{ this.eLID = this.lID;this.eDID = this.dID; }
    console.log('tID', tID,' | this.eLID', this.eLID,' | this.eDID', this.eDID);
    this.eLoc = this.locs.find((location: { lID: any; }) => location.lID === this.eLID); console.log(this.eLoc);
    this.aSrvc.DptGet(this.eLID,this.eDID).subscribe(
      (result) => { this.responseHandler(result); this.dbData = result; },
      (error) => { this.errors = error.error; },
      () => {
        const d_ = this.dbData[0]; this.eDpt = d_; this.dbData = d_;
        console.log('this.eDpt', this.eDpt);
        let xd_ = JSON.parse(d_.Adr); this.eDpt.Adr = xd_;
        xd_ = JSON.parse(d_.Contacts); this.eDpt.Contacts = xd_;
        xd_ = JSON.parse(d_.GSType); this.eDpt.GSType = xd_;
        console.log('this.eDpt', this.eDpt);
        this.cntctP_ = this.eDpt.Contacts.Primary;
        this.cntctS_ = this.eDpt.Contacts.Secondary;

        this.eForm = this.fb.group({
          N: [this.eDpt.N],
          note: [this.eDpt.note],
          T: [this.eDpt.T],
          A: [this.eDpt.Adr.A],
          A2: [this.eDpt.Adr.A2],
          S: [this.eDpt.Adr.S],
          C: [this.eDpt.Adr.C],
          Z: [this.eDpt.Adr.Z],
          P1: [this.eDpt.Adr.P1],
          TF: [this.eDpt.Adr.TF],
          F: [this.eDpt.Adr.F],
          E: [this.eDpt.Adr.E],
          pI: [this.cntctP_.IT],
          pA: [this.cntctP_.Admin],
          pN: [this.cntctP_.Nursing],
          pP: [this.cntctP_.Provider],
          sI: [this.cntctS_.IT],
          sA: [this.cntctS_.Admin],
          sN: [this.cntctS_.Nursing],
          sP: [this.cntctS_.Provider],
        });
        this.isLoading = false;
      }
    );
  }
  responseHandler(data: any) { /* this.token.handleData(data.atoken); */ }// Handle response
}
