import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isSidenavOpen = false;
  isAuthenticated = false;
  isLoading = false;  // Add loading state for async operations
  signInMethod: 'main' | 'assessment' | null = 'main';
  title = 'My-HEalth-Registry';

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Sign out the user and navigate to the login page.
   */
  async signOut(): Promise<void> {
    this.isLoading = true;
    try {
      await this.authService.logout().toPromise();
      this.router.navigate(['/login']);
    } catch (err) {
      this.logError('Logout failed', err);
    } finally {
      this.isLoading = false;
    }
  }


  /**
   * Centralized error logging for consistency across methods.
   */
  private logError(message: string, error: any): void {
    console.error(`${message}:`, error);
    // Optionally integrate a logging service (e.g., Sentry, LogRocket)
  }
}
