import { Component } from '@angular/core';

@Component({
  selector: 'app-employee-detail-page',
  templateUrl: './employee-detail-page.component.html',
  styleUrls: ['./employee-detail-page.component.css']
})
export class EmployeeDetailPageComponent {

}
