// assessment.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  constructor() { }
  private mentalStatusTotalSubject = new BehaviorSubject<number>(0);
  mentalStatusTotal$ = this.mentalStatusTotalSubject.asObservable();

  private psychiatricSymptomTotalSubject = new BehaviorSubject<number>(0);
  psychiatricSymptomTotal$ = this.psychiatricSymptomTotalSubject.asObservable();

  updateMentalStatusTotal(total: any | 0): void { console.log(total); this.mentalStatusTotalSubject.next(total); }

  updatePsychiatricSymptomTotal(total: any | 0): void { console.log(total); this.psychiatricSymptomTotalSubject.next(total); }
}
