import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Appointment } from '../../../Store/Model/type.Interfeaces';
import { UtilitiesService } from '../../../service/utilities.service';
import { ToastrService } from 'ngx-toastr';
import { UpdatePromService } from 'src/app/service/update-prom.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})

export class ReviewComponent implements OnInit {
  isAssessmentMenuActv: boolean = true;
  isLoading: boolean = true;
  rsp: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  Apt: any; aID: any;
  eData: any = ''; // Assuming this is necessary and not sensitive
  Q_: any;
  qck_: boolean = false
  currentQ_: any; prom: any; eQuestions: any;
  theAppts: Appointment[] = [];
  @Output() ActivateEvt = new EventEmitter();
  @Output() updateEvt = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private uSrvc: UtilitiesService,
    private udpSrvc: UpdatePromService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const eData: any = localStorage.getItem('eData'); this.eData = JSON.parse(eData); console.log(this.eData);
    }
  }
  Activate(patient: object) { this.ActivateEvt.emit(patient); }
  submitResults() {
    console.log(localStorage['currentQ_']); console.log(this.Apt);
    if (localStorage['currentQ_'] === "PHQ9" && this.Q_.question[8].value > 0) {
      localStorage.setItem('patient' + this.Apt.Patient.pID[localStorage['currentQ_']], JSON.stringify(this.Q_));
      localStorage.setItem('currentQ_', 'SBQR'); console.log(this.Apt.Details.problems);
      this.router.navigate(['axActivation/' + this.lID + '/' + this.dID + '/' + this.aID])
    }
    //    else if ((localStorage['currentQ_'] === "PHQ9" || localStorage['currentQ_'] === "SBQR") && this.Apt.Details.problems.indexOf('anxiety') > -1) {
    else if (localStorage['currentQ_'] === "PHQ9" || localStorage['currentQ_'] === "SBQR") {
      localStorage.setItem('patient' + this.Apt.Patient.pID[localStorage['currentQ_']], JSON.stringify(this.Q_));
      localStorage.setItem('currentQ_', 'GAD7'); console.log(this.Apt.Details.problems);
      this.router.navigate(['axActivation/' + this.lID + '/' + this.dID + '/' + this.aID])
    }
    else if (localStorage['currentQ_'] === "GAD7") {
      localStorage.setItem('patient' + this.Apt.Patient.pID[localStorage['currentQ_']], JSON.stringify(this.Q_));
      localStorage.setItem('currentQ_', 'WHO5'); console.log(this.Apt.Details.problems);
      this.router.navigate(['axActivation/' + this.lID + '/' + this.dID + '/' + this.aID])
    }
    /*
    else if ((localStorage['currentQ_'] === "PHQ9" || localStorage['currentQ_'] === "GAD7") && this.Apt.Details.problems.indexOf('faitgue') > -1) {
      console.log(this.Apt.Patient.pID);
      localStorage.setItem('patient' + this.Apt.Patient.pID[localStorage['currentQ_']], JSON.stringify(this.Q_));
      console.log(this.Apt.Details.problems);
    }*/
    else {
      console.log(localStorage);
      console.log(this.Apt); let Apt_ = JSON.stringify(this.Apt);
      console.log(this.eData);
      let rst_: any = []; let dialogRef: any = '';

      const rvwProm: any = {eID: this.eData.encounter.id};console.log(rvwProm);
      this.udpSrvc.reviewProm(rvwProm).subscribe(
        (result) => { rst_ = result; console.log("result:", result); },
        (error) => { this.errors = error.error; },
        () => {
          //this.auth.setAuthState(false, null);
          this.authService.logout();
          this.router.navigate(['axSignin']);
          //this.eQuestions = rst_.rJson; console.log("this.rJson:", this.eQuestions);
          //this.eQuestions = JSON.parse(rst_.qJson); console.log("this.eQuestions:", this.eQuestions);
          //const eQ_: any = rst_.questions[0]; console.log("eQ_:", eQ_.text);
          //this.router.navigate(['axDeactivation/' + this.lID + '-' + this.dID + '-' + this.aID])
          //dialogRef = this.dialog.open(QuestionPopupComponent, {
          //  width: '90%', enterAnimationDuration: '1000ms', exitAnimationDuration: '500ms', data: { iQuestions: this.eQuestions,  Apt: this.Apt }
          //}
          //);
        });
      /*
        */
    }
  }

  ngOnInit(): void {
    //  this.company=testCompany;console.log(this.company[0]);
    this.lID = this.route.snapshot.paramMap.get('param1');
    this.dID = this.route.snapshot.paramMap.get('param2');
    this.aID = this.route.snapshot.paramMap.get('param3');
    console.log(this.lID, this.dID, this.aID);

    console.log(localStorage);
    const prom: any = localStorage.getItem('prom');
    this.prom = JSON.parse(prom); console.log(this.prom);
    const Q_: any = localStorage.getItem('Q_');
    this.Q_ = JSON.parse(Q_); console.log(this.Q_);
    const Apt: any = localStorage.getItem('Apt');
    this.Apt = JSON.parse(Apt); console.log(this.Apt);
    this.currentQ_ = localStorage.getItem('currentQ_');

  }
}

