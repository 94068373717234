import { Component, OnInit, Output, EventEmitter, HostListener, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import * as Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-notes-treatment-plan',
  templateUrl: './notes-treatment-plan.component.html',
  styleUrls: ['./notes-treatment-plan.component.css']
})

export class NotesTreatmentPlanComponent implements OnInit {
  [x: string]: any;
  @Input() patient: any;
  @Input() encntr: any;
  @Input() cmpy: any;

  private subscription: Subscription = new Subscription();
  errors: any = null;
  isLoading: boolean = true;

  eForm: FormGroup | any;
  patientRdy: boolean = false;
  SubstanceUseInfo: boolean = false;
  cnfg: any; spCnfg: any;

  PHQ9Cnfg: any;
  iEncntr: any;
  iTreatmentPlan: any;
  treatmentPlan: any;
  GAD7Cnfg: any;
  WHO5Cnfg: any;
  PhysicalCnfg: any;
  FunctionalCnfg: any;

  sympCnfg: any;
  tpCnfg: any;
  axCnfg: any;
  noteCnfg: any;
  templates: any;
  tpCMS: any;
  milCnfg: any;
  psyCnfg: any;
  psyMVCnfg: any;
  psyMACnfg: any;
  PHQ9: any = [];
  GAD7: any = [];
  WHO5: any = [];
  Physical: any = [];
  WellBeing: any = [];
  currentValue: number = 0;

  socialCnfg: any;
  medMngt: any;
  routeOfAdministration: any;
  classes: any;
  frequencyOptions: any;
  medCompliance: any;

  loc: any; lID: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  currentLevel: number = 15;
  targetLevel: number = 40;
  selectedYear!: number;
  onYearSelected(year: number): void {
    this.selectedYear = year;
  }


  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private renderer: Renderer2, private el: ElementRef
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else { this.stf = JSON.parse(stf); console.log(this.stf); }

    this.eForm = this.createForm();

  }
  ngOnInit() {
    this.cnfg = this.cmpy.Cnfg;
    this.PHQ9Cnfg = this.cnfg.PHQ9Cnfg;
    this.GAD7Cnfg = this.cnfg.GAD7Cnfg;
    this.WHO5Cnfg = this.cnfg.WHO5Cnfg;
    this.PhysicalCnfg = this.cnfg.PhysicalCnfg;
    this.FunctionalCnfg = this.cnfg.FunctionalCnfg;

    console.log("ngOnInit -> treatment-plan: this.encntr", this.encntr);
    this.targetLevel = this.encntr.note.Ax.MHTrgt + this.encntr.note.Ax.PsychTrgt + 4;
    this.currentLevel =
      parseInt(this.encntr.note.Ax.SubstancesUse) +
      parseInt(this.encntr.note.Ax.RiskOfHarm) +
      parseInt(this.encntr.note.Ax.Functional) +
      parseInt(this.encntr.note.Ax.Physical) +
      parseInt(this.encntr.note.Ax.MHTLT) +
      parseInt(this.encntr.note.Ax.PsychTLT);
    //console.log("ngOnInit -> treatment-plan: this.targetLevel", this.targetLevel, 'this.currentLevel', this.currentLevel);

    this.tpCnfg = this.cmpy.TreatmentPlan; //console.log("treatment -ngOnInit- tpCnfg", this.tpCnfg);
    this.axCnfg = this.cmpy.Assessments; console.log('encounter notes axCnfg:', this.axCnfg);
    this.medMngt = this.cmpy.medManagement; //console.log("medMngt", this.medMngt);
    const medsMngmt = this.cmpy.medManagement.prescribedMedications;
    this.routeOfAdministration = medsMngmt.routeOfAdministration;
    this.classes = medsMngmt.MedicationClasses; //console.log('classes:', this.classes);
    this.frequencyOptions = medsMngmt.frequencyOptions;
    this.medCompliance = medsMngmt.compliance.adherenceLevel;
    this.noteCnfg = this.cmpy.NoteTemplates; //console.log("treatment -ngOnInit- noteCnfg", this.noteCnfg);
    this.templates = this.cmpy.NoteTemplates; //console.log("treatment -ngOnInit- templates", this.templates);
    this.selectedTemplate = this.noteCnfg[0];
    this.iEncntr = this.patient.encounters[0];
    if (this.iEncntr.history.social !== null) {
      if (this.iEncntr.history.social.substanceUse !== null) { this.SubstanceUseInfo = true; }
    } console.log("treatment -ngOnInit- iEncntr", this.iEncntr);
    this.iTreatmentPlan = this.iEncntr.treatmentPlan.original.TreatmentPlan.components.required; console.log("treatment -ngOnInit- iTreatmentPlan", this.iTreatmentPlan);
    this.initializeForm();
    this.patientRdy = true;
    this.subscribeToSelectedEncounter();
  }
  private subscribeToSelectedEncounter() {
    this.subscription.add(this.uSrvc.selectedEncounter$.subscribe(encounter => { if (encounter) { this.updateEncounter(encounter); } }));
  }
  private updateEncounter(encounter: any) { this.encntr = encounter; console.log('NotesTreatmentPlanComponent: encntr: ', this.encntr); this.initializeForm(); }

  private createForm(): FormGroup {
    return this.fb.group({
      DiagnosticAssessment: ['', Validators.required],
      ProblemList: ['', Validators.required],
      Treatment: this.fb.group({
        PsychotherapyPlan: ['', Validators.required],
        Modality: ['', Validators.required],
        ICD10Code: ['', Validators.required],
        PlannedSessions: ['', Validators.required],
        AttendedSessions: ['', Validators.required],
        Medications: this.fb.array([]),
        Diagnosis: this.fb.group({
          DSM5Diagnoses: ['', Validators.required],
          ICD10Codes: ['', Validators.required],
        }),
        Assessment: this.fb.group({
          PresentingProblem: ['', Validators.required],
          HistoryPresentingProblem: ['', Validators.required],
          PsychiatricHistory: ['', Validators.required],
          MedicalHistory: ['', Validators.required],
          FamilyHistory: ['', Validators.required],
          SubstanceUseHistory: ['', Validators.required],
          SocialHistory: ['', Validators.required],
          MentalStatus: this.fb.group({
            Appearance: ['', Validators.required],
            Behavior: ['', Validators.required],
            MoodAndAffect: ['', Validators.required],
            ThoughtProcess: ['', Validators.required],
            ThoughtContent: ['', Validators.required],
            Cognition: ['', Validators.required],
            InsightJudgment: ['', Validators.required],
          }),
          RiskAssessment: this.fb.group({
            Suicidality: ['', Validators.required],
            Homicidality: ['', Validators.required],
            OtherRisks: ['', Validators.required],
          }),
        }),
        Goal: this.fb.group({
          ShortTerm: ['', Validators.required],
          ShortTermDate: ['', Validators.required],
          ShortTermProgression: ['', Validators.required],
          ShortTermAdjustment: ['', Validators.required],
          LongTerm: ['', Validators.required],
          LongTermDate: ['', Validators.required],
          LongTermProgression: ['', Validators.required],
          LongTermAdjustment: ['', Validators.required],
          Note: ['', Validators.required],
          PHQ9: this.fb.group({
            Initial: ['', Validators.required],
            Current: ['', Validators.required],
            ShortTerm: ['', Validators.required],
            LongTerm: ['', Validators.required],
          }),
          GAD7: this.fb.group({
            Initial: ['', Validators.required],
            Current: ['', Validators.required],
            ShortTerm: ['', Validators.required],
            LongTerm: ['', Validators.required],
          }),
          WHO5: this.fb.group({
            Initial: ['', Validators.required],
            Current: ['', Validators.required],
            ShortTerm: ['', Validators.required],
            LongTerm: ['', Validators.required],
          }),
          Physical: this.fb.group({
            Initial: ['', Validators.required],
            Current: ['', Validators.required],
            ShortTerm: ['', Validators.required],
            LongTerm: ['', Validators.required],
          }),
          WellBeing: this.fb.group({
            Initial: ['', Validators.required],
            Current: ['', Validators.required],
            ShortTerm: ['', Validators.required],
            LongTerm: ['', Validators.required],
          })
        }),
        Interventions: this.fb.group({
          Psychotherapy: ['', Validators.required],
          MedicationManagement: ['', Validators.required],
          LifestyleModifications: ['', Validators.required],
          SupportServices: ['', Validators.required],
        }),
        MonitoringEvaluation: this.fb.group({
          OutcomeMeasures: ['', Validators.required],
          FollowUpSchedule: ['', Validators.required],
        }),
        EmergencyPlan: this.fb.group({
          EmergencyContactInformation: ['', Validators.required],
          CrisisInterventionPlan: ['', Validators.required],
        }),
        ConsentAndConfidentiality: this.fb.group({
          InformedConsent: ['', Validators.required],
          ConfidentialityAgreement: ['', Validators.required],
        }),
        //FollowUp: this.fb.array([]),
        DischargeCriteria: this.fb.group({
          ClinicalStability: this.fb.group({
            SymptomReduction: ['', Validators.required],
            StableVitalSigns: ['', Validators.required],
            NoNeedForIntensiveCare: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
          FunctionalImprovement: this.fb.group({
            DailyActivities: ['', Validators.required],
            SelfCare: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
          AchievementOfTreatmentGoals: this.fb.group({
            MoodImprovement: ['', Validators.required],
            BehavioralImprovement: ['', Validators.required],
            CognitiveFunction: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
          SupportSystems: this.fb.group({
            FamilySupport: ['', Validators.required],
            CommunityResources: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
          FollowUpPlan: this.fb.group({
            ScheduledAppointments: ['', Validators.required],
            MedicationManagement: ['', Validators.required],
            AccessToSupportServices: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
          PatientAndFamilyEducation: this.fb.group({
            UnderstandingOfTreatmentPlan: ['', Validators.required],
            RelapsePrevention: ['', Validators.required],
            EmergencyContacts: ['', Validators.required],
            ICD10Codes: ['', Validators.required],
          }),
        }),
      }),
    });
  }
  initializeForm(): void {
    //      this.eForm.patchValue({
    this.eForm = this.fb.group({
      DiagnosticAssessment: this.iTreatmentPlan?.Diagnosis?.fields['DSM-5 Diagnoses'] || '',
      Treatment: {
        PsychotherapyPlan: this.iTreatmentPlan?.PsychotherapyPlan || '',
        Modality: this.iTreatmentPlan?.Modality || '',
        ICD10Code: this.iTreatmentPlan?.ICD10Code || '',
        PlannedSessions: this.iTreatmentPlan?.PlannedSessions || '',
        AttendedSessions: this.iTreatmentPlan?.AttendedSessions || '',
        Diagnosis: {
          DSM5Diagnoses: this.iTreatmentPlan?.Diagnosis?.fields['DSM-5 Diagnoses'] || '',
          ICD10Codes: this.iTreatmentPlan?.Diagnosis?.fields['ICD-10 Codes'] || '',
        },
        Assessment: {
          PresentingProblem: this.iTreatmentPlan?.Assessment?.fields['Presenting Problem'] || '',
          HistoryPresentingProblem: this.iTreatmentPlan?.Assessment?.fields['History of Presenting Problem'] || '',
          PsychiatricHistory: this.iTreatmentPlan?.Assessment?.fields['Psychiatric History'] || '',
          MedicalHistory: this.iTreatmentPlan?.Assessment?.fields['MedicalHistory'] || '',
          FamilyHistory: this.iTreatmentPlan?.Assessment?.fields['FamilyHistory'] || '',
          SubstanceUseHistory: this.iTreatmentPlan?.Assessment?.fields['Substance Use History'] || '',
          SocialHistory: this.iTreatmentPlan?.Assessment?.fields['SocialHistory'] || '',
          MentalStatus: {
            Appearance: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.Appearance || '',
            Behavior: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.Behavior || '',
            MoodAndAffect: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.['Mood and Affect'] || '',
            ThoughtProcess: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.['Thought Process'] || '',
            ThoughtContent: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.['Thought Content'] || '',
            Cognition: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.Cognition || '',
            InsightJudgment: this.iTreatmentPlan?.Assessment?.fields['Mental Status Examination']?.['Insight and Judgment'] || '',
          },
          RiskAssessment: {
            Suicidality: this.iTreatmentPlan?.Assessment?.fields['Risk Assessment']?.Suicidality || '',
            Homicidality: this.iTreatmentPlan?.Assessment?.fields['Risk Assessment']?.Homicidality || '',
            OtherRisks: this.iTreatmentPlan?.Assessment?.fields['Risk Assessment']?.['Other Risks'] || '',
          }
        },
        Goal: {
          ShortTerm: this.iTreatmentPlan?.['Treatment Goals']?.fields['Short-term Goals'] || '',
          LongTerm: this.iTreatmentPlan?.['Treatment Goals']?.fields['Long-term Goals'] || '',
          PHQ9: {
            Current: this.encntr?.promSummary?.PHQ9?.sumR || 0,
            Initial: this.iEncntr?.promSummary?.PHQ9?.sumR || 0,
            ShortTerm: this.encntr?.note?.Goal?.PHQ9?.NT || 0,
            LongTerm: this.encntr?.note?.Goal?.PHQ9?.LT || 0,
          },
          GAD7: {
            Current: this.encntr?.promSummary?.GAD7?.sumR || 0,
            Initial: this.iEncntr?.promSummary?.GAD7?.sumR || 0,
            ShortTerm: this.encntr?.note?.Goal?.GAD7?.NT || 0,
            LongTerm: this.encntr?.note?.Goal?.GAD7?.LT || 0,
          },
          WHO5: {
            Current: this.encntr?.promSummary?.WHO5?.sumR || 0,
            Initial: this.iEncntr?.promSummary?.WHO5?.sumR || 0,
            ShortTerm: this.encntr?.note?.Goal?.WHO5?.NT || 0,
            LongTerm: this.encntr?.note?.Goal?.WHO5?.LT || 0,
          },
          Physical: {
            Current: this.encntr?.note?.Ax?.Physical || 0,
            Initial: this.encntr?.note?.Goal?.Physical?.Init || 0,
            ShortTerm: this.encntr?.note?.Goal?.Physical?.NT || 0,
            LongTerm: this.encntr?.note?.Goal?.Physical?.LT || 0,
          },
          WellBeing: {
            Current: this.encntr?.note?.Ax?.Functional || 0,
            Initial: this.encntr?.note?.Goal?.Functional?.Init || 0,
            ShortTerm: this.encntr?.note?.Goal?.Functional?.NT || 0,
            LongTerm: this.encntr?.note?.Goal?.Functional?.LT || 0,
          }
        },
        Medications: this.fb.array([]),
        Interventions: {
          Psychotherapy: this.iTreatmentPlan?.Interventions?.fields['Psychotherapy'] || '',
          SupportServices: this.iTreatmentPlan?.Interventions?.fields['Support Services'] || '',
          MedicationManagement: this.iTreatmentPlan?.Interventions?.fields['Medication Management'] || '',
          LifestyleModifications: this.iTreatmentPlan?.Interventions?.fields['Lifestyle Modifications'] || '',
        },
        MonitoringEvaluation: {
          OutcomeMeasures: this.iTreatmentPlan?.['Monitoring and Evaluation']?.fields['Outcome Measures'] || '',
          FollowUpSchedule: this.iTreatmentPlan?.['Monitoring and Evaluation']?.fields['Follow-up Schedule'] || '',
        },
        EmergencyPlan: this.iTreatmentPlan?.['Emergency Plan']?.fields['Crisis Intervention Plan'] || '',
        DischargeCriteria: {
          ClinicalStability: {
            SymptomReduction: this.iTreatmentPlan?.['Discharge Criteria']?.fields.ClinicalStability?.SymptomReduction || '',
            NoNeedForIntensiveCare: this.iTreatmentPlan?.['Discharge Criteria']?.fields.ClinicalStability?.NoNeedForIntensiveCare || '',
            StableVitalSigns: this.iTreatmentPlan?.['Discharge Criteria']?.fields.ClinicalStability?.StableVitalSigns || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.ClinicalStability?.['ICD-10 Codes'] || '',
          },
          FunctionalImprovement: {
            DailyActivities: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FunctionalImprovement?.DailyActivities || '',
            SelfCare: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FunctionalImprovement?.SelfCare || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FunctionalImprovement?.['ICD-10 Codes'] || '',
          },
          AchievementOfTreatmentGoals: {
            MoodImprovement: this.iTreatmentPlan?.['Discharge Criteria']?.fields.AchievementOfTreatmentGoals?.MoodImprovement || '',
            BehavioralImprovement: this.iTreatmentPlan?.['Discharge Criteria']?.fields.AchievementOfTreatmentGoals?.BehavioralImprovement || '',
            CognitiveFunction: this.iTreatmentPlan?.['Discharge Criteria']?.fields.AchievementOfTreatmentGoals?.CognitiveFunction || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.AchievementOfTreatmentGoals?.['ICD-10 Codes'] || '',
          },
          SupportSystems: {
            FamilySupport: this.iTreatmentPlan?.['Discharge Criteria']?.fields.SupportSystems?.FamilySupport || '',
            CommunityResources: this.iTreatmentPlan?.['Discharge Criteria']?.fields.SupportSystems?.CommunityResources || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.SupportSystems?.['ICD-10 Codes'] || '',
          },
          FollowUpPlan: {
            ScheduledAppointments: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FollowUpPlan?.ScheduledAppointments || '',
            MedicationManagement: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FollowUpPlan?.MedicationManagement || '',
            AccessToSupportServices: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FollowUpPlan?.AccessToSupportServices || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.FollowUpPlan?.['ICD-10 Codes'] || '',
          },
          PatientAndFamilyEducation: {
            UnderstandingOfTreatmentPlan: this.iTreatmentPlan?.['Discharge Criteria']?.fields.PatientAndFamilyEducation?.UnderstandingOfTreatmentPlan || '',
            RelapsePrevention: this.iTreatmentPlan?.['Discharge Criteria']?.fields.PatientAndFamilyEducation?.RelapsePrevention || '',
            EmergencyContacts: this.iTreatmentPlan?.['Discharge Criteria']?.fields.PatientAndFamilyEducation?.EmergencyContacts || '',
            ICD10Codes: this.iTreatmentPlan?.['Discharge Criteria']?.fields.PatientAndFamilyEducation?.['ICD-10 Codes'] || '',
          },
        }
      }
    });
  }
  selectedTemplate: any;
  onSelectTemplate(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TemplateID = Number(selectElement.value); console.log(TemplateID);
    this.selectedTemplate = this.noteCnfg.find((Template: { id: number; }) => Template.id === TemplateID);
  }

  selectedService: any;
  onSelectService(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const serviceID = Number(selectElement.value);
    this.selectedService = this.tpCnfg.PsychotherapyServices.find((service: { ID: number; }) => service.ID === serviceID);
  }
  private fillTemplate(template: string, data: any): string {
    console.log(data);
    return template.replace(/\{\{(\w+)\}\}/g, (_, key) => data[key] || `{{${key}}}`);
  }

  dynamicData: any = {}; // This should be populated with actual dynamic data
  filledTemplate: any; // This should be populated with actual dynamic data
  selectTemplate(template: any): void {
    this.selectedTemplate = template; console.log(this.selectedTemplate);
    // Here, you would replace placeholders in the selected template with actual data
    // For demonstration, let's assume dynamicData has all the necessary keys
    this.filledTemplate = this.fillTemplate(template.template, this.patient);
    console.log(this.filledTemplate);
  }

  changeMedClass(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus: any = String(selectElement.value);
    const id = String(selectElement.id);
    const prefix = 'medClass';
    const indexPart = id.substring(prefix.length); console.log('id', id, 'newStatus', newStatus, 'classes', this.classes);
    const class_ = this.classes.find((item: any) => item.ClassName === newStatus);
    console.log('newStatus', newStatus, 'indexPart', indexPart, 'classes', this.classes, 'class_', class_, 'icd10Code', class_.CommonICD10CMCodes);
    this.fb.group({ ['medICD10CMCode' + indexPart]: [class_.CommonICD10CMCodes, Validators.required] });
  }
  get Meds_(): FormArray { return this.eForm.get('Treatment.Medications') as FormArray; }
  addMed() {
    this.Meds_.push(this.fb.group({
      medName: ['', Validators.required],
      medDosage: ['', Validators.required],
      medFrequency: ['', Validators.required],
      medRoute: ['', Validators.required],
      medClass: ['', Validators.required],
      medStartDate: ['', Validators.required],
      medEndDate: ['', Validators.required],
      medPurpose: ['', Validators.required],
      medICD10CMCode: ['', Validators.required],
      medNDC: ['', Validators.required],
      medSideEffects: ['', Validators.required],
      medAdherenceLevel: ['', Validators.required],
      medReasonsNonAdherence: ['', Validators.required],
    }));
  }
  private populateMedications(medications: any[]): void {
    if (Array.isArray(medications)) { medications.forEach((med) => { this.Meds_.push(this.createMedicationFormGroup(med)); }); }
    else { console.error('Expected an array of medications, but received:', medications); }
  }

  private createMedicationFormGroup(med: any): FormGroup {
    return this.fb.group({
      medName: [med.medName, Validators.required],
      medDosage: [med.medDosage, Validators.required],
      medFrequency: [med.medFrequency, Validators.required],
      medRoute: [med.medRoute, Validators.required],
      medClass: [med.medClass, Validators.required],
      medStartDate: [med.medStartDate, Validators.required],
      medEndDate: [med.medEndDate, Validators.required],
      medPurpose: [med.medPurpose, Validators.required],
      medICD10CMCode: [med.medICD10CMCode, Validators.required],
      medNDC: [med.medNDC, Validators.required],
      medSideEffects: [med.medSideEffects, Validators.required],
      medAdherenceLevel: [med.medAdherenceLevel, Validators.required],
      medReasonsNonAdherence: [med.medReasonsNonAdherence, Validators.required],
    });
  }

  modifyKeys(data: any): any {
    const modifiedData: any = {};
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        modifiedData[key] = data[key].map((item: any) => this.modifyKeys(item));
      } else if (typeof data[key] === 'object') {
        modifiedData[key] = this.modifyKeys(data[key]);
      } else {
        const match = key.match(/(\d+)$/);
        const newKey = match ? key.replace(match[0], '') : key;
        modifiedData[newKey] = data[key];
      }
    });
    return modifiedData;
  }

  updatePForm() {
    console.log("Update eForm: ", this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    this.aSrvc.patientUD(transformedPatientData).subscribe(
      (result) => { console.log(result); }
    );
  }
  submitForm() {
    console.log(this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    if (this.eForm.valid) {
      const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
  }

  submitFormOrg() {
    console.log(this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    /**
     * 
    if (this.eForm.valid) {
      const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
     * 
    */
  }


  /*
  onSymptomSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const SymptomID = selectElement.value;
    this.dynamicData.Sx.Name = SymptomID;
    console.log(this.dynamicData.Sx.Name);
  }
  onTreatmentModalitiesSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TreatmentModalitiesID = selectElement.value;
    this.dynamicData.PTP.Modalities = TreatmentModalitiesID;
    console.log(this.dynamicData.PTP.Modalities);
  }
  onTreatmentPlanSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TreatmentPlanID = selectElement.value;
    console.log(TreatmentPlanID);
    this.dynamicData.PTP.Details = TreatmentPlanID;
    console.log(this.dynamicData.PTP.Details);
  }
  onComplexityFactorsSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const ComplexityFactorsID = selectElement.value;
    this.dynamicData.PTP.Complexity.Overall = ComplexityFactorsID;
    console.log(this.dynamicData.PTP.Complexity.Overall);
  }
  */

  predictedICD10Codes: string[] = [];
  predictICD10() {
    const patientData = this.eForm.value;
    // Call the service to predict the ICD-10 codes
    this.aSrvc.predictICD10(patientData).subscribe(
      (codes) => { this.predictedICD10Codes = codes; },
      (error) => { console.error('Error predicting ICD-10 codes:', error); }
    );
  }
  confirmCode(code: string) {
    // Add logic to handle the confirmed code
    console.log('Confirmed code:', code);
    // Possibly add the code to a list of confirmed codes
  }
  predictJCodes(): void {
    const treatmentDetails = this.eForm.value; // Assuming this is your form data
    this.aSrvc.predictJCodes(treatmentDetails).subscribe({
      next: (codes) => {
        this.predictedICD10Codes = codes;
        // Handle UI logic to display codes for selection or confirmation
      },
      error: (error) => console.error('Error predicting ICD-10 codes:', error)
    });
  }
  onValueChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const id = target.id; //console.log('id:', id);
    const newValue = parseInt(target.value, 10);
    this.currentValue = newValue; // Assuming you want to display or use this value
    const parts = id.split('.'); this[parts[0]][parts[1]] = newValue; //console.log(parts);

    // Determine the class based on newValue
    const newClass = this.getClassForValue(newValue, parts[0]);
    // Find the sibling span to the input
    const siblingSpan = this.renderer.nextSibling(target);
    if (siblingSpan) {
      // Clear existing classes and add the new one
      this.renderer.removeClass(siblingSpan, 'Optimal');
      this.renderer.removeClass(siblingSpan, 'Moderate');
      this.renderer.removeClass(siblingSpan, 'Mild');
      this.renderer.removeClass(siblingSpan, 'Severe');
      this.renderer.removeClass(siblingSpan, 'Poor');
      this.renderer.removeClass(siblingSpan, 'Urgent');
      this.renderer.addClass(siblingSpan, newClass);
    }
  }
  getClassForValue(value: number, Ax: string): string {
    if (Ax === "PHQ9") {
      if (value <= this.PHQ9Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PHQ9Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PHQ9Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PHQ9Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "GAD7") {
      if (value <= this.GAD7Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.GAD7Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.GAD7Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.GAD7Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WHO5") {
      if (value <= this.WHO5Cnfg.minimalMax) { return 'Urgent'; }
      else if (value <= this.WHO5Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.WHO5Cnfg.betterMax) { return 'Optimal'; }
    }
    if (Ax === "Physical") {
      if (value <= this.PhysicalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PhysicalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PhysicalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PhysicalCnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WellBeing") {
      if (value <= this.FunctionalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.FunctionalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.FunctionalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.FunctionalCnfg.severeMax) { return 'Urgent'; }
    }
    return 'Urgent';
  }

  updateEForm() {
    this.isLoading = true;
    console.log("Update eForm.value: ", this.eForm.value);
    let udData: any = [];
    udData = this.uSrvc.replaceNullWithEmptyString(this.eForm.value);
    console.log("udData", udData);
    //JSON.stringify(udData)
    /*
    let rsp: any;
    this.aSrvc.encntrUD(this.encntr.id, udData.note).subscribe(
      (result) => { rsp = result; console.log(rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        if (rsp.status === "success") {
          this.encntr = rsp.encounter;
          this.toastr.success(rsp.message);
        }
      }
    )
    */
  };
  uploadEForm() {
  };

}
