<div class="container-fluid"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <div class="row">
    <div class="col-11">
      <h4>{{dpt.N }} | Date: {{dateDisplay}} | Time Interval: {{dateRange}} | Patients: {{tltCnt}}</h4>
    </div>
    <div class="col-1">
      <button class="btn" title="Location Filter" style="float:right;padding:0;"><i class="bi bi-geo-alt"></i></button>
      <button class="btn" title="Patient Filter" (click)="openFilterPopup()" style="float:right;padding:0;"><i
          class="bi bi-filter-square"></i></button>
    </div>
  </div>
  <highcharts-chart id="container" [Highcharts]="Highcharts" [constructorType]="chartConstructor" [oneToOne]="true"
    style="width: 100%; display: block;"></highcharts-chart>
  <!--div id="thePatientCards">
    <app-patient-summary *ngFor="let p_ of patientsDB" [patient]="p_" [showNote]="filter.showNotes"></app-patient-summary>
  </div!-->
  <div id="thePatientCards">
    <app-patient-summary *ngFor="let patient of patients$ | async" [patient]="patient"
      [showNote]="filter.showNotes"></app-patient-summary>
  </div>
</div>