import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface SelectorState {
  id: string;
  value: number;
  color: string;
}
type ColorMappingFunctions = {
  [key: string]: (value: number) => string;
  Physical: (value: number) => string;
  Functional: (value: number) => string;
  RiskOfHarm: (value: number) => string;
  SubstanceUse: (value: number) => string;
  Default: (value: number) => string;
};

@Injectable({
  providedIn: 'root',
})
export class ObservableAssessmentService {
  private selectorStates = new BehaviorSubject<SelectorState[]>([]);
  constructor() {}
  get states$(): Observable<SelectorState[]> {return this.selectorStates.asObservable();}

  private determineColor(value: number, id: string): string {
    const colorMappingFunctions: ColorMappingFunctions = {
      Physical: this.getPhysicalColor,
      Functional: this.getFunctionalColor,
      RiskOfHarm: this.getRiskOfHarmColor,
      SubstanceUse: this.getSubstanceUseColor,
      Default: this.getDefaultColor,
    };
    const mappingFunction = colorMappingFunctions[id] || colorMappingFunctions.Default;
    return mappingFunction(value);
  }

  updateState(id: string, value: number): void {id = id.replace("_slider", "");
    let states = this.selectorStates.getValue();
    const index = states.findIndex(state => state.id === id);
    const color = this.determineColor(value, id);
    if (index !== -1) {states[index] = { ...states[index], value, color };} else {states.push({ id, value, color });}
    this.selectorStates.next([...states]);
  }
  private getPhysicalColor = (value: number): string => ['VeryPoor', 'Poor', 'Fair', 'Good', 'VeryGood', 'Excellent'][value - 1] || 'defaultColor';
  private getFunctionalColor = (value: number): string => ['Complete', 'Severe', 'Moderate', 'Mildly', 'Adequate', 'Optimal'][value - 1] || 'defaultColor';
  private getRiskOfHarmColor = (value: number): string => ['No', 'LowRisk', 'ModerateRisk', 'HighRisk'][value - 1] || 'defaultColor';
  private getSubstanceUseColor = (value: number): string => ['No', 'MildSubstanceUse', 'ModerateSubstanceUse', 'SevereSubstanceUse'][value - 1] || 'defaultColor';
  private getDefaultColor = (value: number): string => ['Normal', 'Mild', 'Moderate', 'Significant', 'Profound'][value - 1] || 'defaultColor';
}
