import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from 'src/app/service/auth.service';
import { forEach } from 'lodash';

@Component({
  selector: 'app-sessions-editor',
  templateUrl: './sessions-editor.component.html',
  styleUrls: ['./sessions-editor.component.css']
})
export class SessionsEditorComponent implements OnInit {
  isLoading: boolean = true;
  rsp: any; index: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; gss: any; gs: any; gID: any; rptDepart: any; stf: any; stfs: any;
  eLoc: any; eLID: any; eDpt: any; eDID: any; eGS: any; eGID: any;
  cnfg_: any = [];
  cnfgChrt_: any = [];
  cntctP_: any = [];
  cntctS_: any = [];
  materials_: any = [];
  coordinators_: any = [];
  seats_: any = [];
  waitList_: any = [];
  dbData: any = [];
  cnfg_UD: any = [];
  eData!: any;
  nData!: any;
  udData: object = {};
  eForm!: FormGroup;

  constructor(
    private route: ActivatedRoute, private router: Router, public fb: FormBuilder,
    public uSrvc: UtilitiesService,
    public aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    this.isLoading = true;
    const stf_: any = localStorage.getItem('stf'); const stfs: any = localStorage.getItem('stfs');
    if (stf_ === null) { console.log("redirect"); this.router.navigate(['patients']); }
    else {
      this.stf = JSON.parse(stf_); console.log(this.stf);
      this.stfs = JSON.parse(stfs); console.log(this.stfs);
      const c: any = localStorage.getItem('cmpy'); const c_: any = JSON.parse(c); this.cmpy = c_; console.log('this.cmpy', this.cmpy);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log('this.locs', this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log('this.loc', this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt_'); this.dpt = JSON.parse(dpt); console.log('this.dpt', this.dpt);
      this.eForm = this.fb.group({
        N: [''],
        note: [''],
        Des: [''],
        sDate: [''],
        sTime: [''],
        eDate: [''],
        eTime: [''],
        duration: [''],
        frequency: [''],
        T: [''],
        A: [''],
        A2: [''],
        S: [''],
        C: [''],
        Z: [''],
        P1: [''],
        TF: [''],
        F: [''],
        E: [''],
        pI: [''],
        pA: [''],
        pN: [''],
        pP: [''],
        sI: [''],
        sA: [''],
        sN: [''],
        sP: [''],
      });
    }
  }

  eFormUD() {
    this.isLoading = true;
    console.log("this.eForm.value", this.eForm.value);
    let T: any = document.getElementById("tSlct");

    this.udData = {
      "id": this.dbData.id,
      'N': this.eForm.value.N,
      'T': this.eForm.value.T,
      'note': this.eForm.value.note,
      'Description': this.eForm.value.Des,
      'Location': {
        'A': this.eForm.value.A,
        'A2': this.eForm.value.A2,
        'C': this.eForm.value.C,
        'S': this.eForm.value.S,
        'Z': this.eForm.value.Z,
        'P1': this.eForm.value.P1,
        'TF': this.eForm.value.TF,
        'F': this.eForm.value.F,
        'E': this.eForm.value.E
      },
      'Schedule': {
        'sDate': this.eForm.value.sDate,
        'sTime': this.eForm.value.sTime,
        'eDate': this.eForm.value.eDate,
        'eTime': this.eForm.value.eTime,
        'duration': this.eForm.value.duration,
        'frequency': this.eForm.value.frequency
      },
      'Contacts': {
        "Primary": {
          "IT": this.eForm.value.pI,
          "Admin": this.eForm.value.pA,
          "Nursing": this.eForm.value.pN,
          "Provider": this.eForm.value.pP
        },
        "Secondary": {
          "IT": this.eForm.value.sI,
          "Admin": this.eForm.value.sA,
          "Nursing": this.eForm.value.sN,
          "Provider": this.eForm.value.sP
        }
      }
    };
    console.log("this.udData", this.udData);
    this.aSrvc.DptUD(this.udData).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        const dData = this.rsp[0];
        if (dData.status === "success") { this.toastr.success(dData.message); }
      }
    )
  };
  updatePhn($event: any) {
    console.log("$event: ", $event.target.id, $event.target.value);
    this.eData[$event.target.id] = this.uSrvc.formatPhone($event.target.value);
  }

  rmThis($event: any) {
    console.log($event); console.log(this.eForm);
  }
  chgDpmt($event: any) {
    this.dID = $event.target.value; console.log(this.dID);
    this.dpt = this.loc.Departments.find((department: { ID: any; }) => department.ID === this.dID);
  }

  chgProvider($event: any) { console.log($event); }
  chgNursing($event: any) { console.log($event); }
  chgAdmin($event: any) { console.log($event); }
  chgIT($event: any) { console.log($event); }

  addInputToForm(hdr_:string,idx: Number,itm:string) {this.eForm.addControl(hdr_ + idx, new FormControl(itm));}
  ngOnInit(): void {
    console.log(localStorage);
    this.isLoading = true;
    const task_: any = this.route.snapshot.url[1].path; console.log(task_);
    const tID: any = this.route.snapshot.paramMap.get('id');
    this.lID = localStorage.getItem('currentLID');
    this.dID = localStorage.getItem('currentDID');
    this.gID = localStorage.getItem('currentDID');
    if (task_ === "e" || task_ === "d") {
      this.eLID = this.uSrvc.getLid(tID);
      this.eDID = this.uSrvc.getDid(tID);
      this.eGID = this.uSrvc.getGid(tID);
    }
    else { this.eLID = this.lID; this.eDID = this.dID; this.eGID = this.gID; }
    console.log('tID', tID, ' | this.eLID', this.eLID, ' | this.eDID', this.eDID, ' | this.eGID', this.eGID);
    this.eLoc = this.locs.find((location: { lID: any; }) => location.lID === this.eLID); console.log(this.eLoc);
    this.eDpt = this.eLoc.Departments.find((department: { dID: any; }) => department.dID === this.eDID); console.log(this.eDpt);
    this.aSrvc.GSGet(this.eLID, this.eDID, this.eGID).subscribe(
      (result) => { this.responseHandler(result); this.dbData = result; },
      (error) => { this.errors = error.error; },
      () => {
        let g_ = this.dbData[0]; this.eGS = g_; this.dbData = g_;
        g_ = this.eGS;
        let xg_ = JSON.parse(g_.Location); this.eGS.Location = xg_;
        xg_ = JSON.parse(g_.Contacts); this.eGS.Contacts = xg_;
        xg_ = JSON.parse(g_.Cnfg); this.eGS.Cnfg = xg_;
        xg_ = JSON.parse(g_.Schedule); this.eGS.Schedule = xg_;
        this.materials_ = JSON.parse(g_.MaterialsNeeded); this.eGS.MaterialsNeeded = this.materials_;
        this.coordinators_ = JSON.parse(g_.Coordinators); this.eGS.Coordinators = this.coordinators_;
        this.seats_ = JSON.parse(g_.Seats); this.eGS.Seats = this.seats_;
        this.waitList_ = JSON.parse(g_.WaitList); this.eGS.WaitList = this.waitList_;
        console.log('this.eGS', this.eGS);
        this.cntctP_ = this.eGS.Contacts.Primary;
        this.cntctS_ = this.eGS.Contacts.Secondary;

        this.eForm = this.fb.group({
          N: [this.eGS.N],
          note: [this.eGS.note],
          T: [this.eGS.T],
          Des: [this.eGS.Description],
          sDate: [this.eGS.Schedule.sDate],
          sTime: [this.eGS.Schedule.sTime],
          eDate: [this.eGS.Schedule.eDate],
          eTime: [this.eGS.Schedule.eTime],
          duration: [this.eGS.Schedule.duration],
          frequency: [this.eGS.Schedule.frequency],
          A: [this.eGS.Location.A],
          A2: [this.eGS.Location.A2],
          S: [this.eGS.Location.S],
          C: [this.eGS.Location.C],
          Z: [this.eGS.Location.Z],
          P1: [this.eGS.Location.P1],
          TF: [this.eGS.Location.TF],
          F: [this.eGS.Location.F],
          E: [this.eGS.Location.E],
          pI: [this.cntctP_.IT],
          pA: [this.cntctP_.Admin],
          pN: [this.cntctP_.Nursing],
          pP: [this.cntctP_.Provider],
          sI: [this.cntctS_.IT],
          sA: [this.cntctS_.Admin],
          sN: [this.cntctS_.Nursing],
          sP: [this.cntctS_.Provider],
        });

        for (let i = 0; i < this.materials_.length; i++) {this.addInputToForm("materials_",i,this.materials_[i].Description);}
        for (let i = 0; i < this.coordinators_.length; i++) {this.addInputToForm("coordinators_",i,this.coordinators_[i].sID);}
        for (let i = 0; i < this.seats_.length; i++) {this.addInputToForm("seat_",i,this.seats_[i].pID);}
        for (let i = 0; i < this.waitList_.length; i++) {this.addInputToForm("waitList_",i,this.waitList_[i].pID);}
        this.isLoading = false;
      }
    );
  }
  responseHandler(data: any) { /* this.token.handleData(data.atoken); */ }// Handle response
}
function addFormFields() {
  throw new Error('Function not implemented.');
}

