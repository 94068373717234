import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.css']
})
export class CompanyEditorComponent implements OnInit {
  isLoading: boolean = true;
  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any; stf: any; stfs: any;
  cnfg_: any = [];
  cnfgChrt_: any = [];
  cntctP_: any = [];
  cntctS_: any = [];
  dbCmpy: any = [];
  cnfg_UD: any = [];
  eData!: any;
  nData!: any;
  udData: object = {};
  eForm!: FormGroup;

  constructor(
    private route: ActivatedRoute, private router: Router, public fb: FormBuilder,
    public uSrvc: UtilitiesService,
    public aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    console.log(localStorage);
    const stf_: any = localStorage.getItem('stf'); const stfs: any = localStorage.getItem('stfs');
    if (stf_ === null) { console.log("redirect"); this.router.navigate(['patients']); }
    else {
      this.stf = JSON.parse(stf_); console.log(this.stf);
      this.stfs = JSON.parse(stfs); console.log(this.stfs);
      const c: any = localStorage.getItem('cmpy'); const c_: any = JSON.parse(c); this.cmpy = c_; console.log(this.cmpy);
      this.cnfg_.idInit = this.cmpy.Cnfg.idInit;
      this.cnfg_.eMUrl = this.cmpy.Cnfg.eMUrl;
      this.cnfg_.dfltPswd = this.cmpy.Cnfg.dfltPswd;
      this.cnfgChrt_ = this.cmpy.Cnfg.PHQ9Cnfg;
      this.cntctP_ = this.cmpy.Contacts.Primary;
      this.cntctS_ = this.cmpy.Contacts.Secondary;
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log(this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log(this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt_'); this.dpt = JSON.parse(dpt); console.log(this.dpt);
    }
    this.eForm = this.fb.group({
      A: [this.cmpy.Adr.A],
      A2: [this.cmpy.Adr.A2],
      S: [this.cmpy.Adr.S],
      C: [this.cmpy.Adr.C],
      Z: [this.cmpy.Adr.Z],
      P1: [this.cmpy.Adr.P1],
      TF: [this.cmpy.Adr.TF],
      F: [this.cmpy.Adr.F],
      E: [this.cmpy.Adr.E],
      N: [this.cmpy.N],
      idInit: [this.cnfg_.idInit],
      eMUrl: [this.cnfg_.eMUrl],
      dfltPswd: [this.cnfg_.dfltPswd],
      Title: [this.cnfgChrt_.Title],
      yTitle: [this.cnfgChrt_.yTitle],
      yMin: [this.cnfgChrt_.yMin],
      yMax: [this.cnfgChrt_.yMax],
      xTitle: [this.cnfgChrt_.xTitle],
      xMin: [this.cnfgChrt_.xMin],
      xMax: [this.cnfgChrt_.xMax],
      zTitle: [this.cnfgChrt_.zTitle],
      zMin: [this.cnfgChrt_.zMin],
      zMax: [this.cnfgChrt_.zMax],
      dataLBL: [this.cnfgChrt_.dataLBL],
      safeMin: [this.cnfgChrt_.safeMin],
      safeMax: [this.cnfgChrt_.safeMax],
      safeLBL: [this.cnfgChrt_.safeLBL],
      warnMin: [this.cnfgChrt_.warnMin],
      warnMax: [this.cnfgChrt_.warnMax],
      warnLBL: [this.cnfgChrt_.warnLBL],
      alarmMin: [this.cnfgChrt_.alarmMin],
      alarmMax: [this.cnfgChrt_.alarmMax],
      alarmLBL: [this.cnfgChrt_.alarmLBL],
      detailsPage: [this.cnfgChrt_.detailsPage],
      note: [this.cmpy.note],
      pI: [this.cntctP_.IT],
      pA: [this.cntctP_.Admin],
      pN: [this.cntctP_.Nursing],
      pP: [this.cntctP_.Provider],
      sI: [this.cntctS_.IT],
      sA: [this.cntctS_.Admin],
      sN: [this.cntctS_.Nursing],
      sP: [this.cntctS_.Provider],

    });
  }

  eFormUD() {
    this.isLoading = true;
    console.log("this.eForm.value", this.eForm.value);
    this.udData = {
      "id": this.dbCmpy.id,
      'N': this.eForm.value.N,
      'note': this.eForm.value.note,
      'Cnfg': {
        'idInit': this.eForm.value.idInit,
        'eMUrl': this.eForm.value.eMUrl,
        'dfltPswd': this.eForm.value.dfltPswd,
        'PHQ9Cnfg': {
          'Title': this.eForm.value.Title,
          'yTitle': this.eForm.value.yTitle,
          'yMin': this.eForm.value.yMin,
          'yMax': this.eForm.value.yMax,
          'xTitle': this.eForm.value.xTitle,
          'xMin': this.eForm.value.xMin,
          'xMax': this.eForm.value.xMax,
          'zTitle': this.eForm.value.zTitle,
          'zMin': this.eForm.value.zMin,
          'zMax': this.eForm.value.zMax,
          'dataLBL': this.eForm.value.dataLBL,
          'safeMin': this.eForm.value.safeMin,
          'safeMax': this.eForm.value.safeMax,
          'safeLBL': this.eForm.value.safeLBL,
          'warnMin': this.eForm.value.warnMin,
          'warnMax': this.eForm.value.warnMax,
          'warnLBL': this.eForm.value.warnLBL,
          'alarmMin': this.eForm.value.alarmMin,
          'alarmMax': this.eForm.value.alarmMax,
          'alarmLBL': this.eForm.value.alarmLBL,
          'detailsPage': this.eForm.value.detailsPage
        }
      },
      'Adr': {
        'A': this.eForm.value.A,
        'A2': this.eForm.value.A2,
        'C': this.eForm.value.C,
        'S': this.eForm.value.S,
        'Z': this.eForm.value.Z,
        'P1': this.eForm.value.P1,
        'TF': this.eForm.value.TF,
        'F': this.eForm.value.F,
        'E': this.eForm.value.E
      },
      'Contacts': {
        "Primary": {
          "IT": this.eForm.value.pI,
          "Admin": this.eForm.value.pA,
          "Nursing": this.eForm.value.pN,
          "Provider": this.eForm.value.pP
        },
        "Secondary": {
          "IT": this.eForm.value.sI,
          "Admin": this.eForm.value.sA,
          "Nursing": this.eForm.value.sN,
          "Provider": this.eForm.value.sP
        }
      }
    };
    console.log("this.udData", this.udData);
    this.aSrvc.CmpyUD(this.udData).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp);this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors);},
      () => {
        const cData = this.rsp[0];
        if (cData.status === "success") { this.toastr.success(cData.message); }
      }
    )
  };
  updatePhn($event: any) {
    console.log("$event: ", $event.target.id, $event.target.value);
    this.eData[$event.target.id] = this.uSrvc.formatPhone($event.target.value);
  }
  chgProvider($event: any) { console.log($event); }
  chgNursing($event: any) { console.log($event); }
  chgAdmin($event: any) { console.log($event); }
  chgIT($event: any) { console.log($event); }

  ngOnInit(): void {
    console.log(localStorage);
    this.isLoading = true;

    this.aSrvc.getCmpy(this.stf.cID).subscribe(
      (result) => { this.responseHandler(result); this.dbCmpy = result; },
      (error) => { this.errors = error.error; },
      () => {
        const c_ = this.dbCmpy[0]; this.cmpy = c_;this.dbCmpy = c_;
        console.log('this.dbCmpy', this.dbCmpy);
        let x_ = JSON.parse(c_.Adr); this.cmpy.Adr = x_;
        x_ = JSON.parse(c_.Cnfg); this.cmpy.Cnfg = x_;
        x_ = JSON.parse(c_.Contacts); this.cmpy.Contacts = x_;
        x_ = JSON.parse(c_.LType); this.cmpy.LType = x_;
        console.log('this.cmpy', this.cmpy);
        this.cnfgChrt_ = this.cmpy.Cnfg.PHQ9Cnfg;
        this.cntctP_ = this.cmpy.Contacts.Primary;
        this.cntctS_ = this.cmpy.Contacts.Secondary;
        this.isLoading = false;
      }
    );
  }
  responseHandler(data: any) { /* this.token.handleData(data.atoken); */ }// Handle response
}
