<div class="container-fluid">><h3>{{dpt.N }}</h3>
  <div *ngFor="let s_ of group_sessions">
    <div class="card bg-info">
        <div class="card-body">
            <h5 class="card-title">{{ s_.N }} | {{s_.lID }} - {{ s_.dID }}</h5>
            <p class="card-text">
            <span><i class="bi bi-bookmark"></i>{{' '+s_.TLong}}</span><br>
            <span><i class="bi bi-geo-alt"></i>{{s_.Location.A}},  {{s_.Location.A2}}</span><br>
            <span><i class="bi bi-calendar-event"></i> Date: {{s_.Schedule.sDate}} to {{s_.Schedule.eDate}} | <i class="bi bi-alarm"></i> Time: {{s_.Schedule.sTime | date:'hh:mm a'}} to {{s_.Schedule.eTime}}, Duration: {{s_.Schedule.duration}} minutes</span><br>
            <span><i class="bi bi-people"></i> Capacity: {{s_.MaxSeats}} |  Occupancy: {{s_.Seats.length}} | Wait List: {{s_.WaitList.length}}</span><br>
            </p>
        </div>
    </div>
  </div>
  <div id="thePatientCards">
    <app-patient-summary *ngFor="let patient of patients" [patient]="patient"></app-patient-summary>
  </div>
</div>
