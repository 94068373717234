<div class="container-fluid"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <header *ngIf="patientRdy">
        <div class="row">
            <div class="col-12">
                <a (click)="backClicked()" style="padding-right:.5rem;font-size: 2rem;float: left;"><i
                        class="bi bi-arrow-left-square"></i> </a>
                <h4 style="display: inline-block;">Patient: {{patient.name}} | DOB: {{patient.dob}}, Age:
                    {{patient.Age}}
                    | ID: {{patient.patient_id}} | Encounter: Type: {{selectEncounter_.T}} , Date:
                    {{selectEncounter_.eDate}}</h4>
                <!--h5 *ngIf="patientRdy">Condition:<span *ngFor="let cnd_ of patient.medical_history.conditions">cnd_</span></h5>
                <h5 *ngIf="patientRdy">Allergies:<span *ngFor="let alg_ of patient.medical_history.allergies">alg_.name|alg_.effect</span></h5>
                <h5 *ngIf="patientRdy">PCP: {{encntrs[eLngth-1].history.medical_history.provider.Name}}</h5-->
            </div>
            <!--div class="col-4">
                <div class="d-flex" style="float: right;">
                    <a title="Edit Patient Demographics" (click)="editPatientDemo(patient)"
                        style="padding:.5rem;font-size: 2rem;"><i class="bi bi-pencil-square"></i></a>
                </div>
            </div!-->
        </div>
    </header>
    <div class="row">
        <figure class="highcharts-figure" style="height: fit-content;">
            <div id="container" style="height:12rem;"></div>
        </figure>
    </div>
    <div class="row" *ngIf="patientRdy">
        <div class="col-12">
            <div class="nav-item-Location">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pDemographics-tab" data-toggle="tooltip" data-bs-target="#pDemographics"
                            title="Patient Demographics" data-bs-toggle="tab" href="#pDemographics" role="tab"
                            aria-controls="pDemographics">Demographics</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pHistory-tab" data-toggle="tooltip" data-bs-target="#pHistory"
                            title="Patient History" data-bs-toggle="tab" href="#pHistory" role="tab"
                            aria-controls="pHistory">History</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="iNotes-tab" data-toggle="tooltip" data-bs-target="#iNotes"
                            title="Encounter Notes" data-bs-toggle="tab" href="#iNotes" role="tab"
                            aria-controls="iNotes">Encounter Notes</a>
                    </li>
                    <!--li class="nav-item" role="presentation" *ngIf="sNotes != null">
                        <a class="nav-link" id="sNotes-tab" data-toggle="tooltip" data-bs-target="#sNotes"
                            title="Group Session Notes" data-bs-toggle="tab" href="#sNotes" role="tab"
                            aria-controls="sNotes">Group Therapy</a>
                    </li!-->
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pTreatmentPlan-tab" data-toggle="tooltip"
                            data-bs-target="#pTreatmentPlan" title="Patient Treatment Plan" data-bs-toggle="tab"
                            href="#pTreatmentPlan" role="tab" aria-controls="pTreatmentPlan">Treatment Plan</a>
                    </li>
                </ul>
            </div>
            <form id="iForm" type="text/template" class="tab-content" [formGroup]="iForm" *ngIf="eD_.T != 'PC'">
                <div id="iNotes" class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="iNotes-tab">
                    <div class="col-md-3">
                        <button title="New Integrated Behavioral Health Notes" type="button" class="btn"
                            data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="newIForm()"><i
                                class="bi bi-file-earmark"></i> Create New Notes</button>
                    </div>
                    <div class="col-md-3">
                        <button title="Save Integrated Behavioral Health Notes" type="submit" class="btn"
                            (click)="saveIForm()"><i class="bi bi-file-earmark-plus"></i> Save New Notes</button>
                    </div>
                    <div class="col-md-3">
                        <button title="Update Integrated Behavioral Health Notes" type="submit" class="btn"
                            (click)="updateIForm()"><i class="bi bi-save"></i> Update Current Notes</button>
                    </div>
                    <div class="col-md-3">
                        <button title="Save and Upload Integrated Behavioral Health Notes" type="submit" class="btn"
                            (click)="updateIForm()"><i class="bi bi-upload"></i> Upload Notes To Records</button>
                    </div>
                    <div class="col-md-3">
                        <a title="Previous" (click)="previRpt()" style="padding-right: .5rem;font-size: 1.25rem;"><i
                                class="bi bi-box-arrow-left"></i></a>
                        <div class="form-group" style="display: inline-block;">
                            <label for="AssessmentDate">Date of Encounter</label><input type="date" class="form-control"
                                [value]="iNote.Date" id="AssessmentDate" formControlName="AssessmentDate">
                        </div>
                        <a title="Next" (click)="nextiRpt()" style="padding-left: .5rem;font-size: 1.25rem;"><i
                                class="bi bi-box-arrow-right"></i></a>
                    </div>
                    <div class="col-md-3">
                        <label>Duration</label>
                        <select class="form-select" id="iDuration" formControlName="iDuration">
                            <option value="">Select Duration</option>
                            <option *ngFor="let o_ of tpCnfg.DurationOptions" [value]="o_.Value">
                                {{ o_.Value }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label>Encounter Type</label>
                        <select class="form-select" id="iType" formControlName="iType">
                            <option value="">Select Encounter Type</option>
                            <option *ngFor="let o_ of tpCnfg.EncounterType" [value]="o_.ID">
                                {{ o_.type }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <div class="form-floating">
                            <input type="text" name="iTitle" class="form-control" [value]="iNote.Title" id="iTitle"
                                formControlName="iTitle">
                            <label for="iTitle">Title of Report</label>
                        </div>
                    </div>
                    <!--div class="row editGrpDiv" formGroupName="psychological_history">
                        <h4 class="col-md-12" style=" width:100%;">Current Status Assessment:</h4>
                        <div class="editGrpDiv" *ngFor="let sel_ of ppCnfg; let i = index" [formArrayName]="sel_.Type">
                            <h4>Current mental status and behaviors</h4>
                            <div class="col-md-12" style="display:inline-block;vertical-align:top;"
                                *ngFor="let q_ of sel_.Questions; let q = index">
                                <label [attr.for]="q_.ControlName">{{ q_.Q }}</label>
                                <select class="form-select" [id]="q_.ControlName" [formControlName]="q_.ControlName">
                                    <option value="">{{q_.Lbl}}</option>
                                    <option *ngFor="let o_ of q_.Option" [value]="o_.val">
                                        {{ o_.des }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div!-->
                    <div class="col-md-6">
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="Reason" aria-label="Reason"
                                [value]="iNote.Reason" formControlName="Reason"></textarea>
                            <label for="Reason">Reason</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="Problems" aria-label="Problems"
                                [value]="iNote.Problems" formControlName="Problems"></textarea>
                            <label for="Problems">Problems</label>
                        </div>
                    </div>
                    <div class="col-md-12" style="display:inline-block;vertical-align:top;">
                        <label for="SubstanceUseAssessment">Substance Use Assessment</label>
                        <select class="form-select" in="SubstanceUseAssessment"
                            formControlName="SubstanceUseAssessment">
                            <option value="">Select Substance Use Assessment</option>
                            <option *ngFor="let o_ of tpCnfg.SubstanceUseAssessmentOptions" [value]="o_.ID">{{
                                o_.Description }}</option>
                        </select>
                    </div>
                    <div class="editGrpDiv row" [formGroupName]="tpCMS.GroupName">
                        <h4 class="col-md-12" style=" width:100%;">{{ tpCMS.Description }}:</h4>
                        <div class="editGrpDiv row" *ngFor="let asmt_ of tpCMS.Assessments; let i = index"
                            [formArrayName]="asmt_.GroupName">
                            <h4 style="width: 100%;">{{ asmt_.Description }}</h4>
                            <div class="col-md-6"
                                style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;"
                                *ngFor="let q_ of asmt_.Questions; let q = index">
                                <label [attr.for]="q_.ControlName">{{ q_.Q }}</label>
                                <select class="form-select" [id]="q_.ControlName" [formControlName]="q_.ControlName">
                                    <option value="">{{q_.Lbl}}</option>
                                    <option *ngFor="let o_ of asmt_.LikertScale" [value]="o_.val">
                                        {{ o_.des }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="editGrpDiv row">
                            <div class="col-md-12"
                                style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;"
                                *ngFor="let asmt_ of tpCMS.Assessments2">
                                <label [attr.for]="asmt_.ControlName">{{ asmt_.Description }}</label>
                                <select class="form-select" [id]="asmt_.ControlName"
                                    [formControlName]="asmt_.ControlName">
                                    <option value="">{{asmt_.Lbl}}</option>
                                    <option *ngFor="let o_ of asmt_.Options" [value]="o_.val">
                                        {{ o_.des }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                        <label for="InterventionType">Intervention</label>
                        <select class="form-select" in="InterventionType" formControlName="InterventionType">
                            <option value="">Select Intervention Type</option>
                            <option *ngFor="let o_ of tpCnfg.InterventionOptions" [value]="o_.ID">{{ o_.Value }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                        <label for="PatientEducation">Patient Education</label>
                        <select class="form-select" in="PatientEducation" formControlName="PatientEducation">
                            <option value="">Select Patient Education Type</option>
                            <option *ngFor="let o_ of tpCnfg.PatientEducationOptions" [value]="o_.ID">{{ o_.Value }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                        <label for="MedicationProvided">Medication Provided</label>
                        <select class="form-select" in="MedicationProvided" formControlName="MedicationProvided">
                            <option value="">Select Medication Type</option>
                            <option *ngFor="let o_ of tpCnfg.MedicationOptions" [value]="o_.ID">{{ o_.Value }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-12" style="display:inline-block;vertical-align:top;">
                        <label for="RiskAssessment">Risk Assessment</label>
                        <select class="form-select" in="RiskAssessment" formControlName="RiskAssessment">
                            <option value="">Select Risk Assessment</option>
                            <option *ngFor="let o_ of tpCnfg.RiskAssessmentOptions" [value]="o_.ID">{{ o_.Value }}
                            </option>
                        </select>
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="SafetyAssessment" aria-label="Safety Assessment"
                                [value]="iNote.SafetyAssessment" formControlName="SafetyAssessment"></textarea><label
                                for="SafetyAssessment">Risk Assessment Notes</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="IBHAssessment"
                                aria-label="Integrated Behavioral Health Assessment" [value]="iNote.IBHAssessment"
                                formControlName="IBHAssessment"></textarea><label for="IBHAssessment">Integrated
                                Behavioral Health Assessment</label>
                        </div>
                    </div>
                    <div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                        <label for="ReferralType">Referral Made</label>
                        <select class="form-select" in="ReferralType" formControlName="ReferralType">
                            <option value="">Select Referral Type</option>
                            <option *ngFor="let o_ of tpCnfg.ReferralOptions" [value]="o_.ID">{{ o_.Value }}
                            </option>
                        </select>
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="ReferralNotes" aria-label="Referral"
                                [value]="iNote.Referral" formControlName="ReferralNotes"></textarea>
                            <label for="ReferralNotes">Referral Notes</label>
                        </div>
                    </div>
                    <!--div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                    </div!-->
                    <div class="col-md-12"
                        style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="iRecommendation" aria-label="iRecommendation"
                                [value]="iNote.Recommendation" formControlName="iRecommendation"></textarea><label
                                for="iRecommendation">Recommendation</label>
                        </div>
                    </div>
                    <div class="col-md-12 row">
                        <div class="col-md-4"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="FollowUpAppointments">FollowUp Appointments</label>
                            <select class="form-select" in="FollowUpAppointments"
                                formControlName="FollowUpAppointments">
                                <option value="">Select FollowUp Appointments Type</option>
                                <option *ngFor="let o_ of tpCnfg.FollowUpAppointmentOptions" [value]="o_.ID">{{ o_.Value
                                    }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="AdditionalTesting">Additional Testing Or Assessments</label>
                            <select class="form-select" in="AdditionalTesting" formControlName="AdditionalTesting">
                                <option value="">Select Additional Testing Or Assessments</option>
                                <option *ngFor="let o_ of tpCnfg.AdditionalTestingOrAssessmentsOptions" [value]="o_.ID">
                                    {{ o_.Value }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4"
                            style="display:inline-block;vertical-align:top;border:1px dotted rgb(18, 9, 99);border-radius: 0.25rem;">
                            <label for="PatientFamilyInvolvement">Patient Family Involvement</label>
                            <select class="form-select" in="PatientFamilyInvolvement"
                                formControlName="PatientFamilyInvolvement">
                                <option value="">Select Patient Family Involvement Type</option>
                                <option *ngFor="let o_ of tpCnfg.PatientFamilyInvolvementOptions" [value]="o_.ID">{{
                                    o_.Value }}
                                </option>
                            </select>
                        </div>
                        <div class="form-floating">
                            <textarea class="form-control textarea" id="FollowUp" aria-label="Follow-Up"
                                [value]="iNote.FollowUp" formControlName="FollowUp"></textarea><label
                                for="FollowUp">Follow-Up Notes</label>
                        </div>
                    </div>
                </div>
            </form>
            <form id="sForm" type="text/template" class="tab-content" [formGroup]="sForm">
                <div id="sNotes" class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="sNotes-tab">
                    <div class="row">
                        <div class="col-md-3">
                            <button title="New Session Notes" type="button" class="btn" data-bs-toggle="modal"
                                data-bs-target="#exampleModal" (click)="newSForm()"><i class="bi bi-file-earmark"></i>
                                Create New Session Notes</button>
                        </div>
                        <div class="col-md-3">
                            <button title="Save Session Notes" type="submit" class="btn" (click)="saveSForm()"><i
                                    class="bi bi-file-earmark-plus"></i> Save New Session Notes</button>
                        </div>
                        <div class="col-md-3">
                            <button title="Update Session Notes" type="submit" class="btn" (click)="updateSForm()"><i
                                    class="bi bi-save"></i> Update Current Session Notes</button>
                        </div>
                        <div class="col-md-3">
                            <button title="Save and Upload Session Notes" type="submit" class="btn"
                                (click)="updateSForm()"><i class="bi bi-upload"></i> Upload Current Session Notes To
                                Records</button>
                        </div>
                        <div class="col-md-12">
                            <a title="Previous" (click)="prevsRpt()" style="padding-right: .5rem;font-size: 1.25rem;"><i
                                    class="bi bi-box-arrow-left"></i></a>
                            <div class="form-group" style="display: inline-block;">
                                <label for="SessionDate">Date of Group Therapy Session</label>
                                <input type="date" class="form-control" [value]="sNote.Date" id="SessionDate"
                                    formControlName="SessionDate">
                            </div>
                            <a title="Next" (click)="nextsRpt()" style="padding-left: .5rem;font-size: 1.25rem;"><i
                                    class="bi bi-box-arrow-right"></i></a>
                        </div>
                    </div>
                    <div class="row" *ngIf="sNotes.length>0">
                        <div class="col-md-6">
                            <div class="form-floating">
                                <input type="text" class="form-control" name="sName" id="sName"
                                    placeholder="Group Therapy Facilitator" [value]="sNote.Name" required>
                                <label for="sName">Group Session Name</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating">
                                <input type="text" name="sTitle" class="form-control" id="sTitle" [value]="sNote.Title"
                                    formControlName="sTitle">
                                <label for="sTitle">Title of Group Session Report</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating">
                                <input type="text" class="form-control" name="Facilitator" id="Facilitator"
                                    placeholder="Group Therapy Facilitator" [value]="sNote.Facilitator"
                                    formControlName="Facilitator" required>
                                <label for="Facilitator">Group Therapy Facilitator</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating">
                                <textarea class="form-control textarea" id="Overview" aria-label="Session Overview"
                                    [value]="sNote.Overview" formControlName="Overview"></textarea>
                                <label for="Overview">Session Overview</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating">
                                <textarea class="form-control textarea" id="ParticipantObservation"
                                    aria-label="Participant Observation" [value]="sNote.ParticipantObservation"
                                    formControlName="ParticipantObservation"></textarea>
                                <label for="ParticipantObservation">Participant Observation</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating">
                                <textarea class="form-control textarea" id="GroupDynamic" aria-label="GroupDynamic"
                                    [value]="sNote.GroupDynamic" formControlName="GroupDynamic"></textarea>
                                <label for="GroupDynamic">Group Dynamic</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating">
                                <textarea class="form-control textarea" id="TherapistObservation"
                                    aria-label="TherapistObservation" [value]="sNote.TherapistObservation"
                                    formControlName="TherapistObservation"></textarea>
                                <label for="TherapistObservation">Therapist's Observation</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating">
                                <textarea class="form-control textarea" id="sRecommendation"
                                    aria-label="Session Recommendation" [value]="sNote.Recommendation"
                                    formControlName="Recommendation"></textarea>
                                <label for="sRecommendation">Recommendation</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div id="pDemographics-Page" class="tab-content"><app-demographic id="pDemographics"
                    class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="pDemographics-tab"
                    [patient]="patient" [cmpy]="cmpy" *ngIf="patientRdy"></app-demographic></div>
            <div id="pHistory-Page" class="tab-content"><app-history id="pHistory"
                    class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="pHistory-tab"
                    [patient]="patient" [cmpy]="cmpy" *ngIf="patientRdy"></app-history></div>
            <div id="treatmentPlan-Page" class="tab-content"><app-notes-treatment-plan id="pTreatmentPlan"
                    class="tab-pane row fade notePage" role="tabpanel" aria-labelledby="pTreatmentPlan-tab"
                    [patient]="patient" [cmpy]="cmpy" *ngIf="patientRdy"></app-notes-treatment-plan></div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Create New Report</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</div>