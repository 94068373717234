import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Staff, Company, Location, Department, Encounter, GroupSession } from '../Store/Model/type.Interfeaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiBaseUrl; // Centralized API URL
  private sanctumUrl = environment.sanctumUrl; // Centralized sanctumUrl URL

  constructor(
    private http: HttpClient,
    private router: Router // Removed unnecessary dependencies
  ) { }

  /**
  * Centralized error handling for HTTP requests.
  */
  private handleError(message: string): (error: HttpErrorResponse) => Observable<never> {
    return (error: HttpErrorResponse) => {
      let errorMessage = `${message}: ${error.status} - ${error.message}`;
      console.error(errorMessage);
      if (error.error instanceof ErrorEvent) {
        // Client-side error
        errorMessage += `: Client-side error: ${error.error.message}`;
      } else {
        // Server-side error
        errorMessage += `: Server-side error: ${error.status} - ${error.message}`;
      }
      console.error(errorMessage);
      return throwError(() => new Error(errorMessage));
    };
  }

  // Replacing `console.log` with structured logging or removing it for production
  ApptER(ehrData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/appt/ehr`, ehrData)
      .pipe(catchError(this.handleError("an error happened!")));
  }

  GetPatientsLoc(lID: any): Observable<any> { return this.http.post(`${this.apiUrl}/patients/loc`, { lID }); }
  GetPatientsDpt(lID: any, dID: any): Observable<any> { console.log(`${this.apiUrl}/patients/dpt`, { lID, dID }); return this.http.post(`${this.apiUrl}/patients/dpt`, { lID, dID }); }
  GetPatientEncntrs(pID: any): Observable<any> { return this.http.post(`${this.apiUrl}/patient/encntrs`, { pID }); }
  getQuestionnaire(lID: any): Observable<any> { return this.http.post(`${this.apiUrl}/assessments/loc`, { lID }); }

  GetPatient(pID: any): Observable<any> { console.log('pID:', pID); return this.http.get(`${this.apiUrl}/pt/encntr/${pID}`); }
  patientUD(pData: any): Observable<any> {
    console.log('Update', 'pData:', pData);
    return this.http.post(`${this.apiUrl}/patient/update/demographic`, { id: pData.id, pData: pData });
  }
  historyUD(hData: any): Observable<any> {
    console.log('Update', 'hData:', hData);
    return this.http.post(`${this.apiUrl}/patient/update/history`, { id: hData.id, hData: hData });
  }
  encntrUD(id: any, eData: any): Observable<any> {
    console.log('Update', 'eData:', eData);
    return this.http.post(`${this.apiUrl}/patient/update/encntr`, { id: id, eData: eData });
  }
  treatmentUD(tData: any): Observable<any> {
    console.log('Update', 'tData:', tData);
    return this.http.post(`${this.apiUrl}/patient/update/treatment`, { id: tData.id, hData: tData });
  }
  EncntrGet(pID: any): Observable<any> {
    console.log(`${this.apiUrl}/encntr/sh/${pID}`);
    return this.http.get(`${this.apiUrl}/encntr/sh/${pID}`);
  }

  EncntrNew(eData: any): Observable<any> {//console.log(eData);
    console.log(`${this.apiUrl}/encntr/new`, { EncounterData: eData });
    return this.http.post(`${this.apiUrl}/encntr/new`, { EncounterData: eData });
  }

  EncntrSV(pData: any, eData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/encntr/sv`, { PatientData: pData, EncounterData: eData });
  }

  // Consolidating redundant methods and ensuring error handling
  EncntrUD(encounter: Encounter): Observable<any> {
    return this.http.post(`${this.apiUrl}/encntr/ud`, { EncounterData: encounter })
      .pipe(catchError(this.handleError("an error happened!")));
  }
  updateEncntrEvents(encounterId: any, events: any[]): Observable<any> {
    console.log(`${this.apiUrl}/encntr/event`, { encounterId, events });
    return this.http.post(`${this.apiUrl}/encntr/event`, { encounterId, events });
  }
  EncntrEvntUD(encounterId: any, events: any[]): Observable<any> {
    console.log('Update EventData:', encounterId, events);
    return this.http.post(`${this.apiUrl}/encntr/event`, { encounterId, events });
  }
  EncntrUL(pData: any, eData: any): Observable<any> {
    console.log('Upload', 'pData:', pData, 'eData:', eData);
    return this.http.post(`${this.apiUrl}/encntr/ul`, { PatientData: pData, EncounterData: eData });
  }
  GetSessionCalendar() { }
  SessionSV(pData: any, sData: any): Observable<any> {
    console.log('Save', 'pData:', pData, 'sData:', sData);
    return this.http.post<any>(`${this.apiUrl}/sssn/sv`, { PatientData: pData, EncounterData: sData });
  }
  SessionUD(pData: any, sData: any): Observable<any> {
    console.log('Update', 'pData:', pData, 'eData:', sData);
    return this.http.post<any>(`${this.apiUrl}/sssn/ud`, { PatientData: pData, EncounterData: sData });
  }
  SessionUL(pData: any, sData: any): Observable<any> {
    console.log('Upload', 'pData:', pData, 'eData:', sData);
    return this.http.post<any>(`${this.apiUrl}/sssn/ul`, { PatientData: pData, EncounterData: sData });
  }
  //ApptER(ehrData: any): Observable<any> {console.log(`${this.apiUrl}/appt/ehr`, { ehrData });
  //  return this.http.post(`${this.apiUrl}/appt/ehr`, { ehrData });
  // }
  ApptUD(appt: any): Observable<any> {
    console.log({ appt });
    return this.http.post(`${this.apiUrl}/appt/ud`, { appt });
  }
  ApptAV(appt: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/appt/av`, { appt });
  }
  GetApptDpt(lID: any, dID: any): Observable<any> {
    console.log(`${this.apiUrl}/appt/dpt`, { lID, dID });
    return this.http.post(`${this.apiUrl}/appt/dpt`, { lID, dID });
  }
  GetApptPt(aID: any): Observable<any> { return this.http.post(`${this.apiUrl}/appt/pt`, { aID }); }

  GetApptDptConfirm(lID: any, dID: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/appt/dpt/${lID}-${dID}/confirm`);
  }
  GetApptDptActive(lID: any, dID: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/appt/dpt/${lID}-${dID}/active`);
  }
  GetApptDptComplete(lID: any, dID: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/appt/dpt/${lID}-${dID}/complete`);
  }
  GetApptDptCancel(lID: any, dID: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/appt/dpt/${lID}-${dID}/cancel`);
  }
  GetApptLoc(lID: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/apptl/loc/${lID}`);
  }
  GetApptLocConfirm(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/appt/loc/${lID}/confirm`); }
  GetApptLocActive(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/appt/loc/${lID}/active`); }
  GetApptLocComplete(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/appt/loc/${lID}/complete`); }
  GetApptLocCancel(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/appt/loc/${lID}/cancel`); }

  /***
   *  Location - Department Configuration DB Management
   ***/
  getCmpyData(): Observable<any> {
    console.log(localStorage);
    let cmpy: any = localStorage.getItem('cmpy'); let cmpyJSON = JSON.parse(cmpy);
    if (cmpyJSON === null) { cmpyJSON = "redirect"; }
    else {
      const loc: any = localStorage.getItem('loc_'); const locJSON = JSON.parse(loc); console.log(locJSON);
      const dpt: any = localStorage.getItem('dpt_'); const dptJSON = JSON.parse(dpt); console.log(dptJSON);
      //this.http.get<any>('../../assets/dataApmts.json').subscribe(data=>{this.theAppts=data;console.log(this.theAppts);}); 
    }
    return cmpy;
  }
  GetLocationCnfg(lID: any): Observable<any> {
    console.log(lID);
    return this.http.get(`${this.apiUrl}/cnfg/loc/${lID}`);
  }

  getCnfg(cID: any): Observable<any> { return this.http.post(`${this.apiUrl}/cmpy/cnfg`, { cID }); }
  getCmpy(cID: any): Observable<any> { return this.http.post(`${this.apiUrl}/cmpy/get`, { cID }); }
  CmpySV(cmpy: Company): Observable<any> { return this.http.post(`${this.apiUrl}/cmpy/sv`, cmpy); }
  CmpyUD(cData: any): Observable<any> { console.log('UD', cData); return this.http.post<any>(`${this.apiUrl}/cmpy/ud`, cData); }

  getLocCnfg(cID: any, lID: any): Observable<any> { return this.http.post(`${this.apiUrl}/loc/cnfg`, { cID, lID }); }
  getLoc(cID: any, lID: any): Observable<any> { return this.http.post(`${this.apiUrl}/loc/get`, { cID, lID }); }

  getDptCnfg(cID: any, lID: any, dID: any): Observable<any> { return this.http.post(`${this.apiUrl}/dpt/cnfg`, { cID, lID, dID }); }
  getDpt(cID: any, lID: any, dID: any): Observable<any> { return this.http.post(`${this.apiUrl}/dpt/get`, { cID, lID, dID }); }

  getRptLoc(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/loc/${lID}/rpt`); }


  LocGet(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/loc/sh/${lID}`); }
  LocSV(loc: Location): Observable<any> { return this.http.post(`${this.apiUrl}/loc/sv`, loc); }
  LocUD(lData: any): Observable<any> { console.log('UD', lData); return this.http.post<any>(`${this.apiUrl}/loc/ud`, lData); }

  DptGet(lID: any, dID: any): Observable<any> { return this.http.get(`${this.apiUrl}/loc/sh/${lID}-${dID}`); }
  DptSV(dpt: Department): Observable<any> { return this.http.post(`${this.apiUrl}/dpt/sv`, dpt); }
  DptUD(dData: any): Observable<any> { console.log('UD', dData); return this.http.post<any>(`${this.apiUrl}/dpt/ud`, dData); }

  GSGet(lID: any, dID: any, gID: any): Observable<any> { return this.http.get(`${this.apiUrl}/gs/sh/${lID}-${dID}-${gID}`); }
  GSSV(gsData: GroupSession): Observable<any> { return this.http.post(`${this.apiUrl}/gs/sv`, gsData); }
  GSUD(gsData: any): Observable<any> { console.log('UD', gsData); return this.http.post<any>(`${this.apiUrl}/gs/ud`, gsData); }

  // Method to get CSRF token
  getCsrfToken(): Observable<void> {
    console.log('Requesting CSRF token from:', `${this.sanctumUrl}/sanctum/csrf-cookie`);
    return this.http.get<void>(`${this.sanctumUrl}/sanctum/csrf-cookie`, { withCredentials: true }).pipe(
      tap(() => {
        console.log('CSRF token successfully retrieved');
      }),
      catchError((error) => {
        console.error('Failed to retrieve CSRF token:', error);
        return throwError(() => new Error('Unable to fetch CSRF token. Please try again.'));
      })
    );
  }
  /**
   * Sign in the user using credentials and handle success/failure.
   */
  signIn(credentials: { E: string; pswrd: string }): Observable<any> {
    console.log('Attempting login to:', `${this.apiUrl}/auth/login`);
    return this.http.post<any>(`${this.apiUrl}/auth/login`, credentials, { withCredentials: true }).pipe(
      tap((response: any) => {
        if (response.status === 'success') {
          console.log('Login successful. User role:', response.staff?.R || 'staff');
        } else {
          console.warn('Login response indicates failure:', response);
        }
      }),
      catchError((error) => {
        // Handle error properly here
        console.error('Login failed:', error);
        return throwError(() => new Error('Login failed: Invalid credentials')); // Return a user-friendly error
      })
    );
  }

  /**
   * Log out a user and redirect to the login page.
   */
  logout(): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/logout`, {}, {
      withCredentials: true, // Ensure `HttpOnly` cookies are sent to invalidate the session
    }).pipe(
      tap((response: any) => {
        if (response.status === 'success') {
          // Session is successfully invalidated on the server.

          // Perform any additional front-end cleanup (optional)
          console.log('User logged out successfully.');

          // Redirect user to the login page or other public route
          //this.router.navigate(['/login']);
        } else {
          throw new Error('Logout failed: Invalid response from server');
        }
      }),
      catchError((error) => {
        // Handle error properly here
        console.error('Logout failed:', error);
        return throwError(() => new Error('Logout failed: Unable to process the request'));
      })
    );
  }

  GetStf(sID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/sh/${sID}`); }
  GetLocStf(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/actv/${lID}`); }
  GetDactvStf(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/dactv/${lID}`); }
  GetLocDactvStf(lID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/loc/dactv/${lID}`); }
  GetDptStf(lID: any, dID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/dpt/${lID}-${dID}`); }
  GetDptDactvStf(lID: any, dID: any): Observable<any> { return this.http.get(`${this.apiUrl}/stf/dpt/dactv/${lID}-${dID}`); }
  StfSV(staff: Staff): Observable<any> { return this.http.post(`${this.apiUrl}/stf/sv`, staff); }
  StfUD(sData: any): Observable<any> { return this.http.post<any>(`${this.apiUrl}/stf/ud`, sData); }
  StfDA(sData: any): Observable<any> { return this.http.post<any>(`${this.apiUrl}/da`, sData); }
  // Access staff profile
  profileStaff(sID: any): Observable<any> { return this.http.post(`${this.apiUrl}/auth/profile`, sID); }
  register(staff: Staff): Observable<any> { return this.http.post<any>(`${this.apiUrl}/auth/register`, staff); }

  predictICD10(patientData: any): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiUrl}/predict-icd10`, patientData);
  }
  // In your auth.service.ts or a new service file
  predictJCodes(treatmentDetails: any): Observable<string[]> {
    return this.http.post<string[]>(`${this.apiUrl}/jcode/predict`, treatmentDetails);
  }

}
