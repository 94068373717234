import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { ToastrService } from 'ngx-toastr'; // Assume ngx-toastr is used for toasts
import * as _ from 'lodash-es';

import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { AppointmentMonitorService } from '../../../service/appointment-monitor.service';
import { SocketService } from '../../../service/socket.service';
import { AppointmentDetailPageComponent } from '../appointment-detail-page/appointment-detail-page.component';
import { EncounterStatusUpdateComponent } from '../encounter-status-update/encounter-status-update.component';
import { AppointmentDataService } from 'src/app/service/appointment-data.service';

@Component({
  selector: 'app-appointments-page',
  templateUrl: './appointments-page.component.html',
  styleUrls: ['./appointments-page.component.css']
})

export class AppointmentsPageComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  private onDestroy = new Subject<void>();

  errors: any = null;
  isLoading: boolean = true;

  header: boolean = false;
  [x: string]: any;
  rows: any[] = [{ Appt_DT_TM: "", AptStatus: "", RESOURCE_1: "", APPT_SYNONYM_FREE: "", NAME_FULL_FORMATTED: "", MRN: "", BIRTH_DT_TM: "", PCP_NAME: "", PROBLEMS: "" }];
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  chrtCnfg: any = [];
  stf: any;
  patients: any;
  appointments: any[] = [];
  appointments$ = this.appointmentDataService.appointments$;
  appointmentsStatus$!: Observable<boolean[]>;
  apptManualAccess: boolean = false;

  @Output() deleteEvt = new EventEmitter();
  @Output() updateEvt = new EventEmitter();
  dateNow: Date = new Date();
  formattedDate = this.dateNow.toDateString();
  dateNowISO = this.dateNow.toISOString();

  constructor(
    private route: ActivatedRoute,
    private aptSrvc: AppointmentMonitorService,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private appointmentDataService: AppointmentDataService,
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private socketService: SocketService, // Your custom socket service
    private toastr: ToastrService, // Toastr service for notifications
    private ngxCsvParser: NgxCsvParser) {
    this.formattedDate = this.dateNow.toDateString();
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
      //const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log("locs", this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log("loc", this.loc);
      //this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt'); this.dpt = JSON.parse(dpt); console.log("dpt", this.dpt);
    }
  }

  @ViewChild('fileImportInput') fileImportInput: any;
  chkForNewAppointments($event: any): void {
    this.isLoading = true;
    const files = $event.srcElement.files;// Select the files from the event
    let aData: any = [];
    this.header = true; let ehrData: any = []; let e = 0;
    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: any) => {
        this.appointments = result; console.log('this.appointments: ', this.appointments);
        let a_ = ''; for (let a = 0; a < this.appointments.length; a++) { a_ += JSON.stringify(this.appointments[a]) + ','; }
        a_ = '[' + a_.substring(0, a_.length - 1) + ']';
        ehrData = JSON.stringify({ "lID": this.lID, "dID": this.dID, "appts": a_ });
        this.aSrvc.ApptER(ehrData).subscribe(
          (result) => { aData = result; },
          (error) => { this.errors = error.error; },
          () => {
            this.appointments = aData[0].appointments; console.log('this.appointments: ', this.appointments);
            for (let a = 0; a < this.appointments.length; a++) {
              this.appointments[a].Patient = JSON.parse(this.appointments[a].Patient);
              this.appointments[a].Details = JSON.parse(this.appointments[a].Details);
            }
            this.appointments = _.sortBy(this.appointments, ['Time']); console.log(this.appointments);
            this.appointmentDataService.updateAppointmentsData(this.appointments); console.log(this.appointments$);
            this.isLoading = false;
          }
        );
      }, (error: NgxCSVParserError) => { console.log('Error', error); });
  }

  getAllAppointments() {
    this.isLoading = true;
    let aData: any; console.log(this.lID, this.dID);
    this.aSrvc.GetApptDpt(this.lID, this.dID).subscribe(
      (result) => { aData = result; },
      (error) => { this.errors = error.error; console.log(this.errors); this.isLoading = true; },
      () => {//console.log(aData);
        this.appointments = _.sortBy(aData, ['Time']);
        this.appointmentDataService.updateAppointmentsData(this.appointments); console.log(this.appointments$);
        this.isLoading = false;
      }
    );
  }
  getCnfrmAppointments() {
    this.isLoading = true;
    let aData: any; console.log(this.lID, this.dID);
    this.aSrvc.GetApptDptConfirm(this.lID, this.dID).subscribe(
      (result) => { aData = result; },
      (error) => { this.errors = error.error; console.log(this.errors); this.isLoading = true; },
      () => {//console.log(aData);
        this.appointments = _.sortBy(aData, ['Time']);
        this.appointmentDataService.updateAppointmentsData(this.appointments); console.log(this.appointments$);
        this.isLoading = false;
      }
    );
  }
  dialogRef: any;
  theUDRst: any;
  /*
  alertStatusUpdate(apt_: object) {
    this.theUDRst = this.OpenInterventionPopup(apt_).subscribe(); console.log(this.theUDRst ? "yes" : "no");
  }
  */
  openInterventionUpdateDialog(updateData: any): void {
    this.dialogRef = this.dialog.open(EncounterStatusUpdateComponent, {
      width: '90%', enterAnimationDuration: '1000ms', exitAnimationDuration: '500ms', data: updateData
    })
  }
  statusUpdate(apt_: object) {
    this.theUDRst = this.OpenPopup(apt_).subscribe(); console.log(this.theUDRst ? "yes" : "no");
  }
  OpenPopup(apt_: object): any {
    console.log(apt_);
    this.dialogRef = this.dialog.open(AppointmentDetailPageComponent, {
      width: '90%', enterAnimationDuration: '1000ms', exitAnimationDuration: '500ms', data: { appointment: apt_, }
    })
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.apptManualAccess = this.cmpy.Cnfg.apptUpdate === "manual" ? true : false;
    const lID = this.route.snapshot.paramMap.get('param1');
    const dID = this.route.snapshot.paramMap.get('param2');console.log(this.lID, this.dID);
    if (lID !== null) { this.lID = lID; this.dID = dID; }
    else { this.lID = localStorage.getItem('currentLID'); this.dID = localStorage.getItem('currentDID'); }
    localStorage.setItem('currentLID', this.lID); localStorage.setItem('currentDID', this.dID);console.log(this.lID, this.dID);
    let aData: any;
    this.aSrvc.GetApptDpt(this.lID, this.dID).subscribe(
      (result) => { aData = result; },
      (error) => { this.errors = error.error; console.log(this.errors); this.isLoading = true; },
      () => {
        this.appointments = [];
        this.appointments = _.sortBy(aData, ['Time']);
        this.appointmentDataService.updateAppointmentsData(this.appointments); console.log(this.appointments$);
        this.isLoading = false;
        this.listenToAppointmentStatusChanges();
        //this.listenToPhq9SuicideAlertEvent();
      }
    );
  }
  private listenToAppointmentStatusChanges(): void {
    this.socketService.connect();// Ensures the socket is connected before subscribing to events
    // Subscribes to the AppointmentStatusChanged event from the SocketService
    const appointmentStatusChangeSubscription = this.socketService.subscribeToAllAppointments()
      .subscribe({
        next: (data: any) => {
          console.log('AppointmentStatusChanged received:', data);
          // Find the index of the appointment with the matching id in the appointments array
          const index = this.appointments.findIndex(appt => appt.id === data.id);
          // If the appointment is found, update it directly
          if (index !== -1) {
            // Assuming 'data' contains the updated status and possibly other updated fields
            this.appointments[index] = { ...this.appointments[index], ...data };
            // Optionally, if you need to trigger change detection manually (e.g., when using OnPush strategy)
            // this.cdRef.markForCheck(); // cdRef is a reference to ChangeDetectorRef injected into your component
            // Note: The spread operator ({...}) is used to create a new object with the updated properties
            // This ensures that we are not mutating the original object directly, which is a good practice in Angular applications
          }
        },
        error: (error) => console.error('Error receiving AppointmentStatusChanged:', error)
      });
    this.subscriptions.add(appointmentStatusChangeSubscription);
  }
  /**************************** * /
  private listenToEncounterInterventionUpdate(): void {
    this.socketService.connect();// Ensures the socket is connected before subscribing to events
    // Subscribes to the EncounterInterventionUpdate event from the SocketService
    const EncounterInterventionUpdateSubscription = this.socketService.subscribeToEncounterInterventionUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (update: any) => { this.handleEncounterInterventionUpdate(update); },
        error: (error: any) => console.error('Error receiving EncounterInterventionUpdate:', error)
      });
    this.subscriptions.add(EncounterInterventionUpdateSubscription);
  }
  private handleEncounterInterventionUpdate(update: any): void {
    if (['ALARM', 'Alarm-PHQ9', 'Alarm-Suicide'].includes(update.status)) {
      this.toastr.warning(`Alarm: ${update.details}`, 'Warning', {
        //timeOut: 10000, // Change as needed
        timeOut: 0, // Disables auto-dismiss
        extendedTimeOut: 0, // Disables auto-dismiss on hover
        tapToDismiss: true, // Requires the user to click the toastr to dismiss it
        closeButton: true, // Adds a close button to the toastr (optional, for user convenience)
        progressBar: false, // Disables the progress bar
        toastClass: 'ngx-toastr red-background' // Adds the custom class for styling
      });
      // Additional logic to process the update can be added here
    }
  }
  /**************************** * /
  private listenToPhq9SuicideAlertEvent(): void {
    this.socketService.connect();// Ensures the socket is connected before subscribing to events
    // Subscribes to the Phq9SuicideAlertEvent event from the SocketService
    const phq9SuicideAlertSubscription = this.socketService.subscribeToPhq9SuicideAlertEvent()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (data: any) => { this.handlephq9SuicideAlert(data); },
        error: (error: any) => console.error('Error receiving Phq9SuicideAlertEvent:', error)
      });
    this.subscriptions.add(phq9SuicideAlertSubscription);
  }
  private handlephq9SuicideAlert(data: any): void {
    const index = this.appointments.findIndex(appt => appt.id === data.id);
    const phq9AlarmApmt = this.appointments[index];
    console.log(data);
    this.toastr.error('A patient alarm: ', phq9AlarmApmt, {
      //this.toastr.error('A patient encounter alarm.', data, {
      timeOut: 0, // Disables auto-dismiss
      extendedTimeOut: 0, // Disables auto-dismiss on hover
      tapToDismiss: true, // Requires the user to click the toastr to dismiss it
      closeButton: true, // Adds a close button to the toastr (optional, for user convenience)
      progressBar: false, // Disables the progress bar
      toastClass: 'ngx-toastr red-background' // Adds the custom class for styling
    });
  }
  /**************************** */

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  getClass(value: string): string {
    switch (value) {
      case 'Appointment: Confirmed': return 'active-value';
      case 'Appointment: Active': return 'active-value';
      case 'Appointment: Complete': return 'active-value';
      case 'Appointment: Assessments Complete': return 'active-value';
      case 'Appointment: Did Not Finish': return 'active-value';
      case 'Appointment: No Show': return 'active-value';
      case 'Appointment: Canceled': return 'active-value';
      case 'Appointment: Delayed': return 'active-value';
      case 'Alarm: Suicidal Ideation': return 'alarm-value';
      case 'Alarm: Severe PHQ-9': return 'alarm-value';
      case 'Alarm: Severe SBQ-R': return 'alarm-value';
      case 'Alarm: High-Risk SBQ-R': return 'alarm-value';
      case 'Alarm: Immediate Risk Identified': return 'alarm-value';
      case 'Alarm: Severe GAD-7': return 'alert-value';
      case 'Alarm: Severe WHO5': return 'alert-value';
      case 'Intervention: Initiated': return 'alert-value';
      case 'Intervention: In Progress': return 'alert-value';
      case 'Intervention: Completed': return 'alert-value';
      case 'Intervention: Under Review': return 'alert-value';
      case 'Intervention: Adjusted': return 'alert-value';
      default: return ''; // Default class
    }
  }

}

