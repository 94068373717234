import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-demographic',
  templateUrl: './demographic.component.html',
  styleUrls: ['./demographic.component.css']
})
export class DemographicComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  isLoading: boolean = true;
  @Input() patient!: any;
  @Input() cmpy!: any;
  stf: any;
  pForm!: FormGroup;
  patientRdy: boolean = false;
  isVeteran = false;
  cnfg: any;
  errors: any = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private aSrvc: AuthService
  ) {
    this.loadStaff();
  }
  private loadStaff() {
    const stf = localStorage.getItem('stf');
    if (stf) {
      this.stf = JSON.parse(stf);
    } else {
      // Handle redirection if necessary
    }
  }
  ngOnInit() {
    this.cnfg = this.cmpy.Cnfg;
    this.initializeForm();
    this.checkStaffAccess();
    this.patientRdy = true;
  }

  private checkStaffAccess() {
    // Replace direct localStorage access with service method
    //if (!this.tSrvc.isLoggedIn()) {
    //  this.router.navigate(['login/:id']);
    //} else {
    // Fetch configurations using service
    this.fetchConfigurations();
    //}
  }

  private fetchConfigurations() {

    //console.log('this.pForm:', this.pForm);
  }

  private getValidators(status: string) {
    return status === 'required' ? [Validators.required] : [];
  }


  initializeForm(): void {
    this.pForm = this.fb.group({
      demographics: this.fb.group({
        dob: this.fb.group({
          y: [this.patient?.demographics?.dob?.y, Validators.required],
          m: [this.patient?.demographics?.dob?.m, Validators.required],
          d: [this.patient?.demographics?.dob?.d, Validators.required],
        }),
        name: this.fb.group({
          f: [this.patient?.demographics?.name?.f, Validators.required],
          m: [this.patient?.demographics?.name?.m],
          l: [this.patient?.demographics?.name?.l, Validators.required],
          full: [this.patient?.demographics?.name?.f + ' ' + this.patient?.demographics?.name?.l],
        }),
        gender: [this.patient?.demographics?.gender, Validators.required],
        race: [this.patient?.demographics?.race, Validators.required],
        ethnicity: [''],
        eColor: [this.patient?.demographics?.eColor],
        hColor: [this.patient?.demographics?.hColor],
        weightLbs: [this.patient?.demographics?.weightLbs],
        heightCM: [''],
        heightFt: [''],
        heightIn: [''],
        // Add more demographics fields with validators
      }),
      comm_method: this.fb.group({
        hp: [this.patient?.comm_method?.hp],
        wp: [this.patient?.comm_method?.wp],
        cp: [this.patient?.comm_method?.cp, Validators.required],
        E: [this.patient?.comm_method?.E, Validators.required],
        pmoc: [this.patient?.comm_method?.pmocE, Validators.required],
        // Add more address fields with validators
      }),
      Adr: this.fb.group({
        A: [this.patient?.Adr?.A, Validators.required],
        A2: [this.patient?.Adr?.A2, Validators.required],
        C: [this.patient?.Adr?.C, Validators.required],
        S: [this.patient?.Adr?.S, Validators.required],
        Z: [this.patient?.Adr?.Z, Validators.required],
        // Add more address fields with validators
      }),
    });
    this.pForm.valueChanges.subscribe(value => { this.updatePatient(value); });
    this.isLoading = false;
  }

  private updatePatient(formValue: any) {
    this.patient = {
      ...this.patient,
      demographics: formValue.demographics,
      Adr: formValue.Adr,
      comm_method: formValue.comm_method,
    };
  }

  modifyKeys(data: any): any {
    const modifiedData: any = {};
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        modifiedData[key] = data[key].map((item: any) => this.modifyKeys(item));
      } else if (typeof data[key] === 'object') {
        modifiedData[key] = this.modifyKeys(data[key]);
      } else {
        const match = key.match(/(\d+)$/);
        const newKey = match ? key.replace(match[0], '') : key;
        modifiedData[newKey] = data[key];
      }
    });
    return modifiedData;
  }

  updatePForm() {
    this.isLoading = true;
    const udData = {
      id: this.patient.id,
      demographics: this.pForm.value.demographics,
      Adr: this.pForm.value.Adr,
      comm_method: this.pForm.value.comm_method,
    };
    
    this.aSrvc.patientUD(udData).subscribe(
      (result) => {
        if (result.status === 'success') {
          this.patient = result.patient;
          this.toastr.success(result.message);
        }
        this.isLoading = false;
      },
      (error) => {
        this.errors = error.error;
        this.toastr.error('Failed to update patient information');
        this.isLoading = false;
      }
    );
  }
  ngOnDestroy() { this.subscription.unsubscribe(); }

}
