import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable, ReplaySubject, EMPTY, filter, take } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: Socket | undefined;
  private connectionSubject = new ReplaySubject<boolean>(1);
  //private listeners = new Set<string>();
  private eventObservers = new Map<string, Observable<any>>();

  constructor() {
  }
  // Dynamically connect to the socket server
  connect(): void {
    if (this.socket) {//console.log('Already connected or in the process of connecting');
      return; // Already connected or in the process of connecting
    }
    this.socket = io(environment.socketUrl, {
      // Additional options if necessary
      // Example: Auth token if required
      // auth: { token: "your_auth_token" },
      // Reconnection attempts
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    });

    this.socket.on('connect', () => {console.log('Socket.IO connected');
      this.connectionSubject.next(true);
    });
    this.socket.on('disconnect', () => {console.log('Socket.IO disconnected');
      this.connectionSubject.next(false);
      this.eventObservers.clear(); // Ensure you clear the correct reference.
      this.socket = undefined;
    });
    // Listen to server-side errors as well
    this.socket.on('connect_error', (err) => console.error('Socket.IO connect_error:', err));
  }

  // Method to emit events
  //emit(eventName: string, data: any) {
  //  this.socket.emit(eventName, data);
  //}
  // Emit events
  emit(eventName: string, data: any): void {
    if (!this.socket) {console.warn('Not connected to socket, connecting...');
      this.connect(); // Ensure connection before attempting to emit
      this.connectionSubject.pipe(filter((connected: boolean) => connected), take(1))
        .subscribe(() => {
          this.socket?.emit(eventName, data);
        });
      } else {
        this.socket.emit(eventName, data);
      }
  }

  // Listen to events with Observable
  on(eventName: string): Observable<any> {
      //console.log('Listen to events with Observable:', eventName);
    if (!this.socket) { this.connect(); }// Ensure connection before attempting to listen
    if (!this.eventObservers.has(eventName)) {
      const observable = new Observable<any>(observer => {
          const eventHandler = (data: any) => observer.next(data);
          this.socket?.on(eventName, eventHandler);
          return () => {
              this.socket?.off(eventName, eventHandler);
          };
      });
      this.eventObservers.set(eventName, observable);
      return observable;
  }
  return this.eventObservers.get(eventName) as Observable<any>;
}

  // Simplified method for subscribing to the AppointmentStatusChanged event
  subscribeToAllAppointments(): Observable<any> { return this.on('AppointmentStatusChanged'); }
  subscribeToEncounterUpdate(): Observable<any> { return this.on('EncounterUpdate'); }
  //subscribeToPhq9SuicideAlertEvent(): Observable<any> { return this.on('Phq9SuicideAlertEvent'); }
  subscribeToEncounterInterventionUpdate(): Observable<any> { return this.on('EncounterInterventionUpdate'); }
  getUpdates(): Observable<any> { return this.on('EncounterInterventionUpdate'); }

  // Disconnect the socket
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
      this.connectionSubject.next(false);
      }
  }

  // Observable to watch the connection status
  getConnectionStatus(): Observable<boolean> {
    return this.connectionSubject.asObservable();
  }
}
