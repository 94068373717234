import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';

@Component({
  selector: 'app-assessment-results',
  templateUrl: './assessment-results.component.html',
  styleUrls: ['./assessment-results.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AssessmentResultsComponent implements OnInit {
  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  pD_: any;
  assessments: any;
  vEnote: boolean = false;
  vPresenting: boolean = false;
  vRecommendation: boolean = false;
  vIBHAssessment: boolean = false;
  vMedManagement: boolean = false;
  vTherapistObservation: boolean = false;
  vEtype: string = '';

  ePrmD_: any;
  qD: any;
  aD: any = [];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    public fb: FormBuilder,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public axData: any
  ) {
    this.stf = this.uSrvc.getLS_item('stf'); console.log("stf", this.stf);
    if (this.stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else {
      this.cmpy = this.uSrvc.getLS_item('cmpy');
    }
  }
  updateAssessment(pD: any, eD: any) {
    pD.encounter['EHR-UL'] = "2023-11-06";
    pD.encounter['EHR-UL-sID'] = "imsJTM";
    this.aSrvc.EncntrUL(pD.patient_id, pD).subscribe(item => {
      const pdItem: any = item; //console.log(pdItem);
    });
  }

  ngOnInit() {
    console.log("this.axData:", this.axData);
    this.ePrmD_ = this.axData.assessment; //console.log("E:", this.ePrmD_);
    this.pD_ = this.axData.pD_; //console.log("P:", this.pD_);
    this.aSrvc.getQuestionnaire(this.stf.lID).subscribe(
      (result) => { this.responseHandler(result); this.rsp = result; },
      (error) => { this.errors = error.error; },
      () => {
        const Qs__: any = this.rsp; let k_ = 0;
        for (let q = 0; q < Qs__.length; q++) { let x_ = JSON.parse(Qs__[q].Questionaires); Qs__[q].Questionaires = x_; x_ = JSON.parse(Qs__[q].Responses); Qs__[q].Responses = x_; } //console.log('Qs__', Qs__);
        for (const key of Object.keys(this.ePrmD_.prom)) {
          //console.log(key,Qs__);
          let qFind = []; let rSrch: any = [];
          for (let q = 0; q < Qs__.length; q++) {
            qFind = [];//console.log(key,q,Qs__[q].Type);
            let qSrch: any = Qs__[q].Questionaires;//console.log('qSrch: ',qSrch);
            rSrch = Qs__[q].Responses;//console.log('rSrch:',rSrch);
            qFind = qSrch.find((q_: any) => q_.ID === key);//console.log('qFind',qFind);
            if (qFind !== undefined && qFind.ID === key) { break; }//console.log('qFind',qFind,qFind.ID);
          }//Qs_=qFind;
          this.aD[k_] = [];
          const Q_ = this.ePrmD_.prom[key];
          const R_: any = qFind.question;//console.log(R_);
          const rg0V_: any = rSrch[0].rg0;//console.log(rg0V_);
          const rg1V_: any = rSrch[0].rg1;
          const rg2V_: any = rSrch[0].rg2;
          const cbV_: any = rSrch[0].cb;
          let aTxt: any = "";
          let a_: any = [];
          this.aD[k_].results = [];
          if (this.ePrmD_.promSummary !== null && key in this.ePrmD_.promSummary) {
            this.aD[k_]["hdr"] = qFind.name + ": " + this.ePrmD_.promSummary[key].sumR + " | " + this.ePrmD_.promSummary[key].sumS;
          }
          else { 
            //console.error("this.ePrmD_.note.Goal",this.ePrmD_.note.Goal);
            if ( this.ePrmD_.note.Goal !== undefined && this.ePrmD_.note.Goal !== null && key in this.ePrmD_.note.Goal) {
              this.aD[k_]["hdr"] = qFind.name + ": " + this.ePrmD_.note.Goal[key].Crnt;
            }
            else{this.aD[k_]["hdr"] = qFind.name;console.error("this.ePrmD_.note.Goal",this.ePrmD_.note.Goal);} 
          }
          for (let i = 0; i < Q_.length; i++) {
            if (R_[i].response === 'rg1') { a_ = Q_[i]; aTxt = rg1V_.find((a: any) => a.value === a_.r); }
            else { a_ = Q_[i]; aTxt = rg0V_.find((a: any) => a.value === a_.r); }
            if (aTxt === null ||  aTxt === undefined) {
              aTxt=[];aTxt.text='';
            }
              //console.log("R_[i].text",R_[i].text,"aTxt.text",aTxt.text);
            // @ts-ignore
            this.aD[k_].results.push({ "q": R_[i].text, "r": aTxt.text, "v": a_.r, "t": a_.t });
          }
          k_++;
        }
        this.vEnote = false; this.vRecommendation = false; this.vEtype = "PC";
        this.vPresenting = false;
        this.vIBHAssessment = false;
        this.vMedManagement = false;
        this.vTherapistObservation = false;
        this.vPresenting = false;
        console.log(this.aD);
      }
    );
  }

  // Handle response
  responseHandler(data: any) {
    //this.token.handleData(data.atoken);
  }
}