<mat-dialog-content>
    <form [formGroup]="filterForm">
        <div class="form-group" style="display: inline-block;">
            <label for="eDate">Date</label><input type="date" class="form-control" id="eDate" formControlName="Date">
        </div>
        <label>Date Range</label>
        <select class="form-select" formControlName="dateRange">
            <option selected>Select Date Range</option>
            <option [value]="o_.value" *ngFor="let o_ of cmpy.Cnfg.dateRange">
                {{ o_.label }}
            </option>
        </select>
        <label>Score Groups</label>
        <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
            <input type="checkbox" class="btn-check" id="btncheck1" autocomplete="off" checked formControlName="minimum">
            <label class="btn btn-outline-success" for="btncheck1">Minimum</label>
            <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off" checked formControlName="mild">
            <label class="btn btn-outline-mild" for="btncheck2">Mild</label>
            <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off" checked formControlName="moderate">
            <label class="btn btn-outline-warning" for="btncheck3">Moderate</label>
            <input type="checkbox" class="btn-check" id="btncheck4" autocomplete="off" checked formControlName="severe">
            <label class="btn btn-outline-danger" for="btncheck4">Severe</label>
        </div>

        <label>Gender</label>
        <select class="form-select" formControlName="gender">
            <option value="" selected>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
        </select>
        <label>Show Patients with the Additional Questionnaire</label>
        <select class="form-select" formControlName="prom">
            <option selected>Open this select menu</option>
            <option [value]="o_.value" *ngFor="let o_ of cmpy.Cnfg.prom">
                {{ o_.label }}
            </option>
        </select>
        <!-- 
            Adolescents (13-17 years): This period involves significant physical, emotional, and social development. Therapies often focus on identity, self-esteem, peer relationships, substance use, and mental health issues like depression and anxiety. Approaches may include individual therapy, group therapy, and family-based interventions.

            Young Adults (18-25 years): Transitioning to adulthood can bring challenges such as college stress, starting a career, and developing adult relationships. Therapeutic interventions might focus on life transitions, mental health disorders, and developing healthy coping strategies.

            Adults (26-64 years): Interventions often address life stressors, mental health disorders, relationship issues, parenting challenges, and work-life balance. Therapy may include individual, couple, or group formats, with a variety of therapeutic modalities employed.

            Older Adults (65 years and older): Therapy may focus on issues related to aging, such as grief and loss, physical health issues, cognitive decline, and life transitions. Interventions can include individual therapy, caregiver support, and interventions aimed at enhancing quality of life.
        -->
        <label>Age Range</label>
        <select class="form-select" formControlName="ageRange">
            <option selected>Select Age Grouping</option>
            <option [value]="o_.value" *ngFor="let o_ of cmpy.Cnfg.ageRange">
                {{ o_.label }}
            </option>
        </select>
        <label>Sort By</label>
        <select class="form-select" formControlName="sortBy">
            <option selected>Open this select menu</option>
            <option value="prom">PHQ-9</option>
            <option value="eDate">Encounter Date</option>
            <option value="lName">Last Name</option>
            <option value="dob">Date of Birth</option>
        </select>
        <div class="form-check form-switch" style="display: block;">
            <label class="form-check-label" for="showVets">Veteran</label>
            <input class="form-check-input" type="checkbox" Role="switch" id="showVets" formControlName="showVets">
        </div>
        <div class="form-check form-switch" style="display: block;">
            <label class="form-check-label" for="showNotes">Notes</label>
            <input class="form-check-input" type="checkbox" Role="switch" id="showNotes" formControlName="showNotes">
        </div>
        <!-- Additional filters as needed -->
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="filterForm.value">Apply Filters</button>
</mat-dialog-actions>