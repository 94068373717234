<div class="container-fluid" style="padding:0.0625rem;">
    <div class="row" style="margin:.125rem 0;width:100%;float:left;">
        <!--div class="col-md-10"><h5 class="fw-normal mb-1 pb-1" style="letter-spacing:1px;margin:.25rem;">Patient Profile Editor</h5></div!-->
        <div class="col-md-8"></div>

        <div class="col-md-2">
            <button title="Save Patient Profile" type="submit" class="btn" (click)="updatePForm()">Save Profile <i
                    class="bi bi-save"></i></button>
        </div>
        <div class="col-md-2">
            <button type="button" class="btn btn-primary" (click)="predictICD10()">Predict ICD-10 Code</button>
        </div>
    </div>
    <div class="row" style="margin:.125rem 0;width:100%;float:left;">
        <div *ngIf="predictedICD10Codes.length">
            <!-- Add this in your template where you want to show the predictions -->
            <div *ngIf="predictedICD10Codes.length">
                <h5>Predicted ICD-10 Codes:</h5>
                <ul>
                    <li *ngFor="let code of predictedICD10Codes">
                        {{ code }}
                        <button (click)="confirmCode(code)">Confirm</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <form id="eForm" type="text/template" class="row g-3 tab-content" [formGroup]="patientForm" *ngIf="patientRdy"
        style="padding:0.5rem;height:85vh;overflow-y:scroll;">
        <div class="row editGrpDiv">
            <div class="col-md-4">
                <label for="fName" class="form-label">First Name</label>
                <input type="text" class="form-control" id="fName" name="fName" formControlName="fName">
            </div>
            <div class="col-md-4">
                <label for="mName" class="form-label">Middle Name</label>
                <input type="text" class="form-control" id="mName" name="mName" formControlName="mName">
            </div>
            <div class="col-md-4">
                <label for="lName" class="form-label">Last Name</label>
                <input type="text" class="form-control" id="lName" name="lName" formControlName="lName">
            </div>
        </div>
        <!-- Demographics -->
        <div formGroupName="demographics" class="row editGrpDiv">
            <div class="col-md-6 row"><label class="form-label">Date of Birth:</label>
                <div class="col-md-4">
                    <label for="dobY" class="form-label">YYYY:</label><input type="text" class="form-control" id="dobY"
                        formControlName="dobY" />
                </div>
                <div class="col-md-4">
                    <label for="dobM" class="form-label">MM:</label><input type="text" class="form-control" id="dobM"
                        formControlName="dobM" />
                </div>
                <div class="col-md-4">
                    <label for="dobD" class="form-label">DD:</label><input type="text" class="form-control" id="dobD"
                        formControlName="dobD" />
                </div>
            </div>
            <div class="col-md-3" style="margin-top: 1.75rem;">
                <label for="RaceSelect">Race</label>
                <select class="form-select" id="RaceSelect" formControlName="race">
                    <option value="">Select Race</option>
                    <option [value]="r_" *ngFor="let r_ of cnfg.raceOptions">{{ r_ }}</option>
                </select>
            </div>
            <div class="col-md-3" style="margin-top: 1.75rem;">
                <label for="EthnicitySelect">Ethnicity</label>
                <select class="form-select" id="EthnicitySelect" formControlName="ethnicity">
                    <option value="">Select Ethnicity </option>
                    <option [value]="e_" *ngFor="let e_ of cnfg.ethnicityOptions">{{ e_ }}</option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="EthnicitySelect">Gender</label>
                <select class="form-select" id="EthnicitySelect" formControlName="gender">
                    <option value="">Select Gender </option>
                    <option [value]="gi_.Opt" *ngFor="let gi_ of cnfg.Gender_Identity_Options">{{ gi_.Opt }}
                    </option>
                </select>
            </div>
            <!--div class="col-md-12 row"><span>Gender</span>
                    <div class="col-md-3" *ngFor="let gi_ of cnfg.Gender_Identity_Options; let i = index">
                        <div class="form-check">
                            <label class="form-control" [for]="'gender' + i">{{gi_.Opt}}
                                <input class="form-label" type="radio" name="gender" [id]="'gender' + i"
                                    [value]="gi_.Opt" formControlName="gender">
                            </label>
                        </div>
                    </div>
                </div!-->
            <div class="col-md-4">
                <label for="eyeColor" class="form-label">Eye Color</label>
                <input type="text" class="form-control" id="eyeColor" name="eyeColor" formControlName="eyeColor">
            </div>
            <div class="col-md-4">
                <label for="hairColor" class="form-label">Hair Color</label>
                <input type="text" class="form-control" id="hairColor" name="hairColor" formControlName="hairColor">
            </div>
            <!--div class="col-md-2"><app-converter></app-converter></div!-->
            <div class="col-md-4">
                <label for="heightFt" class="form-label">Height-Feet</label>
                <input type="text" class="form-control" id="heightFt" name="heightFt" formControlName="heightFt">
            </div>
            <div class="col-md-4">
                <label for="heightIn" class="form-label">Height-Inch</label>
                <input type="text" class="form-control" id="heightIn" name="heightIn" formControlName="heightIn">
            </div>
            <div class="col-md-4">
                <label for="weight" class="form-label">Weight</label>
                <input type="text" class="form-control" id="weight" name="weight" formControlName="weight">
            </div>
        </div>
        <!-- contact method -->
        <div formGroupName="comm_method" class="row editGrpDiv">
            <div class="col-md-6">
                <label for="hp" class="form-label">Home Phone</label><input type="phone" id="hp" class="form-control"
                    formControlName="hp">
            </div>
            <div class="col-md-6">
                <label for="wp" class="form-label">Work Phone</label><input type="phone" id="wp" class="form-control"
                    formControlName="wp">
            </div>
            <div class="col-md-6">
                <label for="cp" class="form-label">Cell Phone</label><input type="phone" id="cp" class="form-control"
                    formControlName="cp">
            </div>
            <div class="col-md-6">
                <label for="E" class="form-label">Email</label><input type="email" class="form-control" id="E" name="E"
                    formControlName="E">
            </div>
            <div class="col-md-12 row"><span>Preferred Method of Contact</span>
                <div class="col-md-3">
                    <div class="form-check">
                        <label class="form-control" for="pmocCP">Cell Phone <input class="form-label" type="radio"
                                name="pmoc" id="pmocCP" value="cp" formControlName="pmoc"></label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check">
                        <label class="form-control" for="pmocE">Email Phone <input class="form-label" type="radio"
                                name="pmoc" id="pmocE" value="e" formControlName="pmoc"></label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check">
                        <label class="form-control" for="pmocHP">Home Phone <input class="form-label" type="radio"
                                name="pmoc" id="pmocHP" value="hp" formControlName="pmoc"></label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-check">
                        <label class="form-control" for="pmocWP">Work Phone <input class="form-label" type="radio"
                                name="pmoc" id="pmocWP" value="wp" formControlName="pmoc"></label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Address -->
        <div formGroupName="address" class="row editGrpDiv">
            <div class="col-md-4">
                <label for="A" class="form-label">Address</label>
                <input type="text" class="form-control" id="A" placeholder="1234 Main St" name="A" formControlName="A">
            </div>
            <div class="col-md-2">
                <label for="A2" class="form-label">Address 2</label>
                <input type="text" class="form-control" id="A2" name="A2" formControlName="A2">
            </div>
            <div class="col-md-2">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" name="C" formControlName="C">
            </div>
            <div class="col-md-2">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" name="S" formControlName="S">
                    <option selected>Choose State</option>
                    <option [value]="s_.abbrv" *ngFor="let s_ of cnfg.usStateOptions">{{ s_.name }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="Z" class="form-label">Zip</label>
                <input type="text" class="form-control" id="Z" name="Z" formControlName="Z">
            </div>
            <!-- Add more address fields -->
        </div>
        <!-- Social History -->
        <div class="row editGrpDiv" formGroupName="social_history">
            <h5 class="col-md-12">Social History:</h5>
            <div class="row editGrpDiv">
                <div class="col-md-4">
                    <label for="preferredLanguage">Preferred Language</label>
                    <select class="form-select" id="preferredLanguage" formControlName="preferredLanguage">
                        <option value="">Select Languages</option>
                        <option [value]="pl_" *ngFor="let pl_ of cnfg.preferredLanguages">
                            {{ pl_ }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="religiousAffiliation">Religious Affiliation</label>
                    <select class="form-select" id="religiousAffiliation" formControlName="religiousAffiliation">
                        <option value="">Select Religious Affiliation</option>
                        <option [value]="ra_.status" *ngFor="let ra_ of cnfg.religiousAffiliationOptions">{{ ra_.status
                            }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="EducationSelect">Educational Level</label>
                    <select class="form-select" id="EducationSelect" formControlName="education">
                        <option value="">Select Education Level</option>
                        <option [value]="el_.status" *ngFor="let el_ of cnfg.educationOptions">
                            {{ el_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="Marital_StatusSelect">Marital Status</label>
                    <select class="form-select" id="Marital_StatusSelect" formControlName="maritalStatus">
                        <option value="">Select Marital Status</option>
                        <option [value]="ms_.Opt" *ngFor="let ms_ of cnfg.maritalStatusOptions">{{ ms_.Opt }}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="socialSupport">Social Support</label>
                    <select class="form-select" id="socialSupport" formArrayName="socialSupport">
                        <option value="">Select Your Social Support</option>
                        <option [value]="ss_.status" *ngFor="let ss_ of cnfg.socialSupportOptions">
                            {{ ss_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="occupation">Occupation</label>
                    <select class="form-select" id="occupation" formControlName="occupation">
                        <option value="">Select Occupation</option>
                        <option [value]="o_.status" *ngFor="let o_ of cnfg.occupationOptions">
                            {{ o_.status }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                    <label for="militarySrvc">Enlistment Status</label>
                    <select class="form-select" id="militarySrvc" formControlName="MilitarySrvcStatus"
                        (change)="onEnlistmentStatusChange($event)">
                        <option value="">Select Enlistment Status</option>
                        <option [value]="opt_" *ngFor="let opt_ of mpCnfg.MilitaryServiceStatus.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- Children -->
            <div class="editGrpDiv" formArrayName="eChildren">
                <h5>Children:</h5>
                <button type="button" class="btn" title="Add Child" (click)="add_eChildren()">Add
                    Child</button>
                <div *ngFor="let child of eChildren_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-6">
                            <label [for]="'eChild_Name' + i" class="form-label">Name</label>
                            <input type="text" [id]="'eChild_Name' + i" class="form-control"
                                [formControlName]="'eChild_Name' + i">
                        </div>
                        <div class="col-md-3">
                            <label [for]="'eChild_Relationship' + i" class="form-label">Relationship</label>
                            <select class="form-select" [id]="'eChild_Relationship' + i"
                                [formControlName]="'eChild_Relationship' + i">
                                <option value="">Select Relationship</option>
                                <option [value]="rel_.value" *ngFor="let rel_ of cnfg.relationships.options">{{
                                    rel_.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label [for]="'eChild_cP' + i" class="form-label">Cell Phone</label>
                            <input type="phone" [id]="'eChild_cP' + i" class="form-control"
                                [formControlName]="'eChild_cP' + i">
                        </div>
                        <div class="col-md-6">
                            <label [for]="'eChild_E' + i" class="form-label">Email</label>
                            <input type="email" [id]="'eChild_E' + i" class="form-control"
                                [formControlName]="'eChild_E' + i">
                        </div>
                        <div class="col-md-6">
                            <label [for]="'eChild_Note' + i" class="form-label">Note:</label>
                            <textarea class="form-control textarea" [id]="'eChild_Note' + i"
                                [formControlName]="'eChild_Note' + i"></textarea>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Emergency Contacts -->
            <div class="editGrpDiv" formArrayName="eContacts">
                <h5>Emergency Contacts:</h5>
                <button type="button" class="btn" title="Add Emergency Contact" (click)="add_eContact()">Add Contact</button>
                <div *ngFor="let contact of eContacts_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-8">
                            <label for="ec_N" class="form-label">Name</label>
                            <input type="text" id="ec_N" class="form-control" [formControlName]="'ec_Name' + i">
                        </div>
                        <div class="col-md-4">
                            <label for="ec_Relationship" class="form-label">Relationship</label>
                            <select class="form-select" id="ec_Relationship" [formControlName]="'ec_Relationship' + i">
                                <option value="">Select Relationship</option>
                                <option [value]="rel_.value" *ngFor="let rel_ of cnfg.relationships.options">{{
                                    rel_.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="ec_E" class="form-label">Email</label>
                            <input type="email" id="ec_E" class="form-control" [formControlName]="'ec_E' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_cP" class="form-label">Cell Phone</label>
                            <input type="phone" id="ec_cP" class="form-control" [formControlName]="'ec_cP' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_hP" class="form-label">Home Phone</label>
                            <input type="phone" id="ec_hP" class="form-control" [formControlName]="'ec_hP' + i">
                        </div>
                        <div class="col-md-3">
                            <label for="ec_wP" class="form-label">Work Phone</label>
                            <input type="phone" id="ec_wP" class="form-control" [formControlName]="'ec_wP' + i">
                        </div>
                        <div class="col-md-12 row"><span>Preferred Method of Contact</span>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-control" [for]="'ec_pmocCP' + i">Cell Phone
                                        <input class="form-label" type="radio" [id]="'ec_pmocCP' + i" value="cp"
                                            [name]="'ec_pmoc' + i" [formControlName]="'ec_pmoc' + i"></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-control" [for]="'ec_pmocE' + i">Email Phone
                                        <input class="form-label" type="radio" [id]="'ec_pmocE' + i" value="e"
                                            [name]="'ec_pmoc' + i" [formControlName]="'ec_pmoc' + i"></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-control" [for]="'ec_pmocHP' + i">Home Phone
                                        <input class="form-label" type="radio" [id]="'ec_pmocHP' + i" value="hp"
                                            [name]="'ec_pmoc' + i" [formControlName]="'ec_pmoc' + i"></label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <label class="form-control" [for]="'ec_pmocWP' + i">Work Phone
                                        <input class="form-label" type="radio" [id]="'ec_pmocWP' + i" value="wp"
                                            [name]="'ec_pmoc' + i" [formControlName]="'ec_pmoc' + i"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div formGroupName="LivingConditions" class="row editGrpDiv">
                <h5 style="width:100%;">Living Conditions:</h5>
                <div class="col-md-6" *ngFor="let t_ of socialCnfg.socialHistory[1].questions">
                    <label for="{{t_.type}}">{{t_.question}}</label>
                    <select class="form-select" id="{{t_.type}}" formControlName={{t_.type}}>
                        <option value="">Select</option>
                        <option [value]="opt_" *ngFor="let opt_ of t_.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
            </div>
            <div formArrayName="substanceUse" class="row editGrpDiv">
                <h5 style="width:100%;">{{socialCnfg.socialHistory[2].substanceUse.category}}:</h5>
                <div *ngIf="SubstanceUseInfo">
                    <div class="row editAIDiv"
                        *ngFor="let comment of patient.encounters[0].history.psychological_history.SubstanceUse">
                        <div class="col-md-6">{{comment.q}}</div>
                        <div class="col-md-6">{{comment.r}}</div>
                    </div>
                </div>

                <div class="col-md-4 editNestGrpDiv"
                    *ngFor="let t_ of socialCnfg.socialHistory[2].substanceUse.questions" formArrayName="{{t_.type}}">
                    <label for="{{t_.type}}">{{t_.question}}</label>
                    <select class="form-select" id="{{t_.type}}" formControlName={{t_.type}}Status>
                        <option value="">Select</option>
                        <option [value]="opt_" *ngFor="let opt_ of t_.options">
                            {{ opt_ }}
                        </option>
                    </select>
                    <!--div formGroupName="followUp" *ngIf="t_.followUp"!-->
                    <div *ngFor="let fup_ of t_.followUp">
                        <label for="{{fup_.type}}">{{fup_.question}}</label>
                        <select class="form-select" id="{{fup_.type}}" formControlName={{fup_.type}}>
                            <option value="">Select</option>
                            <option [value]="opt_" *ngFor="let opt_ of fup_.options">
                                {{ opt_ }}
                            </option>
                        </select>
                    </div>
                    <!--/div!-->
                </div>
            </div>
        </div>
        <!-- Military History -->
        <div class="row editGrpDiv" formGroupName="military_history" *ngIf="isVeteran">
            <h5 class="col-md-12" style=" width:100%;">Military History:</h5>
            <div class="col-md-2">
                <label for="A" class="form-label">Enlistment Year</label>
                <input type="text" class="form-control" id="enlistmentYear" name="enlistmentYear"
                    formControlName="enlistmentYear">
            </div>
            <div class="col-md-2">
                <label for="serviceBranch">Service Branch</label>
                <select class="form-select" id="serviceBranch" formControlName="serviceBranch">
                    <option value="">Select Service Branch</option>
                    <option [value]="opt_" *ngFor="let opt_ of mpCnfg.serviceBranch.options">
                        {{ opt_ }}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="retirementYear" class="form-label">Retirement Year</label>
                <input type="text" class="form-control" id="retirementYear" name="retirementYear"
                    formControlName="retirementYear">
                <!--app-year-picker (yearSelected)="onYearSelected($event)"></app-year-picker>
                <p>Selected Year:{{ selectedYear }}</p!-->
            </div>
            <div class="col-md-2">
                <label for="dischargeYear" class="form-label">Discharge Year</label>
                <input type="text" class="form-control" id="dischargeYear" name="dischargeYear"
                    formControlName="dischargeYear">
            </div>
            <div class="col-md-4 row"><span>Honorable Discharge</span>
                <div class="col-md-6">
                    <div class="form-check">
                        <label class="form-control" for="honorableDischargeYes">YES <input class="form-label"
                                type="radio" name="honorableDischarge" id="honorableDischargeYes" value="YES"
                                formControlName="honorableDischarge"></label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-check">
                        <label class="form-control" for="honorableDischargeNO">NO <input class="form-label" type="radio"
                                name="honorableDischarge" id="honorableDischargeNO" value="NO"
                                formControlName="honorableDischarge"></label>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <label for="A" class="form-label">Service Years</label>
                <input type="text" class="form-control" id="serviceYears" name="serviceYears"
                    formControlName="serviceYears">
            </div>
            <div class="editGrpDiv" formArrayName="CombatExperience">
                <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                    <label for="combatExp">Combat Experience</label>
                    <select class="form-select" id="combatExp" formControlName="CombatExperienceStatus">
                        <option value="">Select Experience</option>
                        <option [value]="opt_" *ngFor="let opt_ of spCnfg.CombatExperience.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
                <div class="row col-md-8" style="display:inline-block;vertical-align:top;"
                    formArrayName="CombatExperienceCmnts">
                    <div class="col-md-4"><!--h5>Comments:</h5!-->
                        <button type="button" class="btn" title="Add A Comment" style="width:fit-content;"
                            (click)="addCombatExperienceCmnt()">Add A Comment</button>
                    </div>
                    <div *ngFor="let CombatExperienceCmnt of CombatExperienceCmnts_?.controls; let i = index">
                        <div [formGroupName]="i" class="row editGrpDiv">
                            <div class="col-md-12">
                                <label [for]="'CombatExperienceCmnt' + i" class="form-label">Comment:</label>
                                <textarea class="form-control textarea" [id]="'CombatExperienceCmnt' + i"
                                    [formControlName]="'CombatExperienceCmnt' + i"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 row"><span>Veterans Benefits Eligible</span>
                <div class="col-md-6">
                    <div class="form-check">
                        <label class="form-control" for="veteransBenefitsEligibleYes">YES <input class="form-label"
                                type="radio" name="veteransBenefitsEligible" id="veteransBenefitsEligibleYes"
                                value="YES" formControlName="veteransBenefitsEligible"></label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-check">
                        <label class="form-control" for="veteransBenefitsEligibleNO">NO <input class="form-label"
                                type="radio" name="veteransBenefitsEligible" id="veteransBenefitsEligibleNO" value="NO"
                                formControlName="veteransBenefitsEligible"></label>
                    </div>
                </div>
            </div>
            <!--div class="editGrpDiv" formArrayName="MilitarySpecificSupport">
                <div class="col-md-4" style="display:inline-block;vertical-align:top;">
                    <label for="militarySpecificSupport">use of military-specific support services</label>
                    <select class="form-select" id="militarySpecificSupport"
                        formControlName="MilitarySpecificSupportStatus">
                        <option value="">Select Use Of Support Services Status</option>
                        <option [value]="opt_" *ngFor="let opt_ of ppCnfg.MilitarySpecificSupport.options">
                            {{ opt_ }}
                        </option>
                    </select>
                </div>
                <div class="row col-md-8" style="display:inline-block;vertical-align:top;"
                    formArrayName="MilitarySpecificSupportCmnts">
                    <div class="col-md-4">
                        <button type="button" class="btn" title="Add A Comment" style="width:fit-content;"
                            (click)="addMilitarySpecificSupportCmnt()">Add A Comment</button>
                    </div>
                    <div
                        *ngFor="let MilitarySpecificSupportCmnt of MilitarySpecificSupportCmnts_?.controls; let i = index">
                        <div [formGroupName]="i" class="row editGrpDiv">
                            <div class="col-md-12">
                                <label [for]="'MilitarySpecificSupportCmnt' + i" class="form-label">Comment:</label>
                                <textarea class="form-control textarea" [id]="'MilitarySpecificSupportCmnt' + i"
                                    [formControlName]="'MilitarySpecificSupportCmnt' + i"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div!-->
        </div>
        <div class="row editGrpDiv" formGroupName="psychological_history"><h4 class="col-md-12" style=" width:100%;">Psychological History:</h4>
            <div class="editGrpDiv" *ngFor="let sel_ of ppCnfg; let i = index" [formArrayName]="sel_.Type">
                <h4>{{ sel_.Description }}</h4>
                <div class="col-md-12" style="display:inline-block;vertical-align:top;"
                    *ngFor="let q_ of sel_.Questions; let q = index">
                    <label [attr.for]="q_.ControlName">{{ q_.Q }}</label>
                    <select class="form-select" [id]="q_.ControlName" [formControlName]="q_.ControlName">
                        <option value="">{{q_.Lbl}}</option>
                        <option *ngFor="let o_ of q_.Option" [value]="o_.val">
                            {{ o_.des }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row editGrpDiv" formGroupName="psychological_mitlitary_history" *ngIf="isVeteran">
            <h4 class="col-md-12" style=" width:100%;">Psychological History - Veteran:</h4>
            <div class="editGrpDiv" *ngFor="let sel_ of ppCnfg; let i = index" [formArrayName]="sel_.Type">
                <h4>{{ sel_.Description }}</h4>
                <div class="col-md-12" style="display:inline-block;vertical-align:top;"
                    *ngFor="let q_ of sel_.Questions; let q = index">
                    <label [attr.for]="q_.ControlName">{{ q_.Q }}</label>
                    <select class="form-select" [id]="q_.ControlName" [formControlName]="q_.ControlName">
                        <option value="">{{q_.Lbl}}</option>
                        <option *ngFor="let o_ of q_.Option" [value]="o_.val">
                            {{ o_.des }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <!-- Symptoms -->
        <div class="editGrpDiv" formArrayName="symptoms">
            <h5>Symptoms:</h5>
            <button type="button" class="btn" title="Add Symptom" (click)="addSymptom()">Add Symptom</button>
            <div *ngFor="let Symptom of Symptoms_?.controls; let i = index">
                <div [formGroupName]="i" class="row editGrpDiv">
                    <div class="col-md-3">
                        <label [for]="'sympName' + i" class="form-label">Symptom Name:</label>
                        <input type="text" class="form-control" [id]="'sympName' + i"
                            [formControlName]="'sympName' + i" />
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympOnsetDate' + i" class="form-label">First Noticed /Onset Date:</label>
                        <input type="date" class="form-control" [id]="'sympOnsetDate' + i"
                            [formControlName]="'sympOnsetDate' + i" />
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympSeverity' + i">Severity</label>
                        <select class="form-select" [id]="'sympSeverity' + i" [formControlName]="'sympSeverity' + i">
                            <option value="">Select Severity</option>
                            <option [value]="p_" *ngFor="let p_ of sympCnfg.severity">
                                {{ p_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <label [for]="'sympDurationVal' + i" class="form-label">Duration:</label>
                        <input type="text" class="form-control" [id]="'sympDurationVal' + i"
                            [formControlName]="'sympDurationVal' + i" />
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympDurationUnit' + i">Duration Unit</label>
                        <select class="form-select" [id]="'sympDurationUnit' + i"
                            [formControlName]="'sympDurationUnit' + i">
                            <option value="">Select Duration Unit</option>
                            <option [value]="unit" *ngFor="let unit of sympCnfg.duration.unit">
                                {{ unit }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympFrequencyTime' + i" class="form-label"
                            title="Number of times the symptom occurs">Frequency:</label>
                        <input type="text" class="form-control" [id]="'sympFrequencyTime' + i"
                            [formControlName]="'sympFrequencyTime' + i" />
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympFrequencyPeriod' + i">Frequency Period</label>
                        <select class="form-select" [id]="'sympFrequencyPeriod' + i"
                            [formControlName]="'sympFrequencyPeriod' + i">
                            <option value="">Select Frequency Period</option>
                            <option [value]="f_" *ngFor="let f_ of sympCnfg.frequency.period">
                                {{ f_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympLocationBodyPart' + i" class="form-label"
                            title="Specific body part or area where the symptom is located">Location On
                            Body:</label>
                        <input type="text" class="form-control" [id]="'sympLocationBodyPart' + i"
                            [formControlName]="'sympLocationBodyPart' + i" />
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympLocationLaterality' + i">Lateral Location</label>
                        <select class="form-select" [id]="'sympLocationLaterality' + i"
                            [formControlName]="'sympLocationLaterality' + i">
                            <option value="">Select Lateral Location</option>
                            <option [value]="ll_" *ngFor="let ll_ of sympCnfg.location.laterality">
                                {{ ll_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label [for]="'sympProgression' + i">Progression</label>
                        <select class="form-select" [id]="'sympProgression' + i"
                            [formControlName]="'sympProgression' + i">
                            <option value="">Select Progression</option>
                            <option [value]="p_" *ngFor="let p_ of sympCnfg.progression">
                                {{ p_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <label for="sympImpactOnDailyActivities">Impact On Daily Activities</label>
                        <select class="form-select" [id]="'sympImpactOnDailyActivities' + i"
                            [formControlName]="'sympImpactOnDailyActivities' + i">
                            <option value="">Select Impact</option>
                            <option [value]="ida_" *ngFor="let ida_ of sympCnfg.impactOnDailyActivities">
                                {{ ida_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'sympTriggers' + i" class="form-label">Description of any known triggers or
                            aggravating factors:</label>
                        <textarea class="form-control textarea" [id]="'sympTriggers' + i"
                            [formControlName]="'sympTriggers' + i"></textarea>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'sympRelievingFactors' + i" class="form-label">Description of any known
                            relieving
                            factors or actions:</label>
                        <textarea class="form-control textarea" [id]="'sympRelievingFactors' + i"
                            [formControlName]="'sympRelievingFactors' + i"></textarea>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'sympAssociatedSymptoms' + i" class="form-label">List of any associated
                            symptoms:</label>
                        <textarea class="form-control textarea" [id]="'sympAssociatedSymptoms' + i"
                            [formControlName]="'sympAssociatedSymptoms' + i"></textarea>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'sympCharacter' + i" class="form-label">Additional descriptive characteristics
                            of
                            the symptom (e.g., sharp, dull, throbbing)":</label>
                        <textarea class="form-control textarea" [id]="'sympCharacter' + i"
                            [formControlName]="'sympCharacter' + i"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <!-- Medical History -->
        <div class="editGrpDiv" formGroupName="medical_history">
            <h5 class="col-md-12">Medical History:</h5>
            <div class="editGrpDiv" formArrayName="Conditions">
                <h5>Chronic Medical Conditions:</h5>
                <button type="button" class="btn" title="Add Condition" (click)="addCondition()">Add
                    Condition</button>
                <div *ngFor="let Condition of Conditions_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-3">
                            <label for="condition" class="form-label">Condition:</label>
                            <input type="text" class="form-control" id="condition"
                                [formControlName]="'Condition' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="TreatmentHistory" class="form-label">Treatment History:</label>
                            <textarea class="form-control textarea" id="TreatmentHistory"
                                [formControlName]="'TreatmentHistory' + i"></textarea>
                        </div>
                        <div class="col-md-3">
                            <label for="DiagnosisDate" class="form-label">Date of diagnosis:</label>
                            <input type="date" class="form-control" id="DiagnosisDate"
                                [formControlName]="'DiagnosisDate' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="Complication" class="form-label">Complications:</label>
                            <textarea class="form-control textarea" id="Complication"
                                [formControlName]="'Complication' + i"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="editGrpDiv" formArrayName="Allergies">
                <h5>Allergies:</h5>
                <button type="button" class="btn" title="Add Allergy" (click)="addAllergy()">Add Allergy</button>
                <div *ngFor="let Allergy of Allergies_?.controls; let i = index">
                    <div [formGroupName]="i" class="row editGrpDiv">
                        <div class="col-md-3">
                            <label for="allergy" class="form-label">Allergy:</label>
                            <input type="text" class="form-control" id="allergy" [formControlName]="'Allergy' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="Reaction" class="form-label">Reaction:</label>
                            <textarea class="form-control textarea" id="Reaction"
                                [formControlName]="'Reaction' + i"></textarea>
                        </div>
                        <div class="col-md-3">
                            <label for="OnsetDate" class="form-label">Date of Onset:</label>
                            <input type="date" class="form-control" id="OnsetDate"
                                [formControlName]="'OnsetDate' + i" />
                        </div>
                        <div class="col-md-9">
                            <label for="ReactionSeverity" class="form-label">Reaction Severity:</label>
                            <textarea class="form-control textarea" id="ReactionSeverity"
                                [formControlName]="'ReactionSeverity' + i"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Medications -->
        <div class="editGrpDiv" formArrayName="medications">
            <h5>Medications:</h5>
            <button type="button" class="btn" title="Add Medication" (click)="addMedication()">Add
                Medication</button>
            <div *ngFor="let medication of medications.controls; let i = index">
                <div [formGroupName]="i" class="row editGrpDiv">
                    <div class="col-md-4">
                        <label [for]="'medName' + i" class="form-label">Name</label>
                        <input type="text" [id]="'medName' + i" class="form-control" [formControlName]="'medName' + i">
                    </div>
                    <div class="col-md-2">
                        <label [for]="'medDosage' + i" class="form-label">Dosage</label>
                        <input type="text" [id]="'medDosage' + i" class="form-control"
                            [formControlName]="'medDosage' + i">
                    </div>
                    <div class="col-md-2">
                        <label [for]="'medFrequency' + i" class="form-label">Frequency</label>
                        <input type="text" [id]="'medFrequency' + i" class="form-control"
                            [formControlName]="'medFrequency' + i">
                    </div>
                    <div class="col-md-2">
                        <label [for]="'medStartDate' + i" class="form-label">Start Date</label>
                        <input type="date" class="form-control" [id]="'medStartDate' + i"
                            [formControlName]="'medStartDate' + i" />
                    </div>
                    <div class="col-md-2">
                        <label [for]="'medEndDate' + i" class="form-label">End Date</label>
                        <input type="date" class="form-control" [id]="'medEndDate' + i"
                            [formControlName]="'medEndDate' + i" />
                    </div>
                    <div class="col-md-6">
                        <label [for]="'medPurpose' + i" class="form-label">Reason for the medication:</label>
                        <textarea class="form-control textarea" [id]="'medPurpose' + i"
                            [formControlName]="'medPurpose' + i"></textarea>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'medSideEffects' + i" class="form-label">List of experienced or potential side
                            effects:</label>
                        <textarea class="form-control textarea" [id]="'medSideEffects' + i"
                            [formControlName]="'medSideEffects' + i"></textarea>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'medAdherenceLevel' + i">Adherence Level</label>
                        <select class="form-select" [id]="'medAdherenceLevel' + i"
                            [formControlName]="'medAdherenceLevel' + i">
                            <option value="">Select Adherence Level</option>
                            <option [value]="ida_"
                                *ngFor="let ida_ of medMngt.prescribedMedications[0].compliance.adherenceLevel">
                                {{ ida_ }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label [for]="'medReasonsNonAdherence' + i" class="form-label">Reasons for not taking the
                            medication as prescribed:</label>
                        <textarea class="form-control textarea" [id]="'medReasonsNonAdherence' + i"
                            [formControlName]="'medReasonsNonAdherence' + i"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <!-- Medical Insurances -->
        <div class="editGrpDiv" formArrayName="medical_insurances">
            <h5>Medical Insurance:</h5>
            <button type="button" class="btn" title="Add Medical Insurance" (click)="addMedicalInsurance()">Add
                Medical Insurance</button>
            <div *ngFor="let insurance of medicalInsurances.controls; let i = index">
                <div [formGroupName]="i" class="row editGrpDiv">
                    <div class="col-md-4">
                        <label for="Type" class="form-label">Type</label>
                        <input type="text" id="Type" class="form-control" [formControlName]="'Type' + i">
                    </div>
                    <div class="col-md-4">
                        <label for="Provider" class="form-label">Provider</label>
                        <input type="text" id="Provider" class="form-control" [formControlName]="'Provider' + i">
                    </div>
                    <div class="col-md-4">
                        <label for="Policy_Number" class="form-label">Policy Number</label>
                        <input type="text" id="Policy_Number" class="form-control"
                            [formControlName]="'Policy_Number' + i">
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>