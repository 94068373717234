import { Directive, Renderer2, Input, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ObservableAssessmentService } from '../service/observable-assessment.service';

@Directive({
  selector: '[appBackgroundColorById]'
})
export class BackgroundColorByIdDirective implements OnInit, OnDestroy {
  @Input('appBackgroundColorById') id!: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private oaService: ObservableAssessmentService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.subscription = this.oaService.states$.subscribe(states => {
      const state = states.find(s => s.id === this.id);
      const allClasses = ['Always', 'Normal', 'Excellent', 'Optimal', 'SoNometimes',
      'Often', 'Mild', 'VeryGood', 'Adequate', 'Low', 'Occasional',
      'Good', 'Mildly', 'Moderate', 'Regular',
      'Sometimes', 'Moderate', 'Fair', 'Moderate', 'High', 'Problematic',
      'LowRisk', 'ModerateRisk', 'HighRisk',
      'MildSubstanceUse', 'ModerateSubstanceUse', 'SevereSubstanceUse',
      'Rarely', 'Significant', 'Poor', 'Severe', 'Urgent', 'Dependency',
      'Never', 'Profound', 'VeryPoor', 'Complete', 'SubstanceAbuse', 'Recovery'];
      allClasses.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));//Remove all potential classes before adding the new one
      if (state) {this.renderer.addClass(this.el.nativeElement, state.color);}
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
