<div class="row">
  <div class="col-sm-1 col-md-1">
    <a><i class="bi bi-arrow-left-square" style="font-size:2.5rem; color: rgb(247, 6, 6);"
        (click)="back($event)"></i></a>
  </div>
  <div class="col-sm-10 col-md-10">
    <h4 class="fw-bold">Hello {{Apt.Patient.fullName}}, Please answer the following questions.</h4>
    <h4 class="fw-bold">{{Hdr}}</h4>
    <mat-progress-bar [color]="color" [value]="value" [bufferValue]="bufferValue" style="height:2rem !important;">
    </mat-progress-bar>
    <h5 class="fw-bold">{{theQ}}</h5>
  </div>
  <div class="col-sm-1 col-md-1"></div>
  <div class="row">
    <div class="col-sm-1 col-md-1"></div>
    <div class="col-sm-10 col-md-10">
      <form class=" bg-white text-center row" action="">
        <div class="btn-group" role="group" aria-label="radio toggle button group">
          <div *ngFor="let r of R_">
            <div *ngIf="theR=='rg0' || theR=='rg1'">
              <!--input type="radio" [(ngModel)]="Q_.slctdOptn" class="btn-check" name="rb{{ Q_.ID}}"
                id="rb{{ Q_.ID}}-{{r.value}}" value={{r.value}} autocomplete="off" (click)="next($event)"!-->
              <input type="radio" class="btn-check" name="rb{{ Q_.ID}}"
                id="rb{{ Q_.ID}}-{{r.value}}" value={{r.value}} autocomplete="off" (click)="next($event)">
              <label class="btn btn-outline-primary" for="rb{{ Q_.ID}}-{{r.value}}">{{r.text}}</label>
            </div>
            <div *ngIf="theR=='cb'">
              <input type="checkbox" class="btn-check" id="cb{{ Q_.ID}}-{{r.value}}" autocomplete="off"
                name="rb{{ Q_.ID }}" value={{r.value}}>
              <label class="btn btn-outline-primary" for="cb{{ Q_.ID}}-{{r.value}}">{{r.text}}</label>
            </div>
            <div *ngIf="Q_.ID=='SBQR' || Q_.ID=='WHO5'">
              <!--input type="radio" [(ngModel)]="Q_.slctdOptn" class="btn-check" name="rb{{ Q_.ID}}"
                id="rb{{ Q_.ID}}-{{r.value}}" value={{r.value}} autocomplete="off" (click)="next($event)">
              <label class="btn btn-outline-primary" for="rb{{ Q_.ID}}-{{r.value}}">{{r.text}}</label!-->
              <input type="radio" class="btn-check" name="rb{{ Q_.ID}}"
                id="rb{{ Q_.ID}}-{{r.value}}" value={{r.value}} autocomplete="off" (click)="next($event)">
              <label class="btn btn-outline-primary" for="rb{{ Q_.ID}}-{{r.value}}">{{r.text}}</label>
            </div>
          </div>
          <div *ngIf="theR=='rg11'">
            <input class="btn btn-primary" type="submit" value="Submit" (click)="allDone($event)">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-1 col-md-1"></div>
  </div>
</div>