import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Patient } from '../../../Store/Model/type.Interfeaces';
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  param4: string | null = '';

  isAssessmentMenuActv: boolean = true;
  isLoading: boolean = true;
  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any; stf: any;
  theAppts: any[] = []; Apt: any; aID: any;
  nwEnctr: any; eID: any;
  @Output() ActivateEvt = new EventEmitter();
  @Output() updateEvt = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
  ) {

    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect"); }
    else {
      this.stf = JSON.parse(stf); console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log("locs", this.locs);
      const loc: any = localStorage.getItem('loc'); this.loc = JSON.parse(loc); console.log("loc", this.loc);
      this.dpts = this.loc.Departments; console.log(this.dpts);
      const dpt: any = localStorage.getItem('dpt'); this.dpt = JSON.parse(dpt); console.log("dpt", this.dpt);
    }
  }

  Deactivate() { this.updateEvt.emit({}); }
  activateAx(Apt: any) {
    localStorage.setItem('Apt', JSON.stringify(Apt));
    localStorage.setItem('currentQ_', this.cmpy.Cnfg.initQ);
    console.log(localStorage);
    console.log("this.Apt", this.Apt);
    this.isLoading = true;
    this.nwEnctr = {
      'appointment_id': this.Apt.id,
      'aID': this.Apt.aID,
      'eID': this.Apt.aID,
      'patient_id': this.Apt.patient_id,
      'eDate': this.Apt.Date,
      'eTime': this.Apt.Time,
      'Status': this.Apt.Status,
      'History': this.Apt.History,
      'T': this.dpt.T,
      'status': {
        "name": this.Apt.Patient.fullName,
        "DOB": this.Apt.Patient.DOB,
        "summary": this.Apt.Patient.patientSummary,
      },
      'patient': {
        "name": this.Apt.Patient.fullName,
        "DOB": this.Apt.Patient.DOB,
        "summary": this.Apt.Patient.patientSummary,
      },
      'location': {
        "Resourse": this.Apt.Details.resource,
        "Date": this.Apt.Details.Date,
        "Time": this.Apt.Details.Time,
        "dID": this.Apt.dID,
        "lID": this.Apt.lID,
        "cID": this.stf.cID
      },
      'promKeys': [this.cmpy.Cnfg.initQ],
      'prom': { 
        'PHQ9' : []
      },
      'note': {
        "reason": this.Apt.Details.reason,
        "problems": this.Apt.Details.problems,
        "Time": this.Apt.Details.Time
      },
      'sID': this.stf.sID,
    };
    console.log("this.nwEnctr", this.nwEnctr);
    let nwEnctr = JSON.stringify(this.nwEnctr);
    this.aSrvc.EncntrNew(nwEnctr).subscribe(
      (result) => { this.rsp = result; console.log(this.rsp); },
      (error) => { this.errors = error.error; console.log(this.errors); this.isLoading = false; },
      () => {
        if (this.rsp.status === "success") {
          const eData=this.rsp;console.log(eData); 
          this.toastr.success(eData.message); this.isLoading = false;
          localStorage.setItem('eData', JSON.stringify(eData));
          this.router.navigate(["/axActivation/" + this.Apt.lID + "/" + this.Apt.dID + "/" + this.aID]);
        }
      }
    )
  };

  ngOnInit() {
    //  this.company=testCompany;console.log(this.company[0]);
    // Example usage of tID$
    this.lID = this.route.snapshot.paramMap.get('param1');
    this.dID = this.route.snapshot.paramMap.get('param2');
    this.aID = this.route.snapshot.paramMap.get('param3');
    this.param4 = this.route.snapshot.paramMap.get('param4');
    
    // Optional: Do something with the parameters, such as sending them to a service or displaying them
    console.log(this.lID, this.dID, this.aID, this.param4);

      //this.lID = this.uSrvc.getLid(tID_); this.dID = this.uSrvc.getDid(tID_);
      //this.aID = this.uSrvc.getAid(tID_); console.log(tID, this.lID, this.dID, this.aID);


    //let aData = {"aID": aID};
    //console.log("this.udData", this.udData);
    this.aSrvc.GetApptPt(this.aID).subscribe(
      (result) => { this.rsp = result; },
      (error) => { this.errors = error.error; console.log(this.errors); this.toastr.error(this.errors); },
      () => {
        const aData = this.rsp[0]; //console.log(aData);
        //this.Apt=aData[0].appointment;
        if (aData.status === "success") {
          this.Apt = aData.appointment[0];
          //this.Apt.Patient = JSON.parse(this.Apt.Patient);
          //this.Apt.Details = JSON.parse(this.Apt.Details);
          this.toastr.success(aData.message);
        }; console.log(this.Apt);
        this.isLoading = false;
      }
    );
  }
}
