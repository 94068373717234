import { Injectable, NgZone } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private ngZone: NgZone) { }

  getRandomInt(max: number) { let x = Math.random() * max; if (x <= (.75 * max)) { return max; } else { return x; } }
  getRandomPrcnt() { let x = Math.random(); if (x <= (.85)) { return 0.85; } else { return x; } }
  getLid(id: string) { return id.toString().slice(0, 4); }
  getDid(id: string) { return id.substring(5, 9); }
  getGid(id: string) { return id.substring(10, 14); }
  getPid(id: string) { return id.substring(10); }
  getAid(id: string) { return id.substring(10, id.length - 2); }
  getSid(id: string) { return id.substring(10); }

  isArray(v: any): boolean { return typeof v === 'object'; }

  getLocation() {// Check if the browser supports geolocation
    if (navigator.geolocation) {// Get the user's location
      navigator.geolocation.getCurrentPosition(function (position) {// Success callback
        console.log("Latitude: " + position.coords.latitude + " | Longitude: " + position.coords.longitude);
      }, function (error) { console.log(error); });
    }
    else { console.log("Geolocation is not supported by your browser."); }// Browser does not support geolocation
  }
  formatPhone = (inP: string | undefined) => {
    let p = '';
    if (inP !== undefined) {
      p = inP.replace(/[^0-9]/g, "");
      if (p.length === 10) { p = "(" + p.substring(0, 3) + ") " + p.substring(3, 6) + "-" + p.substring(6, 10); }
      else if (p.length === 11) { p = "+" + p[0] + " (" + p.substring(1, 4) + ") " + p.substring(4, 7) + "-" + p.substring(7, 11); }
      else if ((p.length > 11) && (p[0] !== "1")) { p = "(" + p.substring(0, 3) + ") " + p.substring(3, 3) + "-" + p.substring(6, 4) + " x" + p.substring(10, p.length - 10); }
    } else { p = ''; }
    return p;
  }
  splitFullName(fullName: string): { title: string, firstName: string, lastName: string } {
    const nameParts = fullName.split(' ');
    const title = nameParts[0];
    let lastName: string = "";
    let xlastName = nameParts.pop(); if (xlastName === undefined) { lastName = ""; } else { lastName = xlastName; }
    const firstName = nameParts.join(' ');
    return { title, firstName, lastName };
  }

  // Function to check the date format and convert
  convertDateIfValid(dateStr: string): string | null {
    // Regular expression to check the date format
    const dateFormatRegex = /^\d{2}-[A-Z]{3}-\d{2}$/;
    //console.log('date:', dateStr);
    dateStr = dateStr.toUpperCase();
    //console.log('date:', dateStr);

    // Check if the date matches the required format
    if (!dateFormatRegex.test(dateStr)) {
      return dateStr; // or throw an error as per your application's error handling policy
    }

    // Mapping of month abbreviations to month numbers
    const monthMap: { [key: string]: string } = {
      'JAN': '01', 'FEB': '02', 'MAR': '03',
      'APR': '04', 'MAY': '05', 'JUN': '06',
      'JUL': '07', 'AUG': '08', 'SEP': '09',
      'OCT': '10', 'NOV': '11', 'DEC': '12',
    };
    const [day, monthAbbr, year] = dateStr.split('-');// Split the date string

    //const fullYear = parseInt(year) + 1900;// Convert year to 4 digits
    let fullYear = parseInt(year, 10);
    fullYear += (fullYear < 50) ? 2000 : 1900;// Determine the correct century

    // Convert the date to the desired format
    return `${fullYear}-${monthMap[monthAbbr]}-${day}`;
  }

  addToScoreIfKeyExists(promKeys: string[], keyToCheck: string, score: number, valueToAdd: number): number {
    if (promKeys.includes(keyToCheck)) {
      return (score + valueToAdd > 27) ? 27 : score + valueToAdd;
    }
    return score;
  }

  private selectedEncounterSource = new BehaviorSubject<any>(null);
  selectedEncounter$ = this.selectedEncounterSource.asObservable();
  //private selectedEncounterSource = new BehaviorSubject<Encounter | null>(null);
  //readonly selectedEncounter$ = this.selectedEncounterSource.asObservable();

  selectEncounter(encounter: any) {
    if (encounter !== null && encounter !== undefined) {
      this.selectedEncounterSource.next(encounter);
    }
  }
  /*
  selectEncounter(encounter: Encounter | null): void {
    if (encounter !== null && encounter !== undefined) {
      this.selectedEncounterSource.next(encounter);
    }
  }
  */

  replaceNullWithEmptyString(obj: any): any {
    if (obj === null) {
      return ""; // Replace null with an empty string
    } else if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        // If it's an array, recursively process each element
        return obj.map((item: any) => this.replaceNullWithEmptyString(item));
      } else {
        // If it's an object, recursively process each property
        const newObj: any = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            newObj[key] = this.replaceNullWithEmptyString(obj[key]);
          }
        }
        return newObj;
      }
    } else {
      return obj; // Leave other data types unchanged
    }
  }

  getResizeEvent(): Observable<Event> {
    return new Observable(observer => {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(window, 'resize')
          .pipe(debounceTime(300)) // Debounce for performance
          .subscribe(event => {
            this.ngZone.run(() => observer.next(event));
          });
      });
    });
  }
  getLS_item__(itm: any): any {
    console.log("itm: ", itm)
    console.log("sessionStorage: ", sessionStorage);
    const i_: any = sessionStorage.getItem(itm);
    if (i_ === null) { return i_; } else { return JSON.parse(i_); }
  }
  getLS_item<T>(key: string): T | null {
    console.log("Retrieving key: ", key);
    const item = sessionStorage.getItem(key);

    // Check if the item exists
    if (item === null) {
      console.warn(`Item with key '${key}' not found in sessionStorage.`);
      return null; // Explicitly return null if the item is not found
    }

    try {
      // Attempt to parse the item as JSON
      const parsedItem: T = JSON.parse(item);
      console.log(`Item retrieved and parsed for key '${key}': `, parsedItem);
      return parsedItem;
    } catch (error) {
      // Log error if JSON parsing fails
      console.error(`Error parsing sessionStorage item for key '${key}':`, error);
      return null; // Return null if parsing fails
    }
  }

}
