// time-monitor.service.ts
import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimeMonitorService {
  private appointmentTime!: Date; // Ideally set via a method that handles time zone correctly

  constructor() { }

  // Set appointment time with correct handling of time zone
  setAppointmentTime(appointmentDateTime: string): void {
    this.appointmentTime = new Date(appointmentDateTime);
  }

  // Emit the current time every second
  getCurrentTime(): Observable<Date> {
    return interval(1000).pipe(
      startWith(new Date()),
      map(() => new Date()),
      shareReplay(1) // Ensures all subscribers share the same instance of the observable
    );
  }

  // Check if the current time has reached or passed the appointment time
  isAppointmentTime(): Observable<boolean> {
    return this.getCurrentTime().pipe(
      map(currentTime => {
        const currentTimestamp = currentTime.getTime();
        const appointmentTimestamp = this.appointmentTime.getTime();
        return currentTimestamp >= appointmentTimestamp;
      })
    );
  }
}
