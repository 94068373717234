import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientDataService {
  private patientsSource = new BehaviorSubject<any[]>([]);
  public patients$ = this.patientsSource.asObservable();

  constructor() { }

  updatePatientsData(newData: any[]) {
    this.patientsSource.next(newData);
    console.log("updatePatientsData patients: ",this.patients$);
  }
}
