import { Component, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  [x: string]: any;
  private subscription: Subscription = new Subscription();
  isLoading: boolean = true;
  @Input() patient: any;
  @Input() cmpy: any;
  @Input() eDate: any;
  stf: any;
  pForm!: FormGroup;
  patientRdy: boolean = false;
  isVeteran = false;
  encntr: any;
  pHstry: any;
  pSoc: any;
  psycH: any;
  psycMH: any;
  psyCnfg: any;
  psyMVCnfg: any;
  psyMACnfg: any;
  pMil: any;
  pMedical: any;
  cnfg: any;
  socialCnfg: any; spCnfg: any; milCnfg: any; sympCnfg: any;

  routeOfAdministration: any;
  classes: any;
  frequencyOptions: any;
  medCompliance: any;

  psCnfg: any;
  pmvCnfg: any; pmaCnfg: any;
  errors: any = null;
  transformedArray: any[] = [];
  selectedYear!: number;
  substanceUseInfo: boolean = false;
  psychologicalHistoryForm!: FormGroup;
  psychologicalMitlitaryHistoryForm!: FormGroup;

  onYearSelected(year: number): void {
    this.selectedYear = year;
  }

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private toastr: ToastrService,
    //@Inject(MAT_DIALOG_DATA) public pData: any
  ) {
    this.patientRdy = false;
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else { this.stf = JSON.parse(stf); }

  }
  //psychological_mitlitary
  createForm() {
    this.psychologicalHistoryForm = this.fb.group({});
    this.psyCnfg.forEach((section: any) => {
      const sectionForm = this.createSection(section.Qs);
      this.psychologicalHistoryForm.addControl(section.T, sectionForm);
      this.initializeSectionFormControls(section.T, 'psycH', sectionForm);
    });
  }
  private initializeSectionFormControls(sectionType: string, patKey: string, sectionForm: FormGroup) {
    //console.log(sectionType,patKey,sectionForm);
    const sectionData = this[patKey][sectionType];
    //console.log(this[patKey],this[patKey][sectionType]);
    if (!sectionData) {
      //console.error(`No data found for section: ${sectionType}`); 
      return;
    }
    Object.keys(sectionData).forEach(key => {
      if (sectionForm.contains(key)) { sectionForm.get(key)?.setValue(sectionData[key]); }
      else {
        //console.warn(`Form control for key '${key}' not found in section '${sectionType}'`);
      }
    });
  }
  createSection(questions: any[]): FormGroup {
    const group: any = {};
    questions.forEach(question => { group[question.C] = this.createQuestion(question); });
    //console.log(this.fb.group(group));
    return this.fb.group(group);
  }

  createQuestion(question: any): any {
    let control;
    if (question.Option && question.Option.length > 0) {
      // Assuming it's a dropdown/select control
      control = [null, Validators.required];
    } else {
      // For other types of controls like text input
      control = ['', Validators.required];
    }
    return control;
  }
  changeMedClass(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus: any = String(selectElement.value);
    const id = String(selectElement.id);
    const prefix = 'medClass';
    const indexPart = id.substring(prefix.length);
    console.log('id', id, 'newStatus', newStatus, 'classes', this.classes);
    const class_ = this.classes.find((item: any) => item.ClassName === newStatus);
    console.log('newStatus', newStatus, 'indexPart', indexPart, 'classes', this.classes, 'class_', class_, 'icd10Code', class_.CommonICD10CMCodes);

    this.fb.group({ ['medICD10CMCode' + indexPart]: [class_.CommonICD10CMCodes, Validators.required] });
    //this.oaxSrvc.updateState(id, newStatus);
  }
  ngOnInit() {
    //console.log("history -ngOnInit- this.patient:", this.patient, "history -ngOnInit- this.cmpy", this.cmpy);
    this.cnfg = this.cmpy.Cnfg;
    this.socialCnfg = this.cmpy.Social; //console.log("history -ngOnInit- sympCnfg", this.socialCnfg);
    this.routeOfAdministration = this.cmpy.medManagement.prescribedMedications.routeOfAdministration;
    this.classes = this.cmpy.medManagement.prescribedMedications.MedicationClasses; //console.log('classes:', this.classes);
    this.frequencyOptions = this.cmpy.medManagement.prescribedMedications.frequencyOptions;
    this.medCompliance = this.cmpy.medManagement.prescribedMedications.compliance.adherenceLevel;


    this.milCnfg = this.cmpy.Military; //console.log("history -ngOnInit- milCnfg", this.milCnfg);
    this.sympCnfg = this.cmpy.SymptomCnfg; //console.log("history -ngOnInit- sympCnfg", this.sympCnfg);
    this.psyCnfg = this.cmpy.Psychological;//console.log("history -ngOnInit- psyCnfg", this.psyCnfg);
    this.psyMVCnfg = this.cmpy.PsychologicalMilitaryVeteran;//console.log("history -ngOnInit- psyMVCnfg", this.psyMVCnfg);
    this.psyMACnfg = this.cmpy.PsychologicalMilitaryActive;//console.log("history -ngOnInit- psyMACnfg", this.psyMACnfg);

    this.psCnfg = this.cmpy.PsychologicalSymptom; //console.log("history -ngOnInit- psCnfg", this.psCnfg);
    this.subscribeToSelectedEncounter();

    /*this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => {
        if (encounter) {
          this.encntr=encounter;console.log(this.encntr);
          //console.log("the history:");
          this.pHstry = encounter.history; console.log("this.pHstry:", this.pHstry);
          this.pSoc = this.pHstry.social; console.log("this.pSoc:", this.pSoc);
          this.psycH = this.pHstry.psychological; console.log("this.psycH:", this.psycH);
          this.psycMH = this.pHstry.psychological_military; console.log("this.psycMH:", this.psycMH);
          this.createForm();
          this.initializeForm();
          this.checkStaffAccess();
          //console.log("history -ngOnInit- this.pForm", this.pForm);
        }
      })
    );*/
  }
  private subscribeToSelectedEncounter() {
    this.subscription.add(
      this.uSrvc.selectedEncounter$.subscribe(encounter => { if (encounter) { this.updateFormWithEncounter(encounter); } })
    );
  }
  private updateFormWithEncounter(encounter: any) {
    this.encntr = encounter;//console.log(this.encntr);
    //console.log("the history:");
    this.pHstry = encounter.history; //console.log("this.pHstry:", this.pHstry);
    this.pMedical = this.pHstry.medical; console.log("this.pMedical:", this.pMedical);
    this.pSoc = this.pHstry.social; //console.log("this.pSoc:", this.pSoc);
    this.psycH = this.pHstry.psychological; //console.log("this.psycH:", this.psycH);
    this.psycMH = this.pHstry.psychological_military; //console.log("this.psycMH:", this.psycMH);
    this.createForm();
    this.initializeForm();
    this.checkStaffAccess();
    //console.log("history -ngOnInit- this.pForm", this.pForm);
  }

  private checkStaffAccess() {
    // Replace direct localStorage access with service method
    //if (!this.tSrvc.isLoggedIn()) {
    //  this.router.navigate(['login/:id']);
    //} else {
    // Fetch configurations using service
    this.fetchConfigurations();
    //}
  }

  private fetchConfigurations() {
    if (this.patient.encounters[0].history.social !== null) {
      if (this.patient.encounters[0].history.social.substanceUse !== null) { this.substanceUseInfo = true; }
    }
    this.patientRdy = true;
  }

  onEnlistmentStatusChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const serviceStatus = String(selectElement.value);
    this.isVeteran = serviceStatus === 'Non-Military/Did Not Serve' ? false : true;
  }

  getSubstanceUseControls(): FormGroup {
    const substanceUseGroup = this.fb.group({});
    this.socialCnfg.socialHistory[2].substanceUse.questions.forEach((question: any) => {
      const questionGroup = this.fb.group({}); // Create a group for each question
      questionGroup.addControl(question.type, this.fb.control('', this.getValidators(question.status)));// Add the main question control
      // Handle follow-up questions, if any
      if (question.followUp && question.followUp.length > 0) {
        const followUpGroup = this.fb.group({});
        question.followUp.forEach((followUpQuestion: any) => {
          followUpGroup.addControl(followUpQuestion.type, this.fb.control('', this.getValidators(followUpQuestion.status)));
        });
        questionGroup.addControl('FollowUp', followUpGroup);
      }
      substanceUseGroup.addControl(question.type, questionGroup);// Add the question group to the main substanceUseGroup
    });
    return substanceUseGroup;
  }

  private getValidators(status: string) { return status === 'required' ? [Validators.required] : []; }

  getLivingConditionsControls(): { [key: string]: any } {
    const group: { [key: string]: any } = {};
    this.socialCnfg.socialHistory[1].questions.forEach((control: { type: string | number; validators: any; }) => { group[control.type] = ['']; });
    return group;
  }

  initializeForm(): void {
    this.pForm = this.fb.group({
      social: this.fb.group({
        preferredLanguage: [this.pSoc.preferredLanguage, Validators.required],
        maritalStatus: [this.pSoc.maritalStatus, Validators.required],
        education: [this.pSoc.education],
        occupation: [this.pSoc.occupation],
        religiousAffiliation: [this.pSoc.religiousAffiliation],
        culturalBackground: [this.pSoc.culturalBackground],
        eChildren: this.fb.array([]),
        eContacts: this.fb.array([]),
        socialSupport: this.fb.array([]),
        substanceUse: this.fb.group({
          tobacco: this.fb.group({
            Status: [this.pSoc.substanceUse.tobacco.Status, Validators.required],
            Type: [this.pSoc.substanceUse.tobacco.Type],
            Frequency: [this.pSoc.substanceUse.tobacco.Frequency],
            Help: [this.pSoc.substanceUse.tobacco.Help],
          }),
          alcohol: this.fb.group({
            Status: [this.pSoc.substanceUse.alcohol.Status, Validators.required],
            Frequency: [this.pSoc.substanceUse.alcohol.Frequency],
            Quantity: [this.pSoc.substanceUse.alcohol.Quantity],
            Help: [this.pSoc.substanceUse.alcohol.Help],
          }),
          recreationalDrugs: this.fb.group({
            Status: [this.pSoc.substanceUse.recreationalDrugs.Status, Validators.required],
            Type: [this.pSoc.substanceUse.recreationalDrugs.Type],
            Frequency: [this.pSoc.substanceUse.recreationalDrugs.Frequency],
            Help: [this.pSoc.substanceUse.recreationalDrugs.Help],
          }),
        }),
        LivingConditions: this.fb.group({
          residenceType: [this.pSoc.LivingConditions.residenceType, Validators.required],
          stableHousing: [this.pSoc.LivingConditions.stableHousing],
          livingDuration: [this.pSoc.LivingConditions.livingDuration],
          safetyMeasures: [this.pSoc.LivingConditions.safetyMeasures],
          currentResidence: [this.pSoc.LivingConditions.currentResidence],
          livingArrangements: [this.pSoc.LivingConditions.livingArrangements],
          accessibilityFeatures: [this.pSoc.LivingConditions.accessibilityFeatures],
          otherLivingConsiderations: [this.pSoc.LivingConditions.otherLivingConsiderations],
        }),
        MilitarySrvcStatus: [this.pSoc.MilitarySrvcStatus, Validators.required],
        militaryHistory: this.fb.group({
          enlistmentYear: ['', Validators.required],
          dischargeYear: ['', Validators.required],
          honorableDischarge: ['', Validators.required],
          serviceYears: ['', Validators.required],
          retirementYear: ['', Validators.required],
          serviceBranch: ['', Validators.required],
          CombatExperience: this.fb.group({
            Status: ['', Validators.required],
            Cmnts: this.fb.array([])
          }),
          veteransBenefitsEligible: ['', Validators.required],
          //MilitarySpecificSupport
          MilitarySpecificSupport: this.fb.group({
            Status: ['', Validators.required],
            Cmnts: this.fb.array([])
          }),
          // Add more medical history fields with validators
        }),
      }),
      medical: this.fb.group({
        Conditions: this.fb.array([]),
        Allergies: this.fb.array([]),
        symptoms: this.fb.array([]),
        Medications: this.fb.array([]),
      }),
      // Add more medical history fields with validators
      psychological: this.fb.group({
        general: this.psychologicalHistoryForm,
        military: this.fb.group({
          MilitaryServiceRelatedIssues: this.fb.group({
            Status: ['', Validators.required],
            Issues: ['', Validators.required],
            Incidents: ['', Validators.required],
          }),
          AdjustmentPostService: this.fb.group({
            AdjustmentPostService: ['', Validators.required],
            Challenge: ['', Validators.required],
            SupportNeed: ['', Validators.required],
          }),
          PTSDandTrauma: this.fb.group({
            PTSDSymptoms: ['', Validators.required],
            Triggers: ['', Validators.required],
            SupportHistory: ['', Validators.required],
          }),
        }),
      }),
      //psychological_military: this.psychologicalMitlitaryHistoryForm,
      /*psychologicalv1: this.fb.group({
        GeneralMentalHealth: this.fb.group({
          Status: [this.psycH.GenMentalHealth.Status, Validators.required],
          MoodChange: [this.psycH.GenMentalHealth.MoodChange, Validators.required],
          Concern: [this.psycH.GenMentalHealth.Concern, Validators.required],
          Cmnts: this.fb.array([])
        }),
        MentalHealth: this.fb.group({
          Current: [this.psycH.MentalHealth.Current, Validators.required],
          Challenges: [this.psycH.MentalHealth.Challenges, Validators.required],
          Impact: [this.psycH.MentalHealth.Impact, Validators.required],
        }),
        GoalsAndExpectations: this.fb.group({
          Goal: ['', Validators.required],
          FocusArea: ['', Validators.required],
          IdealOutcome: ['', Validators.required],
        }),
        DigitalWellness: this.fb.group({
          TimeSpentOnline: ['', Validators.required],
          OnlineHabits: ['', Validators.required],
          ProCon: ['', Validators.required],
        }),
        CurrentLifeSituations: this.fb.group({
          Status: ['', Validators.required],
          Impact: ['', Validators.required],
          Challenge: ['', Validators.required],
        }),
        LifeSatisfaction: this.fb.group({
          Status: ['', Validators.required],
          Fulfilling: ['', Validators.required],
          AreaToImprovement: ['', Validators.required],
        }),
        LifestyleAndStress: this.fb.group({
          Level: ['', Validators.required],
          MainSource: ['', Validators.required],
          CopingMethod: ['', Validators.required],
        }),
        StressAndCopingMechanisms: this.fb.group({ StressAndCopingMechanismsStatus: ['', Validators.required], StressAndCopingMechanismsCmnts: this.fb.array([]), }),
        SocialSupportAndRelationships: this.fb.group({ SocialSupportAndRelationshipsStatus: ['', Validators.required], SocialSupportAndRelationshipsCmnts: this.fb.array([]), }),
        FamilyMentalHealthHistory: this.fb.group({ FamilyMentalHealthHistoryStatus: ['', Validators.required], FamilyMentalHealthHistoryCmnts: this.fb.array([]), }),
        PsychiatricTreatmentHistory: this.fb.group({ PsychiatricTreatmentHistoryStatus: ['', Validators.required], PsychiatricTreatmentHistoryCmnts: this.fb.array([]) }),
        PsychologicalTreatmentHistory: this.fb.group({ PsychologicalTreatmentHistoryStatus: ['', Validators.required], PsychologicalTreatmentHistoryCmnts: this.fb.array([]) }),

        Military: this.fb.group({
          MilitaryServiceRelatedIssues: this.fb.group({ MilitaryServiceRelatedIssuesStatus: ['', Validators.required], MilitaryServiceRelatedIssuesCmnts: this.fb.array([]) }),
          PTSDandTrauma: this.fb.group({ PTSDandTraumaStatus: ['', Validators.required], PTSDandTraumaCmnts: this.fb.array([]) }),
          AdjustmentPostService: this.fb.group({ AdjustmentPostServiceStatus: ['', Validators.required], AdjustmentPostServiceCmnts: this.fb.array([]) }),
          ReintegrationChallenges: this.fb.group({ ReintegrationChallengesCmnts: this.fb.array([]) }),
          OccupationalAdjustment: this.fb.group({ OccupationalAdjustmentCmnts: this.fb.array([]) }),
        }),
        // Add more medical history fields with validators
      }),*/
      medical_insurances: this.fb.array([]),
    });
    this.populateMedications(this.pMedical?.Medications ?? ''); // Populate the Medications form array
    this.isLoading = false;
    //console.log("this.pForm:",this.pForm);
  }

  get eChildren_() { return this.pForm.get('social.eChildren') as FormArray; }
  get eContacts_() { return this.pForm.get('social.eContacts') as FormArray; }
  //get MilitarySrvcCmnts_() { return this.pForm.get('social.MilitarySrvc.MilitarySrvcCmnts') as FormArray; }

  get CombatExperienceCmnts_() { return this.pForm.get('social.militaryHistory.CombatExperience.CombatExperienceCmnts') as FormArray; }
  get MilitarySpecificSupportCmnts_() { return this.pForm.get('social.militaryHistory.MilitarySpecificSupport.MilitarySpecificSupportCmnts') as FormArray; }
  get GeneralMentalHealthCmnts_() { return this.pForm.get('psychological.GeneralMentalHealth.GeneralMentalHealthCmnts') as FormArray; }
  get MentalHealthStatusCmnts_() { return this.pForm.get('psychological.MentalHealth.MentalHealthStatusCmnts') as FormArray; }
  get MilitaryServiceRelatedIssuesCmnts_() { return this.pForm.get('psychological.MilitaryServiceRelatedIssues.MilitaryServiceRelatedIssuesCmnts') as FormArray; }
  get PTSDandTraumaCmnts_() { return this.pForm.get('psychological.PTSDandTrauma.PTSDandTraumaCmnts') as FormArray; }
  get ReintegrationChallengesCmnts_() { return this.pForm.get('psychological.ReintegrationChallenges.ReintegrationChallengesCmnts') as FormArray; }
  get PsychiatricTreatmentHistoryCmnts_() { return this.pForm.get('psychological.PsychiatricTreatmentHistory.PsychiatricTreatmentHistoryCmnts') as FormArray; }
  get PsychologicalTreatmentHistoryCmnts_() { return this.pForm.get('psychological.PsychologicalTreatmentHistory.PsychologicalTreatmentHistoryCmnts') as FormArray; }
  get AdjustmentPostServiceCmnts_() { return this.pForm.get('psychological.AdjustmentPostService.AdjustmentPostServiceCmnts') as FormArray; }
  get CurrentLifeSituationsCmnts_() { return this.pForm.get('psychological.CurrentLifeSituations.CurrentLifeSituationsCmnts') as FormArray; }
  get LifestyleAndStressCmnts_() { return this.pForm.get('psychological.LifestyleAndStress.LifestyleAndStressCmnts') as FormArray; }
  get StressAndCopingMechanismsCmnts_() { return this.pForm.get('psychological.StressAndCopingMechanisms.StressAndCopingMechanismsCmnts') as FormArray; }
  get SocialSupportAndRelationshipsCmnts_() { return this.pForm.get('psychological.SocialSupportAndRelationships.SocialSupportAndRelationshipsCmnts') as FormArray; }
  get SubstanceUseCmnts_() { return this.pForm.get('socoal.substanceUse.SubstanceUseCmnts') as FormArray; }
  get FamilyMentalHealthHistoryCmnts_() { return this.pForm.get('psychological.FamilyMentalHealthHistory.FamilyMentalHealthHistoryCmnts') as FormArray; }
  get DigitalWellnessCmnts_() { return this.pForm.get('psychological.DigitalWellness.DigitalWellnessCmnts') as FormArray; }
  get OccupationalAdjustmentCmnts_() { return this.pForm.get('psychological.OccupationalAdjustment.OccupationalAdjustmentCmnts') as FormArray; }
  get LifeSatisfactionCmnts_() { return this.pForm.get('psychological.LifeSatisfaction.LifeSatisfactionCmnts') as FormArray; }
  get GoalsAndExpectationsCmnts_() { return this.pForm.get('psychological.GoalsAndExpectations.GoalsAndExpectationsCmnts') as FormArray; }

  get Conditions_() { return this.pForm.get('medical.Conditions') as FormArray; }
  get Allergies_() { return this.pForm.get('medical.Allergies') as FormArray; }
  get Symptoms_() { return this.pForm.get('medical.symptoms') as FormArray; }
  get medicalInsurances() { return this.pForm.get('medical_insurances') as FormArray; }

  add_eChildren() {
    const l_ = this.eChildren_.length; console.log(l_);
    this.eChildren_.push(this.fb.group({
      ['eChild_Name' + l_]: ['', Validators.required],
      ['eChild_Relationship' + l_]: ['', Validators.required],
      ['eChild_cP' + l_]: [''],
      ['eChild_E' + l_]: [''],
      ['eChild_Note' + l_]: [''],
    }));
  }

  add_eContact() {
    const l_ = this.eContacts_.length; console.log(l_);
    this.eContacts_.push(this.fb.group({
      ['ec_Name' + l_]: ['', Validators.required],
      ['ec_Relationship' + l_]: ['', Validators.required],
      ['ec_cP' + l_]: ['', Validators.required],
      ['ec_E' + l_]: ['', Validators.required],
      ['ec_hP' + l_]: [''],
      ['ec_wP' + l_]: [''],
      ['ec_pmoc' + l_]: ['', Validators.required]
    }));
  }

  addCondition() {
    const l_ = this.Conditions_.length; console.log(l_);
    this.Conditions_.push(this.fb.group({
      ['Condition' + l_]: ['', Validators.required],
      ['DiagnosisDate' + l_]: ['', Validators.required],
      ['TreatmentHistory' + l_]: ['', Validators.required],
      ['Complication' + l_]: ['', Validators.required],
    }));
  }

  get Meds_(): FormArray { return this.pForm.get('medical.Medications') as FormArray; }
  addMed() {
    this.Meds_.push(this.fb.group({
      medName: ['', Validators.required],
      medDosage: ['', Validators.required],
      medFrequency: ['', Validators.required],
      medRoute: ['', Validators.required],
      medClass: ['', Validators.required],
      medStartDate: ['', Validators.required],
      medEndDate: ['', Validators.required],
      medPurpose: ['', Validators.required],
      medICD10CMCode: ['', Validators.required],
      medNDC: ['', Validators.required],
      medSideEffects: ['', Validators.required],
      medAdherenceLevel: ['', Validators.required],
      medReasonsNonAdherence: ['', Validators.required],
    }));
  }
  populateMedications(medications: any[]): void {
    if (Array.isArray(medications)) { medications.forEach((med) => { this.Meds_.push(this.createMedicationFormGroup(med)); }); }
    else { console.error('Expected an array of medications, but received:', medications); }
  }
  createMedicationFormGroup(med: any): FormGroup {
    return this.fb.group({
      medName: [med.medName, Validators.required],
      medDosage: [med.medDosage, Validators.required],
      medFrequency: [med.medFrequency, Validators.required],
      medRoute: [med.medRoute, Validators.required],
      medClass: [med.medClass, Validators.required],
      medStartDate: [med.medStartDate, Validators.required],
      medEndDate: [med.medEndDate, Validators.required],
      medPurpose: [med.medPurpose, Validators.required],
      medICD10CMCode: [med.medICD10CMCode, Validators.required],
      medNDC: [med.medNDC, Validators.required],
      medSideEffects: [med.medSideEffects, Validators.required],
      medAdherenceLevel: [med.medAdherenceLevel, Validators.required],
      medReasonsNonAdherence: [med.medReasonsNonAdherence, Validators.required],
    });
  }

  addAllergy() {
    const l_ = this.Allergies_.length; console.log(l_);
    this.Allergies_.push(this.fb.group({
      ['Allergy' + l_]: ['', Validators.required],
      ['OnsetDate' + l_]: ['', Validators.required],
      ['Reaction' + l_]: ['', Validators.required],
      ['ReactionSeverity' + l_]: ['', Validators.required],
    }));
  }
  addSymptom() {
    const l_ = this.Symptoms_.length; console.log(l_);
    this.Symptoms_.push(this.fb.group({
      ['sympName' + l_]: ['', Validators.required],
      ['sympOnsetDate' + l_]: ['', Validators.required],
      ['sympDurationVal' + l_]: ['', Validators.required],
      ['sympDurationUnit' + l_]: ['', Validators.required],
      ['sympSeverity' + l_]: ['', Validators.required],
      ['sympFrequencyTime' + l_]: ['', Validators.required],
      ['sympFrequencyPeriod' + l_]: ['', Validators.required],
      ['sympTriggers' + l_]: ['', Validators.required],
      ['sympRelievingFactors' + l_]: ['', Validators.required],
      ['sympAssociatedSymptoms' + l_]: ['', Validators.required],
      ['sympLocationBodyPart' + l_]: ['', Validators.required],
      ['sympLocationLaterality' + l_]: ['', Validators.required],
      ['sympCharacter' + l_]: ['', Validators.required],
      ['sympSeverity' + l_]: ['', Validators.required],
      ['sympProgression' + l_]: ['', Validators.required],
      ['sympImpactOnDailyActivities' + l_]: ['', Validators.required],
    }));
  }

  addGeneralMentalHealthCmnt() { this.GeneralMentalHealthCmnts_.push(this.fb.group({ ['GeneralMentalHealthCmnt' + this.GeneralMentalHealthCmnts_.length]: ['', Validators.required], })); }
  addMentalHealthStatusCmnt() { this.MentalHealthStatusCmnts_.push(this.fb.group({ ['MentalHealthStatusCmnt' + this.MentalHealthStatusCmnts_.length]: ['', Validators.required], })); }
  addMilitaryServiceRelatedIssuesCmnt() { this.MilitaryServiceRelatedIssuesCmnts_.push(this.fb.group({ ['MilitaryServiceRelatedIssuesCmnt' + this.MilitaryServiceRelatedIssuesCmnts_.length]: ['', Validators.required], })); }
  addCombatExperienceCmnt() { this.CombatExperienceCmnts_.push(this.fb.group({ ['CombatExperienceCmnt' + this.CombatExperienceCmnts_.length]: ['', Validators.required], })); }
  addPTSDandTraumaCmnt() { this.PTSDandTraumaCmnts_.push(this.fb.group({ ['PTSDandTraumaCmnt' + this.PTSDandTraumaCmnts_.length]: ['', Validators.required], })); }
  addReintegrationChallengesCmnt() { this.ReintegrationChallengesCmnts_.push(this.fb.group({ ['ReintegrationChallengesCmnt' + this.ReintegrationChallengesCmnts_.length]: ['', Validators.required], })); }
  addPsychiatricTreatmentHistoryCmnt() { this.PsychiatricTreatmentHistoryCmnts_.push(this.fb.group({ ['PsychiatricTreatmentHistoryCmnt' + this.PsychiatricTreatmentHistoryCmnts_.length]: ['', Validators.required], })); }
  addPsychologicalTreatmentHistoryCmnt() { this.PsychologicalTreatmentHistoryCmnts_.push(this.fb.group({ ['PsychologicalTreatmentHistoryCmnt' + this.PsychologicalTreatmentHistoryCmnts_.length]: ['', Validators.required], })); }
  addAdjustmentPostServiceCmnt() { this.AdjustmentPostServiceCmnts_.push(this.fb.group({ ['AdjustmentPostServiceCmnt' + this.AdjustmentPostServiceCmnts_.length]: ['', Validators.required], })); }
  addCurrentLifeSituationsCmnt() { this.CurrentLifeSituationsCmnts_.push(this.fb.group({ ['CurrentLifeSituationsCmnt' + this.CurrentLifeSituationsCmnts_.length]: ['', Validators.required], })); }
  addLifestyleAndStressCmnt() { this.LifestyleAndStressCmnts_.push(this.fb.group({ ['LifestyleAndStressCmnt' + this.LifestyleAndStressCmnts_.length]: ['', Validators.required], })); }
  addStressAndCopingMechanismsCmnt() { this.StressAndCopingMechanismsCmnts_.push(this.fb.group({ ['StressAndCopingMechanismsCmnt' + this.StressAndCopingMechanismsCmnts_.length]: ['', Validators.required], })); }
  addSocialSupportAndRelationshipsCmnt() { this.SocialSupportAndRelationshipsCmnts_.push(this.fb.group({ ['SocialSupportAndRelationshipsCmnt' + this.SocialSupportAndRelationshipsCmnts_.length]: ['', Validators.required], })); }
  addSubstanceUseCmnt() { this.SubstanceUseCmnts_.push(this.fb.group({ ['SubstanceUseCmnt' + this.SubstanceUseCmnts_.length]: ['', Validators.required], })); }
  addFamilyMentalHealthHistoryCmnt() { this.FamilyMentalHealthHistoryCmnts_.push(this.fb.group({ ['FamilyMentalHealthHistoryCmnt' + this.FamilyMentalHealthHistoryCmnts_.length]: ['', Validators.required], })); }
  addDigitalWellnessCmnt() { this.DigitalWellnessCmnts_.push(this.fb.group({ ['DigitalWellnessCmnt' + this.DigitalWellnessCmnts_.length]: ['', Validators.required], })); }
  addLifeSatisfactionCmnt() { this.LifeSatisfactionCmnts_.push(this.fb.group({ ['LifeSatisfactionCmnt' + this.LifeSatisfactionCmnts_.length]: ['', Validators.required], })); }
  addOccupationalAdjustmentCmnt() { this.OccupationalAdjustmentCmnts_.push(this.fb.group({ ['OccupationalAdjustmentCmnt' + this.OccupationalAdjustmentCmnts_.length]: ['', Validators.required], })); }
  addGoalsAndExpectationsCmnt() { this.GoalsAndExpectationsCmnts_.push(this.fb.group({ ['GoalsAndExpectationsCmnt' + this.GoalsAndExpectationsCmnts_.length]: ['', Validators.required], })); }

  /*
  addMilitarySrvcCmnt() {
    const l_ = this.MilitarySrvcCmnts_.length; console.log(l_);
    this.MilitarySrvcCmnts_.push(this.fb.group({
      ['MilitarySrvcCmnt' + l_]: ['', Validators.required],
    }));
  }*/
  addMedicalInsurance() {
    const l_ = this.medicalInsurances.length; console.log(l_);
    this.medicalInsurances.push(this.fb.group({
      ['Type' + l_]: ['', Validators.required],
      ['Provider' + l_]: ['', Validators.required],
      ['Policy_Number' + l_]: ['', Validators.required],
    }));
  }

  modifyKeys(data: any): any {
    const modifiedData: any = {};
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        modifiedData[key] = data[key].map((item: any) => this.modifyKeys(item));
      } else if (typeof data[key] === 'object') {
        modifiedData[key] = this.modifyKeys(data[key]);
      } else {
        const match = key.match(/(\d+)$/);
        const newKey = match ? key.replace(match[0], '') : key;
        modifiedData[newKey] = data[key];
      }
    });
    return modifiedData;
  }

  updatePForm() {
    this.isLoading = true;
    console.log("Update pForm: ", this.pForm);
    console.log("Update pForm.value: ", this.pForm.value);
    console.log("Update this.pHstry: ", this.pHstry);
    let udData: any = this.pForm.value;
    udData.id = this.pHstry.id;
    console.log("this.udData", udData);
    let rsp: any;
    this.aSrvc.historyUD(udData).subscribe(
      (result) => { rsp = result; console.log(rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        if (rsp.status === "success") {
          this.pHstry = rsp.history;
          this.toastr.success(rsp.message);
        }
      }
    )
  };

  submitForm() {
    console.log(this.pForm.value);
    const patientData = this.pForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    if (this.pForm.valid) {
      const patientData = this.pForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
  }

  submitFormOrg() {
    console.log(this.pForm.value);
    const patientData = this.pForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    /**
     * 
    if (this.pForm.valid) {
      const patientData = this.pForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
     * 
    */
  }

  predictedICD10Codes: string[] = [];
  predictICD10() {
    const patientData = this.pForm.value;
    // Call the service to predict the ICD-10 codes
    this.aSrvc.predictICD10(patientData).subscribe(
      (codes) => {
        this.predictedICD10Codes = codes;
      },
      (error) => {
        console.error('Error predicting ICD-10 codes:', error);
      }
    );
  }
  confirmCode(code: string) {
    // Add logic to handle the confirmed code
    console.log('Confirmed code:', code);
    // Possibly add the code to a list of confirmed codes
  }

}
