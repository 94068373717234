<div class="container-fluid"><h3>Group Therapy Sessions:</h3>
    <div class="row">
        <div class="col-12">
            <h4 style="display: inline-block;">Sort By:</h4>
            <div class="form-check" style="display: inline-block;">
                <input type="radio" class="btn-check" name="sGrouping" id="sGrouping1" autocomplete="off" checked (click)="showSession('Type')">
                <label class="btn btn-outline-primary" for="sGrouping1"><i class="bi bi-bookmark"></i> Therapy Type</label>
                <input type="radio" class="btn-check" name="sGrouping" id="sGrouping2" autocomplete="off" (click)="showSession('Location')">
                <label class="btn btn-outline-primary" for="sGrouping2"><i class="bi bi-geo-alt"></i> Room Location</label>
                <input type="radio" class="btn-check" name="sGrouping" id="sGrouping3" autocomplete="off" (click)="showSession('Department')">
                <label class="btn btn-outline-primary" for="sGrouping3"><i class="bi bi-diagram-3"></i> Department</label>
            </div>
        </div>
    </div>
    <!--div class="input-group w-50">
      <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
      <input type="text" class="form-control" placeholder="Search Hospital Directory by Name" aria-label="Search Hospital Directory by Name" aria-describedby="basic-addon1">
    </div!-->
    <div class="row" id="sessionRw">
        <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let s_ of sTypes_">
            <div class="card bg-info">
              <div class="card-body">
                <h5 class="card-title btn-primary">{{ s_.Header }}</h5>
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-start" *ngFor="let s_ of s_.group_sessions">
                        <a routerLink="/sessions/{{s_.lID}}-{{s_.dID}}-{{s_.gID}}" class="w-100 btn" role="button">
                        <h5 class="card-title" style="display:inline-block;">{{ s_.N }}: </h5><br>
                        <span><i class="bi bi-bookmark"></i> {{s_.Type}}</span><br>
                        <span *ngFor="let c_ of s_.Coordinators"><i class="bi bi-person-gear"> Coordinator: {{c_.ID}}</i><br></span>
                        <span><i class="bi bi-geo-alt"></i> {{s_.Location.N}},  {{s_.Location.Adr.A}},  {{s_.Location.Adr.A2}}</span><br>
                        <span><i class="bi bi-calendar-event"></i> Date: {{s_.Schedule.sDate}} to {{s_.Schedule.eDate}}<br><i class="bi bi-alarm"></i> Time: {{s_.Schedule.sTime}} to {{s_.Schedule.eTime | date: 'hh:mm a'}}, Duration: {{s_.Schedule.duration}} minutes</span><br>
                        <span><i class="bi bi-people"></i> Capacity: {{s_.MaxSeats}} |  Occupancy: {{s_.Occupancy}} | Wait List: {{s_.Waiting}}</span><br>
                        </a>
                    </li>
                </ul>
              </div>
            </div>
        </div>
    </div>
</div>