
// encounter.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
interface PromUpdate {
  id: number;
  PROM: any; // Consider defining a more specific type for PROM if possible
}

@Injectable({
  providedIn: 'root'
})
export class UpdatePromService {
  private apiUrl = environment.apiBaseUrl;  // Centralized API base URL

  constructor(private http: HttpClient) { }

  updateProm(promData: PromUpdate): Observable<any> { //console.log(`${this.apiUrl}/prom/update`, promData);
    return this.http.post(`${this.apiUrl}/prom/update`, promData);
  }

  reviewProm(promReview: any): Observable<any> { //console.log(`${this.apiUrl}/prom/review`, promReview);
    return this.http.post(`${this.apiUrl}/prom/review`, promReview);
  }

  writeupProm(promWriteup: any): Observable<any> { //console.log(`${this.apiUrl}/openai/createWriteUp`, promWriteup);
    return this.http.post(`${this.apiUrl}/openai/createWriteUp`, promWriteup);
  }

  /*reviewProm(eID: any, patient_id: any): Observable<any> {
    console.log(`${this.apiUrl}/prom/review`, { eID: eID, patient_id: patient_id });
    return this.http.post(`${this.apiUrl}/prom/review`, { eID: eID, patient_id: patient_id });
  }*/

  submitAnswer(hID: any, ansData: any) { //console.log(`${this.apiUrl}/encntr/ui-question`, { hID: hID, Ans: ansData });
    return this.http.post(`${this.apiUrl}/encntr/ui-question`, { hID: hID, Ans: ansData });
  }

}
