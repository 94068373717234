import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/internal/operators/switchMap';

interface Staff {
  lID: number;
  dID: number;
  iPg: string;
  R: string;
}

interface SignInResponse {
  token: string;
  expires_in: number;
  staff: any;
  company: any;
  location: any;
  department: any;
}

@Component({
  selector: 'app-signin',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  isSigningIn = false;
  errors: any = null;
  passwordVisible: boolean = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.signinForm = this.fb.group({
      E: ['', [Validators.required, Validators.email]],
      pswrd: ['', [Validators.required, Validators.minLength(6)]], // Password should have a minimum length
    });
  }

  ngOnInit(): void { }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  onSubmit(): void {
    // Check if form is invalid
    if (this.signinForm.invalid) {
      this.toastr.error('Please provide valid credentials');
      return;
    }

    this.isSigningIn = true;

    // Request CSRF token and then proceed with login
    this.authService.getCsrfToken()
      .pipe(
        switchMap(() => {
          // Proceed with login after CSRF token is retrieved
          return this.authService.signIn(this.signinForm.value);
        })
      )
      .subscribe({
        next: (result: SignInResponse) => {
          this.isSigningIn = false;
          this.handleSuccess(result);
        },
        error: (error) => {
          this.isSigningIn = false;
          this.handleError(error);
        },
      });
  }

  /**
   * Handle successful login response.
   */
  private handleSuccess(result: SignInResponse): void {
    this.toastr.success('Login successful');

    const { staff } = result;
    if (staff) {
      this.storeUserData(result);
      this.navigateToNextPage(staff);
    } else {
      this.toastr.error('Login failed: Missing staff data.');
    }
  }

  /**
   * Store relevant user data in session storage.
   */
  private storeUserData(result: SignInResponse): void {
    const { staff, company, location, department } = result;

    // Storing necessary non-sensitive data
    sessionStorage.setItem('stf', JSON.stringify(staff));
    sessionStorage.setItem('cmpy', JSON.stringify(company));
    sessionStorage.setItem('loc', JSON.stringify(location));
    sessionStorage.setItem('dpt', JSON.stringify(department));

    // Additional session keys for current location and department
    sessionStorage.setItem('currentLocationId', staff.lID.toString());
    sessionStorage.setItem('currentDepartmentId', staff.dID.toString());
  }

  /**
   * Navigate to the next page after successful login.
   */
  private navigateToNextPage(staff: any): void {
    let nextPage = `${staff.iPg}/${staff.lID}/${staff.dID}`;
    if (staff.R === '0000') {
      nextPage = staff.iPg;
    }
    this.router.navigate([nextPage]);
  }

  /**
   * Handle error from sign-in attempt.
   */
  handleError(error: any): void {
    console.error('Sign-in error:', error); // Add this for debugging
    if (error.status === 403) {
      this.toastr.error('CSRF token retrieval failed. Please refresh and try again.');
    } else if (error.status === 401) {
      this.toastr.error('Invalid credentials. Please try again.');
    } else {
      this.toastr.error('An unexpected error occurred. Please try again later.');
    }
  }
}
