import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr'; // Assume ngx-toastr is used for toasts

import { AssessmentResultsComponent } from '../assessment-results/assessment-results.component';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { SocketService } from 'src/app/service/socket.service';
import { EncounterStatusUpdateComponent } from '../encounter-status-update/encounter-status-update.component';

import { PromTotalPipe } from '../../../thePipes/prom-total.pipe';
import { PatientDetailsPipe } from '../../../thePipes/patient-details.pipe';

@Component({
  selector: 'app-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientSummaryComponent implements OnInit {
  @Input() patient: any;
  @Input() showNote: boolean = false;
  @Output() update = new EventEmitter<any>();

  private subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private toastr: ToastrService, // Toastr service for notifications
    private uSrvc: UtilitiesService,
    private socketService: SocketService, // Your custom socket service
    private promTotal: PromTotalPipe,
    private pDetails: PatientDetailsPipe,
    private cd: ChangeDetectorRef
  ) { }

  assessmentKeys: any = [];
  pReady: boolean = false;

  minimal_: boolean = false;
  mild_: boolean = false;
  moderate_: boolean = false;
  severe_: boolean = false;
  dialogRef: any;
  theUDRst: any;

  getBadgeClass(status: string): string {
    //console.log("getBadgeClass-status:", status);
    switch (status) {
      case 'scr-severe': return 'bg-danger';
      case 'scr-moderate': return 'bg-warning';
      case 'scr-mild': return 'bg-info';
      case 'scr-min': return 'bg-success';
      default: return 'bg-light';
    }
  }

  goToNotes() { console.log('go to the IBH Notes for this patient'); }
  //updateEHR(){console.log('go Update EHR with Current Results');this.update.emit(this.patient);}
  viewResults(pD_: any, aD_: any) {
    this.dialog.open(AssessmentResultsComponent, {
      width: '100%',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      data: { pD_: pD_, assessment: aD_ }
    })
  }
  // Getter to determine the classes based on the patient's encounter scores
  get encounterClasses() {
    const scrSBQR_ = this.uSrvc.addToScoreIfKeyExists(this.patient.encounter.promKeys, "SBQR", this.patient.encounter.prom.PHQ9.tlt_, 15);
    return {
      'scr-severe': scrSBQR_ >= 15, // Example condition
      'scr-moderate': scrSBQR_ >= 10 && scrSBQR_ < 15,
      'scr-mild': scrSBQR_ >= 5 && scrSBQR_ < 10,
      'scr-min': scrSBQR_ < 5,
    };
  }
  getBackgroundClass(status: string): string {
    switch (status) {
      case 'Alarm: Suicidal Ideation':
      case 'Alarm: Severe PHQ-9':
      case 'Alarm: Severe SBQ-R':
      case 'Alarm: High-Risk SBQ-R':
      case 'Alarm: Immediate Risk Identified':
      case 'Intervention: Escalated':
        return 'background-High-Urgency';
      case 'Intervention: Initiated':
      case 'Intervention: In Progress':
        return 'background-Elevated-Urgency';
      case 'Intervention: Completed':
      case 'Intervention: Under Review':
      case 'Intervention: Adjusted':
        return 'background-Moderate-Urgency';
      case 'Alarm: Severe GAD-7':
      case 'Alarm: Severe WHO-5':
        return 'background-Moderate-Urgency';
      default:
        return 'background-hidden';
    }
  }

  isHidden(status: string): boolean {
    return this.getBackgroundClass(status) === 'background-hidden';
  }

  getPromTlt() {
    const predefinedOrder = ['PHQ9', 'GAD7', 'WHO5', 'SBQR'];
    this.assessmentKeys = [];
    this.patient.demographics.dob = this.patient.dob;

    let pE_: any = this.patient.encounter;
    //console.log('pE_:', pE_, 'pE_.prom:', pE_.prom);
    if (pE_.prom !== null && typeof pE_.prom === 'object') {
      // Collect all keys from the encounter object
      const allKeys = Object.keys(pE_.prom);
      //console.log('All keys:', allKeys);

      // Ensure predefined keys are at the beginning in the correct order
      this.assessmentKeys = predefinedOrder.filter(key => allKeys.includes(key));

      // Find keys that are not in the predefined list and append them
      const additionalKeys = allKeys.filter(key => !predefinedOrder.includes(key));
      this.assessmentKeys.push(...additionalKeys);
    }
    //console.log('assessmentKeys:', this.assessmentKeys);
    for (const key of Object.keys(pE_.prom)) {
      if (pE_.prom !== null || pE_.prom !== "{}") {
        let prom_: any;
        if (Array.isArray(pE_.prom)) { prom_ = pE_.prom; }
        else if (pE_.prom !== null && typeof pE_.prom === 'object') {
          prom_ = pE_.prom[key];
        }
        if (pE_.promSummary !== null && key in pE_.promSummary) {
          let pSm_: any = pE_.promSummary[key];
          this.patient.encounter.prom[key].tlt_ = pSm_.sumR;
          this.patient.encounter.prom[key].pA = pSm_.nonZeroRCount;
          this.patient.encounter.prom[key].s = pSm_.sumS;
          //console.log("promSummary_ prom:", this.patient.encounter.prom);
        }
        else {
          let tlt_: any = this.promTotal.transform(prom_, 'pipeFilter');
          const scr_ = parseInt(tlt_[0]);
          if (Array.isArray(pE_.prom)) {
            this.patient.encounter.prom.tlt_ = scr_;
            this.patient.encounter.prom[key].tlt_ = scr_;
            this.patient.encounter.prom.pA = tlt_[1];
            this.patient.encounter.prom[key].pA = tlt_[1];
            this.patient.encounter.prom.s = tlt_[2];
            this.patient.encounter.prom[key].s = tlt_[2];
          } else if (pE_.prom !== null && typeof pE_.prom === 'object') {
            this.patient.encounter.prom[key].tlt_ = scr_;
            this.patient.encounter.prom[key].pA = tlt_[1];
            this.patient.encounter.prom[key].s = tlt_[2];
          }
          //console.log("prom:", this.patient.encounter.prom);
        }
        this.showWarningIcon("");
      }
    }
    this.pReady = true;
  }

  ngOnInit(): void {
    this.patient.dob = this.uSrvc.convertDateIfValid(this.patient.dob);
    this.getPromTlt();
    this.listenToEncounterUpdate();
  }
  /**************************** */
  private listenToEncounterUpdate(): void {
    // Ensures the socket is connected before subscribing to events
    this.socketService.connect();
    // Subscribes to the EncounterUpdate event from the SocketService
    const encounterUpdateSubscription = this.socketService.subscribeToEncounterUpdate()
      .subscribe({
        next: (data: any) => { this.handleEncounterUpdate(data); },
        error: (error: any) => console.error('Error receiving EncounterUpdate:', error)
      });
    this.subscriptions.add(encounterUpdateSubscription);
  }
  private handleEncounterUpdate(data: any): void {
    if (this.patient.id === parseInt(data.patient_id)) {
      //this.toastr.success('An encounter has been updated.');
      const histroy_O = this.patient.encounter.history;
      this.patient.encounter = data;
      this.patient.encounter.history = histroy_O;
      this.getPromTlt();
      this.cd.detectChanges(); // Manually trigger change detection
      console.log("PatientSummaryComponent EncounterUpdate:", this.patient.encounter);
    };
  }
  // Method to determine whether to show the warning icon
  showWarningIcon(appointmentStatus: string): boolean {
    // Define the statuses that require a warning icon
    const warningStatuses = ['Cancelled', 'Missed', 'No Show', 'Rescheduled'];

    // Return true if the status matches any of the warning statuses
    return warningStatuses.includes(appointmentStatus);
  }
  openInterventionUpdateDialog(updateData: any): void {
    this.dialogRef = this.dialog.open(EncounterStatusUpdateComponent, {
      width: '90%', enterAnimationDuration: '1000ms', exitAnimationDuration: '500ms', data: updateData
    })
  }

}
