import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
//import { Model } from "survey-core";
import { ThemePalette } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UtilitiesService } from '../../../service/utilities.service';
import { AuthService } from '../../../service/auth.service';
import { UpdatePromService } from '../../../service/update-prom.service';
import { ToastrService } from "ngx-toastr";

interface Question {
  text: string;
  options: string[];
  selectedOption?: string;
}

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css'],

})

export class QuestionnaireComponent implements OnInit {
  isAssessmentMenuActv: boolean = true;
  private lastClickTime: number = 0;
  public timeDifference: number = 0;

  color: ThemePalette = 'accent';
  mode: MatProgressBarModule = 'determinate';
  value = 0;
  bufferValue = 0;

  rsp: any; auth: any; errors: any = null;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  pD_: any;
  eData: any;
  Apt: any; aID: any;
  dateTime: any;
  prom: any = [];

  P_: any;
  R_: any;
  Q_: any;
  Hdr: any;
  theQ: any;
  theR: any;
  currentScore: number = 0;
  currentQ_: string = "";
  iNote: any = []; iNv: any = [];
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private uSrvc: UtilitiesService,
    private udpSrvc: UpdatePromService,
    private aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { console.log("redirect");/*this.router.navigate(['login/:id']);*/ }
    else {this.stf = JSON.parse(stf); const eData: any = localStorage.getItem('eData'); this.eData = JSON.parse(eData); console.log("eData", this.eData);}
  }
  back($event: any) {
    console.log($event);
    const pPg = this.iNv.pPg;
    const cPg = this.iNv.cPg;
    const nPg = this.iNv.nPg;
    this.iNv.pPg = cPg;
    this.iNv.cPg = pPg;
    this.iNv.nPg = nPg - 1;
    if (this.iNv.nPg <= -1) { this.iNv.nPg = 1; }
    this.value = (1 - (this.iNv.mPg - this.iNv.cPg) / this.iNv.mPg) * 100;
    console.log(this.iNv, this.value);
    this.theQ = this.Q_.question[this.iNv.cPg].text;
  }
  next($event: any) {
    const currentTime = new Date().getTime();
    if (this.lastClickTime !== 0) {
      this.timeDifference = Math.ceil((currentTime - this.lastClickTime) / 1000);
    }
    this.lastClickTime = currentTime;
    /*console.log($event.target);*///console.log($event.target.labels[0].innerText);
    //this.Q_.slctdOptn = null;
    this.dateTime = new Date(); /*console.log(this.dateTime);*/
    /*console.log($event.target.value);*/
    this.Q_.question[this.iNv.cPg].value = $event.target.value;//console.log(this.Q_.question);
    this.currentScore = this.currentScore + parseInt($event.target.value);
    this.Q_.question[this.iNv.cPg].answer = $event.target.labels[0].innerText;//console.log(this.Q_.question);
    this.Q_.question[this.iNv.cPg].Time = this.dateTime;//console.log(this.Q_.question);

    this.Q_.question[this.iNv.cPg].patientResponse = $event.target.value;//console.log(this.Q_.question);
    this.Q_.currentScore = this.currentScore;//console.log(this.Q_.question);
    this.prom[this.Q_.ID].push({ q: (this.iNv.cPg + 1), r: parseInt($event.target.value), t: Math.ceil(this.timeDifference) });
    let promData = { [this.Q_.ID]: this.prom[this.Q_.ID] };

    /*console.log(this.Q_.ID, promData);*/
    localStorage.setItem('prom', JSON.stringify(promData));
    interface PromUpdate {
      id: number;
      PROM: any; // Consider defining a more specific type for PROM if possible
    }
    const upProm: PromUpdate = {
      id: this.eData.encounter.id,
      PROM: promData
    };
    this.udpSrvc.updateProm(upProm).subscribe({
      next: (response) => console.log('Response:', response),
      error: (error) => console.error('Error:', error)
    });

    const pPg = this.iNv.pPg;
    const cPg = this.iNv.cPg; const nPg = this.iNv.nPg;
    this.iNv.pPg = cPg; this.iNv.cPg = nPg; this.iNv.nPg = nPg + 1;
    if (this.iNv.nPg === this.Q_.question.length) { this.iNv.nPg = 0; }
    this.value = (1 - (this.iNv.mPg - this.iNv.cPg) / this.iNv.mPg) * 100;
    /*console.log(this.currentScore);*/
    if ((cPg === 1 && this.currentScore === 0) || cPg === this.iNv.mPg - 1) {
      localStorage.setItem('Q_', JSON.stringify(this.Q_));
      localStorage.setItem('currentQ_', this.Q_.ID);
      this.router.navigate(['axReview/' + this.lID + '/' + this.dID + '/' + this.aID]);
    }
    else {
      this.theQ = this.Q_.question[this.iNv.cPg].text;
      this.theR = this.Q_.question[this.iNv.cPg].response;
      if (this.Q_.ID === "SBQR" || this.Q_.ID === "WHO5") { this.R_ = this.Q_.question[this.iNv.cPg].response; /*console.log(this.R_);*/ }
      else { this.R_ = this.P_.Responses[0][this.Q_.question[this.iNv.cPg].response]; /*console.log(this.R_);*/ }
      //this.Q_.slctdOptn = null;
      /*console.log(this.theQ, this.theR);*/
    }
    //document.querySelector('input[type="radio"]:checked').checked = false;
  }
  allDone($event: any) {//console.log($event.target);console.log($event.target.labels[0].innerText);
    //console.log(document.getElementById("is3dCheckBox"));
    this.Q_.question[this.iNv.cPg].patientResponse = $event.target.value;//console.log(this.Q_.question);
    this.currentScore = this.currentScore + parseInt($event.target.value);
    this.Q_.question[this.iNv.cPg].answer = $event.target.labels[0].innerText;//console.log(this.Q_.question);
    this.Q_.question[this.iNv.cPg].patientResponse = $event.target.value;//console.log(this.Q_.question);
    this.Q_.currentScore = this.currentScore;//console.log(this.Q_.question);
    //this.Q_.question[this.iNv.cPg].time= new Date();//console.log(this.Q_.question);
    /*console.log(this.currentScore);*/
    this.router.navigate(['axReview/' + this.lID + '/' + this.dID + '/' + this.aID]);
    localStorage.setItem('Q_', JSON.stringify(this.Q_));
    localStorage.setItem('currentQ_', this.Q_.ID);
  }

  ngOnInit() {
    this.lID = this.route.snapshot.paramMap.get('param1');
    this.dID = this.route.snapshot.paramMap.get('param2');
    this.aID = this.route.snapshot.paramMap.get('param3');
    /*console.log(this.lID, this.dID, this.aID);*/
    const A_: any = localStorage.getItem('Apt'); this.Apt = JSON.parse(A_); console.log(this.Apt);
    console.log(localStorage['currentQ_']);

    this.aSrvc.getQuestionnaire(this.stf.lID).subscribe(
      (result) => { this.responseHandler(result); this.rsp = result; },
      (error) => { this.errors = error.error; },
      () => {
        let Qs__: any = this.rsp; let k_ = 0;
        let Qs_ = Qs__[0];
        let x_ = JSON.parse(Qs_.Questionaires); Qs_.Questionaires = x_;
        x_ = JSON.parse(Qs_.Responses); Qs_.Responses = x_;
        //this.P_ = Qs_.Pages.find((P_: { Type: string; }) => P_.Type === "Mental Health"); console.log(this.P_);
        this.P_ = Qs_; //console.log(this.P_, Qs_);
        this.R_ = this.P_.Responses[0].rg0; //console.log(this.R_);
        this.Q_ = this.P_.Questionaires.find((Q_: { ID: string; }) => Q_.ID === localStorage['currentQ_']);
        this.Q_.slctdOptn = null;

        this.Hdr = this.Q_.Header;
        this.iNv.cPg = 0;
        this.iNv.nPg = 1;
        this.iNv.mPg = this.Q_.question.length;
        this.iNv.pPg = this.iNv.mPg - 1;
        //console.log(this.iNv);
        //this.value=(1-(this.iNv.mPg-this.iNv.cPg) / this.iNv.mPg)*100;console.log(this.value);
        this.theQ = this.Q_.question[this.iNv.cPg].text; //console.log(this.theQ);
        this.theR = this.Q_.question[this.iNv.cPg].response; //console.log(this.theR);
        if (this.Q_.ID === "SBQR" || this.Q_.ID === "WHO5") {
          this.theR = this.Q_.ID;
          this.R_ = this.Q_.question[this.iNv.cPg].response; //console.log(this.R_);
        }
        console.log(this.Q_);
        this.prom[this.Q_.ID] = [];
        this.lastClickTime = new Date().getTime();
      }
    );
  }

  // Handle response
  responseHandler(data: any) {
  }
}