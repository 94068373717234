import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import { Staff } from 'src/app/Store/Model/type.Interfeaces';

@Component({
  selector: 'app-staff-editor',
  templateUrl: './staff-editor.component.html',
  styleUrls: ['./staff-editor.component.css']
})
export class StaffEditorComponent implements OnInit {
  isLoading: boolean = true;
  nwStf: boolean = true;
  enableGS: boolean = false;

  [x: string]: any;
  rsp: any; auth: any; errors: any = null;
  cmpy: any = []; locs: any = []; loc: any; lID: any; dpts: any = []; dpt: any; dID: any; gs: any; gID: any;
  stf: any; sID: any;
  roleID: any;
  eSTF!: any; eSID: any; eLoc: any; eLID: any; eDpt: any; eDID: any; eGS: any; eGID: any;
  nSTF!: Staff;
  udData: object = {};
  eForm: FormGroup;

  constructor(private route: ActivatedRoute, public fb: FormBuilder,
    public uSrvc: UtilitiesService,
    public aSrvc: AuthService,
    private toastr: ToastrService,
  ) {
    const stf_: any = localStorage.getItem('stf');
    if (stf_ === null) {//console.log("redirect");this.router.navigate(['patients']);
    }
    else {
      this.stf = JSON.parse(stf_); console.log(this.stf);
      const cmpy: any = localStorage.getItem('cmpy'); this.cmpy = JSON.parse(cmpy); console.log(this.cmpy);
      const locs: any = localStorage.getItem('locs'); this.locs = JSON.parse(locs); console.log(this.locs);
    }
    this.eForm = this.fb.group({
      fN: [''], mN: [''], lN: [''],
      pfx: [''], sfx: [''], tlt: [''], dgr: [''],
      gndr: [''], dbY: [''], dbM: [''], dbD: [''],
      E: [''], cP: [''], wP: [''],
      sID: [''], pswrd: [''], pswrdC: [''],
      AL: [''], R: [''], iPg: [''],
      cID: [''], lID: [''], dID: [''], gID: [''],
      actv: [''], actvD: [''], dactvD: [''],
      //      img: [''],imgD: [''],
      A: [''],
      A2: [''],
      S: [''],
      C: [''],
      Z: [''],
      note: [''],
    });
  }

  eFormSV() {
    this.isLoading = true;
    console.log("Save new staff", this.eForm.value);
    this.aSrvc.StfSV(this.eForm.value).subscribe(
      (result) => { this.rsp = result; },
      (error) => { this.errors = error.error; },
      () => {
        console.log(this.rsp);
        const sData = this.rsp[0];
        if (sData.status === "success") {
          this.nSTF = sData.staff; this.eSTF = this.nSTF;
          this.eForm = this.fb.group({
            fN: [this.nSTF.fN], mN: [this.nSTF.mN], lN: [this.nSTF.lN],
            pfx: [this.nSTF.pfx], sfx: [this.nSTF.sfx], tlt: [this.nSTF.tlt], dgr: [this.nSTF.dgr],
            gndr: [this.nSTF.gndr], dbY: [this.nSTF.dbY], dbM: [this.nSTF.dbM], dbD: [this.nSTF.dbD],
            E: [this.nSTF.E], cP: [this.nSTF.cP], wP: [this.nSTF.wP],
            sID: [this.nSTF.sID], pswrd: [this.nSTF.pswrd], pswrdC: [this.nSTF.pswrdC],
            AL: [this.nSTF.AL], R: [this.nSTF.R], iPg: [this.nSTF.iPg],
            cID: [this.nSTF.cID], lID: [this.nSTF.lID], dID: [this.nSTF.dID], gID: [this.nSTF.cID],
            actv: [this.nSTF.actv], actvD: [this.nSTF.actvD], dactvD: [this.nSTF.dactvD],
            //      img: [this.nSTF.cID],imgD: [this.nSTF.cID],
            Adr: [this.nSTF.Adr],
            note: [this.nSTF.note],
          });
          this.toastr.success(sData.message);
          this.isLoading = false;
        }
      }
    );
  }
  eFormUD() {
    this.isLoading = true;
    console.log("this.eForm.value", this.eForm.value);
    this.udData = {
      "id": this.eSTF.id,
      "sID": this.eSTF.sID,
      "AL": this.eForm.value.AL,
      "R": this.eForm.value.R,
      "iPg": this.eForm.value.iPg,
      "fN": this.eForm.value.fN,
      "mN": this.eForm.value.mN,
      "lN": this.eForm.value.lN,
      "pfx": this.eForm.value.pfx,
      "sfx": this.eForm.value.sfx,
      "dgr": this.eForm.value.dgr,
      "tlt": this.eForm.value.tlt,
      "gndr": this.eForm.value.gndr,
      "dbY": this.eForm.value.dbY,
      "dbM": this.eForm.value.dbM,
      "dbD": this.eForm.value.dbD,
      "E": this.eForm.value.E,
      "cP": this.eForm.value.cP,
      "wP": this.eForm.value.wP,
      "img": this.eSTF.img, "imgD": this.eSTF.imgD,
      "cID": this.eSTF.cID, "lID": this.eForm.value.lID, "dID": this.eForm.value.dID, "gID": this.eForm.value.gID,
      "actv": this.eForm.value.actv, "actvD": this.eForm.value.actvD, "dactvD": this.eForm.value.dactvD,
      "pswrd": this.eForm.value.pswrd, "pswrdC": this.eForm.value.pswrdC,
      "Adr": {
        "A": this.eForm.value.A,
        "A2": this.eForm.value.A2,
        "C": this.eForm.value.C,
        "S": this.eForm.value.S,
        "Z": this.eForm.value.Z
      },
      "note": this.eForm.value.note
    };
    console.log("this.udData", this.udData);
    this.aSrvc.StfUD(this.udData).subscribe(
      (result) => { this.rsp = result; },
      (error) => { this.errors = error.error; },
      () => {
        console.log(this.rsp);
        const sData = this.rsp[0];
        if (sData.status === "success") {
          this.nSTF = sData.staff; this.eSTF = this.nSTF;
          this.eForm = this.fb.group({
            fN: [this.nSTF.fN], mN: [this.nSTF.mN], lN: [this.nSTF.lN],
            pfx: [this.nSTF.pfx], sfx: [this.nSTF.sfx], tlt: [this.nSTF.tlt], dgr: [this.nSTF.dgr],
            gndr: [this.nSTF.gndr], dbY: [this.nSTF.dbY], dbM: [this.nSTF.dbM], dbD: [this.nSTF.dbD],
            E: [this.nSTF.E], cP: [this.nSTF.cP], wP: [this.nSTF.wP],
            sID: [this.nSTF.sID], pswrd: [this.nSTF.pswrd], pswrdC: [this.nSTF.pswrdC],
            AL: [this.nSTF.AL], R: [this.nSTF.R], iPg: [this.nSTF.iPg],
            cID: [this.nSTF.cID], lID: [this.nSTF.lID], dID: [this.nSTF.dID], gID: [this.nSTF.cID],
            actv: [this.nSTF.actv], actvD: [this.nSTF.actvD], dactvD: [this.nSTF.dactvD],
            //      img: [this.nSTF.cID],imgD: [this.nSTF.cID],
            Adr: [this.nSTF.Adr],
            note: [this.nSTF.note],
          });
          this.toastr.success(sData.message);
          this.isLoading = false;
        }
      }
    );
  }
  updatePhn($event: any) {
    console.log("$event: ", $event.target.id, $event.target.value);
    this.eSTF[$event.target.id] = this.uSrvc.formatPhone($event.target.value);
    //this.eLID=this.uSrvc.formatPhone($event);
  }
  chgLoc($event: any) {
    this.eLID = $event.target.value; this.eDID = "0001"; console.log(this.eLID);
    this.eLoc = this.locs.find((loc: { lID: any; }) => loc.lID === this.eLID); console.log(this.eLoc);
    this.eDpt = this.eLoc.Departments.find((dpt: { dID: any; }) => dpt.dID === this.eDID); console.log(this.eDpt);
  }
  chgDpt($event: any) {
    this.eDID = $event.target.value; console.log(this.eDID);
    this.eDpt = this.eLoc.Departments.find((dpt: { dID: any; }) => dpt.dID === this.eDID); console.log(this.eDpt);
    if (this.eDpt.GroupSessions === null || this.eDpt.GroupSessions === '' || typeof this.eDpt.GroupSessions !== 'object') {
      this.enableGS = false;
    }
    else { this.enableGS = true; this.eGS = this.eDpt.GroupSessions; }
  }
  chgGS($event: any) {
    this.eGID = $event.target.value; console.log(this.eGID);
    this.eGS = this.eDpt.GroupSessions.find((gs: { gID: any; }) => gs.gID === this.eGID); console.log(this.eGS);
  }
  chgRole($event: any) {
    this.roleID = $event.target.value; this.eSTF.R = this.roleID;
    if (this.roleID === '000A') { this.eSTF.iPg = 'home'; }
    else if (this.roleID === '0008') { this.eSTF.iPg = 'appointments'; }
    else if (this.roleID === '0006' || this.roleID === '0004') { this.eSTF.iPg = 'patients'; }
    else { this.eSTF.iPg = 'home'; }
    console.log('new Role', this.roleID, 'loc.Roles', this.eLoc.Roles, 'loc.LPs', this.eLoc.LPs);
  }

  ngOnInit(): void {
    this.isLoading = true;
    const task_: any = this.route.snapshot.url[1].path; console.log(task_);
    const tID: any = this.route.snapshot.paramMap.get('id'); console.log('tID', tID);
    this.lID = localStorage.getItem('currentLID');
    this.dID = localStorage.getItem('currentDID');
    if (task_ === "e" || task_ === "d") { this.eLID = this.uSrvc.getLid(tID); this.eDID = this.uSrvc.getDid(tID); this.eSID = this.uSrvc.getSid(tID); }
    else { this.eLID = this.lID; this.eDID = this.dID; this.eSID = '' }
    this.eLoc = this.locs.find((loc: { lID: any; }) => loc.lID === this.eLID); console.log('this.eLID', this.eLID, 'this.eLoc', this.eLoc);
    this.eDpt = this.eLoc.Departments.find((dpt: { dID: any; }) => dpt.dID === this.eDID); console.log('this.eDID', this.eDID, 'this.eDpt', this.eDpt);
    if (task_ === "e" || task_ === "d") {
      this.nwStf = false;
      this.aSrvc.GetStf(this.eSID).subscribe(
        (result) => { this.responseHandler(result); this.rsp = result; console.log(this.rsp); },
        (error) => { this.errors = error.error; },
        () => {
          const eSTF: Staff = this.rsp[0]; this.eSTF = eSTF; console.log('this.eSID', this.eSID, 'this.eSTF', this.eSTF);
          this.eLID = this.eSTF.lID;
          this.eDID = this.eSTF.dID;
          this.eGID = this.eSTF.gID;
          if (this.eGID === null || this.eGID === '') { this.enableGS = false; } else { this.enableGS = true; }
          this.eForm = this.fb.group({
            fN: [this.eSTF.fN], mN: [this.eSTF.mN], lN: [this.eSTF.lN],
            pfx: [this.eSTF.pfx], sfx: [this.eSTF.sfx], tlt: [this.eSTF.tlt], dgr: [this.eSTF.dgr],
            gndr: [this.eSTF.gndr], dbY: [this.eSTF.dbY], dbM: [this.eSTF.dbM], dbD: [this.eSTF.dbD],
            E: [this.eSTF.E], cP: [this.eSTF.cP], wP: [this.eSTF.wP],
            sID: [this.eSTF.sID], pswrd: [this.eSTF.pswrd], pswrdC: [''],
            AL: [this.eSTF.AL], R: [this.eSTF.R], iPg: [this.eSTF.iPg],
            cID: [this.eSTF.cID], lID: [this.eSTF.lID], dID: [this.eSTF.dID], gID: [this.eSTF.gID],
            actv: [this.eSTF.actv === "1" ? true : false], actvD: [this.eSTF.actvD], dactvD: [''],
            //      img: [''],imgD: [''],
            A: [this.eSTF.Adr !== null ? this.eSTF.Adr.A : ''],
            A2: [this.eSTF.Adr !== null ? this.eSTF.Adr.A2 : ''],
            C: [this.eSTF.Adr !== null ? this.eSTF.Adr.C : ''],
            S: [this.eSTF.Adr !== null ? this.eSTF.Adr.S : ''],
            Z: [this.eSTF.Adr !== null ? this.eSTF.Adr.Z : ''],
            note: [this.eSTF.note]
          });

          this.isLoading = false;
        }
      );
    }
    else {
      this.nwStf = true;
      let dateTime = new Date()
      this.eSTF = [];
      this.eSTF.gID = '';
      this.eSTF.actv = false;
      this.eSTF.actvD = dateTime;
      this.eSTF.T = '';
      this.eSTF.R = '';
      this.eSTF.iPg = '';
      this.isLoading = false;
    }
  }
  responseHandler(data: any) { /* this.token.handleData(data.atoken); */ }
}
