import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone({
      withCredentials: true  // Ensures HttpOnly cookies (if any) are sent with the request
    });

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(`HTTP Error: Status ${error.status}`, {
          message: error.message,
          url: error.url,
          errorDetails: error.error,
        });

        if (error.status === 401) {
          // Redirect to login page if session has expired
          this.toastr.warning('Session expired. Redirecting to login.');
          this.router.navigate(['/login']);
        } else if (error.status === 403) {
          // Handle 403 Forbidden error
          this.toastr.error('Access denied. You do not have permission to access this resource.');
        }

        // Propagate other errors
        return throwError(() => new Error(`HTTP Error: ${error.message || 'Unknown error occurred'}`));
      })
    );
  }
}
