import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private apiUrl = environment.apiBaseUrl; // Use the correct environment file
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();
  private activeRequests = 0; // Track active requests

  constructor(private http: HttpClient) { }

  // Example method to fetch appointments
  getSortedAppointments(lID: string, dID: string): Observable<any[]> {
    this.manageLoadingIndicator(true);
    //console.log('getSortedAppointments post:', `${this.apiUrl}/appt/dpt`, { lID, dID }); 

    return this.http.post<any[]>(`${this.apiUrl}/appt/dpt`, { lID, dID }).pipe(
      map(appointments => {
        //console.log('getSortedAppointments post:', appointments);
        return appointments.sort((a, b) => a.Time.localeCompare(b.Time));
      }),
      tap(() => this.manageLoadingIndicator(false)), // Adjust loading state on success
      catchError(error => {
        console.error('Error fetching appointments', error);
        this.manageLoadingIndicator(false);
        return throwError(() => new Error('Failed to fetch appointments'));
      })
    );
  }
  // Manage the loading indicator for multiple concurrent requests
  private manageLoadingIndicator(startRequest: boolean): void {
    this.activeRequests += startRequest ? 1 : -1;
    this.isLoadingSubject.next(this.activeRequests > 0);
  }

  // Add more service methods as needed...
}
