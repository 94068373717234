import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { GroupSession } from '../../Store/Model/type.Interfeaces';
import { SessionData1 } from '../../testingHCNdata';
import { UtilitiesService } from 'src/app/service/utilities.service';

@Component({
  selector: 'app-cmpy-dashboard',
  templateUrl: './cmpy-dashboard.component.html',
  styleUrls: ['./cmpy-dashboard.component.css']
})
export class CmpyDashboardComponent implements OnInit {
  sessions: any[]=[];
  theSessions: any[]=[];
  sessionTypes_: any[]=[];
  sessionTypes: any[]=[];
  cmpy:any;locs:any;loc:any;dpts:any;dpt:any;rptDepart:any;
  stf:any;

  showLocation(item:object){console.log(item);}

  constructor(private route: ActivatedRoute,private router: Router,public uSrvc: UtilitiesService){
    const stf_:any=localStorage.getItem('stf');
    if(stf_===null){console.log("redirect");this.router.navigate(['patients']);}
    else{
        this.stf = JSON.parse(stf_);console.log(this.stf);
        const cmpy:any=localStorage.getItem('cmpy');this.cmpy = JSON.parse(cmpy);console.log(this.cmpy);
        const locs:any=localStorage.getItem('locs');this.locs = JSON.parse(locs);console.log(this.locs);
        const loc:any=localStorage.getItem('loc');this.loc = JSON.parse(loc);console.log(this.loc);
        this.dpts=this.loc.Departments;console.log(this.dpts);
        const dpt:any=localStorage.getItem('dpt_');this.dpt = JSON.parse(dpt);console.log(this.dpt);
    }
  }

  ngOnInit(): void {
    this.sessions=SessionData1;console.log(this.sessions);
    const sVw_=this.sessions.reduce<{[k:string]:any[]}>((acc,val)=>((acc[val.Location.N]||(acc[val.Location.N]=[])).push(val),acc),{});console.log(sVw_);
    var xobj:any;
    for(let [key, value] of Object.entries(sVw_)){xobj={Header:key,Location:value[0].lID,Department:value[0].dID,TheSessions: value,};this.sessionTypes_.push(xobj);}console.log(this.sessionTypes_);
    for (var $f=0;$f<this.locs.length;$f++ ){
      for (var $c=0;$c<this.locs[$f].Departments.length;$c++ ){
        for (var $s=0;$s<this.sessionTypes_.length;$s++ ){
        //console.log(this.facilities[$f].Clinics[$c].lID);console.log(this.sessionTypes_[$s].Location);
          if ((this.locs[$f].Departments[$c].lID === this.sessionTypes_[$s].Location) && (this.locs[$f].Departments[$c].dID === this.sessionTypes_[$s].Department)){
            this.locs[$f].Departments[$c].sessions=this.sessionTypes_[$s];
            console.log(this.locs[$f]);
          }
          }
        }
    }
  }

}
