import { Component, OnInit, Output, EventEmitter, HostListener, Input, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { UtilitiesService } from 'src/app/service/utilities.service';
import * as Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-notes-initial-evaluation',
  templateUrl: './notes-initial-evaluation.component.html',
  styleUrls: ['./notes-initial-evaluation.component.css']
})

export class NotesInitialEvaluationComponent implements OnInit {
  [x: string]: any;
  @Input() patient: any;
  @Input() encntr: any;
  @Input() cmpy: any;
  @Input() templates: any;
  private subscription: Subscription = new Subscription();
  errors: any = null;
  isLoading: boolean = true;

  eForm: FormGroup | any;
  patientRdy: boolean = false;
  SubstanceUseInfo: boolean = false;
  cnfg: any; spCnfg: any;

  PHQ9Cnfg: any;
  iEncntr: any;
  initAx: any;
  GAD7Cnfg: any;
  WHO5Cnfg: any;
  PhysicalCnfg: any;
  FunctionalCnfg: any;

  sympCnfg: any;
  tpCnfg: any;
  axCnfg: any;
  noteCnfg: any;
  tpCMS: any;
  milCnfg: any;
  psyCnfg: any;
  psyMVCnfg: any;
  psyMACnfg: any;
  PHQ9: any = [];
  GAD7: any = [];
  WHO5: any = [];
  Physical: any = [];
  WellBeing: any = [];
  currentValue: number = 0;

  socialCnfg: any;
  medMngt: any;
  routeOfAdministration: any;
  classes: any;
  frequencyOptions: any;
  medCompliance: any;

  loc: any; lID: any; dpt: any; dID: any; rptDepart: any;
  stf: any;
  currentLevel: number = 15;
  targetLevel: number = 40;
  selectedYear!: number;
  onYearSelected(year: number): void {
    this.selectedYear = year;
  }

  constructor(private fb: FormBuilder, private http: HttpClient, private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private uSrvc: UtilitiesService,
    private aSrvc: AuthService,
    private renderer: Renderer2, private el: ElementRef
  ) {
    const stf: any = localStorage.getItem('stf');
    if (stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else { this.stf = JSON.parse(stf); console.log(this.stf); }

    this.eForm = this.createForm();
  }
  ngOnInit() {
    console.log("NotesInitialEvaluationComponent - ngOnInit - cmpy", this.cmpy);
    this.initializeConfigurations();
    this.selectedTemplate = this.noteCnfg[0];
    this.iEncntr = this.patient.encounters[0];
    this.checkSubstanceUseInfo();

    if (this.iEncntr?.initAx) {
      this.initAx = this.iEncntr.initAx.original.AssessmentNotes;
      console.log("NotesInitialEvaluationComponent - ngOnInit - initAx", this.initAx);
    }

    this.patientRdy = true;
    this.subscribeToSelectedEncounter();
  }

  private initializeConfigurations() {
    this.tpCnfg = this.cmpy.TreatmentPlan;
    this.axCnfg = this.cmpy.Assessments;
    this.medMngt = this.cmpy.medManagement;
    const medsMngmt = this.medMngt.prescribedMedications;
    this.routeOfAdministration = medsMngmt.routeOfAdministration;
    this.classes = medsMngmt.MedicationClasses;
    this.frequencyOptions = medsMngmt.frequencyOptions;
    this.medCompliance = medsMngmt.compliance.adherenceLevel;
    this.noteCnfg = this.cmpy.NoteTemplates;
    this.templates = this.cmpy.NoteTemplates;
    this.cnfg = this.cmpy.Cnfg;
    this.PHQ9Cnfg = this.cnfg.PHQ9Cnfg;
    this.GAD7Cnfg = this.cnfg.GAD7Cnfg;
    this.WHO5Cnfg = this.cnfg.WHO5Cnfg;
    this.PhysicalCnfg = this.cnfg.PhysicalCnfg;
    this.FunctionalCnfg = this.cnfg.FunctionalCnfg;
  }

  private checkSubstanceUseInfo() {
    if (this.iEncntr.history?.social?.substanceUse) {
      this.SubstanceUseInfo = true;
    }
  }

  private subscribeToSelectedEncounter() {
    this.subscription.add(this.uSrvc.selectedEncounter$.subscribe(encounter => { if (encounter) { this.updateEncounter(encounter); } }));
  }
  private updateEncounter(encounter: any) {
    this.encntr = encounter; console.log('updateEncounter: encntr: ', this.encntr);
    this.initializeForm();
    if (this.encntr.initAx !== null) {
      this.initAx = this.encntr.initAx.original.AssessmentNotes; console.log("updateEncounter initAx", this.initAx);
    }
  }

  selectedTemplate: any;
  onSelectTemplate(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TemplateID = Number(selectElement.value); console.log(TemplateID);
    this.selectedTemplate = this.noteCnfg.find((Template: { id: number; }) => Template.id === TemplateID);
  }

  selectedService: any;
  onSelectService(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const serviceID = Number(selectElement.value);
    this.selectedService = this.tpCnfg.PsychotherapyServices.find((service: { ID: number; }) => service.ID === serviceID);
  }
  private fillTemplate(template: string, data: any): string {
    console.log(data);
    return template.replace(/\{\{(\w+)\}\}/g, (_, key) => data[key] || `{{${key}}}`);
  }

  dynamicData: any = {}; // This should be populated with actual dynamic data
  filledTemplate: any; // This should be populated with actual dynamic data
  selectTemplate(template: any): void {
    this.selectedTemplate = template; console.log(this.selectedTemplate);
    // Here, you would replace placeholders in the selected template with actual data
    // For demonstration, let's assume dynamicData has all the necessary keys
    this.filledTemplate = this.fillTemplate(template.template, this.patient);
    console.log(this.filledTemplate);
  }

  promMeasures:any;
  promMeasures_:any=[];
  Sx_:any=[];
  private populateSxs(sxs: any[]): void {
    let ix=0;
    if (Array.isArray(sxs)) { sxs.forEach((sx) => { 
      this.Sx_.push(this.createSxFormGroup(sx, ix++)); }); }
    else { console.error('Expected an array of Symptoms, but received:', sxs); }
    console.log(this.Sx_);
  }
  private createSxFormGroup(sx: any,ix:any): FormGroup {
    let sName="";
    if (ix===0){sName=sx.PrimarySymptom;}if (ix===1){sName=sx.SecondarySymptom;}if (ix===2){sName=sx.ThirdSymptom;}
  return this.fb.group({
      sxName: [sName, Validators.required],
      sxOnset: [sx.sxOnset, Validators.required],
      sxSeverity: [sx.sxSeverity, Validators.required],
      sxDescription: [sx.sxDescription, Validators.required],
      sxDuration: [sx.sxDuration, Validators.required],
    });
  }

  private createForm(): FormGroup {
    return this.fb.group({
      PsychosocialFactors: ['', Validators.required],
      AnticipatedOutcomes: ['', Validators.required],
      MonitoringAndEvaluationPlan: ['', Validators.required],
      UtilizationReviewConsiderations: ['', Validators.required],
      FollowUpPlan: ['', Validators.required],
      ComplexityFactors: ['', Validators.required],
      PreliminaryDiagnosis: this.fb.group({
        ClinicalImpression: ['', Validators.required],
        Justification: ['', Validators.required],
        ICD10CMCodes: ['', Validators.required],
        JCodesConsiderations: ['', Validators.required],
      }),
      Prognosis: this.fb.group({
        Description: ['', Validators.required],
        Justification: ['', Validators.required],
      }),
      ClinicalAssessment: this.fb.group({
        ClinicalObservations: this.fb.group({
          MentalHealthStatus: ['', Validators.required],
          PsychologicalHealth: ['', Validators.required],
          SubstanceUse: ['', Validators.required],
          PhysicalHealth: ['', Validators.required],
          FunctionalCapacity: ['', Validators.required],
          Overall: ['', Validators.required],
        }),
        PatientReportedOutcomeMeasures: this.fb.group({
          PHQ9: this.fb.group({
            Score: ['', Validators.required],
            Assessment: ['', Validators.required],
          }),
          GAD7: this.fb.group({
            Score: ['', Validators.required],
            Assessment: ['', Validators.required],
          }),
          WHO5: this.fb.group({
            Score: ['', Validators.required],
            Assessment: ['', Validators.required],
          }),
        }),
      }),
      PreliminaryTreatmentPlan: this.fb.group({
        ProposedTreatment: ['', Validators.required],
        Justification: ['', Validators.required],
        ICD10CMCodes: ['', Validators.required],
        TreatmentModality: ['', Validators.required],
        ProposedMedication: this.fb.array([]),
      }),
      PresentingSymptoms: this.fb.array([]),
    });
  }

  initializeForm(): void {
    const formGroups: any = {};
    this.promMeasures_=[];
    this.promMeasures = this.initAx.ClinicalAssessment?.PatientReportedOutcomeMeasures[0];console.log(this.promMeasures);

    if (this.promMeasures) {
      Object.keys(this.promMeasures).forEach(measure => {console.log(this.promMeasures[measure]);
        if (!this.promMeasures_.includes(measure)) {
          this.promMeasures_.push(measure);
          formGroups[measure] = this.fb.group({
            TotalScore: [this.promMeasures[measure].TotalScore, Validators.required],
            SummaryAssessment: [this.promMeasures[measure].SummaryAssessment, Validators.required]
          });
        }
      });
    }

    this.eForm = this.fb.group({
      PsychosocialFactors: [this.initAx?.PsychosocialFactors || '', Validators.required],
      AnticipatedOutcomes: [this.initAx?.AnticipatedOutcomes || '', Validators.required],
      MonitoringAndEvaluationPlan: [this.initAx?.MonitoringAndEvaluationPlan || '', Validators.required],
      UtilizationReviewConsiderations: [this.initAx?.UtilizationReviewConsiderations || '', Validators.required],
      FollowUpPlan: [this.initAx?.FollowUpPlan || '', Validators.required],
      ComplexityFactors: [this.initAx?.ComplexityFactors || '', Validators.required],
      PreliminaryDiagnosis: this.fb.group({
        ClinicalImpression: [this.initAx?.PreliminaryDiagnosis.ClinicalImpression || '', Validators.required],
        Justification: [this.initAx?.PreliminaryDiagnosis.Justification || '', Validators.required],
        ICD10CMCodes: [this.initAx?.PreliminaryDiagnosis.ICD10CMCodes || '', Validators.required],
        JCodesConsiderations: [this.initAx?.PreliminaryDiagnosis.JCodesConsiderations || '', Validators.required],
      }),
      Prognosis: this.fb.group({
        Description: [this.initAx?.Prognosis.Description || '', Validators.required],
        Justification: [this.initAx?.Prognosis.Justification || '', Validators.required],
      }),
      ClinicalAssessment: this.fb.group({
        ClinicalObservations: this.fb.group({
          MentalHealthStatus: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.MentalHealthStatus || '', Validators.required],
          PsychologicalHealth: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.PsychologicalHealth || '', Validators.required],
          SubstanceUse: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.SubstanceUse || '', Validators.required],
          PhysicalHealth: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.PhysicalHealth || '', Validators.required],
          FunctionalCapacity: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.FunctionalCapacity || '', Validators.required],
          Overall: [this.initAx?.ClinicalAssessment?.ClinicalObservations?.Overall || '', Validators.required],
        }),
        PatientReportedOutcomeMeasures: this.fb.group(formGroups),
      }),
      PreliminaryTreatmentPlan: this.fb.group({
        ProposedTreatment: [this.initAx?.PreliminaryTreatmentPlan.ProposedTreatment || '', Validators.required],
        Justification: [this.initAx?.PreliminaryTreatmentPlan.Justification || '', Validators.required],
        ICD10CMCodes: [this.initAx?.PreliminaryTreatmentPlan.ICD10CMCodes || '', Validators.required],
        TreatmentModality: [this.initAx?.PreliminaryTreatmentPlan.TreatmentModality || '', Validators.required],
        ProposedMedication: this.fb.array([]),
      }),
      PresentingSymptoms: this.fb.array([]),
    });

    this.populatePresentingSymptoms(this.initAx?.PresentingSymptoms || []);
    this.populateProposedMedication(this.initAx?.PreliminaryTreatmentPlan?.ProposedMedication || []);
    console.log('this.eForm:', this.eForm);
  }
  get patientReportedOutcomeMeasures(): FormArray {
    return this.eForm.get('ClinicalAssessment').get('PatientReportedOutcomeMeasures') as FormArray;
  }
  private populatePresentingSymptoms(symptoms: any[]) {
    const symptomsArray = this.eForm.get('PresentingSymptoms') as FormArray;
    symptoms.forEach(symptom => {
      symptomsArray.push(this.createSymptomFormGroup(symptom));
    });
  }

  private createSymptomFormGroup(symptom: any): FormGroup {
    return this.fb.group({
      Onset: [symptom.Onset || '', Validators.required],
      Duration: [symptom.Duration || '', Validators.required],
      Severity: [symptom.Severity || '', Validators.required],
      Description: [symptom.Description || '', Validators.required],
      SymptomName: [symptom.PrimarySymptom || symptom.SecondarySymptom || symptom.ThirdSymptom || '', Validators.required]
    });
  }

  private populateProposedMedication(medications: any[]) {
    const medicationsArray = this.eForm.get('PreliminaryTreatmentPlan').get('ProposedMedication') as FormArray;
    medications.forEach(medication => {
      medicationsArray.push(this.createMedicationFormGroup(medication));
    });
  }

  private createMedicationFormGroup(medication: any): FormGroup {
    return this.fb.group({
      Dosage: [medication.Dosage || '', Validators.required],
      Interval: [medication.Interval || '', Validators.required],
      Frequency: [medication.Frequency || '', Validators.required],
      ICD10CMCodes: [medication.ICD10CMCodes || '', Validators.required],
      Justification: [medication.Justification || '', Validators.required],
      PossibleSideEffects: [medication.PossibleSideEffects || '', Validators.required],
      Medication: [medication.PrimaryMedication || medication.SecondaryMedication || medication.ThirdMedication || '', Validators.required]
    });
  }


  get PresentingSymptoms(): FormArray {
    return this.eForm.get('PresentingSymptoms') as FormArray;
  }

  changeMedClass(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus: any = String(selectElement.value);
    const id = String(selectElement.id);
    const prefix = 'medClass';
    const indexPart = id.substring(prefix.length); console.log('id', id, 'newStatus', newStatus, 'classes', this.classes);
    const class_ = this.classes.find((item: any) => item.ClassName === newStatus);
    console.log('newStatus', newStatus, 'indexPart', indexPart, 'classes', this.classes, 'class_', class_, 'icd10Code', class_.CommonICD10CMCodes);
    this.fb.group({ ['medICD10CMCode' + indexPart]: [class_.CommonICD10CMCodes, Validators.required] });
  }
  /*
  get Meds_(): FormArray { return this.eForm.get('Treatment.Medications') as FormArray; }
  addMed() {
    this.Meds_.push(this.fb.group({
      medName: ['', Validators.required],
      medDosage: ['', Validators.required],
      medFrequency: ['', Validators.required],
      medRoute: ['', Validators.required],
      medClass: ['', Validators.required],
      medStartDate: ['', Validators.required],
      medEndDate: ['', Validators.required],
      medPurpose: ['', Validators.required],
      medICD10CMCode: ['', Validators.required],
      medNDC: ['', Validators.required],
      medSideEffects: ['', Validators.required],
      medAdherenceLevel: ['', Validators.required],
      medReasonsNonAdherence: ['', Validators.required],
    }));
  }
  private populateMedications(medications: any[]): void {
    if (Array: any.isArray(medications)) { medications.forEach((med: any) => { this.Meds_.push(this.createMedicationFormGroup(med)); }); }
    else { console.error('Expected an array of medications, but received:', medications); }
  }

  private createMedicationFormGroup(med: any): FormGroup {
    return this.fb.group({
      medName: [med.medName, Validators.required],
      medDosage: [med.medDosage, Validators.required],
      medFrequency: [med.medFrequency, Validators.required],
      medRoute: [med.medRoute, Validators.required],
      medClass: [med.medClass, Validators.required],
      medStartDate: [med.medStartDate, Validators.required],
      medEndDate: [med.medEndDate, Validators.required],
      medPurpose: [med.medPurpose, Validators.required],
      medICD10CMCode: [med.medICD10CMCode, Validators.required],
      medNDC: [med.medNDC, Validators.required],
      medSideEffects: [med.medSideEffects, Validators.required],
      medAdherenceLevel: [med.medAdherenceLevel, Validators.required],
      medReasonsNonAdherence: [med.medReasonsNonAdherence, Validators.required],
    });
  }
*/
  modifyKeys(data: any): any {
    const modifiedData: any = {};
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        modifiedData[key] = data[key].map((item: any) => this.modifyKeys(item));
      } else if (typeof data[key] === 'object') {
        modifiedData[key] = this.modifyKeys(data[key]);
      } else {
        const match = key.match(/(\d+)$/);
        const newKey = match ? key.replace(match[0], '') : key;
        modifiedData[newKey] = data[key];
      }
    });
    return modifiedData;
  }

  updatePForm() {
    console.log("Update eForm: ", this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    this.aSrvc.patientUD(transformedPatientData).subscribe(
      (result: any) => { console.log(result); }
    );
  }
  submitForm() {
    console.log(this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    if (this.eForm.valid) {
      const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe((response: any) => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, (error: any) => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
  }

  submitFormOrg() {
    console.log(this.eForm.value);
    const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
    /**
     * 
    if (this.eForm.valid) {
      const patientData = this.eForm.value; const transformedPatientData = this.modifyKeys(patientData); console.log(transformedPatientData);
      this.http.post<any>('http://your-api-endpoint/create-patient', transformedPatientData)
        .subscribe(response => {
          console.log('Patient created successfully', response);
          // Handle success, e.g., navigate to another page
        }, error => {
          console.error('Error creating patient', error);
          // Handle error
        });
    } else {
      // Handle form validation errors
    }
     * 
    */
  }


  /*
  onSymptomSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const SymptomID = selectElement.value;
    this.dynamicData.Sx.Name = SymptomID;
    console.log(this.dynamicData.Sx.Name);
  }
  onTreatmentModalitiesSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TreatmentModalitiesID = selectElement.value;
    this.dynamicData.PTP.Modalities = TreatmentModalitiesID;
    console.log(this.dynamicData.PTP.Modalities);
  }
  onTreatmentPlanSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const TreatmentPlanID = selectElement.value;
    console.log(TreatmentPlanID);
    this.dynamicData.PTP.Details = TreatmentPlanID;
    console.log(this.dynamicData.PTP.Details);
  }
  onComplexityFactorsSelectChange(event: Event) {
    console.log(event);
    const selectElement = event.target as HTMLSelectElement;
    const ComplexityFactorsID = selectElement.value;
    this.dynamicData.PTP.Complexity.Overall = ComplexityFactorsID;
    console.log(this.dynamicData.PTP.Complexity.Overall);
  }
  */

  predictedICD10Codes: string[] = [];
  predictICD10() {
    const patientData = this.eForm.value;
    // Call the service to predict the ICD-10 codes
    this.aSrvc.predictICD10(patientData).subscribe(
      (codes: any) => { this.predictedICD10Codes = codes; },
      (error: any) => { console.error('Error predicting ICD-10 codes:', error); }
    );
  }
  confirmCode(code: string) {
    // Add logic to handle the confirmed code
    console.log('Confirmed code:', code);
    // Possibly add the code to a list of confirmed codes
  }
  predictJCodes(): void {
    const treatmentDetails = this.eForm.value; // Assuming this is your form data
    this.aSrvc.predictJCodes(treatmentDetails).subscribe({
      next: (codes: any) => {
        this.predictedICD10Codes = codes;
        // Handle UI logic to display codes for selection or confirmation
      },
      error: (error: any) => console.error('Error predicting ICD-10 codes:', error)
    });
  }
  /*
  onValueChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const id = target.id; //console.log('id:', id);
    const newValue = parseInt(target.value, 10);
    this.currentValue = newValue; // Assuming you want to display or use this value
    const parts = id.split('.'); this[parts[0]][parts[1]] = newValue; //console.log(parts);

    // Determine the class based on newValue
    const newClass = this.getClassForValue(newValue, parts[0]);
    // Find the sibling span to the input
    const siblingSpan = this.renderer.nextSibling(target);
    if (siblingSpan: any) {
      // Clear existing classes and add the new one
      this.renderer.removeClass(siblingSpan, 'Optimal');
      this.renderer.removeClass(siblingSpan, 'Moderate');
      this.renderer.removeClass(siblingSpan, 'Mild');
      this.renderer.removeClass(siblingSpan, 'Severe');
      this.renderer.removeClass(siblingSpan, 'Poor');
      this.renderer.removeClass(siblingSpan, 'Urgent');
      this.renderer.addClass(siblingSpan, newClass);
    }
  }
  */
  getClassForValue(value: number, Ax: string): string {
    if (Ax === "PHQ9") {
      if (value <= this.PHQ9Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PHQ9Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PHQ9Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PHQ9Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "GAD7") {
      if (value <= this.GAD7Cnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.GAD7Cnfg.mildMax) { return 'Mild'; }
      else if (value <= this.GAD7Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.GAD7Cnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WHO5") {
      if (value <= this.WHO5Cnfg.minimalMax) { return 'Urgent'; }
      else if (value <= this.WHO5Cnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.WHO5Cnfg.betterMax) { return 'Optimal'; }
    }
    if (Ax === "Physical") {
      if (value <= this.PhysicalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.PhysicalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.PhysicalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.PhysicalCnfg.severeMax) { return 'Urgent'; }
    }
    if (Ax === "WellBeing") {
      if (value <= this.FunctionalCnfg.minimalMax) { return 'Optimal'; }
      else if (value <= this.FunctionalCnfg.mildMax) { return 'Mild'; }
      else if (value <= this.FunctionalCnfg.moderateMax) { return 'Moderate'; }
      else if (value <= this.FunctionalCnfg.severeMax) { return 'Urgent'; }
    }
    return 'Urgent';
  }

  updateEForm() {
    this.isLoading = true;
    console.log("Update eForm.value: ", this.eForm.value);
    let udData: any = [];
    udData = this.uSrvc.replaceNullWithEmptyString(this.eForm.value);
    console.log("udData", udData);
    //JSON.stringify(udData)
    /*
    let rsp: any;
    this.aSrvc.encntrUD(this.encntr.id, udData.note).subscribe(
      (result) => { rsp = result; console.log(rsp); this.isLoading = false; },
      (error) => { this.errors = error.error; console.log(this.errors); },
      () => {
        if (rsp.status === "success") {
          this.encntr = rsp.encounter;
          this.toastr.success(rsp.message);
        }
      }
    )
    */
  };
  uploadEForm() {
  };

}
