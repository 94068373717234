<div class="container-fluid p-1"><mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <form id="eForm" class="row g-1 tab-content p-2" [formGroup]="pForm" (ngSubmit)="updatePForm()" *ngIf="patientRdy">
        <div class="col-md-12 text-end">
            <button title="Save Patient Demographics" type="submit" class="btn btn-success me-2"
                style="padding: .125rem;"><i class="bi bi-save"></i>Save</button>
        </div>
        <!-- Demographics -->
        <div formGroupName="demographics" class="row editGrpDiv">
            <div formGroupName="name" class="row editGrpDiv">
                <div class="col-md-4">
                    <label for="fName" class="form-label">First Name</label>
                    <input type="text" class="form-control" id="fName" formControlName="f">
                </div>
                <div class="col-md-4">
                    <label for="mName" class="form-label">Middle Name</label>
                    <input type="text" class="form-control" id="mName" formControlName="m">
                </div>
                <div class="col-md-4">
                    <label for="lName" class="form-label">Last Name</label>
                    <input type="text" class="form-control" id="lName" formControlName="l">
                </div>
            </div>
            <div formGroupName="dob" class="col-md-6 row editGrpDiv">
                <label class="form-label">Date of Birth:</label>
                <div class="col-md-4">
                    <label for="dobY" class="form-label">YYYY:</label>
                    <input type="text" class="form-control" id="dobY" formControlName="y">
                </div>
                <div class="col-md-4">
                    <label for="dobM" class="form-label">MM:</label>
                    <input type="text" class="form-control" id="dobM" formControlName="m">
                </div>
                <div class="col-md-4">
                    <label for="dobD" class="form-label">DD:</label>
                    <input type="text" class="form-control" id="dobD" formControlName="d">
                </div>
            </div>
            <div class="col-md-3">
                <label for="EthnicitySelect">Gender</label>
                <select class="form-select" id="EthnicitySelect" formControlName="gender">
                    <option value="">Select Gender </option>
                    <option [value]="gi_.Opt" *ngFor="let gi_ of cnfg.Gender_Identity_Options">{{ gi_.Opt }}
                    </option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="RaceSelect">Race</label>
                <select class="form-select" id="RaceSelect" formControlName="race">
                    <option value="">Select Race</option>
                    <option [value]="r_" *ngFor="let r_ of cnfg.raceOptions">{{ r_ }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="EthnicitySelect">Ethnicity</label>
                <select class="form-select" id="EthnicitySelect" formControlName="ethnicity">
                    <option value="">Select Ethnicity </option>
                    <option [value]="e_" *ngFor="let e_ of cnfg.ethnicityOptions">{{ e_ }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label for="eyeColor" class="form-label">Eye Color</label>
                <input type="text" class="form-control" id="eyeColor" name="eyeColor" formControlName="eColor">
            </div>
            <div class="col-md-3">
                <label for="hairColor" class="form-label">Hair Color</label>
                <input type="text" class="form-control" id="hairColor" name="hairColor" formControlName="hColor">
            </div>
            <!--div class="col-md-2"><app-converter></app-converter></div!-->
            <div class="col-md-3">
                <label for="heightFt" class="form-label">Height-Feet</label>
                <input type="text" class="form-control" id="heightFt" name="heightFt" formControlName="heightFt">
            </div>
            <div class="col-md-3">
                <label for="heightIn" class="form-label">Height-Inch</label>
                <input type="text" class="form-control" id="heightIn" name="heightIn" formControlName="heightIn">
            </div>
            <div class="col-md-3">
                <label for="weightLbs" class="form-label">Weight</label>
                <input type="text" class="form-control" id="weightLbs" name="weightLbs" formControlName="weightLbs">
            </div>
        </div>
        <!-- contact method -->
        <div formGroupName="comm_method" class="row editGrpDiv">
            <div class="col-md-6">
                <label for="hp" class="form-label">Home Phone</label><input type="phone" id="hp" class="form-control"
                    formControlName="hp">
            </div>
            <div class="col-md-6">
                <label for="wp" class="form-label">Work Phone</label><input type="phone" id="wp" class="form-control"
                    formControlName="wp">
            </div>
            <div class="col-md-6">
                <label for="cp" class="form-label">Cell Phone</label><input type="phone" id="cp" class="form-control"
                    formControlName="cp">
            </div>
            <div class="col-md-6">
                <label for="E" class="form-label">Email</label><input type="email" class="form-control" id="E" name="E"
                    formControlName="E">
            </div>
            <div class="col-md-12 row"><label>Preferred Method of Contact</label>
                <div class="btn-group" role="group">
                    <input class="btn-check" type="radio" name="pmoc" id="pmocCP" value="cp" formControlName="pmoc">
                    <label class="btn btn-outline-primary" for="pmocCP">Cell Phone</label>
                    <input class="btn-check" type="radio" name="pmoc" id="pmocE" value="e" formControlName="pmoc">
                    <label class="btn btn-outline-primary" for="pmocE">Email</label>
                    <input class="btn-check" type="radio" name="pmoc" id="pmocHP" value="hp" formControlName="pmoc">
                    <label class="btn btn-outline-primary" for="pmocHP">Home Phone</label>
                    <input class="btn-check" type="radio" name="pmoc" id="pmocWP" value="wp" formControlName="pmoc">
                    <label class="btn btn-outline-primary" for="pmocWP">Work Phone</label>
                </div>
            </div>
        </div>
        <!-- Address -->
        <div formGroupName="Adr" class="row editGrpDiv">
            <div class="col-md-4">
                <label for="A" class="form-label">Address</label>
                <input type="text" class="form-control" id="A" placeholder="1234 Main St" name="A" formControlName="A">
            </div>
            <div class="col-md-2">
                <label for="A2" class="form-label">Address 2</label>
                <input type="text" class="form-control" id="A2" name="A2" formControlName="A2">
            </div>
            <div class="col-md-2">
                <label for="C" class="form-label">City</label>
                <input type="text" class="form-control" id="C" name="C" formControlName="C">
            </div>
            <div class="col-md-2">
                <label for="S" class="form-label">State</label>
                <select id="S" class="form-select" name="S" formControlName="S">
                    <option selected>Choose State</option>
                    <option [value]="s_.abbrv" *ngFor="let s_ of cnfg.usStateOptions">{{ s_.name }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="Z" class="form-label">Zip</label>
                <input type="text" class="form-control" id="Z" name="Z" formControlName="Z">
            </div>
            <!-- Add more address fields -->
        </div>
    </form>
</div>