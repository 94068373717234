import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'; // For showing alerts
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as accessibility from 'highcharts/modules/accessibility';
import * as _ from 'lodash-es';
import * as Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
Highcharts3d(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);

import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr'; // Assume ngx-toastr is used for toasts
import moment from 'moment-timezone';

import { AuthService } from '../../../service/auth.service';
import { AppointmentMonitorService } from '../../../service/appointment-monitor.service';
import { SocketService } from '../../../service/socket.service';
import { UtilitiesService } from '../../../service/utilities.service';
import { DateCalculationsService } from '../../../service/date-calculations.service';
import { PatientDataService } from 'src/app/service/patient-data.service';
import { AppointmentDataService } from 'src/app/service/appointment-data.service';
import { PromTotalPipe } from 'src/app/thePipes/prom-total.pipe';
import { PatientDetailsPipe } from 'src/app/thePipes/patient-details.pipe';
//import { chart } from 'highcharts';
import { PatientFilterPopupComponent } from '../../../assetComponents/patient-filter-popup/patient-filter-popup.component';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-patients-page',
  templateUrl: './patients-page.component.html',
  styleUrls: ['./patients-page.component.css']
})
export class PatientsPageComponent implements OnInit, OnDestroy {
  patients$ = this.patientDataService.patients$;
  appointments$ = this.appointmentDataService.appointments$;
  private destroy$ = new Subject<void>();

  [x: string]: any;
  isLoading: boolean = true;
  errors: any = null;
  private onDestroy = new Subject<void>();
  private subscriptions = new Subscription();
  private encounterUpdated = false;
  showNote: boolean = false;
  cmpy: any; locs: any; loc: any; lID: any; dpts: any; dpt: any; dID: any; rptDepart: any;
  chrtCnfg: any = [];
  dateRange: any = "Today";
  stf: any;
  patients: any; patientsDB: any; patient: any; patient_id: any; encounter: any;
  filter: any = [];

  tltCnt: number = 0;
  SevereCnt: number = 0;
  ModerateCnt: number = 0;
  MildCnt: number = 0;
  MinimumCnt: number = 0;
  calculatedIntervals: any;
  dInterval: any;
  dateDisplay: any;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar, // Inject MatSnackBar for alerts
    private socketService: SocketService, // Your custom socket service
    private toastr: ToastrService, // Toastr service for notifications
    private cdRef: ChangeDetectorRef, // ChangeDetectorRef for manual change detection
    private router: Router,
    private aSrvc: AuthService,
    private uSrvc: UtilitiesService,
    private dSrvc: DateCalculationsService,
    private promTotal: PromTotalPipe,
    private patientDataService: PatientDataService,
    private appointmentDataService: AppointmentDataService,
    private pDetails: PatientDetailsPipe) {
    this.stf = this.uSrvc.getLS_item('stf'); console.log("stf", this.stf);
    if (this.stf === null) { /*console.log("redirect");this.router.navigate(['login/:id']);*/ }
    else {
      const showNote_: any = sessionStorage.getItem('showNote'); //console.log(showNote_);
      if (showNote_ === 'true') { this.showNote = true; } else { this.showNote = false; }
      this.cmpy = this.uSrvc.getLS_item('cmpy'); console.log("cmpy", this.cmpy);
      this.loc = this.uSrvc.getLS_item('loc');
      this.dpt = this.uSrvc.getLS_item('dpt'); //console.log("dpt", this.dpt);
    }
    //this.calculatedIntervals = this.dSrvc.calculateIntervals(new Date()); console.log(this.calculatedIntervals);
  }
  showNoteToggle($event: any) {
    this.showNote = !this.showNote; //console.log(this.showNote);
    sessionStorage.setItem('showNote', this.showNote ? 'true' : 'false');
  }
  sortPatients(sVw: any) {
    const pD_: any = this.patients;
    if (sVw === 'PHQ9') { this.patients = _.orderBy(pD_, ['phq9', 'LAPMT', 'dob'], ['desc', 'desc', 'desc']); }
    //else if(sVw==='GAD7'){this.patients=_.orderBy(pD_, ['gad7','LAPMT','dob'], ['desc','desc','desc']);}
    else if (sVw === 'dob') { this.patients = _.orderBy(pD_, ['dob'], ['desc']); }
    else if (sVw === 'Date') { this.patients = _.orderBy(pD_, ['LAPMT', 'phq9', 'dob'], ['desc', 'desc', 'desc']); }
  }

  chartConstructor = "chart";
  Highcharts: typeof Highcharts = Highcharts;
  //  chart: Highcharts.Chart | undefined;
  chart: any;
  highcharts = Highcharts;
  currentLabel: any = null; // Track the current label
  handleMouseOver(e: any) {
    if (this.currentLabel) { this.currentLabel.destroy(); } // Remove previous label
    const pIdnx = e.target.index;
    const chart = this.chart;
    var chart_Data = this.chart_Data;
    var patients_ = this.patientsDB;
    var router_ = this.router;
    var detailsPage = this.chrtCnfg.detailsPage;
    var chartTitle = this.chrtCnfg.Title;

    var pD = patients_[pIdnx]; console.log(pD);
    var data = pD['chrtD']; //console.log('pD', pD, 'data', data);
    var trend_: number = data[data.length - 1] - data[data.length - 2];

    var trendPic = '&#x2192;'; if (trend_ > 0) { trendPic = '&#x2197;'; } else if (trend_ < 0) { trendPic = '&#x2198;'; }
    var Color_ = '#000000'; var toolColorRGBA = 'rgba(0,255,0)';

    var pV = data[0][2]; //console.log(pVal);

    if (pV >= this.chrtCnfg.minimalMin && pV <= this.chrtCnfg.minimalMax) { Color_ = '#000000'; toolColorRGBA = 'rgba(0,255,0)'; }
    else if (pV >= this.chrtCnfg.mildMin && pV <= this.chrtCnfg.mildMax) { Color_ = '#000000'; toolColorRGBA = 'rgba(255,255,0)'; }
    else if (pV >= this.chrtCnfg.moderateMin && pV <= this.chrtCnfg.moderateMax) { Color_ = '#000000'; toolColorRGBA = 'rgba(255,165,0)'; }
    else if (pV >= this.chrtCnfg.severeMin && pV <= this.chrtCnfg.severeMax) { Color_ = '#ffffff'; toolColorRGBA = 'rgba(255,0,0)'; }
    this.currentLabel = chart.renderer.label(pD.name + ', ' + pD.dob + '|' + pD.Age
      + '<br>ID: ' + pD.patient_id
      + '<br>Results: ' + pD['chrtD'][0][2] + ' | ' + pD['chrtD'][0][0] + ' | ' + trendPic, 50, 0)
      .css({ color: Color_ }).css({ fontSize: '1.125rem' }).css({ fontWeight: '600' }).css({ wordWrap: 'normal' })
      .attr({ fill: toolColorRGBA, minWidth: 500, maxWidth: 500, wordWrap: 500, padding: 8, r: 5, zIndex: 6 }).add();
  }
  chart_Date: any = [];
  chart_Data: any = [];
  mActive = false; pgX = 0; pgY = 0; alpha_ = 0; beta_ = 0;
  @HostListener('mousemove', ['$event'])
  documentMoveEvent($event: MouseEvent) {
    if (this.mActive) {//console.log('Move pgX:',$event.pageX,`pgY:`, $event.pageY);
      // @ts-ignore
      var alpha = this.chart.options.chart.options3d.alpha, beta = this.chart.options.chart.options3d.beta,
        sensitivity = 3; // lower is more sensitive
      // @ts-ignore
      this.beta_ = beta + (this.pgX - $event.pageX) / sensitivity;
      // @ts-ignore
      this.chart.options.chart.options3d.beta = this.beta_;
      // @ts-ignore
      this.alpha_ = alpha + ($event.pageY - this.pgY) / sensitivity;
      this.pgX = $event.pageX; this.pgY = $event.pageY;
      // @ts-ignore
      this.chart.options.chart.options3d.alpha = this.alpha_;
      // @ts-ignore
      this.chartUpdate();
    }
  }
  @HostListener('mousedown', ['$event'])
  documentDownEvent($event: MouseEvent) { this.mActive = true; this.pgX = $event.pageX; this.pgY = $event.pageY; }
  @HostListener('mouseup', ['$event'])
  documentUpEvent(_$event: MouseEvent) { this.mActive = false; }
  showPatientIndex(item: any) { console.log(item); }
  chartUpdate() {
    const chart_Data = this.chart_Data;
    const patients_ = this.patientsDB;
    const router_ = this.router;
    const detailsPage = this.chrtCnfg.detailsPage;
    const chartTitle = this.chrtCnfg.Title;

    if (!this.chrtCnfg || !this.chrtCnfg.xMin || !this.chrtCnfg.xMax || !this.chrtCnfg.xTitle) {
      console.error('Chart configuration is missing xAxis settings.');
      return;
    }
      // @ts-ignore
    this.chart = Highcharts.chart('container', {
      accessibility: {enabled: false},
      chart: {
        renderTo: 'container',
        type: 'scatter3d',
        margin: 50,
        animation: false,

        options3d: {
          enabled: true,
          drag: { enabled: true, flipAxes: true, snap: 15, animateSnap: true },
          alpha: this.alpha_,
          beta: this.beta_,
          depth: 300,
          viewDistance: 5,
          fitToPlot: false,
          frame: { bottom: { size: 1, color: 'rgba(0,0,0,0.02)' }, back: { size: 1, color: 'rgba(0,0,0,0.04)' }, side: { size: 1, color: 'rgba(0,0,0,0.06)' } }
        }
      },
      title: { text: chartTitle },
      //tooltip: { outside: true, useHTML: true },
      tooltip: { enabled: false },// Disabling the tooltip
      plotOptions: {
        series: {
          turboThreshold: 5000, width: 200, height: 50, depth: 10, cursor: 'pointer', allowPointSelect: true,
          point: {
            events:
            {
              mouseOver: this.handleMouseOver.bind(this),
              click: function () { router_.navigate([detailsPage + patients_[this.index].patient_id]); },
            }
          },
          //states: {hover: {enabled: false /* Disabling the hover state*/}}
        }
      },
      xAxis: {
        min: this.chrtCnfg.xMin,
        max: this.chrtCnfg.xMax,
        title: { text: this.chrtCnfg.xTitle },
        gridLineWidth: 1
      },
      yAxis: {
          min: this.chrtCnfg.yMin, max: this.chrtCnfg.yMax, title: { text: this.chrtCnfg.yTitle },
        plotBands: [
          { from: this.chrtCnfg.minimalMin, to: this.chrtCnfg.minimalMax, color: 'rgba(0,255,0,0.25)', label: { text: this.chrtCnfg.minimalLBL + ": " + this.MinimumCnt + " Patients", style: { color: '#000000' } } },
          { from: this.chrtCnfg.mildMin, to: this.chrtCnfg.mildMax, color: 'rgba(255,255,0,0.25)', label: { text: this.chrtCnfg.mildLBL + ": " + this.MildCnt + " Patients", style: { color: '#000000' } } },
          { from: this.chrtCnfg.moderateMin, to: this.chrtCnfg.moderateMax, color: 'rgba(255,165,0,0.25)', label: { text: this.chrtCnfg.moderateLBL + ": " + this.ModerateCnt + " Patients", style: { color: '#000000' } } },
          { from: this.chrtCnfg.severeMin, to: this.chrtCnfg.severeMax, color: 'rgba(255,0,0,0.25)', label: { text: this.chrtCnfg.severeLBL + ": " + this.SevereCnt + " Patients", style: { color: '#000000' } } }]
      },
      //zAxis: { min: this.chrtCnfg.zMin, max: this.chrtCnfg.zMax, title: { text: this.chrtCnfg.zTitle }, showFirstLabel: true },
      zAxis: {
        min: this.chrtCnfg.zMin,
        max: this.chrtCnfg.zMax,
        title: { text: this.chrtCnfg.zTitle }
      },
        legend: { enabled: false },
      series: [
        {
          name: this.chrtCnfg['Data-LBL'],
          colorByPoint: true,
          accessibility: { exposeAsGroupOnly: true },
          states: { hover: { color: '#BADA55' } },
          dataLabels: { enabled: true, format: '{point.name}' },
          data: chart_Data
        }
      ]
    });
    this.isLoading = false;
  }
  showDepartment(item: object) { console.log(item); }
  findDateRange(value: string): string | null {
    const option = this.cmpy.Cnfg.dateRange.find((option: { value: string; }) => option.value === value); //console.log("option:", option);
    this.calculatedIntervals = this.dSrvc.calculateIntervals(new Date());
    this.dInterval = this.calculatedIntervals[option.value]; //console.log(this.dInterval);
    if (this.dInterval.start === this.dInterval.end) { this.dateDisplay = this.dInterval.end; }
    else { this.dateDisplay = this.dInterval.end + ' to ' + this.dInterval.start; }
    return option ? option.label : null;
  }

  ngOnInit(): void {
    this.isLoading = true; //console.log(sessionStorage);
    const sFilter: any = sessionStorage.getItem('filter');
    if (sFilter !== undefined && sFilter !== null) { this.filter = JSON.parse(sFilter); }
    else {
      this.filter = this.cmpy.Cnfg.promDashboardFilter;
      this.filter.Date = moment().tz("America/New_York").format().split('T')[0];
      this.filter.minimum = true;
      this.filter.mild = true;
      this.filter.moderate = true;
      this.filter.severe = true;
      this.dateRange = this.findDateRange(this.filter.dateRange);
    } //console.log(this.filter);

    const lID = this.route.snapshot.paramMap.get('param1');
    const dID = this.route.snapshot.paramMap.get('param2'); console.log(this.lID, this.dID);
    if (lID !== null) { this.lID = lID; this.dID = dID; }
    else {
      this.lID = sessionStorage.getItem('currentLID');
      this.dID = sessionStorage.getItem('currentDID'); }
    sessionStorage.setItem('currentLID', this.lID);
    sessionStorage.setItem('currentDID', this.dID);
    console.log(this.lID, this.dID);

    /*
    const tID: any = this.route.snapshot.paramMap.get('id');//console.log(tID);
    if (tID !== null) { this.lID = this.uSrvc.getLid(tID); this.dID = this.uSrvc.getDid(tID); this.patient_id = this.uSrvc.getPid(tID); }
    else { this.lID = sessionStorage.getItem('currentLID'); this.dID = sessionStorage.getItem('currentDID'); }
    sessionStorage.setItem('currentLID', this.lID); sessionStorage.setItem('currentDID', this.dID);
    */

    this.chrtCnfg = this.cmpy.Cnfg[this.cmpy.Cnfg.initQ + "Cnfg"]; //console.log(this.chrtCnfg);
    this.dateRange = this.findDateRange(this.filter.dateRange);
    this.aSrvc.GetPatientsDpt(this.lID, this.dID).subscribe(
      (result) => { this.patients = result; },
      (error) => { this.errors = error.error; },
      () => {
        console.log("the patients:", this.patients);
        this.patientsDB = []; this.chart_Date = []; this.chart_Data = [];
        this.tltCnt = 0; this.SevereCnt = 0; this.ModerateCnt = 0; this.MildCnt = 0; this.MinimumCnt = 0;
        let vP: boolean = false; let age_: any = ""; let scr_: any = ""; let pA_: any = "";
        this.patients = _.orderBy(this.patients, ['encounter.eDate'], ['desc']);
        for (let p = 0; p < this.patients.length; p++) {
          let dob_ = this.uSrvc.convertDateIfValid(this.patients[p].dob);
          this.patients[p].dob = dob_; this.patients[p].demographics.dob.full = dob_;
          let pDemo: any = this.pDetails.transform(this.patients[p].demographics); //console.log(pDemo);
          this.patients[p].Age = pDemo[1];
          const pD_: any = this.patients[p];
          let pE_ = pD_.encounter;//console.log("pE_:", pE_, pE_.prom);
          const [lo, hi] = this.filter.ageRange.split('-');
          vP = false;
          let vAge = false; if (pD_.Age >= lo && pD_.Age <= hi) { vAge = true; } if (!vAge) { vAge = true; }
          let vGndr = false; if (pD_.demographics.gender === '' || pD_.demographics.gender === this.filter.gender) { vGndr = true; } if (!vGndr) { vGndr = true; }

          let key = 'PHQ9';
          if (vAge && vGndr && pE_.promSummary !== null && key in pE_.promSummary) {
            //console.log("pE_.promKeys:", pE_.promKeys,"pE_.promKeys:", pE_.promKeys);
            let pSm_: any = pE_.promSummary[key];
            if ("SBQR" in pE_.promKeys) { this.patients[p].scr_ = pSm_.sumR + 15; } else { this.patients[p].scr_ = pSm_.sumR; }
            if (this.filter.severe && this.patients[p].scr_ >= 15) { vP = true; this.SevereCnt++; }
            else if (this.filter.moderate && this.patients[p].scr_ >= 10 && scr_ <= 14) { vP = true; this.ModerateCnt++; }
            else if (this.filter.mild && this.patients[p].scr_ >= 5 && scr_ <= 9) { vP = true; this.MildCnt++; }
            else if (this.filter.minimum && this.patients[p].scr_ <= 4) { vP = true; this.MinimumCnt++; }
            this.patients[p].LAPMT = pE_.eDate;
            this.patients[p].pA_ = pSm_.nonZeroRCount;
            this.patients[p].T_ = pSm_.sumS;
            let pEData_: Object = [pE_.eDate, pD_.Age, this.patients[p].scr_, pSm_.sumS]; //console.log(pEData_);
            this.patients[p]['chrtD'] = [pEData_, pE_.note.iIBHAssessment];
            this.patientsDB.push(this.patients[p]);
            this.chart_Date.push(pE_.eDate);
            this.chart_Data.push([pD_.Age, this.patients[p].scr_, this.patients[p].pA_]);
            //console.log("promSummary_ prom:", this.patients[p]);
          }
          else {
            if (vAge && vGndr && (pE_.prom !== null || pE_.prom !== "{}")) {
              //pE_.prom=this.uSrvc.convertStringToJson(pE_.prom);
              let tlt_: any = this.promTotal.transform(pE_.prom.PHQ9, 'pipeFilter');
              scr_ = this.uSrvc.addToScoreIfKeyExists(pE_.promKeys, "SBQR", tlt_[0], 15);
              if (this.filter.severe && scr_ >= 15) { vP = true; this.SevereCnt++; }
              else if (this.filter.moderate && scr_ >= 10 && scr_ <= 14) { vP = true; this.ModerateCnt++; }
              else if (this.filter.mild && scr_ >= 5 && scr_ <= 9) { vP = true; this.MildCnt++; }
              else if (this.filter.minimum && scr_ <= 4) { vP = true; this.MinimumCnt++; }
              if (vP) {//console.log("pD_:", pD_);
                this.tltCnt++;
                this.patients[p].LAPMT = pE_.eDate;
                this.patients[p].scr_ = scr_;
                this.patients[p].pA_ = tlt_[1];
                let pEData_: Object = [pE_.eDate, pD_.Age, scr_, tlt_[1]]; //console.log(pEData_);
                this.patients[p]['chrtD'] = [pEData_, pE_.note.iIBHAssessment];
                this.patientsDB.push(this.patients[p]);
                this.chart_Date.push(pE_.eDate);
                this.chart_Data.push([pD_.Age, scr_, pA_]);
              }
            }
          }
        }
        //this.patientsDB = _.orderBy(this.patientsDB, ['encounter.eDate', 'Age'], ['desc', 'desc']);
        this.patientDataService.updatePatientsData(this.patientsDB); console.log("this.patients$:", this.patients$);
        this.listenToEncounterUpdate();
        this.chartUpdate();
      }
    );
    //this.listenToSocketEvents();
    //this.subscribeToAppointmentUpdates();
  }
  openFilterPopup(): void {
    const dialogRef = this.dialog.open(PatientFilterPopupComponent, { width: '30rem', data: { filter: this.filter }, });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result !== null) {
        this.filter = result; sessionStorage.setItem('filter', JSON.stringify(this.filter)); console.log('this.filter:', this.filter);
        this.dateRange = this.findDateRange(this.filter.dateRange); console.log(this.dateRange);
        this.patientsDB = []; this.chart_Data = [];
        this.tltCnt = 0; this.SevereCnt = 0; this.ModerateCnt = 0; this.MildCnt = 0; this.MinimumCnt = 0;
        let vP: boolean = false; let age_: any = ""; let scr_: any = ""; let pA_: any = "";
        this.patients = _.orderBy(this.patients, ['encounter.eDate'], ['desc']);
        for (let p = 0; p < this.patients.length; p++) {
          const pD_: any = this.patients[p]; let pE_ = pD_.encounter; //console.log("pD_:", pD_);console.log("pE_:", pE_, pE_.prom);
          const [lo, hi] = this.filter.ageRange.split('-');
          vP = false;
          let vAge = false; if (pD_.Age >= lo && pD_.Age <= hi) { vAge = true; } if (!vAge) { vAge = true; }
          let vGndr = false; if (pD_.demographics.gender === '' || pD_.demographics.gender === this.filter.gender) { vGndr = true; } if (!vGndr) { vGndr = true; }
          if (vAge && vGndr && (pE_.prom !== null || pE_.prom !== "{}")) {
            age_ = pD_.Age;
            scr_ = pD_.scr_;
            pA_ = pD_.pA_;
            this.tltCnt++;
            if (this.filter.severe && scr_ >= 15) { vP = true; this.SevereCnt++; }
            else if (this.filter.moderate && scr_ >= 10 && scr_ <= 14) { vP = true; this.ModerateCnt++; }
            else if (this.filter.mild && scr_ >= 5 && scr_ <= 9) { vP = true; this.MildCnt++; }
            else if (this.filter.minimum && scr_ <= 4) { vP = true; this.MinimumCnt++; }
            if (vP) {
              this.patientsDB.push(pD_);
              this.chart_Date.push(pE_.eDate);
              this.chart_Data.push([age_, scr_, pA_]);
            }
          }
        }
        //this.patientsDB = _.orderBy(this.patientsDB, ['encounter.eDate', 'Age'], ['desc', 'desc']);
        this.patientDataService.updatePatientsData(this.patientsDB);
        this.chartUpdate(); console.log("the patients$:", this.patients$);
      }
    });
  }

  /**************************** * /
  private listenToPhq9SuicideAlertEvent(): void {
    // Ensures the socket is connected before subscribing to events
    this.socketService.connect();
    // Subscribes to the Phq9SuicideAlertEvent event from the SocketService
    const phq9SuicideAlertSubscription = this.socketService.subscribeToPhq9SuicideAlertEvent()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (data: any) => {
          if (this.encounterUpdated) {
            this.handlephq9SuicideAlert(data);
            this.encounterUpdated = false;
          }
        },
        error: (error: any) => console.error('Error receiving Phq9SuicideAlertEvent:', error)
      });
    this.subscriptions.add(phq9SuicideAlertSubscription);
  }
  private handlephq9SuicideAlert(data: any): void {
    console.log(data);
    const index = this.patientsDB.findIndex((patient: { id: number; }) => patient.id === parseInt(data.patient_id));
    console.log("index:",index,"this.patientsDB:",this.patientsDB);
    const phq9PatientsDB = this.patientsDB[index];
    console.log("phq9PatientsDB:",phq9PatientsDB);
    this.toastr.error(`Patient alarm: ${phq9PatientsDB.name}`, '', {
      timeOut: 0, // Disables auto-dismiss
      extendedTimeOut: 0, // Disables auto-dismiss on hover
      tapToDismiss: true, // Requires the user to click the toastr to dismiss it
      closeButton: true, // Adds a close button to the toastr (optional, for user convenience)
      progressBar: false, // Disables the progress bar
      toastClass: 'ngx-toastr red-background' // Adds the custom class for styling
    });
  }
  /**************************** */
  private listenToEncounterUpdate(): void {
    // Ensures the socket is connected before subscribing to events
    this.socketService.connect();
    // Subscribes to the AppointmentStatusChanged event from the SocketService
    const encounterUpdateSubscription = this.socketService.subscribeToEncounterUpdate()
      //.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          //console.log('Encounter Update:', data);
          this.handleEncounterUpdate(data);
        },
        error: (error: any) => console.error('Error receiving EncounterUpdate:', error)
      });
    this.subscriptions.add(encounterUpdateSubscription);
    /*const encounterUpdateSubscription1 = this.socketService.subscribeToEncounterUpdate()
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: (data: any) => {
          this.handleEncounterUpdate(data);
        },
        error: (error: any) => console.error('Error receiving EncounterUpdate:', error)
      });
    */
  }
  private handleEncounterUpdate(data: any): void {
    //console.log("this.patientsDB:", this.patientsDB, "this.patients$:", this.patients$);
    const recentlyUpdated = moment().diff(moment(data.updatedAt), 'seconds') <= 5;
    const index = this.patientsDB.findIndex((patient: { id: number; }) => patient.id === parseInt(data.patient_id));
    console.log("handleEncounterUpdate data.patient_id:", data.patient_id, "index:", index);
    this.chart_Data = []; this.chart_Date = [];
    if (index !== -1) {
      const orgP = this.patientsDB[index];console.log("Orginal Patient Data:", orgP);
      const histroy_O = this.patientsDB[index].encounter.history;
      this.patientsDB[index].encounter = { ...this.patientsDB[index].encounter, ...data };
      this.patientsDB[index].encounter.history = histroy_O;
      //this.markForCheck();
      this.patientsDB = _.orderBy(this.patientsDB, ['encounter.eDate'], ['desc']);
      let udPatientsDB = this.patientsDB;
      if (recentlyUpdated) {
        if (this.patientsDB[index].encounter.eDate !== orgP.encounter.eDate) {
          console.log("Updated Patient:", this.patientsDB[index]);
          this.toastr.success('Patient: ' + this.patientsDB[index].name + ' | DOB: ' + this.patientsDB[index].dob + ' has been updated.');
        }
        // You can also add specific logic here to highlight the updated patient in your component
        this.patientsDB = []; this.chart_Data = []; this.chart_Date = [];
        this.tltCnt = 0; this.SevereCnt = 0; this.ModerateCnt = 0; this.MildCnt = 0; this.MinimumCnt = 0;
        let vP: boolean = false; let age_: any = ""; let scr_: any = ""; let pA_: any = "";
        for (let p = 0; p < udPatientsDB.length; p++) {
          //let dob_ = this.uSrvc.convertDateIfValid(this.patients[p].dob);
          //this.patients[p].dob = dob_; this.patients[p].demographics.dob.full = dob_;
          //let pDemo: any = this.pDetails.transform(this.patients[p].demographics); //console.log(pDemo);
          //this.patients[p].Age = pDemo[1];
          //const pD_: any = this.patients[p]; console.log(pD_);
          const pD_: any = udPatientsDB[p]; //console.log(pD_);
          let pE_ = pD_.encounter;//console.log("pE_:", pE_, pE_.prom);
          const [lo, hi] = this.filter.ageRange.split('-');
          vP = false;
          let vAge = false; if (pD_.Age >= lo && pD_.Age <= hi) { vAge = true; } if (!vAge) { vAge = true; }
          let vGndr = false; if (pD_.demographics.gender === '' || pD_.demographics.gender === this.filter.gender) { vGndr = true; } if (!vGndr) { vGndr = true; }
          if (vAge && vGndr && (pE_.prom !== null || pE_.prom !== "{}")) {
            //pE_.prom=this.uSrvc.convertStringToJson(pE_.prom);
            let tlt_: any = this.promTotal.transform(pE_.prom.PHQ9, 'pipeFilter');
            scr_ = this.uSrvc.addToScoreIfKeyExists(pE_.promKeys, "SBQR", tlt_[0], 15);
            if (this.filter.severe && scr_ >= 15) { vP = true; this.SevereCnt++; }
            else if (this.filter.moderate && scr_ >= 10 && scr_ <= 14) { vP = true; this.ModerateCnt++; }
            else if (this.filter.mild && scr_ >= 5 && scr_ <= 9) { vP = true; this.MildCnt++; }
            else if (this.filter.minimum && scr_ <= 4) { vP = true; this.MinimumCnt++; }
            if (vP) {//console.log("pD_:", pD_);
              this.tltCnt++;
              udPatientsDB[p].LAPMT = pE_.eDate;
              udPatientsDB[p].scr_ = scr_; udPatientsDB[p].encounter.prom.PHQ9.tlt_ = scr_;
              udPatientsDB[p].s = tlt_[2]; udPatientsDB[p].encounter.prom.PHQ9.s = tlt_[2];
              udPatientsDB[p].pA_ = tlt_[1]; udPatientsDB[p].encounter.prom.PHQ9.pA_ = tlt_[1];
              let pEData_: Object = [pE_.eDate, pD_.Age, scr_, tlt_[1]]; //console.log(pEData_);
              udPatientsDB[p]['chrtD'] = [pEData_, pE_.note.iIBHAssessment];
              this.patientsDB.push(udPatientsDB[p]);
              this.chart_Date.push(pE_.eDate);
              this.chart_Data.push([pD_.Age, scr_, pA_]);
            }
          }
        }
        this.patientDataService.updatePatientsData(this.patientsDB);
        this.chartUpdate();
        this.encounterUpdated = true;
      }
    }
    else {
      // If the patient is not found, you might want to refresh your patient's list or handle accordingly
      console.log("Patient not listed:");
      this.aSrvc.GetPatientsDpt(this.lID, this.dID).subscribe(
        (result) => { this.patients = result; },
        (error) => { this.errors = error.error; },
        () => {
          this.patientsDB = [];
          this.tltCnt = 0; this.SevereCnt = 0; this.ModerateCnt = 0; this.MildCnt = 0; this.MinimumCnt = 0;
          let vP: boolean = false; let age_: any = ""; let scr_: any = ""; let pA_: any = "";
          this.patients = _.orderBy(this.patients, ['encounter.eDate'], ['desc']);
          for (let p = 0; p < this.patients.length; p++) {
            let dob_ = this.uSrvc.convertDateIfValid(this.patients[p].dob);
            this.patients[p].dob = dob_; this.patients[p].demographics.dob.full = dob_;
            let pDemo: any = this.pDetails.transform(this.patients[p].demographics); //console.log(pDemo);
            this.patients[p].Age = pDemo[1];
            const pD_: any = this.patients[p]; console.log(pD_);
            let pE_ = pD_.encounter;//console.log("pE_:", pE_, pE_.prom);
            const [lo, hi] = this.filter.ageRange.split('-');
            vP = false;
            let vAge = false; if (pD_.Age >= lo && pD_.Age <= hi) { vAge = true; } if (!vAge) { vAge = true; }
            let vGndr = false; if (pD_.demographics.gender === '' || pD_.demographics.gender === this.filter.gender) { vGndr = true; } if (!vGndr) { vGndr = true; }
            if (vAge && vGndr && (pE_.prom !== null || pE_.prom !== "{}")) {
              //pE_.prom=this.uSrvc.convertStringToJson(pE_.prom);
              let tlt_: any = this.promTotal.transform(pE_.prom.PHQ9, 'pipeFilter');
              scr_ = this.uSrvc.addToScoreIfKeyExists(pE_.promKeys, "SBQR", tlt_[0], 15);
              if (this.filter.severe && scr_ >= 15) { vP = true; this.SevereCnt++; }
              else if (this.filter.moderate && scr_ >= 10 && scr_ <= 14) { vP = true; this.ModerateCnt++; }
              else if (this.filter.mild && scr_ >= 5 && scr_ <= 9) { vP = true; this.MildCnt++; }
              else if (this.filter.minimum && scr_ <= 4) { vP = true; this.MinimumCnt++; }
              if (vP) {//console.log("pD_:", pD_);
                this.tltCnt++;
                this.patients[p].LAPMT = pE_.eDate;
                this.patients[p].scr_ = scr_;
                this.patients[p].pA_ = tlt_[1];
                let pEData_: Object = [pE_.eDate, pD_.Age, scr_, tlt_[1]]; //console.log(pEData_);
                this.patients[p]['chrtD'] = [pEData_, pE_.note.iIBHAssessment];
                this.patientsDB.push(this.patients[p]);
                this.chart_Date.push(pE_.eDate);
                this.chart_Data.push([pD_.Age, scr_, pA_]);
              }
            }
          }
          this.cdRef.detectChanges();
          this.toastr.success('A patient has been added.');
          //this.patientsDB = _.orderBy(this.patientsDB, ['encounter.eDate', 'Age'], ['desc', 'desc']);
          this.patientDataService.updatePatientsData(this.patientsDB);
          this.chartUpdate();
        }
      );
    }
  }
  private markForCheck(): void {
    this.cdRef.markForCheck(); // Trigger change detection manually if using OnPush strategy
  }
  ngOnDestroy() {
    if (this.chart) { this.chart.destroy(); }
    this.onDestroy.next();
    this.onDestroy.complete();
    this.destroy$.next();
    this.destroy$.complete();
    //  this.subscriptions.unsubscribe(); // Properly unsubscribe to avoid memory leaks
    //this.socketService.disconnect();
  }
}
